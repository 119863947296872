/* eslint-disable max-len */
import React from 'react';

function SvgBlaulicht(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M10.798 23.648v2.332H5.486v-2.657h5.312v.325zm0 17.544h26.576v-2.659H10.798v2.659zM37.374 25.98h5.314v-2.657h-5.314v2.657zm-23.917 9.896h2.657V23.651a6.384 6.384 0 016.375-6.375h3.187a6.384 6.384 0 016.375 6.375v12.225h2.657V23.651a9.062 9.062 0 00-9.02-9.026H22.5a9.062 9.062 0 00-9.036 9.036l-.007 12.215zM25.416 6.643h-2.66v5.316h2.66V6.643zm-3.323 25.91a1.993 1.993 0 103.986 0 1.993 1.993 0 00-3.986.003m13.215-15.527l3.829-3.829-1.875-1.875-3.7 3.694a11.827 11.827 0 011.75 2.008m-9.893 4.234h-2.662v6.643h2.659l.003-6.643zM14.61 15.023l-3.774-3.773-1.875 1.875 3.9 3.906a11.827 11.827 0 011.749-2.008"
          fill="#1D1D1B"
          fillRule="nonzero"
        />
        <path d="M0 0h48v48H0z" />
      </g>
    </svg>
  );
}

export default SvgBlaulicht;
