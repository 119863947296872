import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import styles from './styles.jss';

const ValidationMessage = ({
  classes,
  message,
}, { t }) => (
  <p className={classes.validationMessage}>
    {t(message)}
  </p>
);

ValidationMessage.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  message: PropTypes.string,
};

ValidationMessage.defaultProps = {
  classes: {},
  message: '',
};

ValidationMessage.contextTypes = {
  t: PropTypes.func,
};

export { ValidationMessage as ValidationMessagePlain };
export default injectSheet(styles)(ValidationMessage);
