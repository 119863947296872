/* eslint-disable max-len */
import React from 'react';

const SvgHundHundehalter = props => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <g fill="currentColor" fillRule="nonzero">
      <path d="M43.938 7.073c-.338-.106-.79-.194-1.316-.297-.627-.121-2.037-.398-2.517-.66-.083-.254-.182-.659-.272-1.015-.417-1.662-.846-3.38-2.296-3.695a19.08 19.08 0 00-7.834 0c-2.835.587-5.042 2.959-5.742 6.194-.079.425-.133.855-.162 1.286-.22 2.244-.643 6.912-4.252 9.58-4.733 3.5-8.349 7.216-9.428 9.7-2.357 5.387-.274 9.831-.184 10.018a1.449 1.449 0 102.605-1.273c-.015-.033-1.6-3.415.22-7.596.699-1.597 3.659-4.95 8.504-8.532 4.648-3.437 5.166-8.978 5.414-11.649.035-.384.072-.78.105-.934.467-2.134 1.778-3.62 3.508-3.978 2.063-.432 4.19-.46 6.262-.08.194.538.355 1.087.482 1.645.12.554.283 1.099.487 1.628.579 1.32 2.25 1.746 4.55 2.198.3.06.607.12.822.17.195.402.318 1.481.112 2.198-.27.947-.505 1.222-1.535 1.222h-4.705a17.98 17.98 0 00-1.064-1.554c-.219-.315-.469-.64-.693-.97-.954-1.405-1.595-3-1.879-4.677a3.21 3.21 0 00-.046-.44l-2.835.39c-.003.05-.003.1 0 .151.312 2.22 1.12 4.34 2.362 6.205.254.376.51.723.758 1.06.67.91 1.246 1.694 1.316 2.4.083.879-.246 1.468-1.004 1.826-1.004.475-2.237.27-2.717-.209-.537-.54-.476-2.66-.085-4.224a1.451 1.451 0 00-.335-1.477 1.442 1.442 0 00-2.459.771c-.206.818-1.135 4.996.844 6.98.862.865 2.173 1.32 3.526 1.32a5.768 5.768 0 002.465-.543 4.504 4.504 0 002.668-4.108h3.876c3.373 0 4.085-2.5 4.32-3.321.482-1.69.276-5.044-1.846-5.71z" />
      <path d="M38.987 38.584c-1.049-.74-2.741-1-4.008-1.084l.243-7.205a11.252 11.252 0 002.12-2.886c1.19-2.298.821-4.963.774-5.26a1.467 1.467 0 00-2.894.444c0 .02.283 1.998-.481 3.47a7.785 7.785 0 01-1.841 2.39 1.46 1.46 0 00-.584 1.11l-.314 9.307c-.014.404.14.796.425 1.082.286.287.678.442 1.083.43 1.19-.035 3.17.15 3.785.582.795.562.85 1.448.75 2.11h-4.578c-.81 0-1.467.655-1.467 1.463S32.657 46 33.467 46h5.678a1.467 1.467 0 001.336-.873c.646-1.44 1.16-4.665-1.494-6.543zM27.25 38.047c-.576-.292-1.46-.457-2.327-.547 1.121-2.262 1.193-3.658 1.193-3.974 0-2.35-.733-4.154-2.173-5.361-2.495-2.087-6.141-1.413-6.302-1.385a1.446 1.446 0 00-1.09 1.68 1.433 1.433 0 001.624 1.16c.024 0 2.489-.44 3.936.77.767.643 1.14 1.668 1.14 3.136 0 1.062-1.142 3.281-1.955 4.517a1.456 1.456 0 00-.066 1.488c.253.47.742.76 1.272.754 1.428-.013 3.075.15 3.455.345 1.054.535 1.217 1.648 1.17 2.473H16.121c-4.027 0-6.817-1.025-8.288-3.044-1.571-2.155-1.684-5.611-.333-9.998.172-.562.448-1.253.74-1.975.98-2.46 2.198-5.524 1.333-8.032-.471-1.363-1.608-2.38-2.825-3.11a9.212 9.212 0 012.087 0c2.746.327 4.483 2.893 4.515 2.94.081.126.182.237.298.33l2.173-1.775c-.026-.049-.052-.097-.083-.143-.097-.154-2.464-3.73-6.57-4.222a13.052 13.052 0 00-7.446 1.42 1.449 1.449 0 00.393 2.664c1.335.307 4.253 1.404 4.75 2.853.514 1.483-.484 3.987-1.286 6.002-.313.785-.61 1.536-.817 2.194-1.656 5.377-1.401 9.606.76 12.573C7.568 44.58 11.13 46 16.119 46H28.33c.625 0 1.178-.41 1.365-1.012.537-1.705.704-5.341-2.445-6.941z" />
    </g>
  </svg>
);

export default SvgHundHundehalter;
