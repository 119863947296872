import {
  isString,
  isArray,
  isPlainObject,
  isEmpty,
} from 'lodash';
import validatorLibrary from './validators';
import { isDefined } from './utils';

/* Single strategy is used by simplest validation declaration: validators: 'validator' */
export const singleStrategy = (value, validationSchema, config) => {
  const validationFn = validatorLibrary[validationSchema];
  return [validationFn(config)(value)].filter(isDefined);
};

/* Multiple strategy is used by listed validators: validators: [validator, validator, validator] */
export const multipleStrategy = (value, validationSchema, config) => {
  const result = validationSchema
    .map(validator => {
      let validationFn;
      let validatorConfig = { ...config };

      /* if validator is defined as an object, it has to have a name */
      if (isPlainObject(validator) && validator.name) {
        validationFn = validatorLibrary[validator.name];
        validatorConfig = {
          ...config,
          ...validator,
        };
      }

      if (isString(validator)) {
        validationFn = validatorLibrary[validator];
      }
      return validationFn ? validationFn(validatorConfig)(value) : undefined;
    });

  return result.filter(isDefined);
};

/* Schema strategy is used by schema validators:
  validators: {
    foo: [validator, {name: validator, option, option}],
    foo2: 'validator'
  }
*/
export const schemaStrategy = (value, validationSchema, context) => {
  const fields = Object.keys(validationSchema);
  const partialResult = {};
  return fields
    .map(key => {
      if (isString(validationSchema[key])) {
        const singleValidationPartial = singleStrategy(value && value[key], validationSchema[key], context);
        if (!isEmpty(singleValidationPartial)) {
          partialResult[key] = singleValidationPartial.shift();
        }
      }

      /* arrays may contain object validators with accessAll flag, because
      some validators in order to validate one field need to have access to all of them */
      if (isArray(validationSchema[key])) {
        const needMoreAccess = validationSchema[key].some(validator => validator.accessAll);
        const data = needMoreAccess ? value : (value && value[key]);
        const multiValidationPartial = multipleStrategy(data, validationSchema[key], context);
        const message = multiValidationPartial.shift();
        if (!isEmpty(message)) {
          partialResult[key] = message;
        }
      }

      return partialResult;
    });
};

export const validate = ({ validators, name, localePath }) => (values) => {
  let result;
  let validationMessages = [];
  const value = values[name];
  const context = { localePath };

  if (isString(validators)) {
    validationMessages = singleStrategy(value, validators, context);
  }

  if (isArray(validators)) {
    validationMessages = multipleStrategy(value, validators, context);
  }

  if (isPlainObject(validators)) {
    validationMessages = schemaStrategy(value, validators, context);
  }

  const resultMessage = validationMessages.filter(isDefined).shift();

  if (!isEmpty(resultMessage)) {
    result = {
      [name]: resultMessage,
    };
  }

  return result;
};

export const getValidators = componentConfig => {
  const componentValidators = componentConfig.validator || componentConfig.validators || [];
  let validators = [];

  if (componentConfig.options && componentConfig.options.some(item => (item.exclude || item.include || []).length)) {
    validators.push('required');
  }

  if (typeof componentValidators === 'string') {
    validators.push(componentValidators);
  } else if (componentValidators.constructor === Array) {
    validators = [...validators, ...componentValidators];
  } else {
    validators = componentValidators;
  }

  return validators;
};

export default {
  validate,
};
