/* eslint-disable max-len */
import React from 'react';

const SvgReparatur = props => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <defs>
      <path id="Reparatur_svg__a" d="M.084.02h9.98V39.93H.084z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M20.747 4.369a2.063 2.063 0 00-2.248-.086c-.717.422-1.162 1.23-1.162 2.11v4.023h-2.066V6.394c0-.88-.445-1.69-1.162-2.111a2.063 2.063 0 00-2.247.086c-2.359 1.642-3.767 4.43-3.767 7.457 0 3.025 1.459 5.875 3.82 7.493v19.949c0 2.614 1.969 4.741 4.39 4.741 2.42 0 4.388-2.127 4.388-4.741v-2.535h-2.61v2.535c0 1.059-.798 1.92-1.779 1.92-.98 0-1.778-.861-1.778-1.92V19.083c0-.838-.419-1.63-1.093-2.066-1.708-1.104-2.727-3.045-2.727-5.19 0-1.787.719-3.45 1.955-4.593v3.604c0 1.323.995 2.398 2.22 2.398h2.847c1.224 0 2.22-1.075 2.22-2.398V7.234c1.235 1.142 1.955 2.806 1.955 4.592 0 2.146-1.02 4.087-2.727 5.191-.654.423-1.093 1.248-1.093 2.054v14.766h2.61V19.319c2.362-1.618 3.82-4.468 3.82-7.493 0-3.027-1.408-5.815-3.766-7.457"
        fill="currentColor"
        fillRule="nonzero"
      />
      <g transform="translate(29.644 3.99)">
        <mask id="Reparatur_svg__b" fill="#fff">
          <use xlinkHref="#Reparatur_svg__a" />
        </mask>
        <path
          d="M7.096 35.496c0 .896-.907 1.625-2.022 1.625-1.115 0-2.022-.73-2.022-1.625V25.035h4.044v10.46zM5.093 2.828h.043l.99 4.54-.575.559h-.894l-.536-.544.972-4.555zM8.58 22.227H6.594v-11.55a1.5 1.5 0 00.648-.368l1.56-1.518c.343-.333.488-.806.39-1.262L7.798 1.141C7.657.488 7.049.02 6.346.02H3.879c-.706 0-1.315.47-1.455 1.125L1.061 7.535c-.095.444.043.904.37 1.235l1.496 1.518a1.5 1.5 0 00.699.396v11.543H1.568c-.82 0-1.484.628-1.484 1.404v11.865c0 2.444 2.238 4.433 4.99 4.433 2.752 0 4.99-1.99 4.99-4.433V23.63c0-.776-.664-1.404-1.484-1.404z"
          fill="currentColor"
          fillRule="nonzero"
          mask="url(#Reparatur_svg__b)"
        />
      </g>
    </g>
  </svg>
);

export default SvgReparatur;
