/* eslint-disable max-len */
import React from 'react';

function SvgWand(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g id="icons/05_other/Wand/48" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <polygon id="Fill-18" fill="#1D1D1B" points="21.3572625 27.1991625 20.2855125 27.1991625 20.2855125 28.846725 18.6280125 28.846725 18.6280125 24.4601625 20.2855125 24.4601625 20.2855125 25.0155375 21.3572625 25.0155375 21.3572625 23.3884125 17.55645 23.3884125 17.55645 29.918475 21.3572625 29.918475" />
        <polygon id="Fill-19" fill="#1D1D1B" points="19.1840625 26.6435625 23.0049375 26.6435625 23.0049375 25.5718125 19.1840625 25.5718125" />
        <g id="Group-32" transform="translate(0.000000, -0.165000)">
          <path d="M32.8398,39.8769245 L16.4448,39.8769245 L16.4448,11.1564887 L32.8399875,11.1564887 L32.8398,39.8769245 Z M33.91155,37.1635237 L33.91155,10.0889089 L17.156925,10.0889089 C16.1718,10.0889089 15.37305,10.884551 15.37305,11.8658428 L15.37305,37.1637105 L15.37305,38.2311035 L15.37305,39.8771113 L6.23805,39.8771113 L0.7103625,46.9713992 L2.0202375,46.9713992 L6.50505,40.9446911 L41.6146125,40.9446911 L45.230175,46.9713992 L46.3516125,46.9713992 L42.1092375,39.8771113 L40.6152375,39.8771113 L33.91155,39.8771113 L33.91155,38.2309167 L33.91155,37.1635237 Z" id="Fill-20" fill="#1D1D1B" />
          <path d="M32.8398,39.8769245 L16.4448,39.8769245 L16.4448,11.1564887 L32.8399875,11.1564887 L32.8398,39.8769245 Z M33.91155,37.1635237 L33.91155,10.0889089 L17.156925,10.0889089 C16.1718,10.0889089 15.37305,10.884551 15.37305,11.8658428 L15.37305,37.1637105 L15.37305,38.2311035 L15.37305,39.8771113 L6.23805,39.8771113 L0.7103625,46.9713992 L2.0202375,46.9713992 L6.50505,40.9446911 L41.6146125,40.9446911 L45.230175,46.9713992 L46.3516125,46.9713992 L42.1092375,39.8771113 L40.6152375,39.8771113 L33.91155,39.8771113 L33.91155,38.2309167 L33.91155,37.1635237 Z" id="Stroke-22" stroke="#000000" strokeWidth="0.9375" strokeLinejoin="round" />
          <line x1="6.98604375" y1="39.8770926" x2="6.98604375" y2="4.12493696" id="Stroke-24" stroke="#000000" strokeWidth="2.0625" />
          <line x1="41.7780938" y1="40.247365" x2="41.7780938" y2="4.49576965" id="Stroke-26" stroke="#000000" strokeWidth="2.0625" />
          <rect id="Rectangle" x="0" y="0.164357977" width="48" height="47.8132296" />
        </g>
      </g>
    </svg>
  );
}

export default SvgWand;
