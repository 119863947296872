/* eslint-disable max-len */
import React from 'react';

function SvgAdvertisement(props) {
  return (
    <svg viewBox="0 0 255.12 255.12" {...props}>
      <g id="icons_cmyk_black" dataName="icons cmyk black" fill="none" fillRule="evenodd">
        <g id="Kampagne" fill="none" fillRule="evenodd">
          <path fill="#1d1d1b" d="M168.64 206.47a16.16 16.16 0 01-7.16-1.69l-.19-.09-79.54-42.33V92.75l79.73-42.42a16.2 16.2 0 0123.36 15.16v124.14a16.33 16.33 0 01-1.66 7.8 16.28 16.28 0 01-14.54 9zm-.9-14.21a2.19 2.19 0 001.62.08 2.18 2.18 0 001.49-2.19V65a2.2 2.2 0 00-2.08-2.31 2.16 2.16 0 00-1 .2l-72 38.3V154zM43 201.61a17.88 17.88 0 01-17.86-17.86v-21.83A20.08 20.08 0 0110 142.47v-30.41A20 20 0 0130 92h41.61v70.49H60.89v21.24A17.89 17.89 0 0143 201.61zM30 106a6 6 0 00-6 6v30.41a6 6 0 006 6h9.16v35.24a3.87 3.87 0 007.73 0v-35.14h10.72V106zM195.71 120.39h42.23v14h-42.23zM231.78 72.1l-36.25 18.56v15.7l.01.02 42.62-21.82-6.38-12.46zM195.53 148.74v15.75l35.86 18.48 6.41-12.44-42.27-21.79z" />
        </g>
      </g>
      <path fill="none" d="M0 0h255.12v255.12H0z" id="Ebene_2" dataName="Ebene 2" />
    </svg>
  );
}


export default SvgAdvertisement;
