/* eslint-disable max-len */
import React from 'react';

function SvgCheckliste(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g fill="#1D1D1B" fillRule="nonzero">
        <path d="M14.566 23.063h2.626v2.626h-2.626zM19.657 31.864h10.801v2.626H19.657zM14.566 31.864h2.626v2.626h-2.626zM30.458 14.656h-8.946l-1.847 2.543-.008.011v.072h10.801zM19.144 11.128l-2.51 3.456-1.419-1.174-1.673 2.022 3.572 2.959 4.155-5.719z" />
        <path d="M32.93 27.294v11.942H12.1V8.032h20.83v6.53l1.843-2.498.805-1.092V7.761a2.38 2.38 0 00-2.368-2.376H11.812a2.38 2.38 0 00-2.368 2.367v31.777a2.374 2.374 0 002.372 2.37h21.4a2.376 2.376 0 002.372-2.37V23.693l-2.647 3.59-.011.011z" />
        <path d="M19.657 25.688h5.066l1.937-2.625h-7.003zM26.927 26.856v.009l-.055 1.694a.435.435 0 00.142.377c.06.042.131.064.205.062a.638.638 0 00.209-.038l1.6-.544h.011l8.847-12.004-2.121-1.541-8.838 11.985zM40.621 12.06a1.318 1.318 0 00-2.349-.578l-1.506 2.054-.022.032 2.112 1.559.024-.032 1.506-2.052c.207-.283.292-.637.235-.983z" />
      </g>
    </svg>
  );
}

export default SvgCheckliste;
