/* eslint-disable max-len */
import React from 'react';

const SvgWindschutzscheibe = props => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <g fill="currentColor" fillRule="nonzero">
      <path d="M34.876 10.66a4.76 4.76 0 014.517 3.292l4.52 13.815a1.921 1.921 0 01-1.051 2.36c-5.664 2.468-10.93 3.92-15.795 4.354l2.391-4.173c3.216-.542 6.647-1.548 10.29-3.026l-3.969-12.13a.952.952 0 00-.903-.658H13.124a.952.952 0 00-.903.658l-3.97 12.13c2.886 1.17 5.638 2.045 8.256 2.627l-1.988 3.468c-2.995-.747-6.121-1.83-9.38-3.25a1.921 1.921 0 01-1.052-2.36l4.52-13.815a4.76 4.76 0 014.517-3.291h21.752z" />
      <path d="M11.587 18.854l2.695-2.695 1.347 1.348-2.695 2.695zM12.738 22.276l5.39-5.39 1.347 1.348-5.39 5.39zM15.378 34.46l9.766-16.917 2.476 1.43-9.767 16.916a1.43 1.43 0 01-2.475-1.43zM21.095 34.46l9.766-16.917 2.476 1.43-9.767 16.916a1.43 1.43 0 01-2.475-1.43z" />
    </g>
  </svg>
);

export default SvgWindschutzscheibe;
