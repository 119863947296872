import moment from 'moment';

const accidentDateSelector = (formData) => {
  try {
    const { date } = formData.accident['event-circumstances_day'];
    return date ? moment(date, 'DD.MM.YYYY').toDate() : undefined;
  } catch (error) {
    return undefined;
  }
};

// eslint-disable-next-line import/prefer-default-export
export { accidentDateSelector };
