import Polyglot from 'node-polyglot';
import { path } from 'ramda';
import phrases from '../i18n/locales/de-DE.yaml';

const unhash = k => k.split('.').map(part => part.split('_')[0]).join('.');

export const polyglot = new Polyglot({
  phrases,
  interpolation: {
    prefix: '{',
    suffix: '}',
  },
  // If you would like to see the key of empty possible translation at web please write "key" at false option
  // Please remember that change would work globally for all translations at the app!
  onMissingKey: key => (polyglot.has(unhash(key)) ? polyglot.t(unhash(key)) : ''),
});

export const getTranslationArray = (key) => (
  key ? path(key.split('.'), phrases) : []
);

export default polyglot;
