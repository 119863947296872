/* eslint-disable max-len */
import React from 'react';

function SvgImmobilien(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g fill="none" fillRule="evenodd">
        <g fill="#1D1D1B" fillRule="nonzero">
          <path d="M27.731 44.64H8.638a1.313 1.313 0 01-1.312-1.313V10.297c-.001-.46.238-.887.632-1.125l9.547-5.787a1.312 1.312 0 011.361 0l9.546 5.789c.393.238.633.665.632 1.125v33.03c0 .724-.588 1.312-1.313 1.312zm-17.78-2.625h16.468v-30.98l-8.232-4.992-8.236 4.991v30.981z" />
          <path d="M14.073 15.113h2.702v3.645h-2.701zM19.666 15.113h2.702v3.645h-2.702zM14.073 21.018h2.702v3.645h-2.701zM19.666 21.018h2.702v3.645h-2.702zM14.073 26.928h2.702v3.645h-2.701zM19.666 26.928h2.702v3.645h-2.702zM14.073 32.835h2.702v3.645h-2.701zM19.666 32.835h2.702v3.645h-2.702zM31.662 21.018h2.702v3.645h-2.702zM31.662 26.928h2.702v3.645h-2.702zM31.662 32.835h2.702v3.645h-2.702z" />
          <path d="M39.197 44.64H31.66v-2.625h6.223V15.69h-6.223v-2.625h7.536c.725 0 1.312.588 1.312 1.313v28.95c0 .724-.587 1.312-1.312 1.312z" />
        </g>
        <path d="M0 0h48v48H0z" />
      </g>
    </svg>
  );
}

export default SvgImmobilien;
