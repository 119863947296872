/* eslint-disable max-len */
import React from 'react';

function SvgGolftascheGolfschlaeger(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g fill="none" fillRule="evenodd">
        <g fill="#1D1D1B" fillRule="nonzero">
          <path d="M29.704 21.852H29.1v-5.835a3.627 3.627 0 01-2.134-.264v6.101h-2.391V9.035a3.624 3.624 0 01-2.133.264v12.559h-1.965v-3.846a3.631 3.631 0 01-2.134.264v3.576h-.968c-.728 0-1.318.59-1.318 1.318v16.822c0 1.695.509 3.03 1.506 3.98 1.292 1.223 2.94 1.332 3.494 1.332h8.646c.727 0 1.317-.59 1.317-1.317v-1.318h-9.939c-.01 0-1.031.027-1.707-.612-.457-.433-.689-1.129-.689-2.07v-15.5h9.7V40.66h2.635v-17.49c0-.727-.59-1.317-1.317-1.317z" />
          <path d="M25.634 13.007c.318 1.167 1.61 1.894 3.37 1.894a7.12 7.12 0 001.853-.258 6.423 6.423 0 002.775-1.506c.81-.794 1.13-1.694.894-2.553-.235-.858-.967-1.472-2.07-1.744a6.4 6.4 0 00-3.155.109c-2.493.677-4.104 2.462-3.667 4.058zm4.107-2.426a4.8 4.8 0 012.3-.102c.465.117.782.32.844.548.062.228-.106.565-.448.898a4.762 4.762 0 01-2.033 1.082 4.873 4.873 0 01-2.176.13c-.53-.104-.898-.326-.968-.576-.132-.484.79-1.52 2.481-1.98zM18.28 17.161c.433.003.865-.047 1.287-.148 1.101-.275 1.835-.894 2.07-1.745.236-.851-.084-1.758-.893-2.555a6.419 6.419 0 00-2.775-1.505 6.4 6.4 0 00-3.155-.112c-1.101.275-1.835.895-2.07 1.745-.236.851.084 1.759.894 2.555a6.4 6.4 0 002.774 1.506c.608.17 1.236.257 1.868.26zm-3.904-3.875c.062-.226.376-.431.843-.546a3.59 3.59 0 01.875-.098c.481 0 .96.063 1.425.188a4.772 4.772 0 012.033 1.1c.343.336.51.671.448.9-.062.227-.376.43-.843.545a4.753 4.753 0 01-2.3-.1 4.762 4.762 0 01-2.033-1.082c-.343-.345-.518-.68-.448-.907zM20.518 7.92a7.112 7.112 0 001.85.257c1.76 0 3.053-.725 3.371-1.882.435-1.597-1.176-3.389-3.668-4.063a6.4 6.4 0 00-3.157-.109c-1.101.273-1.835.893-2.07 1.745-.236.853.084 1.758.893 2.553a6.423 6.423 0 002.78 1.5zm1.108-4.061c1.694.461 2.613 1.506 2.481 1.98-.131.474-1.453.907-3.143.446a4.751 4.751 0 01-2.033-1.082c-.343-.335-.51-.672-.448-.898.062-.228.376-.431.843-.548.287-.067.58-.1.875-.098.48.004.956.071 1.418.2h.007zM35.887 26.76l-3.923-2.56-.019.03v3.104l1.903 1.242v7.287l-1.903 1.393v3.102l.078.107 3.93-2.873c.338-.248.538-.642.538-1.061v-8.672a1.318 1.318 0 00-.604-1.1z" />
        </g>
        <path d="M0 0h48.023v48.023H0z" />
      </g>
    </svg>
  );
}

export default SvgGolftascheGolfschlaeger;
