/* eslint-disable max-len */
import React from 'react';

function SvgItUnternehmen(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 100 100"
      {...props}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#1D1D1B" fillRule="nonzero" transform="translate(10 21)">
          <path d="M42.5886007 53.7675907L42.5886007 45.5330164 37.3172683 45.5330164 37.3172683 53.7675907 23.7963007 53.7675907 23.7963007 59.0389231 56.1058032 59.0389231 56.1058032 53.7675907z" />
          <path d="M75.23 42.25H4.77A4.774 4.774 0 010 37.48V4.77A4.774 4.774 0 014.77 0h70.46A4.774 4.774 0 0180 4.77v32.71a4.774 4.774 0 01-4.77 4.77zM5.27 36.978H74.73V5.271H5.27v31.707z" />
          <path d="M46.757 14.515l-.983 8.148c-.064.52-.097 1.045-.098 1.57a.783.783 0 00.162.523.5.5 0 00.395.2 2.233 2.233 0 001.39-.493c.451-.36.828-.803 1.11-1.307.34-.588.594-1.222.754-1.882a8.916 8.916 0 00.263-2.162 7.884 7.884 0 00-.723-3.388 7.658 7.658 0 00-1.977-2.636 9.09 9.09 0 00-2.978-1.645 11.729 11.729 0 00-7.84.18 10.234 10.234 0 00-3.301 2.079 9.413 9.413 0 00-2.177 3.159 9.861 9.861 0 00-.786 3.927 10.038 10.038 0 00.85 4.22 9.198 9.198 0 002.309 3.14 9.997 9.997 0 003.388 1.948 12.58 12.58 0 004.142.674c.851 0 1.604-.03 2.26-.083.623-.05 1.242-.155 1.848-.313a8.8 8.8 0 001.668-.621c.604-.3 1.189-.639 1.75-1.013l1.409 2.749a15.991 15.991 0 01-4.172 1.848c-1.542.416-3.133.62-4.73.607-1.97.03-3.928-.323-5.764-1.04a13.107 13.107 0 01-4.36-2.846 12.681 12.681 0 01-2.779-4.187 13.2 13.2 0 01-.986-5.072 12.802 12.802 0 013.927-9.326 13.513 13.513 0 014.304-2.783 13.931 13.931 0 015.343-1.013 13.638 13.638 0 014.94.881 12.53 12.53 0 013.976 2.421 11.296 11.296 0 012.635 3.619c.646 1.388.976 2.901.968 4.432a12.35 12.35 0 01-.508 3.648 8.717 8.717 0 01-1.442 2.85 6.344 6.344 0 01-5.185 2.504 2.854 2.854 0 01-1.589-.46 4.341 4.341 0 01-1.227-1.242 4.948 4.948 0 01-2.06 1.356 6.642 6.642 0 01-1.834.278 5.298 5.298 0 01-2.176-.508 6.924 6.924 0 01-2.06-1.423 7.858 7.858 0 01-1.555-2.192 6.34 6.34 0 01-.62-2.812c-.012-.955.189-1.9.586-2.768a7.03 7.03 0 011.54-2.157 6.747 6.747 0 012.177-1.393 6.676 6.676 0 012.504-.49 5.298 5.298 0 012.45.539 3.57 3.57 0 011.54 1.423l.294-1.668h3.028zm-10.543 6.48c0 .439.09.873.264 1.276a3.472 3.472 0 001.75 1.834c.799.372 1.72.372 2.52 0 .393-.182.749-.437 1.046-.753a3.55 3.55 0 00.704-1.08 3.28 3.28 0 00.26-1.277c0-.434-.088-.863-.26-1.261a3.905 3.905 0 00-.704-1.081 3.325 3.325 0 00-1.047-.753 2.986 2.986 0 00-2.519 0c-.395.18-.752.436-1.05.753a3.81 3.81 0 00-.7 1.08 3.118 3.118 0 00-.264 1.262z" />
        </g>
        <path d="M0 0H100V100H0z" />
      </g>
    </svg>
  );
}

export default SvgItUnternehmen;
