import {
  getMessage,
  VALIDATION_KEYS,
} from './messages';

import { defaultConfig } from './utils';

const {
  INVALID_GERMAN_IBAN,
} = VALIDATION_KEYS;

export const DE_IBAN = /^(DE)(\d{2})(\d{18})$/i;
// export const DE_IBAN = new RegExp('^(DE)(\\d{2})(\\d{18})$', 'i');

export const germanIban = iban => iban && iban.match(DE_IBAN);

export const isGermanIBANValid = value => {
  if (!value) {
    return false;
  }

  const stripped = value.replace(/\s+/g, '');
  // we only validate if iban is german otherwise anything goes
  const isGerman = stripped && stripped.substring(0, 2).toLowerCase() === 'de';
  const IBAN = stripped && germanIban(stripped.toUpperCase());

  if (!isGerman && (stripped && stripped.length > 14)) {
    return true;
  }

  if (!!IBAN === false) {
    return false;
  }

  const digits = (IBAN[3] + IBAN[1] + IBAN[2]).replace(
    /[A-Z]/g,
    letter => letter.charCodeAt(0) - 55,
  );

  let checksum = digits.slice(0, 2);
  let fragment;
  for (let offset = 2; offset < digits.length; offset += 7) {
    fragment = String(checksum) + digits.substring(offset, offset + 7);
    checksum = parseInt(fragment, 10) % 97;
  }
  return checksum === 1;
};


export const validGermanIBANorNonGermanAny = (config = defaultConfig) => {
  const { localePath, message } = config;
  return (value) => (!isGermanIBANValid(value)
    ? message || getMessage(localePath, INVALID_GERMAN_IBAN)
    : undefined);
};

export default {
  validGermanIBANorNonGermanAny,
};
