/* eslint-disable max-len */
import React from 'react';

const SvgCarGlasbruch = props => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <path
      d="M7.063 23.79l-1.36 4.125C12.243 30.648 18.178 32 23.5 32c5.322 0 11.256-1.352 17.797-4.085l-2.022-6.135-7.24 6.193-6.38-4.56-2.819 5.465-9.685-7.746-6.088 2.657zm1.153-3.498L13.238 18l8.776 7.02 2.744-5.322 7.139 5.103 6.532-5.587-1.596-4.84a2 2 0 00-1.9-1.374H12.067a2 2 0 00-1.9 1.374l-1.95 5.918zM12.066 10h22.868a5 5 0 014.748 3.435l4.752 14.414a2 2 0 01-1.105 2.462C36.1 33.437 29.49 35 23.5 35c-5.991 0-12.6-1.563-19.829-4.69a2 2 0 01-1.105-2.461l4.752-14.414A5 5 0 0112.066 10z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

export default SvgCarGlasbruch;
