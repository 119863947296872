/* eslint-disable max-len */
import React from 'react';

function SvgArbeitsSchulweg(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g strokeWidth="1" fill="none" fillRule="evenodd">
        <g>
          <polygon id="Path" fill="#1D1D1B" fillRule="nonzero" points="18.418125 1.035 20.319375 0.973125 21 6.615 19.09875 8.364375" />
          <polygon id="Path" fill="#1D1D1B" fillRule="nonzero" points="6.058125 0.99 7.99125 1.003125 3.375 46.655625 1.44 46.6425" />
          <polygon id="Path" fill="#1D1D1B" fillRule="nonzero" points="12.15 46.861875 12.084375 33.879375 14.030625 33.87 14.09625 46.8525" />
          <path d="M30.90375,3.890625 C23.0709715,3.890625 16.72125,10.2403465 16.72125,18.073125 C16.72125,25.9059035 23.0709715,32.255625 30.90375,32.255625 C38.7365285,32.255625 45.08625,25.9059035 45.08625,18.073125 C45.08625,10.2403465 38.7365285,3.890625 30.90375,3.890625 L30.90375,3.890625 Z" id="Path" stroke="#1D1D1B" strokeWidth="2.625" />
          <polygon id="Path" fill="#1D1D1B" fillRule="nonzero" points="12.076875 29.4675 12.02625 19.250625 13.970625 19.239375 14.023125 29.458125" />
          <polygon id="Path" fill="#1D1D1B" fillRule="nonzero" points="12.075 14.895 12.03 5.985 13.974375 5.97375 14.019375 14.885625" />
          <path d="M31.14,17.3981253 C33.297136,17.3988834 35.2422529,16.0999395 36.0681035,14.1071507 C36.8939541,12.1143619 36.4378472,9.820304 34.9125216,8.29497842 C33.387196,6.76965284 31.0931381,6.31354595 29.1003493,7.1393965 C27.1075605,7.96524706 25.8086166,9.91036401 25.8093747,12.0675 L25.8093747,12.0675 C25.8093747,15.0115229 28.1959771,17.3981253 31.14,17.3981253 L31.14,17.3981253 Z M27.31875,11.25 L27.343125,11.23875 C28.246875,10.80375 28.996875,10.783125 29.870625,10.760625 C30.7296108,10.7742011 31.585477,10.6540129 32.4075,10.404375 C33.0159062,10.2222702 33.5567668,9.86408865 33.961875,9.375 C35.2533111,10.7204303 35.4152044,12.7905018 34.3486337,14.3203438 C33.2820629,15.8501859 31.2837417,16.4142054 29.5746994,15.667771 C27.8656572,14.9213365 26.9213393,13.0721017 27.31875,11.25 Z" id="Shape" fill="#1D1D1B" fillRule="nonzero" />
          <path d="M29.8125,13.190625 C30.1809057,13.190625 30.4796531,12.8921538 30.4799974,12.5237482 C30.4803411,12.1553427 30.1821515,11.8563156 29.8137465,11.855625 C29.4453415,11.8549368 29.1460348,12.1528458 29.1449974,12.52125 L29.1449974,12.52125 C29.1445018,12.6986067 29.2146075,12.86887 29.3398421,12.9944563 C29.4650766,13.1200427 29.6351426,13.190625 29.8125,13.190625 L29.8125,13.190625 Z" id="Path" fill="#1D1D1B" fillRule="nonzero" />
          <path d="M32.46375,13.1831277 C32.8321557,13.1831277 33.1309031,12.8846538 33.1312474,12.5162482 C33.1315911,12.1478427 32.8334015,11.8488156 32.4649965,11.848125 C32.0965915,11.8474368 31.7972848,12.1453458 31.7962394,12.51375 C31.7952492,12.691259 31.8651932,12.8618143 31.9905368,12.98751 C32.1158805,13.1132058 32.2862389,13.1836279 32.46375,13.1831277 L32.46375,13.1831277 Z" id="Path" fill="#1D1D1B" fillRule="nonzero" />
          <path d="M25.783125,18.66375 C25.569375,18.66375 25.164375,18.965625 24.931875,19.1325 C22.81875,20.570625 22.4775,23.705625 23.14875,27.0825 L24.601875,27.0825 C24.226875,25.385625 24.159375,23.91 24.39,22.68375 C24.4275,22.483125 24.474375,22.29375 24.526875,22.12125 C24.7610549,23.8463283 25.2409198,25.5290126 25.951875,27.118125 L27.451875,27.118125 C26.701875,25.501875 25.764375,22.340625 25.764375,19.828125 L25.783125,18.66375 Z" id="Path" fill="#1D1D1B" fillRule="nonzero" />
          <path d="M36.601875,18.855 C36.1142859,18.5861773 35.5717414,18.432264 35.015625,18.405 C34.7340288,18.402859 34.4558897,18.4670943 34.20375,18.5925 C32.1861367,19.582441 29.8238633,19.582441 27.80625,18.5925 C27.5558095,18.4679526 27.2796979,18.4037406 27,18.405 C26.94,18.405 26.88,18.405 26.821875,18.405 C26.559375,18.36 26.446875,18.343125 26.420625,18.41625 L26.398125,18.5625 C26.3701857,19.1333041 26.4431796,19.704561 26.61375,20.25 C26.97375,21.538125 27.703125,22.86375 28.785,24.1875 L28.820625,24.226875 C28.944375,24.358125 31.84875,27.414375 32.685,28.095 L32.7075,28.06875 L32.71875,28.093125 C32.92875,27.976875 34.790625,26.934375 35.563125,26.161875 C36.729375,24.995625 36.635625,23.563125 35.304375,22.224375 C34.006875,20.92875 32.55375,21.34875 31.93875,21.766875 L29.71875,23.0925 C29.0259155,22.2403448 28.4779755,21.2800245 28.096875,20.25 C30.3015853,21.0339313 32.7293991,20.8946582 34.83,19.86375 C34.888938,19.8376437 34.9530943,19.825454 35.0175,19.828125 C35.3534762,19.856777 35.6788533,19.9598985 35.97,20.13 C36.84,20.563125 37.38,21.399375 37.621875,22.6875 C37.8525,23.91375 37.7775,25.3875 37.411875,27.08625 L38.863125,27.08625 C39.58125,23.465625 39.136875,20.116875 36.601875,18.855 Z M32.683125,22.966875 L32.73,22.936875 C32.76,22.914375 33.49875,22.408125 34.314375,23.22375 C34.846875,23.75625 35.35125,24.39 34.573125,25.168125 C34.23,25.51125 33.5025,25.989375 32.885625,26.371875 C32.25375,25.755 31.385625,24.871875 30.695625,24.15375 L32.683125,22.966875 Z" id="Shape" fill="#1D1D1B" fillRule="nonzero" />
          <polygon id="Path" fill="#1D1D1B" fillRule="nonzero" points="21.915 30.470625 23.82375 30.6375 25.955625 46.779375 24.04875 46.8" />
          <rect id="Rectangle" x="1.35891298e-13" y="-3.46389584e-14" width="48" height="48" />
        </g>
      </g>
    </svg>
  );
}

export default SvgArbeitsSchulweg;
