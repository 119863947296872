/* eslint-disable max-len */
import React from 'react';

function SvgLadestation(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M40.444 14.456V9.638h-2.67v4.818h-1.727V9.638H33.38v4.818a1.335 1.335 0 00-1.333 1.335v5.525c0 1.317 1.14 2.402 2.545 2.402h.988v11.774h-2.038v-8.053a2.404 2.404 0 00-2.4-2.402h-5.376V12.002A2.406 2.406 0 0023.364 9.6H10.496a2.406 2.406 0 00-2.402 2.402v20.662h2.667V12.27h12.337v20.395h2.668v-4.958h5.108v8.053a2.404 2.404 0 002.4 2.402h2.57a2.404 2.404 0 002.4-2.402V23.712h.99c1.402 0 2.545-1.077 2.545-2.402v-5.519a1.336 1.336 0 00-1.335-1.335zm-1.334 6.589h-4.4v-3.92h4.4v3.92z"
          fill="#1D1D1B"
          fillRule="nonzero"
        />
        <path
          fill="#1D1D1B"
          fillRule="nonzero"
          d="M6.342 38.02l.008-2.667 21.169.059-.008 2.667zM12.941 20.107l8.021-.016.006 2.667-8.021.016zM12.941 15.07l8.021-.016.006 2.667-8.021.016z"
        />
        <path d="M0 0h48.023v48.023H0z" />
      </g>
    </svg>
  );
}

export default SvgLadestation;
