/* eslint-disable max-len */
import React from 'react';

function SvgDigitalePost(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g fill="#1D1D1B" fillRule="nonzero">
        <path d="M21.947 22.335l-8.756-8.771h-1.875v1.875l8.904 8.92a2.438 2.438 0 003.457 0l8.903-8.907v-1.888h-1.875l-8.758 8.771z" />
        <path d="M25.455 32.203c0-.458.035-.915.105-1.367H9.744c-.6 0-1.087-.487-1.087-1.087V11.994c0-.6.486-1.088 1.087-1.089h24.4a1.09 1.09 0 011.087 1.09V23.13a9.375 9.375 0 012.657.527v-12.22a3.188 3.188 0 00-3.187-3.187H9.187A3.188 3.188 0 006 11.438v18.868a3.188 3.188 0 003.188 3.187h16.359a8.897 8.897 0 01-.092-1.29z" />
        <path d="M38.501 28.639l-.562 4.668c-.038.299-.056.6-.056.9a.46.46 0 00.093.3c.054.07.137.112.225.113.29 0 .571-.1.797-.281.26-.206.476-.46.638-.75.19-.335.332-.695.421-1.069.102-.405.152-.82.15-1.238a4.532 4.532 0 00-.412-1.95 4.38 4.38 0 00-1.125-1.5 5.162 5.162 0 00-1.697-.956 6.703 6.703 0 00-4.49.103 5.891 5.891 0 00-1.894 1.2 5.411 5.411 0 00-1.247 1.81 5.646 5.646 0 00-.45 2.25 5.775 5.775 0 00.487 2.419c.308.687.759 1.3 1.322 1.8a5.812 5.812 0 001.95 1.115 7.271 7.271 0 002.366.385c.488 0 .92-.017 1.294-.047a6.306 6.306 0 001.06-.188c.33-.087.65-.206.956-.356.346-.169.68-.359 1.003-.568l.806 1.575a9.163 9.163 0 01-2.394 1.05c-.883.237-1.795.354-2.71.347a8.717 8.717 0 01-3.309-.6 7.537 7.537 0 01-2.503-1.632 7.301 7.301 0 01-1.594-2.4 7.577 7.577 0 01-.562-2.906 7.301 7.301 0 01.6-2.962 7.42 7.42 0 011.65-2.382 7.755 7.755 0 012.465-1.593 7.99 7.99 0 013.066-.582 7.794 7.794 0 012.831.507c.84.315 1.613.785 2.278 1.387a6.407 6.407 0 011.52 2.063c.369.795.558 1.663.552 2.54a7.07 7.07 0 01-.29 2.09 5.021 5.021 0 01-.825 1.632 3.636 3.636 0 01-2.972 1.434 1.639 1.639 0 01-.91-.262c-.28-.188-.519-.43-.703-.712a2.856 2.856 0 01-1.181.778c-.341.1-.694.154-1.05.159a3.049 3.049 0 01-1.247-.29 4.091 4.091 0 01-1.181-.816 4.534 4.534 0 01-.89-1.256 3.65 3.65 0 01-.357-1.613 3.688 3.688 0 01.337-1.584c.213-.465.512-.885.882-1.238.36-.343.784-.613 1.247-.797a3.83 3.83 0 011.434-.281c.487-.012.97.095 1.406.31.37.176.677.46.882.815l.168-.956 1.725.015zm-6.037 3.712c0 .252.051.5.15.732.096.228.232.438.403.618.172.177.375.32.6.422.458.213.986.213 1.444 0 .224-.102.428-.245.6-.422.17-.18.308-.39.403-.618.099-.232.15-.48.15-.732a1.774 1.774 0 00-.15-.722 2.111 2.111 0 00-.403-.618 1.845 1.845 0 00-.6-.432 1.714 1.714 0 00-1.444 0c-.227.103-.43.25-.6.432-.17.181-.306.39-.403.618-.1.228-.15.474-.15.722z" />
      </g>
    </svg>
  );
}

export default SvgDigitalePost;
