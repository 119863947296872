import React from 'react';
import injectSheet from 'react-jss';
import PropTypes from 'prop-types';
import styles from './styles.jss';

/**
* Component show main icons wrapper with common styles
*
* @param children
* @param classes
* @param viewBox
* @constructor
*/
const Icon = ({
  children,
  className,
  classes,
  viewBox,
}) => (
  <svg
    className={`${classes.svg} ${className}`}
    viewBox={viewBox}
  >
    {children}
  </svg>
);

Icon.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  classes: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  viewBox: PropTypes.string,
};

Icon.defaultProps = {
  children: '',
  classes: '',
  className: '',
  viewBox: '0 0 30 30',
};

export { Icon as IconPlain };
export default injectSheet(styles)(Icon);
