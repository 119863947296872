import moment from 'moment';
import { isPlainObject, isString } from 'lodash';
import { getMessage, VALIDATION_KEYS } from './messages';
import {
  checkLength,
  defaultConfig,
  isTimeValid,
  isHourValid,
  isMinValid,
  isToday,
} from './utils';
import { INVALID_DATE } from '../../services/claimData/claimData.helpers';


const {
  DATE, TIME,
} = VALIDATION_KEYS;

const dateFormat = 'DD-MM-YYYY';
const dateAndTimeFormat = 'DD-MM-YYYY-HH-mm';


export const getRangeDate = (val, unit, ref) => (val !== 0 ? moment(ref).add(val, unit) : moment(ref));

export const inDateRange = (input, { min, max }, precision) => {
  if (min && max) {
    return input.isBetween(min, max, precision, '[]');
  }
  if (min || max) {
    return max ? input.isSameOrBefore(max, precision) : input.isSameOrAfter(min, precision);
  }
  return false;
};

export const validateDate = (date, range) => {
  const dateObj = moment(date, dateFormat);
  return !checkLength(date, 10) || !dateObj.isValid() || !inDateRange(dateObj, range, 'day');
};

export const validateDateAndTime = ({ date, time }, range) => {
  if (!time) return undefined;
  const dateObj = moment(date, dateFormat);
  const dateAndTimeObj = moment(`${date} ${time}`, dateAndTimeFormat);

  const precondition = checkLength(date, 10) && dateObj.isValid() && isToday(dateObj);

  return !isTimeValid(time) || (precondition && !inDateRange(dateAndTimeObj, range, 'minute'));
};

export const validateDateHourAndMin = ({ date, hour, min }, range) => {
  if (!date) return true;
  if (!hour && !min) return undefined;
  const dateObj = moment(date, dateFormat);
  const dateHourAndMinObj = moment(`${date} ${hour} ${min}`, dateAndTimeFormat);

  const precondition = checkLength(date, 10) && dateObj.isValid() && isToday(dateObj);
  return !isHourValid(hour) || !isMinValid(min) || (precondition && !inDateRange(dateHourAndMinObj, range, 'minute'));
};

export const dateRange = (config = defaultConfig) => {
  const {
    localePath, message, min, max, unit,
  } = config;

  const refDate = moment();
  const rangeDates = {
    min: min || min === 0 ? getRangeDate(min, unit, refDate) : null,
    max: max || max === 0 ? getRangeDate(max, unit, refDate) : null,
  };

  return (value) => {
    if (isPlainObject(value)) {
      return validateDateHourAndMin(value, rangeDates)
        ? message || getMessage(localePath, TIME)
        : undefined;
    }

    if (isString(value)) {
      return validateDate(value, rangeDates)
        ? message || getMessage(localePath, DATE)
        : undefined;
    }

    return undefined;
  };
};

export const dateRangeOrNothing = (config = defaultConfig) => value => (
  (value === INVALID_DATE) ? undefined : (dateRange(config))
);

export default {
  dateRange,
  dateRangeOrNothing,
};
