/* eslint-disable max-len */
import React from 'react';

function SvgWindrad(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g id="ERGO_Icon_black_Windrad" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g>
          <polygon id="Path" fill="#1D1D1B" fillRule="nonzero" points="21.105 47.29125 23.761875 47.29125 23.761875 38.355 21.105 37.00125" />
          <g id="Group" transform="translate(6.375000, 0.750000)" fill="#1D1D1B" fillRule="nonzero">
            <path d="M11.829375,19.239375 C12.0036071,20.3629744 12.6186505,21.37073 13.5382669,22.0394139 C14.4578832,22.7080978 15.6061628,22.9825135 16.72875,22.801875 L16.72875,22.801875 C19.0646192,22.4323242 20.6587564,20.2393096 20.2895382,17.9033878 C19.9203201,15.567466 17.7275325,13.9730166 15.3915581,14.3419021 C13.0555838,14.7107876 11.4608221,16.9033481 11.829375,19.239375 M15.804375,16.965 C15.8887307,16.9516824 15.9739756,16.9447876 16.059375,16.9443716 C16.9333143,16.9426021 17.6526417,17.6313716 17.6887843,18.504565 C17.7249269,19.3777584 17.0649806,20.1236178 16.1938842,20.1940741 C15.3227878,20.2645304 14.5515505,19.6344285 14.4468895,18.7667771 C14.3422285,17.8991256 14.941511,17.1037038 15.804375,16.965" id="Shape" />
            <path d="M11.866875,13.6875 L5.0625,9.42375 L11.01,8.48625 C11.385,8.42625 11.55,8.48625 11.5725,8.50125 C11.5920658,8.53007567 11.6054705,8.56262997 11.611875,8.596875 L12.361875,13.30875 C13.116116,12.7768722 13.976365,12.4142245 14.88375,12.245625 L14.236875,8.1525 C14.236875,8.139375 14.236875,8.12625 14.236875,8.113125 C13.875,6.2175 12.15375,5.615625 10.606875,5.863125 L1.125,7.355625 C0.5788464,7.44356288 0.144230821,7.86052773 0.0337379055,8.40256845 C-0.0767550104,8.94460917 0.159970788,9.49842437 0.628125,9.793125 L10.246875,15.804375 C10.6347115,14.994336 11.1863269,14.273533 11.866875,13.6875" id="Path" />
            <path d="M10.11,21.035625 L3.980625,26.175 L4.918125,20.225625 C4.978125,19.850625 5.083125,19.711875 5.105625,19.696875 C5.12854868,19.6925596 5.15207632,19.6925596 5.175,19.696875 L5.206875,19.696875 L9.894375,20.446875 C9.81321607,20.1669241 9.75058056,19.8819325 9.706875,19.59375 C9.60948641,18.9729866 9.60316991,18.3413368 9.688125,17.71875 L5.600625,17.0625 L5.559375,17.0625 C3.645,16.820625 2.5425,18.271875 2.296875,19.81875 L0.796875,29.27625 C0.710156871,29.8240224 0.97260776,30.3680741 1.45530789,30.6411588 C1.93800801,30.9142434 2.53952111,30.8589742 2.964375,30.5025 L11.625,23.233125 C10.9728122,22.6142335 10.4573859,21.8656839 10.111875,21.035625" id="Path" />
            <path d="M19.15125,24.2175 C18.5017456,24.5761917 17.7950701,24.8197854 17.0625,24.9375 C16.905,24.961875 16.7475,24.97875 16.591875,24.99375 L19.573125,32.38125 L14.206875,29.6475 C13.8675,29.475 13.77,29.3325 13.7625,29.304375 C13.7632384,29.2700737 13.7708911,29.2362741 13.785,29.205 L15.920625,25.014375 C14.9993955,24.9959161 14.0930543,24.7784454 13.26375,24.376875 L11.405625,28.02375 C11.3986476,28.0352277 11.3923857,28.0471253 11.386875,28.059375 C10.56375,29.805 11.604375,31.303125 13.00125,32.015625 L21.5325,36.361875 C22.0276714,36.615223 22.6282198,36.5335105 23.0377006,36.1570727 C23.4471813,35.7806348 23.5790148,35.1890646 23.368125,34.674375 L19.15125,24.2175 Z" id="Path" />
            <path d="M34.95375,17.3325 C34.7250324,16.8272835 34.2056371,16.5181909 33.6525,16.558125 L22.378125,17.341875 C22.393125,17.415 22.40625,17.488125 22.419375,17.56125 C22.5483369,18.3754532 22.519052,19.2068878 22.333125,20.01 L30.294375,19.4475 L26.034375,23.7075 C25.77,23.97375 25.605,24.024375 25.573125,24.024375 L25.573125,24.024375 C25.5406041,24.0127963 25.5106657,23.9949606 25.485,23.971875 L22.153125,20.64 C21.8526573,21.5121783 21.3701044,22.3104678 20.7375,22.981875 L23.625,25.875 L23.653125,25.903125 C24.151376,26.399597 24.8247635,26.6803996 25.528125,26.685 C26.413125,26.685 27.275625,26.235 27.920625,25.59 L34.6875,18.823125 C35.0841523,18.4297434 35.1920829,17.8301289 34.9575,17.323125" id="Path" />
            <path d="M21.31875,5.2725 L24.054375,10.63875 C24.226875,10.978125 24.223125,11.150625 24.211875,11.176875 C24.1913558,11.2045988 24.1651446,11.2276135 24.135,11.244375 L19.895625,13.404375 C20.6381673,13.9547328 21.2539138,14.6580777 21.70125,15.466875 L25.366875,13.591875 L25.4025,13.573125 C27.09,12.635625 27.133125,10.820625 26.420625,9.425625 L22.074375,0.901875 C21.8226119,0.408582629 21.2914012,0.123054889 20.7410765,0.185221972 C20.1907518,0.247389056 19.736624,0.644224931 19.60125,1.18125 L16.854375,12.1875 C17.7467868,12.2994701 18.605854,12.5971097 19.37625,13.06125 L21.31875,5.2725 Z" id="Path" />
          </g>
          <rect id="Rectangle" x="0" y="0" width="48.1655691" height="48" />
        </g>
      </g>
    </svg>
  );
}

export default SvgWindrad;
