/* eslint-disable max-len */
import React from 'react';

const SvgAngehaengteDokumente = props => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <path
      d="M28.029 36.065c-3.807 3.83-10.01 3.83-13.816 0-3.806-3.83-3.806-10.071 0-13.901l12.434-12.51a5.835 5.835 0 018.29 0 5.871 5.871 0 011.72 4.17 5.871 5.871 0 01-1.72 4.17l-11.749 11.81c-.763.768-2 .768-2.763 0a1.974 1.974 0 010-2.78l7.034-7.076-2.763-2.78-7.034 7.076c-2.29 2.304-2.29 6.046 0 8.34a5.833 5.833 0 004.145 1.721 5.833 5.833 0 004.145-1.72L37.7 20.765a9.795 9.795 0 002.86-6.951 9.795 9.795 0 00-2.86-6.95c-3.807-3.83-10.01-3.83-13.816 0L11.44 19.373c-5.333 5.366-5.333 14.095 0 19.47a13.6 13.6 0 009.671 4.025 13.6 13.6 0 009.671-4.024l7.72-7.767-2.764-2.78-7.71 7.767z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

export default SvgAngehaengteDokumente;
