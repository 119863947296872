import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import styles from './styles.jss';

const Button = ({
  classes,
  className,
  disabled,
  category,
  onClick,
  title,
  icon,
  type,
}) => (
  // eslint-disable-next-line react/button-has-type
  <button
    type={type}
    className={[classes.button, classes[category], className].join(' ')}
    disabled={disabled}
    onClick={onClick}
  >
    <span>{title}</span>
    <span>{icon}</span>
  </button>
);

Button.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  category: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  type: PropTypes.string,
};

Button.defaultProps = {
  classes: {},
  className: '',
  disabled: false,
  category: '',
  onClick: () => {},
  title: '',
  icon: '',
  type: 'submit',
};

export { Button as ButtonPlain };
export default injectSheet(styles)(Button);
