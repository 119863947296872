/* eslint-disable max-len */
import React from 'react';

function SvgSpuelmaschine(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g id="icons/05_other/spuelmaschine/48" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="ERGO_Icon_black_Spuelmaschine">
          <path d="M36.061875,5.709375 L11.4375,5.709375 L14.0625,8.334375 L35.17125,8.334375 L35.17125,12.12 L12.75,12.12 L12.75,9.793125 L10.125,7.168125 L10.125,41.08875 C10.1270658,42.0496003 10.906023,42.827719 11.866875,42.82875 L36.061875,42.82875 C37.0224221,42.8277166 37.8008416,42.0492971 37.801875,41.08875 L37.801875,7.45125 C37.800844,6.49039802 37.0227253,5.7114408 36.061875,5.709375 Z M35.17125,14.750625 L35.17125,40.198125 L12.75,40.198125 L12.75,14.750625 L35.17125,14.750625 Z" id="Shape" fill="#1D1D1B" fillRule="nonzero" />
          <path d="M19.97625,19.70625 L27.9375,19.70625 C28.6623737,19.70625 29.25,19.1186237 29.25,18.39375 C29.25,17.6688763 28.6623737,17.08125 27.9375,17.08125 L19.97625,17.08125 C19.2513763,17.08125 18.66375,17.6688763 18.66375,18.39375 C18.66375,19.1186237 19.2513763,19.70625 19.97625,19.70625 Z" id="Path" fill="#1D1D1B" fillRule="nonzero" />
          <rect id="Rectangle" x="0" y="0" width="48" height="48" />
        </g>
      </g>
    </svg>
  );
}

export default SvgSpuelmaschine;
