/* eslint-disable max-len */
import React from 'react';

function SvgHaus2(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g
        id="icons/12_buildings/Haus/48"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="ERGO_Icon_black_Haus2">
          <g
            id="Haus"
            transform="translate(4.500000, 4.875000)"
            fill="#1D1D1B"
            fillRule="nonzero"
          >
            <path
              d="M32.761875,3.009375 L30.103125,3.009375 L30.103125,0.3525 L35.41875,0.3525 L35.41875,7.1925 L32.761875,5.484375 L32.761875,3.009375 Z M11.50125,22.8075 C10.8435102,22.8033762 10.3125,22.2690027 10.3125,21.61125 L10.3125,14.968125 C10.3125,14.3074544 10.8480794,13.771875 11.50875,13.771875 L16.824375,13.771875 C17.4850456,13.771875 18.020625,14.3074544 18.020625,14.968125 L18.020625,21.61125 C18.020625,22.2719206 17.4850456,22.8075 16.824375,22.8075 L11.50125,22.8075 Z M12.6975,20.416875 L15.620625,20.416875 L15.620625,16.164375 L12.6975,16.164375 L12.6975,20.416875 Z M0.15375,12.52125 L1.59,14.75625 L19.47375,3.260625 L37.3575,14.75625 L38.795625,12.52125 L19.483125,0.10125 L0.15375,12.52125 Z M32.761875,34.899375 L24.789375,34.899375 L24.789375,27.961875 C24.7873102,26.658994 23.731631,25.6033148 22.42875,25.60125 L16.51875,25.60125 C15.215869,25.6033148 14.1601898,26.658994 14.158125,27.961875 L14.158125,34.899375 L6.1875,34.899375 L6.1875,14.960625 L3.530625,16.670625 L3.530625,35.1975 C3.53268979,36.4993454 4.58752957,37.5541852 5.889375,37.55625 L14.45625,37.55625 C15.7588276,37.5552178 16.8148078,36.5000764 16.816875,35.1975 L16.816875,28.26 L22.125,28.26 L22.125,35.1975 C22.1270672,36.5000764 23.1830474,37.5552178 24.485625,37.55625 L33.054375,37.55625 C34.3562204,37.5541852 35.4110602,36.4993454 35.413125,35.1975 L35.413125,16.670625 L32.75625,14.960625 L32.761875,34.899375 Z M22.125,22.8075 C21.8079069,22.8075 21.5038285,22.6814075 21.2797857,22.457013 C21.055743,22.2326186 20.9301272,21.9283427 20.9306235,21.61125 L20.9306235,14.968125 C20.9306235,14.3081859 21.4650617,13.7729094 22.125,13.771875 L27.440625,13.771875 C28.1012956,13.771875 28.636875,14.3074544 28.636875,14.968125 L28.636875,21.61125 C28.636875,22.2719206 28.1012956,22.8075 27.440625,22.8075 L22.125,22.8075 Z M23.32125,20.416875 L26.25,20.416875 L26.25,16.164375 L23.326875,16.164375 L23.32125,20.416875 Z"
              id="Shape"
            />
          </g>
          <rect
            id="Rectangle"
            x="0"
            y="0"
            width="47.835"
            height="47.835"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgHaus2;
