import { setSummary, getSummary } from '../services/session/session';

export const extractTemplateData = (nodeId) => {
  const summaryData = document.getElementById(nodeId);

  const excludeRegexp = /Schließen|Öffnen/gi;
  // we need to exclude these because textContent is packing
  // the alt tag of the toggling icons
  const templateData = summaryData ? [...summaryData.childNodes].map(node => ({
    title: node.childNodes[0].textContent.replace(excludeRegexp, ''),
    body: node.childNodes[1].outerHTML,
  })) : [];

  return setSummary(templateData);
};

export const getSummaryData = getSummary;
