import {
  claimsKeys,
  isCurrentUserFlow,
  isOtherPrivateClient,
  compareValues,
  getDate,
  getCountryName,
  isCompany,
  YES_VALUE, YES_LIMITED, NO_VALUE,
} from './formData';
import { getSummaryData } from '../pdfHelpers';
import { getSourceFromSession } from '../../services';

export const PROPERTY_WHAT_HAPPENED = 'property-what-happened';

export const userFlows = {
  FIRE: 'Feuer',
  THEFT: 'Diebstahl, Raub, Gebäudebeschädigung durch Dritte',
  WATER: 'Leitungswasser',
  STORM: 'Sturm/Hagel',
  HAZARDS: 'Elementar',
  GLASS: 'Glasbruch',
  OTHER: 'Sonstiges',
  BICYCLE_THEFT: 'Fahrraddiebstahl',
};

export const insideOutside = {
  BUILDING: 'Gebäude',
  HOUSEHOLD: 'Hausrat',
  BOTH: 'Gebäude und Hausrat',
};

export const insideOutsideBusiness = {
  BUILDING: 'Geschäftsgebäude',
  HOUSEHOLD: 'Geschäftsinhalt',
  BOTH: 'Sach-Gewerbe',
};

export const businessDamagedBuildingInsideOutside = {
  INSIDE: 'Innenbereich',
  OUTSIDE: 'Außenbereich',
  BOTH: 'Innen- und Außenbereich',
};

const whatHappened = {
  [insideOutside.BUILDING]: 'Wohngebäude',
  [insideOutside.HOUSEHOLD]: 'Hausrat',
  [insideOutside.BOTH]: 'Sach',
  GLASS: 'Glas',
  BICYCLE_THEFT: 'Fahrrad',
  OTHER: 'Hausrat',
};

const whatHappenedBusiness = {
  [insideOutsideBusiness.BUILDING]: 'Geschäftsgebäude',
  [insideOutsideBusiness.HOUSEHOLD]: 'Geschäftsinhalt',
  [insideOutsideBusiness.BOTH]: 'Sach-Gewerbe',
  GLASS: 'Glas-Gewerbe',
  OTHER: 'Sach-Gewerbe-Sonstiges',
};

export const damageLocation = {
  INSURED_RISK_LOCATION: 'Am Risikoort (Versicherungsort)',
  ANOTHER_PLACE: 'Nicht am Risikoort',
};
export const fireOptions = {
  LIGHTNING: 'Direkter Blitzeinschlag',
  OVERVOLTAGE: 'Überspannung durch Blitz',
  OPEN_FIRE: 'Offenes Feuer',
  SINGEING_DAMAGE: 'Sengschäden',
  ARSON: 'Brandstiftung',
  EXPLOSION: 'Explosion',
  OTHER: 'Sonstiges',
};

export const bicycleTheftBikeOptions = {
  EBIKE: 'E-Bike',
  BIKE: 'Fahrrad',
  OTHER: 'Sonstiges',
};

export const bicycleTheftOptions = {
  BIKE: 'Gesamtes Fahrrad',
  PARTS: 'Teilentwendung',
};

export const whatItemsStolen = {
  lock: 'Schloss',
  handlebars: 'Lenker',
  basket: 'Fahrradkorb',
  'children-seat': 'Kindersitz',
  battery: 'Batterie',
  saddle: 'Sattel',
  wheel: 'Vorder- und/oder Hinterrad',
  different: 'Sonstiges',
};

export const theftOptions = {
  BICYCLE: 'Fahrraddiebstahl',
  BURGLARY: 'Einbruchdiebstahl',
  ATTEMPTED_BURGLARY: 'Einbruchdiebstahlversuch',
  ROBBERY: 'Diebstahl / Raub',
  VEHICLE: 'Diebstahl aus verschlossenem Kfz',
  GRAFFITI: 'Graffiti',
  OTHER: 'Sonstiges',
};

export const theftBusinessOptions = {
  BURGLARY: 'Einbruchdiebstahl',
  ATTEMPTED_BURGLARY: 'Einbruchdiebstahlversuch',
  ROBBERY: 'Diebstahl / Raub',
  GRAFFITI: 'Graffiti',
  DIFFERENT: 'Sonstiges',
};

export const waterOptions = {
  PIPE_BURST: 'Rohrbruch',
  PIPE_BURST_FROST: 'Rohrbruch durch Frost',
  DEFECTIVE: 'Schadhafte Dichtungen, Armatur, Einrichtung',
  TAPS: 'Verstopfungen/Offenlassen von Hähnen',
  BURST_HOSES: 'Defekte Wasch-,Spülmaschinenschläuche, Armaturen',
  OTHER: 'Sonstiges',
};

export const stormOptions = {
  STORM: 'Sturm',
  HAIL: 'Hagel',
};

export const otherPrivateDamageCause = {
  TRANSPORTATION_DAMAGE: 'Transportmittelschaden',
  UNNAMED_DANGERS: 'Unbenannte Gefahren',
  DIFFERENT: 'Sonstiges',
};

export const otherBusinessDamageCause = {
  EXTENDED_COVERAGE: 'Extended Coverage (Unruhen, böswillige Beschädigungen, Streik, etc.)',
  UNNAMED_DANGERS: 'Unbenannte Gefahren / Unvorhergesehene Zerstörung',
  COMPANY_CLOSURES: 'Betriebsschließungen',
  DIFFERENT: 'Sonstiges',
};

export const naturalOptions = {
  FLOODING: 'Überschwemmung',
  BACKWATER: 'Rückstau',
  SNOW: 'Schneedruck und Lawinen',
  OTHER: 'Sonstiges',
};

export const glassOptions = {
  BREAK: 'Bruch',
  BLINDESS: 'Blindwerden',
  OTHER: 'Sonstiges',
};

export const glassCircumstances = {
  WIND: 'Luftzug/Wind/Sturm',
  PERSONAL_NEGLIGENCE: 'Eigenverschulden/Unvorsichtigkeit',
  EXTERNAL_NEGLIGENCE: 'Fremdverschulden',
  OTHER: 'Sonstiges',
};

export const whatDamagedBuilding = {
  doors: 'Türen/Fenster',
  roof: 'Dach',
  awnings: 'Markisen',
  'external-walls': 'Außenwände',
  walls: 'Wände (Innenbereich)',
  floor: 'Fußboden (Innenbereich)',
  antennas: 'Antennen',
  outhouse: 'Carport oder Nebengebäude',
  'heat-sources': 'Sonnenkollektoren, Energiedächer',
  plants: 'Pflanzen',
  different: 'Sonstiges',
};

export const whatDamagedBuildingBusiness = {
  doors: 'Türen/Fenster',
  roof: 'Dach',
  awnings: 'Markisen',
  'external-walls': 'Außenwände',
  walls: 'Wände (Innenbereich)',
  floor: 'Fußboden (Innenbereich)',
  antennas: 'Antennen',
  outhouse: 'Carport oder Nebengebäude',
  'heat-sources': 'Sonnenkollektoren, Energiedächer',
  plants: 'Pflanzen',
  different: 'Sonstiges',
  advertisement: 'Werbeanlagen',
};

export const whatDamagedBuildingTheftBusiness = {
  'store-window': 'Schaufenster',
  doors: 'Türen',
  floor: 'Fenster',
  different: 'Sonstiges',
};

export const whatDamagedHousehold = {
  valuables: 'Wertsachen (Bargeld, Schmuck etc.)',
  'jewellery-watches': 'Schmuck/Uhren',
  furniture: 'Möbel',
  carpets: 'Teppiche',
  clothing: 'Bekleidung',
  'electronic-devices': 'Elektronik-Geräte',
  bike: 'Fahrrad',
  different: 'Sonstiges',
};

export const whatDamagedHouseholdBusiness = {
  furniture: 'Möbel',
  carpets: 'Teppiche',
  'electronic-devices': 'Elektronik-Geräte',
  cargo: 'Waren/Vorräte',
  people: 'fremdes Eigentum',
  money: 'Bargeld',
  different: 'Sonstiges',
};

export const whichFloor = {
  basement: 'Keller',
  'ground-floor': 'Erdgeschoss',
  'first-floor': 'Obergeschoss',
};

export const haveYouDamagedProperty = {
  OWN_CONTRUBUTION: 'Eigenleistung',
  PARTNER: 'ERGO Sanierungspartner',
  OTHER: 'Sonstige Handwerker',
};

export const glassWhatDamaged = {
  'door-or-window-panes': 'Tür- oder Fensterscheiben',
  hobs: 'Cerankochfelder',
  'fireplace-pane': 'Backofen/Kaminscheibe',
  'display-screen': 'Display/Bildschirm',
  'mirror-table-top': 'Spiegel/Tischplatte',
  'kitchen-worktop': 'Küchenarbeitsplatte',
  'shower-cubicle': 'Duschkabine',
  roofing: 'Überdachung',
  different: 'Sonstiges',
};

export const glassBusinessWhatDamaged = {
  'store-window': 'Schaufenster',
  'door-or-window-panes': 'Tür- oder Fensterscheiben',
  hobs: 'Cerankochfelder',
  'display-screen': 'Display/Bildschirm',
  'mirror-table-top': 'Spiegel/Tischplatte',
  'kitchen-worktop': 'Küchenarbeitsplatte',
  roofing: 'Überdachung',
  different: 'Sonstiges',
};

const isTheftFlow = kindOfDamage => isCurrentUserFlow(
  kindOfDamage, userFlows.THEFT,
);

const isGlassFlow = kindOfDamage => isCurrentUserFlow(
  kindOfDamage, userFlows.GLASS,
);

const isBicycleTheftFlow = kindOfDamage => isCurrentUserFlow(
  kindOfDamage, userFlows.BICYCLE_THEFT,
);

const isOtherPrivateFlow = (kindOfDamage, whoFills) => {
  const isCurrentFlowOther = isCurrentUserFlow(kindOfDamage, userFlows.OTHER);
  const isPrivateFlow = isOtherPrivateClient(whoFills, claimsKeys.property.PRIVATE);

  if (isCurrentFlowOther && isPrivateFlow) {
    return true;
  }
  return false;
};

const isStormFlow = kindOfDamage => isCurrentUserFlow(
  kindOfDamage, userFlows.STORM,
);

const hasAnotherBankAccount = account => compareValues(
  account, claimsKeys.ANOTHER_ACCOUNT,
);

const isBusinessFlow = whoFills => compareValues(whoFills, claimsKeys.property.BUSINESS);

export const getHeadingData = data => {
  const kindOfDamage = data[claimsKeys.KIND_OF_DAMAGE];
  const whoFills = data[claimsKeys.property.WHO_FILLS];
  const isRepaired = data[claimsKeys.IS_REPAIRED];
  if (isGlassFlow(kindOfDamage) && isBusinessFlow(whoFills) && compareValues(isRepaired, YES_VALUE)) {
    return '';
  }
  const firstName = data[claimsKeys.owner.FIRST_NAME];
  if (isCompany(claimsKeys.owner.SALUTATION)(data)) {
    return firstName;
  }
  const lastName = data[claimsKeys.owner.LAST_NAME];

  return `${firstName} ${lastName}`;
};

export const getContactData = data => {
  const firstName = data[claimsKeys.owner.FIRST_NAME];
  const lastName = data[claimsKeys.owner.LAST_NAME];
  const phone = data[claimsKeys.owner.PHONE];
  const email = data[claimsKeys.owner.EMAIL];
  const kindOfDamage = data[claimsKeys.KIND_OF_DAMAGE];
  const whoFills = data[claimsKeys.property.WHO_FILLS];
  const isRepaired = data[claimsKeys.IS_REPAIRED];

  return {
    firstName,
    lastName,
    phone,
    email,
    isCompany: isCompany(claimsKeys.owner.SALUTATION)(data),
    isGlassFlow: isGlassFlow(kindOfDamage),
    isBusinessFlow: isBusinessFlow(whoFills),
    isRepaired: compareValues(isRepaired, YES_VALUE),
  };
};

export const getIncidentDetails = ({
  [claimsKeys.DATE]: date,
  [claimsKeys.property.DAMAGE_LOCATION]: dmgLocation,
  [claimsKeys.LOCATION]: location,
  [claimsKeys.COUNTRY_CODE]: country,
  [claimsKeys.KIND_OF_DAMAGE]: kindOfDamage,
  [claimsKeys.property.CASE]: caseInfo,
  [claimsKeys.property.RESULT_STRUCTURE]: resultStructure,
  [claimsKeys.property.DIVISION]: division,
  [claimsKeys.property.BUILDING_OWNER]: buildingOwner,
  [claimsKeys.CIRCUMSTANCES]: originOfDamage,
  // eslint-disable-next-line no-shadow
  [claimsKeys.property.WHICH_FLOOR]: whichFloor,
  [claimsKeys.property.WAS_LOCKED]: wasLocked,
  [claimsKeys.property.WHAT_WAS_STOLEN]: whatWasStolen,
  [claimsKeys.property.WHO_FILLS]: whoFills,
}) => ({
  date: getDate(date),
  dmgLocation,
  location,
  country: getCountryName(country),
  kindOfDamage,
  caseInfo,
  resultStructure,
  isGlassFlow: isGlassFlow(kindOfDamage),
  isBicycleTheftFlow: isBicycleTheftFlow(kindOfDamage),
  division,
  buildingOwner,
  originOfDamage,
  whichFloor,
  wasLocked,
  whatWasStolen,
  isBusinessFlow: isBusinessFlow(whoFills),
});

const isTheftWithSpecificOptions = (kindOfDamage, caseInfo) => (
  isTheftFlow(kindOfDamage) && [
    theftOptions.BICYCLE,
    theftOptions.BURGLARY,
    theftOptions.ROBBERY,
    theftOptions.VEHICLE,
  ].includes(caseInfo)
);

export const getPoliceDetails = ({
  [claimsKeys.POLICE]: police,
  [claimsKeys.DEPARTMENT]: department,
  [claimsKeys.CASE_NUMBER]: caseNumber,
  [claimsKeys.property.LIST_OF_STOLEN_GOODS]: stolenGoods,
  [claimsKeys.KIND_OF_DAMAGE]: kindOfDamage,
  [claimsKeys.property.CASE]: caseInfo,
  [claimsKeys.property.LIST_OF_STOLEN_GOODS]: stolenGoodsBicycle,
}) => ({
  police,
  department,
  caseNumber,
  stolenGoods,
  stolenGoodsBicycle,
  hasStolenGoods: stolenGoods && isTheftWithSpecificOptions(kindOfDamage, caseInfo),
});

const yesNoLimitedOptions = {
  [YES_VALUE]: 'Ja',
  [YES_LIMITED]: 'Ja, aber eingeschränkt',
  [NO_VALUE]: 'Nein',
};

export const getDamageOfAccidentDetails = ({
  [claimsKeys.property.OBJECT_DESCRIPTION]: householdItems,
  [claimsKeys.property.CAUSE_NOTE]: buildingItems,
  [claimsKeys.property.FLOOR_WALL_COVERING]: floorWallCovering,
  [claimsKeys.IS_REPAIRED]: isRepaired,
  [claimsKeys.property.EXECUTION_OF_REPAIRS]: executionOfRepairs,
  [claimsKeys.property.DAMAGE_DESCRIPTION]: damageDescription,
  [claimsKeys.KIND_OF_DAMAGE]: kindOfDamage,
  [claimsKeys.property.DIVISION]: division,
  [claimsKeys.property.OPERATIONS]: operations,
  [claimsKeys.property.DAMAGE_LOCATION]: dmgLocation,
  [claimsKeys.property.WHO_FILLS]: whoFills,
  [claimsKeys.property.IS_EBIKE]: bikeType,
  [claimsKeys.property.MODEL_DESCRIPTION]: bikeModel,
  [claimsKeys.property.PRICE]: bikePrice,
  [claimsKeys.property.listOfStolenGoods.GOODS_1]: stolenAccessoiresValue,
  [claimsKeys.property.OBJECT_DESCRIPTION]: stolenAccessoires,
}) => ({
  householdItems,
  buildingItems,
  floorWallCovering,
  isRepaired,
  isBicycleTheftFlow: isBicycleTheftFlow(kindOfDamage),
  bikeType,
  bikeModel,
  bikePrice,
  stolenAccessoiresValue,
  stolenAccessoires,
  executionOfRepairs,
  damageDescription,
  isTheftFlow: isTheftFlow(kindOfDamage),
  isGlassFlow: isGlassFlow(kindOfDamage),
  isStormFlow: isStormFlow(kindOfDamage),
  isOtherPrivateFlow: isOtherPrivateFlow(kindOfDamage, whoFills),
  isHouseholdDivision: division === insideOutside.HOUSEHOLD,
  operations: yesNoLimitedOptions[operations],
  dmgLocation,
  isBusinessFlow: isBusinessFlow(whoFills),
});

export const getDamagedItems = ({
  [claimsKeys.property.damageValue.VALUE_1]: item1,
  [claimsKeys.property.damageValue.VALUE_2]: item2,
  [claimsKeys.property.damageValue.VALUE_3]: item3,
  [claimsKeys.property.damageValue.VALUE_4]: item4,
  [claimsKeys.property.damageValue.VALUE_5]: item5,
}) => {
  const items = [item1, item2, item3, item4, item5];
  return items.filter(e => e != null);
};

export const getPayingOutDetails = ({
  [claimsKeys.BANK_ACCOUNT]: account,
  [claimsKeys.IBAN_NAME]: name,
  [claimsKeys.IBAN]: iban,
  [claimsKeys.property.TAX_FOR_DAMAGE]: taxForDamage,
  [claimsKeys.property.WHO_FILLS]: whoFills,
}) => ({
  account,
  name,
  iban,
  hasAnotherBankAccount: hasAnotherBankAccount(account),
  taxForDamage: yesNoLimitedOptions[taxForDamage],
  isBusinessFlow: isBusinessFlow(whoFills),
});

export const getYourMessageDetails = ({
  [claimsKeys.YOUR_MESSAGE]: message,
}) => message;

export const getPageType = ({ [claimsKeys.KIND_OF_DAMAGE]: kindOfDamage }) => {
  let output;
  if (isBicycleTheftFlow(kindOfDamage)) {
    output = { [claimsKeys.PAGE_TYPE]: claimsKeys.BICYCLE_THEFT_DAMAGE_REPORT };
  } else {
    output = { [claimsKeys.PAGE_TYPE]: claimsKeys.DAMAGE_REPORT };
  }
  const source = getSourceFromSession();
  if (source) {
    output[claimsKeys.LANDING_PAGE_SRC] = source;
  }
  return output;
};

export const getWhatHappened = (data) => {
  const flowName = data[claimsKeys.KIND_OF_DAMAGE];
  const insideOutsideValue = data[claimsKeys.property.DIVISION];
  let whatHappenedValue = '';
  if (data[claimsKeys.property.WHO_FILLS] === claimsKeys.property.PRIVATE) {
    if (flowName === userFlows.GLASS) {
      whatHappenedValue = whatHappened.GLASS;
    }
    if (flowName === userFlows.OTHER) {
      whatHappenedValue = whatHappened.OTHER;
    }
  } else if (data[claimsKeys.property.WHO_FILLS] === claimsKeys.property.BUSINESS) {
    if (flowName === userFlows.GLASS) {
      whatHappenedValue = whatHappenedBusiness.GLASS;
    } else if (flowName === userFlows.OTHER) {
      whatHappenedValue = whatHappenedBusiness.OTHER;
    }
  }

  if (flowName === userFlows.BICYCLE_THEFT) {
    whatHappenedValue = whatHappened.BICYCLE_THEFT;
  }

  if (!whatHappenedValue) {
    whatHappenedValue = whatHappened[insideOutsideValue] || whatHappenedBusiness[insideOutsideValue] || '';
  }

  return { [claimsKeys.WHAT_HAPPENED]: whatHappenedValue };
};

export const getAdditionalData = data => ({
  additionalData: {
    isLiability: false,
    firstName: data[claimsKeys.owner.FIRST_NAME],
    lastName: data[claimsKeys.owner.LAST_NAME],
    email: data[claimsKeys.owner.EMAIL],
  },
  summary: getSummaryData(),
});
