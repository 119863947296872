/* eslint-disable max-len */
import React from 'react';

function SvgTelefonhoerer(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h47.835v47.835H0z" />
        <path
          d="M40.113 37.597a3.718 3.718 0 001.1-2.77 3.82 3.82 0 00-1.295-2.727l-6.577-5.771a4.16 4.16 0 00-5.573.198l-1.631 1.589a1.313 1.313 0 01-1.804 0l-4.102-4.142a1.286 1.286 0 01-.364-.917c.003-.328.138-.64.375-.866l1.554-1.553a4.17 4.17 0 00.188-5.625l-5.687-6.562a3.713 3.713 0 00-2.835-1.326c-.971 0-1.875.375-2.685 1.125A15.497 15.497 0 007.2 15.624c-1.125 6.02 1.354 12.132 7.37 18.167 2.438 2.477 4.97 4.382 7.533 5.665l.03.013c.86.412 1.746.772 2.65 1.076h.025c.319.107.653.208 1.026.306l.427-2.661a13.115 13.115 0 01-.63-.197l-.078-.026a20.027 20.027 0 01-2.25-.92c-2.308-1.158-4.598-2.888-6.822-5.15-12.453-12.48-4.23-21.33-3.873-21.7l.03-.029a1.2 1.2 0 01.823-.364c.311.004.605.145.804.385l5.704 6.592a1.5 1.5 0 01-.071 1.969l-1.53 1.532a3.821 3.821 0 00-1.183 2.754 3.967 3.967 0 001.145 2.835l4.1 4.129a3.992 3.992 0 005.59.019l1.63-1.588a1.55 1.55 0 011.029-.402c.332.01.652.126.915.329l.015.013 6.538 5.737c.236.202.376.493.386.803.01.287-.099.565-.302.768a11.73 11.73 0 01-3.245 2.035l.864 2.55c2.66-1.107 4.193-2.599 4.262-2.667z"
          fill="#1D1D1B"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default SvgTelefonhoerer;
