import { mediaQuery } from '../../styles/helpers';
import { breakpoints } from '../../styles/constants'; // eslint-disable-line import/prefer-default-export

const styles = theme => ({
  light: {
    backgroundColor: theme.primaryBasicColor,
  },

  buttonBox: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.buttonBoxPaddingTop,

    ...mediaQuery.max(breakpoints.desktopNav, {
      transform: 'translate3d(0, 0, 0)',
      position: 'fixed',
      top: 'calc(100% - 64px)',
      left: 0,
      width: '100vw',
      padding: 0,
      zIndex: 90,
    }),

    '& button': {
      paddingRight: '26px',
      paddingLeft: '26px',
      marginRight: '0 !important',
      minHeight: '16px',
      display: 'block',
      position: 'relative !important',

      '&:hover': {
        color: theme.eightFontColor,
      },

      ...mediaQuery.max(breakpoints.desktopNav, {
        minHeight: '64px',
        marginTop: 0,
        '&:hover': {
          backgroundColor: theme.thirdBasicColor,
        },
      }),
    },
  },

  children: {
    minHeight: '190px',
    textAlign: 'center',
  },
});

export default styles;
