import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import RadioTile from '@eg/elements/RadioTile';
import styles from './styles.jss';

const ToggleButton = ({
  option,
  classes,
  onClick,
  children,
  selected,
  radioTile,
}) => {
  const disabled = option[0] === '!';
  const clickHandler = disabled ? () => {} : onClick;
  let buttonClassName = '';

  if (selected) {
    buttonClassName = classes.toggleCheckSelected;
  } else if (disabled) {
    buttonClassName = classes.toggleCheckDisabled;
  }

  return (
    <RadioTile
      {...radioTile}
      name={`radiobutton-${option}`}
      className={`${classes.radioTitle} ${buttonClassName}`}
      hideCheckMark
      onClick={clickHandler}
    >
      {children}
    </RadioTile>
  );
};

ToggleButton.propTypes = {
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  option: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  children: PropTypes.node,
  radioTile: PropTypes.shape({
    title: PropTypes.string,
  }),
};

ToggleButton.defaultProps = {
  option: '',
  classes: {},
  selected: false,
  onClick: () => {},
  children: null,
  radioTile: {},
};

export { ToggleButton as ToggleButtonPlain };
export default injectSheet(styles)(ToggleButton);
