/* eslint-disable max-len */
import React from 'react';

const SvgKleidungDamage = props => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <g fill="currentColor" fillRule="nonzero" stroke="currentColor">
      <path
        d="M31.64 7.375l1.738 3.045a2.364 2.364 0 001.807 1.199 2.32 2.32 0 001.995-.81l2.371-2.744-.872 3.37a2.573 2.573 0 00.233 1.883c.314.57.833.987 1.442 1.158.202.058.41.087.62.087h1.38l-2.386 3.997L42.401 20l3.408-6.09c.181-.312.236-.687.153-1.041a1.373 1.373 0 00-.598-.85 1.292 1.292 0 00-.681-.194h-3.37l2.233-8.584c.158-.615-.108-1.262-.643-1.567a1.28 1.28 0 00-1.61.284L35.46 8.691l-4.007-7.019a1.296 1.296 0 00-1.48-.624c-.57.162-.969.702-.973 1.32v10.065l2.64 1.495V7.375z"
        strokeWidth={0.5}
      />
      <path
        d="M30.209 16h-3.854c0 2.14-1.726 3.875-3.854 3.875S18.647 18.14 18.647 16h-3.856L4 22.202l3.084 8.52 4.626-1.55V47h21.582V29.179l4.626 1.55L41 22.21 30.209 16zm6.777 12.781l-3.2-1.073-2.03-.68v18.429H13.25V27.028l-2.03.68-3.2 1.073L5.891 22.9l9.31-5.348h2.125a5.4 5.4 0 005.172 3.878 5.4 5.4 0 005.172-3.878h2.127l9.31 5.348-2.122 5.882z"
        strokeWidth={0.94}
      />
    </g>
  </svg>
);

export default SvgKleidungDamage;
