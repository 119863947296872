const styles = theme => ({
  radioTitle: {
    cursor: 'pointer',
    width: '100% !important',
    height: '100%',
    '& .ee_radio-tile__wrapper': {
      height: '100%',
    },
    '& label': {
      height: '100%',
    },
    [`@media (max-width: ${theme.breakpoints.breakpointS})`]: {
      padding: '10px !important',
    },
  },
  toggleCheckSelected: {
    '& label': {
      backgroundColor: `${theme.colors.themeAdditional3Light} !important`,
    },
  },
});

export default styles;
