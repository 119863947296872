import React from 'react';
import PropTypes from 'prop-types';
import Details from '@eg/elements/Details';
import { SvgSpinacz } from '../../helpers/IconLoader';
import { legalConsentProps } from '../../models/panels';
import { claimsKeys, getFileInfo, getFileInfoBeforeMapped } from '../../helpers/formData/formData';
import { getFileName, getFileExtension } from '../Upload/Preview';

// match first non-greedy
const splitPattern = /[_]/m;
const MAX_FILENAME_LENGTH = 20;

const parseFilename = filename => {
  const { index } = splitPattern.exec(filename);
  const name = filename.substr(index + 1, filename.length);
  const fileName = getFileName(name);
  const extension = getFileExtension(name);
  return fileName.length > MAX_FILENAME_LENGTH ? `${fileName.substr(0, MAX_FILENAME_LENGTH)}... .${extension}` : name;
};

const FileDetails = ({
  formData, localePath, className, whatDocuments,
}, { t }) => (
  <>
    {formData[claimsKeys.TYPE_FILES] && formData[claimsKeys.FILES] && (
      <Details iconLeft={SvgSpinacz} summary={t(`${localePath}.docs.title`)} className={className}>
        <p>{t(`${localePath}.docs.content`)}</p>
        {formData[claimsKeys.FILES].map(file => (
          <p key={file}>{decodeURIComponent(parseFilename(file))}</p>
        ))}
      </Details>
    )}
  </>
);

FileDetails.propTypes = legalConsentProps.sectionProps;
FileDetails.defaultProps = legalConsentProps.sectionDefaults;

FileDetails.contextTypes = {
  t: PropTypes.func,
};

export default FileDetails;
