import { getMessage, VALIDATION_KEYS } from './messages';
import { defaultConfig } from './utils';
import { getTranslationArray } from '../polyglot';

const {
  INVALID_IDENTITY,
} = VALIDATION_KEYS;

const identities = getTranslationArray('dropdowns.identities') || [];

const identityList = identities.reduce(
  (aggr, val) => {
    aggr.push(val.name);
    return aggr;
  },
  [],
);

export const identity = (config = defaultConfig) => {
  const { localePath, message } = config;
  return (value) => (identityList.indexOf(value) === -1
    ? message || getMessage(localePath, INVALID_IDENTITY)
    : undefined);
};

export default {
  identity,
};
