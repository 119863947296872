import React from 'react';
import PropTypes from 'prop-types';
import store from '../../../store';
import { fieldUpdate } from '../../../actions/formData';
import { validate, getValidators } from '../../../helpers/validation/Validator';

import DateTime from '../../../panels/DateTime/DateTime';
import DateHospital from '../../../panels/DateHospital';
import Select from '../../../panels/Select/Select';
import ButtonSwitch from '../../../panels/ButtonSwitch';
import ButtonImageSwitch from '../../../panels/ButtonImageSwitch';
import SummaryNotice from '../../../panels/SummaryNotice/SummaryNotice';
import LongText from '../../../panels/LongText/LongText';
import Location from '../../../panels/Location/Location';
import ThankYou from '../../../panels/ThankYou/ThankYou';
import ShortText from '../../../panels/ShortText/ShortText';
import FileUpload from '../../../panels/FileUpload';
import MultipleSelect from '../../../panels/MultipleSelect/MultipleSelect';
import ErgoMultipleSelect from '../../../panels/ErgoMultipleSelect';
import MultipleSelectWithComment from '../../../panels/MultipleSelectWithComment';
import MultiSelect from '../../../panels/MultiSelect';
import RepairShop from '../../../panels/RepairShop/RepairShop';
import RepairShopPostalCode from '../../../panels/RepairShop/RepairShopPostalCode/RepairShopPostalCode';
import ErgoLicenseNumber from '../../../panels/ErgoLicenseNumber/index';
import ErgoLicenseNumberVehicle from '../../../panels/ErgoLicenseNumberVehicle/index';
import ErgoIban from '../../../panels/ErgoIban';
import CarMap from '../../../panels/CarMap';
import PoliceCase from '../../../panels/PoliceCase/PoliceCase';
import VehicleSummaryPage from '../../../panels/VehicleSummaryPage';
import BrokerSummaryPage from '../../../panels/BrokerSummaryPage/BrokerSummaryPage';
import SelectBool from '../../../panels/SelectBool/SelectBool';
import SelectWorkshops from '../../../panels/SelectWorkshops/SelectWorkshops';
import SelectWantToRepair from '../../../panels/SelectWantToRepair/SelectWantToRepair';
import SelectWantToUseCalculationApp from '../../../panels/SelectWantToUseCalculationApp/SelectWantToUseCalculationApp';
import SelectNumber from '../../../panels/SelectNumber/SelectNumber';
import SummaryTodo from '../../../panels/SummaryTodo/SummaryTodo';
import PersonName from '../../../panels/PersonName/PersonName';
import PoliceReport from '../../../panels/PoliceReport';
import PolicyNumber from '../../../panels/PolicyNumber';
import ContactData from '../../../panels/ContactData';
import ContactDataWithoutEmail from '../../../panels/ContactDataWithoutEmail';
import ContactDataNonpersonal from '../../../panels/ContactDataNonpersonal';
import ErgoContactDataRepairShop from '../../../panels/ErgoContactDataRepairShop';
import ErgoContactDataNotRepairShop from '../../../panels/ErgoContactDataNotRepairShop';
import PostalCode from '../../../panels/PostalCode';
import LocationGeneral from '../../../panels/LocationGeneral';
import LocationAccident from '../../../panels/LocationAccident';
import HospitalLocation from '../../../panels/HospitalLocation';
import InstantRedirect from '../../../panels/InstantRedirect';
import WitnessContactData from '../../../panels/WitnessContactData';
import PersonContactData from '../../../panels/PersonContactData';
import Circumstance from '../../../panels/Circumstance';
import MultipleSelectCircumstance from '../../../panels/MultipleSelectCircumstance';
import PrivateLiabilitySummaryPage from '../../../panels/PrivateLiabilitySummaryPage';
import DateWithLongText from '../../../panels/DateWithLongText';
import LegalProtectionSummaryPage from '../../../panels/LegalProtectionSummaryPage';
import AccidentSummaryPage from '../../../panels/AccidentSummaryPage';
import InjuredContactData from '../../../panels/InjuredContactData';
import BrokerContactData from '../../../panels/BrokerContactData';
import LegalProtectionContactData from '../../../panels/LegalProtectionContactData';
import Call from '../../../panels/Call';
import FormWrapper from '../../../components/FormWrapper/FormWrapper';
import FormNavigationProxy from '../../../components/FormNavigationProxy/FormNavigationProxy';
import { isMandatory } from '../../../helpers/validation/utils';
import MultipleSelectWithColumns from '../../../panels/MultipleSelectWithColumns';
import PropertySummaryPage from '../../../panels/PropertySummaryPage';
import DoublePolicyNumber from '../../../panels/DoublePolicyNumber';
import DateFirstAid from '../../../panels/DateFirstAid';
import DamageValues from '../../../panels/DamageValues';
import ClaimNumberSelector from '../../../panels/ClaimNumberSelector';
import IdentityWithDescription from '../../../panels/IdentityWithDescription';
import WitnessContactDataWithAddress from '../../../panels/WitnessContactDataWithAddress';
import CollisionAbroadLicenseNumber from '../../../panels/CollisionAbroadLicenseNumber';
import CollisionAbroadInsurerSelector from '../../../panels/CollisionAbroadInsurerSelector';
import CollisionAbroadSummaryPage from '../../../panels/CollisionAbroadSummaryPage';
import SelectBoolWithIconList from '../../../panels/SelectBoolWithIconList/SelectBoolWithIconList';
import SelectBoolWithInfoBox from '../../../panels/SelectBoolWithInfoBox/SelectBoolWithInfoBox';
import SelectBoolWithInfoBoxAndPicture from '../../../panels/SelectBoolWithInfoBoxAndPicture/SelectBoolWithInfoBox';
import IcdCodes from '../../../panels/IcdCodes';
import DateTimeWithInfoBoxAndIcon from '../../../panels/DateTimeWithInfoBoxAndIcon/DateTimeWithInfoBoxAndIcon';
import LongTextWithInfoBox from '../../../panels/LongTextWithInfoBox/LongTextWithInfoBox';
import AssetLiabilitySummaryPage from '../../../panels/AssetLiabilitySummaryPage';

const formComponents = {
  DateTime,
  DateHospital,
  Select,
  ButtonSwitch,
  ButtonImageSwitch,
  SummaryNotice,
  LongText,
  Location,
  ThankYou,
  ShortText,
  FileUpload,
  MultipleSelect,
  ErgoMultipleSelect,
  MultipleSelectWithComment,
  MultiSelect,
  RepairShop,
  RepairShopPostalCode,
  ErgoLicenseNumber,
  ErgoLicenseNumberVehicle,
  ErgoIban,
  CarMap,
  PoliceCase,
  PolicyNumber,
  VehicleSummaryPage,
  BrokerSummaryPage,
  SelectBool,
  SelectWorkshops,
  SelectWantToRepair,
  SelectWantToUseCalculationApp,
  SelectNumber,
  SummaryTodo,
  PersonName,
  PoliceReport,
  ContactData,
  ContactDataWithoutEmail,
  ContactDataNonpersonal,
  ErgoContactDataRepairShop,
  ErgoContactDataNotRepairShop,
  PostalCode,
  LocationGeneral,
  LocationAccident,
  HospitalLocation,
  InstantRedirect,
  WitnessContactData,
  PersonContactData,
  Circumstance,
  MultipleSelectCircumstance,
  PrivateLiabilitySummaryPage,
  DateWithLongText,
  LegalProtectionSummaryPage,
  AccidentSummaryPage,
  InjuredContactData,
  BrokerContactData,
  LegalProtectionContactData,
  Call,
  MultipleSelectWithColumns,
  PropertySummaryPage,
  DoublePolicyNumber,
  DateFirstAid,
  DamageValues,
  ClaimNumberSelector,
  IdentityWithDescription,
  WitnessContactDataWithAddress,
  CollisionAbroadLicenseNumber,
  CollisionAbroadInsurerSelector,
  CollisionAbroadSummaryPage,
  SelectBoolWithIconList,
  SelectBoolWithInfoBox,
  SelectBoolWithInfoBoxAndPicture,
  IcdCodes,
  DateTimeWithInfoBoxAndIcon,
  LongTextWithInfoBox,
  AssetLiabilitySummaryPage,
};

const PanelProvider = ({
  formData, params, flowConfig, bindFormSubmit,
}) => {
  const { pathName, name } = params;
  const formikSupportedComponents = [
    'Select',
    'SelectBool',
    'SelectNumber',
    'SelectWorkshops',
    'SelectWantToRepair',
    'SelectWantToUseCalculationApp',
    'ShortText',
    'FileUpload',
    'PoliceCase',
    'DateTime',
    'DateHospital',
    'Location',
    'HospitalLocation',
    'ErgoLicenseNumber',
    'ErgoLicenseNumberVehicle',
    'PersonName',
    'ErgoIban',
    'VehicleSummaryPage',
    'BrokerSummaryPage',
    'ContactData',
    'ContactDataWithoutEmail',
    'ContactDataNonpersonal',
    'ErgoContactDataRepairShop',
    'ErgoContactDataNotRepairShop',
    'LongText',
    'PoliceReport',
    'PolicyNumber',
    'MultipleSelect',
    'ErgoMultipleSelect',
    'MultipleSelectWithComment',
    'MultiSelect',
    'PostalCode',
    'LocationGeneral',
    'LocationAccident',
    'RepairShop',
    'RepairShopPostalCode',
    'CarMap',
    'WitnessContactData',
    'PersonContactData',
    'Circumstance',
    'MultipleSelectCircumstance',
    'ButtonImageSwitch',
    'PrivateLiabilitySummaryPage',
    'DateWithLongText',
    'LegalProtectionSummaryPage',
    'AccidentSummaryPage',
    'InjuredContactData',
    'BrokerContactData',
    'LegalProtectionContactData',
    'Call',
    'MultipleSelectWithColumns',
    'PropertySummaryPage',
    'DoublePolicyNumber',
    'DateFirstAid',
    'DamageValues',
    'ClaimNumberSelector',
    'IdentityWithDescription',
    'WitnessContactDataWithAddress',
    'CollisionAbroadLicenseNumber',
    'CollisionAbroadInsurerSelector',
    'CollisionAbroadSummaryPage',
    'SelectBoolWithIconList',
    'SelectBoolWithInfoBox',
    'SelectBoolWithInfoBoxAndPicture',
    'IcdCodes',
    'DateTimeWithInfoBoxAndIcon',
    'LongTextWithInfoBox',
    'AssetLiabilitySummaryPage',
  ];

  if (flowConfig[pathName]) {
    const config = { ...flowConfig[pathName].find(e => e.name === name) };
    const updateField = e => {
      store.dispatch({
        ...fieldUpdate,
        pathName,
        fieldName: config.name,
        payload: e.target ? e.target.value : e.value,
      });
    };

    const componentType = config.component;
    const InputComponent = formComponents[config.component];
    const data = formData[pathName] && formData[pathName][name];
    delete config.component;
    const localePath = `${pathName}.${name.replace(/_/g, '.')}`;
    const validators = getValidators(config);
    config.isMandatory = isMandatory(validators);

    const inputComponent = (
      <InputComponent
        {...config}
        pathName={pathName}
        pageName={name}
        localePath={`${pathName}.${name.replace(/_/g, '.')}`}
        data={data}
        onChange={updateField}
      />
    );

    const noValidationStrategy = (
      componentType === 'ThankYou'
        ? inputComponent
        : (
          <FormNavigationProxy
            isValid
            renderChildren={() => {
              bindFormSubmit(undefined);
              return inputComponent;
            }}
          />
        )
    );

    const formikStrategy = (
      <FormWrapper
        {...config}
        localePath={localePath}
        onChange={updateField}
        formikConfig={{
          initialValues: {
            [name]: data,
          },
          validate: validate({
            validators,
            localePath,
            name,
          }),
        }}
      >
        {({
          errors, values, handleChange, handleBlur, handleFocus, submitForm, isValid,
        }) => {
          bindFormSubmit(submitForm);
          return (
            <InputComponent
              {...config}
              isValid={isValid}
              errors={errors}
              values={values}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              pathName={pathName}
              pageName={name}
              localePath={`${pathName}.${name.replace(/_/g, '.')}`}
            />
          );
        }}
      </FormWrapper>
    );

    return (
      <>
        {formikSupportedComponents.includes(componentType) // @TODO: add formik support for all panels
          ? formikStrategy
          : noValidationStrategy}
      </>
    );
  }

  return null;
};

PanelProvider.propTypes = {
  formData: PropTypes.objectOf(PropTypes.any).isRequired,
  flowConfig: PropTypes.objectOf(PropTypes.array).isRequired,
  params: PropTypes.shape({
    pathName: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  bindFormSubmit: PropTypes.func,
};

PanelProvider.defaultProps = {
  bindFormSubmit: () => {},
};

export default PanelProvider;
