/* eslint-disable max-len */
import React from 'react';

function SvgFahrrad1(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M37.483 19.953a8.804 8.804 0 00-1.026.064l-1.195-4.205.015-.02h-.02l-1.6-5.627H27.88a1.318 1.318 0 100 2.635h3.787l.85 2.991H20.076l-.82-2.673H13.5a1.318 1.318 0 100 2.635h3.818l.44 1.435-3.038 4.045-1.606 2.129h.01l-4.446 5.892h7.678a5.854 5.854 0 11-5.819-6.493c.189 0 .386 0 .576.027l1.762-2.334a8.262 8.262 0 00-1.508-.288A8.49 8.49 0 1019 29.254h5.213v-.021c.319-.054.606-.226.805-.48l8.234-10.398.677 2.38a8.482 8.482 0 103.554-.782zm-23.52 6.665l1.192-1.583c.373.48.67 1.015.879 1.585l-2.07-.002zm4.82 0a8.585 8.585 0 00-2.006-3.738l1.932-2.564 1.937 6.302h-1.864zm4.603-.054l-2.492-8.138h8.945l-6.453 8.138zm14.097 7.717a5.854 5.854 0 01-2.823-10.977l1.378 4.84a1.318 1.318 0 002.535-.72l-1.381-4.836h.291a5.854 5.854 0 010 11.708v-.015z"
          fill="#1D1D1B"
          fillRule="nonzero"
        />
        <path d="M0 0h48.023v48.023H0z" />
      </g>
    </svg>
  );
}

export default SvgFahrrad1;
