/* eslint-disable max-len */
import React from 'react';

function SvgBerufsSchule(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g>
          <path d="M21.9917961,41.78625 C21.9868814,42.3829933 22.2191584,42.957277 22.6376034,43.3827547 C23.0560484,43.8082324 23.6263814,44.0500479 24.223125,44.0550789 L44.450625,44.0550789 C45.0473683,44.0599936 45.621652,43.8277166 46.0471297,43.4092716 C46.4726074,42.9908266 46.7144229,42.4204936 46.719375,41.82375 L46.719375,32.878125 L44.424375,32.878125 L44.424375,41.77125 L24.285,41.77125 L24.13875,39.1875 C24.13875,39.12375 24.459375,37.82625 24.45,37.7625" id="Path" fill="#1D1D1B" fillRule="nonzero" />
          <path d="M38.690625,19.351875 C38.2824736,18.9500914 37.7314613,18.7268539 37.15875,18.73125 L31.550625,18.73125 C30.9862018,18.725251 30.4425161,18.9437302 30.0391888,19.3386183 C29.6358615,19.7335063 29.4059359,20.2724512 29.3999859,20.836875 L29.3999859,21.444375 C29.3996464,21.5141872 29.4059246,21.5838751 29.41875,21.6525 L29.49,22.0275 L31.365,22.0275 L31.434375,21.64125 C31.4456541,21.57308 31.4512982,21.5040967 31.45125,21.435 L31.45125,20.8725 C31.45125,20.795625 31.46625,20.780625 31.543125,20.780625 L37.15125,20.780625 C37.23,20.780625 37.245,20.795625 37.245,20.8725 L37.245,21.435 C37.2449518,21.5040967 37.2505959,21.57308 37.261875,21.64125 L37.33125,22.0275 L39.20625,22.0275 L39.2775,21.6525 C39.2894009,21.5837505 39.2956717,21.5141445 39.29625,21.444375 L39.29625,20.881875 C39.3045616,20.3118693 39.0868126,19.7617664 38.690625,19.351875 Z" id="Path" fill="#1D1D1B" fillRule="nonzero" />
          <path d="M45.19875,23.5948796 L23.8875,23.5948796 C23.3230768,23.589001 22.7793911,23.8074802 22.3760638,24.2023683 C21.9727365,24.5972563 21.7428109,25.1362012 21.736875,25.700625 L21.736875,29.334375 C21.7405209,30.3284986 22.4250591,31.1905671 23.3925,31.419375 L31.621875,33.253125 L31.621875,35.720625 L37.291875,35.720625 L37.291875,33.283125 L45.6975,31.408125 C46.6620017,31.1767933 47.3439262,30.3168409 47.3495165,29.325 L47.3495165,25.745625 C47.3558768,25.1816975 47.1380739,24.638288 46.7438879,24.2349595 C46.3497019,23.8316309 45.8114283,23.6014277 45.2475,23.5948796 L45.19875,23.5948796 Z M35.50875,33.9375 L33.405,33.9375 L33.405,31.82625 L35.50875,31.82625 L35.50875,33.9375 Z M45.290625,29.341875 C45.290625,29.428125 45.249375,29.4375 45.208125,29.445 L37.291875,31.216875 L37.291875,30.050625 L31.621875,30.050625 L31.621875,31.175625 L23.866875,29.4375 C23.836875,29.4375 23.795625,29.4225 23.795625,29.33625 L23.795625,25.745625 C23.795625,25.66875 23.810625,25.65375 23.8875,25.65375 L45.19875,25.65375 C45.275625,25.65375 45.290625,25.66875 45.290625,25.745625 L45.290625,29.341875 Z" id="Shape" fill="#1D1D1B" fillRule="nonzero" />
          <path d="M13.98375,16.021875 C14.67238,16.021875 15.230625,15.46363 15.230625,14.775 C15.230625,14.08637 14.67238,13.528125 13.98375,13.528125 C13.29512,13.528125 12.736875,14.08637 12.736875,14.775 C12.736875,15.46363 13.29512,16.021875 13.98375,16.021875 L13.98375,16.021875 M18.954375,16.0087514 C19.6430039,16.0097848 20.2020869,15.4523798 20.2031236,14.7637509 C20.2041575,14.075122 19.646753,13.5160386 18.9581241,13.5150014 C18.2694952,13.513967 17.7104113,14.0713711 17.709375,14.76 L17.709375,14.76 C17.709375,15.4475945 18.2667805,16.005 18.954375,16.005 M6.5175,13.92375 C6.5175,8.41988729 10.9792623,3.958125 16.483125,3.958125 C21.9869877,3.958125 26.44875,8.41988729 26.44875,13.92375 C26.44875,19.4276127 21.9869877,23.889375 16.483125,23.889375 L16.483125,23.889375 C10.9801198,23.8873054 6.51956958,19.4267552 6.5175,13.92375 M9.17433065,13.92375 C9.16295424,17.2202044 11.3585495,20.1167523 14.5354868,20.9964181 C17.712424,21.8760839 21.0851595,20.5213576 22.7708897,17.6885046 C24.45662,14.8556517 24.0385237,11.245136 21.75,8.8725 C21.174375,9.60375 20.266875,10.3275 18.841875,10.801875 C17.075625,11.394375 15.500625,11.4375 14.109375,11.47125 C12.4725,11.5125 11.0625,11.55 9.38625,12.375 L9.339375,12.39375 C9.2300794,12.9002281 9.1747705,13.4168635 9.17433065,13.935" id="Shape" fill="#1D1D1B" fillRule="nonzero" />
          <path d="M6.4725,26.25 C6.073125,26.25 5.315625,26.8125 4.87875,27.129375 C0.9375,29.8125 0.285,35.675625 1.545,42 L4.26,42 C3.57375,38.825625 3.435,36.069375 3.86625,33.78 C3.933563,33.41943 4.01993408,33.0626799 4.125,32.71125 C4.629375,36.315 5.683125,39.69 6.789375,42.050625 L9.58125,42.050625 C8.16,39.020625 6.433125,33.110625 6.43875,28.415625 L6.4725,26.25 Z" id="Path" fill="#1D1D1B" fillRule="nonzero" />
          <path d="M20.68875,26.25 C16.91625,28.09875 14.0175,27.958125 10.245,26.109375 C9.7730527,25.8805766 9.25445367,25.7644053 8.73,25.77 C8.61848249,25.7728908 8.50690719,25.7672494 8.39625,25.753125 C7.906875,25.666875 7.68375,25.636875 7.64625,25.77375 L7.6125,26.045625 C7.599375,26.17125 7.49625,27.328125 8.019375,29.199375 C8.70375,31.606875 10.06875,34.0875 12.0975,36.5625 L12.165,36.639375 C12.395625,36.88125 17.825625,42.585 19.3875,43.869375 L19.426875,43.81875 L19.45125,43.86375 C19.843125,43.64625 23.32125,41.7 24.765,40.254375 C26.945625,38.07375 26.769375,35.39625 24.28125,32.91 C21.85875,30.48375 19.14,31.27125 18,32.0625 L13.824375,34.535625 C12.170625,32.42625 11.27625,30.598125 10.794375,29.21625 C14.919375,30.680625 17.146875,30.44625 20.7075,29.29125 M19.365,34.2975 L19.453125,34.24125 C19.51125,34.2 20.89125,33.25125 22.415625,34.775625 C23.41125,35.773125 24.3525,36.95625 22.899375,38.409375 C22.258125,39.0525 20.900625,39.94875 19.749375,40.659375 C18.568125,39.51 16.95,37.846875 15.654375,36.51375 L19.365,34.2975 Z" id="Shape" fill="#1D1D1B" fillRule="nonzero" />
          <rect id="Rectangle" x="-5.46229728e-13" y="-3.46389584e-14" width="48" height="48" />
        </g>
      </g>
    </svg>
  );
}

export default SvgBerufsSchule;
