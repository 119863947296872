/* eslint-disable max-len */
import React from 'react';

const SvgKey = props => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <g fill="currentColor" fillRule="nonzero">
      <path d="M45.982 43.412l-.673-4.506a2.797 2.797 0 00-.825-2.04L28.634 20.98c2.714-6.805-.336-14.552-6.955-17.666C15.06.2 7.166 2.797 3.676 9.238c-3.49 6.44-1.365 14.491 4.845 18.36 6.21 3.87 14.353 2.215 18.571-3.772l15.113 15.148c0 .077.007.154.018.23l.528 3.544-3.551-.52c-.053 0-.105-.014-.158-.016a.587.587 0 01-.159-.092l-1.588-1.59.024-3.899a1.554 1.554 0 00-1.632-1.563l-2.828.14-.123-3.235a1.546 1.546 0 00-1.579-1.494l-3.857.084-1.256-1.257a16.52 16.52 0 01-2.709 1.673l2.25 2.25c.298.299.705.462 1.127.453l2.995-.065.122 3.284a1.552 1.552 0 001.625 1.49l2.759-.137-.018 2.905c-.002.414.162.813.455 1.105l2.042 2.05a3.491 3.491 0 002.145.996l4.587.674c.145.021.292.021.437 0a2.46 2.46 0 002.125-2.137c.02-.144.018-.291-.004-.435zM23.414 23.399a10.577 10.577 0 01-14.982 0c-4.138-4.146-4.138-10.87 0-15.016a10.577 10.577 0 0114.982 0c4.13 4.15 4.13 10.867 0 15.016z" />
      <path d="M14.43 8.696a4.782 4.782 0 00-3.377 8.164 4.782 4.782 0 103.378-8.164zm1.126 5.906a1.59 1.59 0 01-2.251 0 1.59 1.59 0 112.251 0z" />
    </g>
  </svg>
);

export default SvgKey;
