const styles = theme => ({
  wrapper: {
    fontSize: '16px',
    display: 'block',
    cursor: 'pointer',
  },

  containerBox: {
    marginLeft: '10px',
    [`@media (max-width: ${theme.breakpoints.breakpointS})`]: {
      width: '85%',
    },
  },

  mainText: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.themeDarkestGray,
    textAlign: 'left',
  },

  toggleCheck: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    outline: 'none',
    border: `1px solid ${theme.colors.themeLightGray}`,
    backgroundColor: theme.colors.themeLightestGray,
    width: '100%',
    margin: ' 16px 0',
    padding: '12px',
    transition: 'all .3s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
  },

  toggleCheckSelected: {
    backgroundColor: theme.colors.themeAdditional3Light,
    '& $svg': {
      opacity: 1,
    },
  },

  signBox: {
    fontSize: '12px',
    border: `1px solid ${theme.colors.themePrimary}`,
    padding: '2px 5px 2px',
    color: theme.colors.themePrimary,
    height: '24px',
    width: '26px',
    lineHeight: '20px',
  },

  svg: {
    position: 'absolute',
    right: '28px',
    fill: `${theme.colors.extendedIconBlack} !important`,
    opacity: 0,
    transition: 'opacity .3s ease-in',
    transitionDelay: '.1s',
  },

  // eslint-disable-next-line
  ['@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)']: {
    // IE10+ CSS here
    svg: {
      top: '12px',
    },
  },
  // eslint-enable

});

export default styles;
