import React from 'react';
import { camelCase } from 'lodash';

export const linesToParagraphs = (...nodes) => {
  const children = [];
  let key;
  nodes.forEach((node, i) => {
    key = i;
    if (typeof node === 'string') {
      node.split('\n').forEach((item, j, arr) => {
        children.push(item);
        if (arr.length > 1 && arr.length - 1 !== j) {
          children.push(<p key={key} style={{ marginTop: '24px' }} />);
        }
      });
    } else {
      children.push({ ...node, key: i });
    }
  });
  return children;
};

export const objectsForTheme = collection => Object.assign(
  {}, ...collection.default.map(col => ({ [camelCase(col.name)]: col.value })),
);
