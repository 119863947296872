import { getMessage, VALIDATION_KEYS } from './messages';
import { defaultConfig } from './utils';

const {
  INVALID_POLICY_NUMBER,
  INVALID_LEGAL_PROTECTION_POLICY_NUMBER,
  INVALID_ACCIDENT_POLICY_NUMBER,
  INVALID_PROPERTY_POLICY_NUMBER,
  INVALID_COLLISION_ABROAD_POLICY_NUMBER,
} = VALIDATION_KEYS;

const POLICY_TEST = /^(HA|SV)\d{1,9}$/i;

export const policyNumber = (config = defaultConfig) => {
  const { localePath, message } = config;
  return (value) => (!POLICY_TEST.test(value)
    ? message || getMessage(localePath, INVALID_POLICY_NUMBER)
    : undefined);
};

const LEGAL_PROTECTION_POLICY_TEST = /^(DA|SV)\d{1,9}$/i;

export const legalProtectionPolicyNumber = (config = defaultConfig) => {
  const { localePath, message } = config;
  return (value) => (!LEGAL_PROTECTION_POLICY_TEST.test(value)
    ? message || getMessage(localePath, INVALID_LEGAL_PROTECTION_POLICY_NUMBER)
    : undefined);
};

// starting in SV, UV, LV, 103
const ACCIDENT_POLICY_TEST = /^(UV|SV|LV|UR)\d{1,9}$|^103\d{1,10}$|^26\d{1,8}$|^0\d{4}\/\d{7}-\d{2}$/i;

export const accidentPolicyNumber = (config = defaultConfig) => {
  const { localePath, message } = config;
  return (value) => (!ACCIDENT_POLICY_TEST.test(value)
    ? message || getMessage(localePath, INVALID_ACCIDENT_POLICY_NUMBER)
    : undefined);
};

// starting in SV, AS, 103, 26
const PROPERTY_POLICY_TEST = /^(AS|SV)\d{1,9}$|^(103)\d{1,10}$|^(26)\d{1,8}$/i;

export const propertyPolicyNumber = (config = defaultConfig) => {
  const { localePath, message } = config;
  return (value) => {
    if (!value) return undefined;
    return !PROPERTY_POLICY_TEST.test(value)
      ? message || getMessage(localePath, INVALID_PROPERTY_POLICY_NUMBER)
      : undefined;
  };
};

const COLLISION_ABROAD_POLICY_TEST = /^[A-Z\-0-9]+$/s;

export const collisionAbroadPolicyNumber = (config = defaultConfig) => {
  const { localePath, message } = config;
  return (value) => {
    if (!value) return undefined;
    return !COLLISION_ABROAD_POLICY_TEST.test(value)
      ? message || getMessage(localePath, INVALID_COLLISION_ABROAD_POLICY_NUMBER)
      : undefined;
  };
};


export default {
  policyNumber,
  legalProtectionPolicyNumber,
  accidentPolicyNumber,
  propertyPolicyNumber,
  collisionAbroadPolicyNumber,
};
