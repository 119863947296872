/* eslint-disable max-len */
import React from 'react';

function SvgHand9(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g fill="none" fillRule="evenodd">
        <g fill="#1D1D1B" fillRule="nonzero">
          <path d="M40.725 25.05h-3.14a2.38 2.38 0 00-2.354 2.063l-2.638-1.875a1.312 1.312 0 00-.71-.24l-8.86-.336h-.077a6.638 6.638 0 00-2.525.63c-1.75.838-2.764 2.396-2.854 4.384a1.313 1.313 0 001.312 1.372h6.938v-2.625h-5.192c.684-.986 1.978-1.125 2.353-1.136l8.42.319 3.816 2.705v3.608l-.956-.895a1.313 1.313 0 00-1.172-.324l-11.058 2.363L8.11 25.459c1.36-1.009 2.284-.563 2.458-.46a.405.405 0 00.07.045l5.267 3.154 1.35-2.25-5.241-3.14a4.17 4.17 0 00-2.239-.617H9.75c-1.147 0-2.895.457-4.708 2.587a1.312 1.312 0 00.253 1.931L21 37.545c.297.206.666.28 1.02.204l10.937-2.34 2.25 2.104v3.433a1.313 1.313 0 002.625 0V27.675h2.642v13.29a1.313 1.313 0 002.625 0v-13.54a2.378 2.378 0 00-2.374-2.375z" />
          <path d="M40.725 25.05h-3.14a2.38 2.38 0 00-2.354 2.063l-2.638-1.875a1.312 1.312 0 00-.71-.24l-8.86-.336h-.077a6.638 6.638 0 00-2.525.63c-1.75.838-2.764 2.396-2.854 4.384a1.313 1.313 0 001.312 1.372h6.938v-2.625h-5.192c.684-.986 1.978-1.125 2.353-1.136l8.42.319 3.816 2.705v3.608l-.956-.895a1.313 1.313 0 00-1.172-.324l-11.058 2.363L8.11 25.459c1.36-1.009 2.284-.563 2.458-.46a.405.405 0 00.07.045l5.267 3.154 1.35-2.25-5.241-3.14a4.17 4.17 0 00-2.239-.617H9.75c-1.147 0-2.895.457-4.708 2.587a1.312 1.312 0 00.253 1.931L21 37.545c.297.206.666.28 1.02.204l10.937-2.34 2.25 2.104v3.433a1.313 1.313 0 002.625 0V27.675h2.642v13.29a1.313 1.313 0 002.625 0v-13.54a2.378 2.378 0 00-2.374-2.375z" />
          <path d="M18.058 23.824l-.724-.477c-1.582-1.046-3.864-2.705-5.74-4.687-2.38-2.49-3.586-4.92-3.586-7.211a5.893 5.893 0 0110.05-4.178 5.835 5.835 0 014.157-1.715 5.899 5.899 0 015.891 5.893c0 2.291-1.205 4.721-3.585 7.22-1.875 1.975-4.159 3.634-5.741 4.688l-.722.467zM16.515 9.489a3.266 3.266 0 00-5.882 1.96c0 1.595.964 3.416 2.863 5.411a30 30 0 004.562 3.808 30.068 30.068 0 004.562-3.808c1.9-1.995 2.861-3.816 2.861-5.411a3.266 3.266 0 00-5.882-1.96l-1.54 2.063" />
        </g>
        <path d="M0 0h48v48H0z" />
      </g>
    </svg>
  );
}

export default SvgHand9;
