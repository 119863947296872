import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import styles from './styles.jss';

const ContentWrapper = ({
  category,
  classes,
  children,
  className,
}) => (
  <div className={[classes[category], className, 'esc_container'].join(' ')}>
    <div className={classes.children}>
      {children}
    </div>
  </div>
);

ContentWrapper.propTypes = {
  category: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

ContentWrapper.defaultProps = {
  category: '',
  classes: {},
  className: '',
};

export { ContentWrapper as ContentWrapperPlain };
export default injectSheet(styles)(ContentWrapper);
