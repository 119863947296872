import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import CloseIcon from '@eg/elements/Icons/CloseIcon';
import styles from './styles.jss';

export const getFileName = (fileName = ' ') => {
  const dotPosition = fileName.lastIndexOf('.');
  if (dotPosition < 1 || dotPosition === fileName.length - 1) {
    return fileName;
  }

  return fileName.slice(0, dotPosition);
};

export const getFileExtension = (fileName = ' ') => {
  const dotPosition = fileName.lastIndexOf('.');
  return fileName.slice((Math.max(0, dotPosition) || Infinity) + 1);
};

const Preview = ({
  file,
  classes,
  onRemove,
}) => {
  const fileName = getFileName(file.fileName);
  return (
    <div className={classes.preview}>
      <div className={classes.fileWrap}>
        <span className={classes.fileName} title={fileName}>{fileName}</span>
        <span>{`.${getFileExtension(file.fileName)}`}</span>
      </div>
      <button
        type="button"
        className={classes.closeButton}
        onClick={onRemove}
      >
        <CloseIcon className={classes.svg} />
      </button>
    </div>
  );
};

Preview.propTypes = {
  onRemove: PropTypes.func,
  file: PropTypes.objectOf(PropTypes.string),
  classes: PropTypes.objectOf(PropTypes.string),
};

Preview.defaultProps = {
  file: {
    fileName: '',
  },
  classes: {},
  onRemove: () => {},
};

export { Preview as PreviewPlain };
export default injectSheet(styles)(Preview);
