import { moduleNames } from '../services/claimData/claimData';
import { getTranslationArray } from './polyglot';

export const customerDataUrl = {
  [moduleNames.VEHICLE]: {
    contact: [
      'animal-damage_details_policyholder-contact',
      'fire-or-explosion_contact-data',
      'glass-damage_contact-data',
      'theft_contact-person',
      'theft_part_contact-person',
      'vandalism_contact-data',
      'collision_contact-person',
      'damage-caused-by-weather_contact-data',
    ],
    specialClaimNumber: [
      'animal-damage_details_vehicle-id_license-number',
      'fire-or-explosion_vehicle-id-ergo_license-number',
      'glass-damage_vehicle-id_license-number',
      'theft_license-number',
      'theft_part_license-number',
      'vandalism_vehicle-id-ergo_license-number',
      'collision_vehicle-id-ergo_license-number',
      'damage-caused-by-weather_vehicle-id-ergo_license-number',
      'liability_vehicle-id-ergo-liability_license-number',
    ],
    beforeSpecialClaimNumber: [
      'collision_event-circumstances_guilt',
      'animal-damage_details_circumstances-animal_car-map',
      'glass-damage_circumstances_describe-damage',
      'damage-caused-by-weather_event-circumstances-and-aftermath_car-map',
      'vandalism_event-circumstances-and-aftermath_car-map',
      'fire-or-explosion_event-circumstances_car-map',
      'theft_theft-location',
      'theft_part_theft-circumstances',
      'liability_event-circumstances_guilt',
    ],
    afterSpecialClaimNumber: [
      'animal-damage_details_policyholder-contact',
      'collision_contact-person',
      'glass-damage_contact-data',
      'damage-caused-by-weather_contact-data',
      'vandalism_contact-data',
      'fire-or-explosion_contact-data',
      'theft_contact-person',
      'theft_part_contact-person',
      'liability_contact-person',
    ],
  },
  [moduleNames.PRIVATE_LIABILITY]: {
    contact: [
      'policy-holder_contact',
      'liability_contact-details_contact',
    ],
    claimNumber: [
      'policy-holder_policy-number',
      'liability_non-ergo-id_policy-number',
    ],
  },
  [moduleNames.LEGAL_PROTECTION]: {
    contact: ['policyholder-contact'],
    claimNumber: ['id-policy-number'],
  },
  [moduleNames.ACCIDENT]: {
    contact: ['injured_policyholder-contact-data'],
    claimNumber: ['id-policy-number'],
  },
  [moduleNames.PROPERTY]: {
    contact: [
      'fire_have-you-damage_contact',
      'fire_have-you-damage-without-repair_contact',
      'theft_have-you-damage_contact',
      'theft_have-you-damage-without-repair_contact',
      'water_have-you-damage_contact',
      'water_have-you-damage-with-partner_contact',
      'water_have-you-damage-without-repair_contact',
      'storm-hail_have-you-damage-no-artisan-work_contact',
      'storm-hail_have-you-damage-without-repair_contact',
      'natural-hazards_have-you-damage-no-artisan-work_contact',
      'natural-hazards_have-you-damage-without-repair_contact',
      'glass-breakage_have-you-damage_contact',
    ],
    claimNumber: [
      'fire_id-policy-number_one-item',
      'theft_id-policy-number_one-item',
      'water_id-policy-number_one-item',
      'storm-hail_id-policy-number_one-item',
      'natural-hazards_id-policy-number_one-item',
      'glass-breakage_id-policy-number_one-item',
    ],
    claimNumbers: [
      'fire_ids-policy-number_two-items',
      'theft_ids-policy-number_two-items',
      'water_ids-policy-number_two-items',
      'storm-hail_ids-policy-number_two-items',
      'natural-hazards_ids-policy-number_two-items',
    ],
  },
  [moduleNames.COLLISION_ABROAD]: {
    contact: ['contact_person'],
    address: ['contact_address'],
  },
};

const getSalutationKey = who => {
  const labels = getTranslationArray('common.salutation');
  let currentKey = '';
  Object.keys(labels).forEach((key) => {
    if (labels[key] === who) {
      currentKey = key;
    }
  });
  return currentKey;
};

export const buildContactData = data => ({
  who: data.salutation ? getSalutationKey(data.salutation) : '',
  firstName: data.firstName,
  lastName: data.lastName,
});

export const buildClaimNumber = data => (data.claimNumber ? data.claimNumber : '');

export const buildSpecialClaimNumber = data => ({
  claimNumber: data.claimNumber,
});

const getCountryName = countryCode => {
  const countryList = getTranslationArray('dropdowns.countries');
  const currentCountry = countryList.find(obj => obj.value === countryCode);
  return currentCountry ? currentCountry.name : 'Deutschland';
};

export const buildAddressData = data => ({
  address: data.address,
  postCode: data.postCode,
  city: data.city,
  country: getCountryName(data.country),
});
