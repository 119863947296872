const paths = {
  HOME: '/',
  SOURCE: '/:source',
  CLAIM_END: '/claim/:pathName/thank-you',
  CLAIM_START: '/claim/:pathName',
  CLAIM: '/claim/:pathName/:name',
  SHARE: '/share/:token',
  NOT_FOUND: '/404/',
  HOME_NO_SLASH: '',
  CLAIM_PARTIAL: 'claim',
  UPLOAD_SCREEN_FROM: '/upload/:screen',
  UPLOAD_SCREEN_TO: '/claim/upload/:screen',
  UPLOAD_FROM: '/upload',
  UPLOAD_TO: '/claim/upload',
};

export const getRoot = path => path.split('/')[1];

export default paths;
