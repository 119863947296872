/* eslint-disable max-len */
import React from 'react';

function SvgFußgaenger(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g>
          <path d="M33.294375,5.9025 C32.7309237,3.27018956 30.140539,1.5928033 27.5080957,2.15563359 C24.8756524,2.71846388 23.197655,5.30845277 23.7598643,7.94102878 C24.3220735,10.5736048 26.9116664,12.2522131 29.544375,11.690625 C30.8094419,11.4207712 31.9153972,10.6592034 32.6187349,9.57360165 C33.3220727,8.48799985 33.5651241,7.16737554 33.294375,5.9025 Z M29.083125,9.52125 C27.6458039,9.83191017 26.2287852,8.91857106 25.918125,7.48125 C25.6074648,6.04392894 26.5208039,4.62691017 27.958125,4.31625 L27.969375,4.31625 L27.969375,4.3275 C29.3945285,4.04259398 30.7841272,4.95566163 31.0882615,6.37683586 C31.3923959,7.79801009 30.4981856,9.19981781 29.08125,9.523125 L29.083125,9.52125 Z" id="Shape" fill="#1D1D1B" fillRule="nonzero" />
          <path d="M24.121875,13.6875 L28.475625,14.25 C28.7010963,14.2787553 28.90017,14.4112399 29.01375,14.608125 L33.106875,21.71625 C33.1813116,21.8453923 33.293995,21.9482486 33.429375,22.010625 L38.600625,24.448125 C38.649375,24.470625 40.929375,25.044375 39.931875,27.226875 C39.369375,28.66125 37.5375,27.815625 37.395,27.751875 L30.91125,24.87 C30.7767776,24.8109201 30.6635905,24.7121267 30.586875,24.586875 L29.82375,23.34 C29.6619788,23.0778198 29.3526079,22.9459592 29.0514423,23.0108256 C28.7502767,23.0756921 28.5226202,23.3232201 28.483125,23.62875 L27.79125,29.03625 C27.7729041,29.1804165 27.7983848,29.326767 27.864375,29.45625 L33.52125,40.648125 C33.5775,40.756875 35.19375,43.5 32.761875,44.623125 C30.33,45.74625 29.21625,43.580625 29.143125,43.438125 L20.86125,27.553125 C20.7901624,27.4185944 20.7638865,27.2648802 20.78625,27.114375 L21.684375,21.631875 C21.7293511,21.3311492 21.5820577,21.0342756 21.315388,20.8881725 C21.0487184,20.7420693 20.7192373,20.7777274 20.49,20.9775 L19.3125,21.976875 C19.2046459,22.0706163 19.1264567,22.1937644 19.0875,22.33125 L17.5875,27.691875 C17.5465161,27.8398048 17.4599053,27.9710333 17.34,28.066875 L16.59,28.66875 C16.4059719,28.8143202 16.1631485,28.8624662 15.9375,28.798125 L14.833125,28.483125 C14.625619,28.4239757 14.4552003,28.2755465 14.368125,28.078125 L14.1,27.47625 C14.0312133,27.3216618 14.0186119,27.1478953 14.064375,26.985 L15.92625,20.30625 C15.9459942,20.2396608 15.9757058,20.1764445 16.014375,20.11875 L16.275,19.71375 C16.3178091,19.6475549 16.3709885,19.5886777 16.4325,19.539375 L23.589375,13.820625 C23.7425972,13.7082385 23.9337911,13.66044 24.121875,13.6875 Z" id="Path" stroke="#1D1D1B" strokeWidth="2.625" />
          <path d="M20.229375,32.40375 L16.8,34.65375 L11.128125,32.030625 C11.128125,32.030625 8.821875,31.468125 7.88625,33.58875 C7.40970855,34.5567449 7.6692462,35.7258334 8.510625,36.40125 L17.7975,40.3125 L22.66125,37.26" id="Path" stroke="#1D1D1B" strokeWidth="2.625" strokeLinejoin="round" />
          <rect id="Rectangle" x="0" y="0" width="48" height="48" />
        </g>
      </g>
    </svg>
  );
}

export default SvgFußgaenger;
