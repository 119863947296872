import React from 'react';
/* eslint-disable */
export default (props) => (
  <svg {...props}>
    <g id="Page-1--Animal-flow-" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="ECM-524" transform="translate(-294.000000, -349.000000)" fill="#F6CB00">
            <g id="License-plate-input-field" transform="translate(289.000000, 339.000000)">
                <g id="DE-plate">
                    <g id="stars" transform="translate(5.000000, 10.000000)">
                        <polygon id="Star" points="7 1.5 6.41221475 1.80901699 6.52447174 1.1545085 6.04894348 0.690983006 6.70610737 0.595491503 7 0 7.29389263 0.595491503 7.95105652 0.690983006 7.47552826 1.1545085 7.58778525 1.80901699"></polygon>
                        <polygon id="Star" transform="translate(10.151624, 1.652697) rotate(28.000000) translate(-10.151624, -1.652697) " points="10.1431973 2.31144289 9.51099432 2.66061744 9.63793666 1.93227399 9.13390545 1.42195625 9.83812595 1.30934494 10.1588205 0.644776675 10.4671103 1.30352251 11.1693416 1.40311448 10.6556548 1.92285311 10.7689631 2.64897259"></polygon>
                        <polygon id="Star" transform="translate(12.226053, 3.451591) rotate(55.000000) translate(-12.226053, -3.451591) " points="12.2165021 4.11669027 11.5901989 4.47014072 11.7168423 3.73423835 11.218576 3.21930118 11.9158529 3.10473982 12.2342106 2.43304069 12.5385079 3.09814025 13.2335301 3.19794453 12.7243193 3.72356002 12.835509 4.45694158"></polygon>
                        <polygon id="Star" transform="translate(13.210180, 5.984373) rotate(83.000000) translate(-13.210180, -5.984373) " points="13.2077208 6.65662332 12.588539 7.0076124 12.7086021 6.26747975 12.2098421 5.74491926 12.9013486 5.63508312 13.2122798 4.9611339 13.5195912 5.63338407 14.2105172 5.73942104 13.7089396 6.26473064 13.8250242 7.00421431"></polygon>
                        <polygon id="Star" transform="translate(12.875038, 8.673577) rotate(111.000000) translate(-12.875038, -8.673577) " points="12.8818394 9.34262713 12.2645992 9.68633592 12.3774756 8.94929308 11.8721196 8.42287896 12.5643167 8.32046898 12.8692296 7.65141837 13.1841546 8.32516836 13.8779572 8.43808647 13.3803944 8.95689684 13.5042751 9.69573468"></polygon>
                        <polygon id="Star" transform="translate(11.300488, 10.903847) rotate(138.000000) translate(-11.300488, -10.903847) " points="11.3105959 11.5654133 10.6886657 11.903546 10.8000034 11.1739098 10.2879362 10.6505832 10.9863989 10.5517452 11.2918542 9.89017897 11.6121901 10.5587298 12.3130391 10.673186 11.8125549 11.1852112 11.9402481 11.9175152"></polygon>
                        <polygon id="Star" transform="translate(8.845511, 12.166766) rotate(166.000000) translate(-8.845511, -12.166766) " points="8.85028251 12.8243524 8.22084699 13.1631504 8.33754619 12.4392108 7.8241137 11.9234006 8.52931854 11.8213736 8.84143533 11.1637873 9.16057668 11.8246708 9.86690796 11.9340704 9.35894333 12.4445457 9.48336328 13.1697447"></polygon>
                        <polygon id="Star" transform="translate(6.069978, 12.166766) rotate(194.000000) translate(-6.069978, -12.166766) " points="6.06520671 12.8243524 5.43212595 13.1697447 5.5565459 12.4445457 5.04858126 11.9340704 5.75491254 11.8246708 6.0740539 11.1637873 6.38617069 11.8213736 7.09137552 11.9234006 6.57794303 12.4392108 6.69464223 13.1631504"></polygon>
                        <polygon id="Star" transform="translate(3.615002, 10.903847) rotate(222.000000) translate(-3.615002, -10.903847) " points="3.60489331 11.5654133 2.97524108 11.9175152 3.10293432 11.1852112 2.60245011 10.673186 3.30329908 10.5587298 3.62363506 9.89017897 3.9290903 10.5517452 4.62755303 10.6505832 4.11548578 11.1739098 4.22682351 11.903546"></polygon>
                        <polygon id="Star" transform="translate(2.040451, 8.673577) rotate(249.000000) translate(-2.040451, -8.673577) " points="2.03364982 9.34262713 1.41121416 9.69573468 1.5350948 8.95689684 1.03753202 8.43808647 1.73133462 8.32516836 2.04625958 7.65141837 2.35117254 8.32046898 3.04336964 8.42287896 2.5380136 8.94929308 2.65088998 9.68633592"></polygon>
                        <polygon id="Star" transform="translate(1.705310, 5.984373) rotate(277.000000) translate(-1.705310, -5.984373) " points="1.70776844 6.65662332 1.09046504 7.00421431 1.20654959 6.26473064 0.704972006 5.73942104 1.39589802 5.63338407 1.70320942 4.9611339 2.01414063 5.63508312 2.7056471 5.74491926 2.20688714 6.26747975 2.32695025 7.0076124"></polygon>
                        <polygon id="Star" transform="translate(2.689436, 3.451591) rotate(305.000000) translate(-2.689436, -3.451591) " points="2.69898716 4.11669027 2.07998025 4.45694158 2.19116988 3.72356002 1.68195913 3.19794453 2.3769813 3.09814025 2.68127866 2.43304069 2.99963636 3.10473982 3.69691322 3.21930118 3.19864692 3.73423835 3.32529036 4.47014072"></polygon>
                        <polygon id="Star" transform="translate(4.763866, 1.652697) rotate(332.000000) translate(-4.763866, -1.652697) " points="4.77229197 2.31144289 4.14652615 2.64897259 4.25983446 1.92285311 3.74614757 1.40311448 4.4483789 1.30352251 4.75666876 0.644776675 5.07736328 1.30934494 5.78158378 1.42195625 5.27755256 1.93227399 5.4044949 2.66061744"></polygon>
                    </g>
                </g>
            </g>
        </g>
    </g>
  </svg>
);