/* eslint-disable max-len */
import React from 'react';

function SvgAnlagevermittler(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 100 100"
      {...props}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          fill="#1D1D1B"
          fillRule="nonzero"
          d="M75.38 34.415l.035-.047-19.292-16.541v.02l-15.68.043H29.156a4.947 4.947 0 00-4.927 4.938v58.977c0 2.708 2.218 5.42 4.93 5.42H70.52a4.939 4.939 0 004.93-4.91v-47.9h-.07zm-19.206-9.184l10.728 9.184H56.189l-.015-9.184zm13.809 46.253v10.274H29.649V23.354H40.412l10.329-.032v11.61a4.935 4.935 0 004.92 4.932h14.326l-.004 31.62z"
        />
        <path
          fill="#000"
          fillRule="nonzero"
          d="M51.838 49.91c1.529 0 2.642.345 4.17 1.25.346.208.487.137 1.216-1.078l.392-.627c.659-1.078.73-1.423.102-1.842a10.278 10.278 0 00-6.185-1.838c-5.284 0-8.624 3.37-9.73 8.72h-2.398c-.349 0-.486.036-.486 1.569v.035c0 1.529.137 1.568.486 1.568h2.015c-.036.415-.036.784-.036 1.215v.87h-1.96c-.348 0-.485.032-.485 1.568v.032c0 1.532.137 1.567.486 1.567h2.293c.972 5.421 4.03 8.585 9.52 8.585 2.3.034 4.56-.606 6.5-1.843.591-.392.52-.658 0-1.736l-.314-.659c-.557-1.176-.784-1.254-1.215-1.007a8.333 8.333 0 01-4.206 1.074c-2.78 0-4.378-1.595-5.096-4.414h5.7c.348 0 .486-.03.486-1.567v-.032c0-1.532-.138-1.568-.486-1.568h-6.123c-.031-.313-.031-.662-.031-1.007 0-.345 0-.733.031-1.078h6.083c.35 0 .486-.035.486-1.568v-.047c0-1.529-.137-1.568-.486-1.568h-5.664c.73-2.979 2.43-4.574 4.935-4.574z"
        />
        <path d="M0 0H100V100H0z" />
      </g>
    </svg>
  );
}


export default SvgAnlagevermittler;
