import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import autosize from 'autosize';
import styles from './styles.jss';
import ValidationMessage from '../ValidationMessage/ValidationMessage';

class InputText extends React.PureComponent {
  constructor(props) {
    super();

    this.alignLeft = props.type === 'textAutosize';
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  componentDidMount() {
    if (this.textarea) {
      autosize(this.textarea);
    }
  }

  handleFocus(e) {
    const { onFocus } = this.props;
    onFocus(e);
  }

  handleBlur(e) {
    const { onBlur } = this.props;
    onBlur(e);
  }

  render() {
    const {
      value,
      name,
      label,
      required,
      mockPlaceholder,
      type,
      classes,
      className,
      inputSize,
      error,
      onChange,
      onKeyDown,
    } = this.props;

    const labelAsPlaceholder = !value;

    let labelClasses = classes.labelText;
    const errorClass = error ? classes.error : '';

    if (labelAsPlaceholder) {
      labelClasses += ` ${classes.placeholderLabel}`;
    } else {
      labelClasses += ` ${classes.topLabel}`;
    }
    if (this.alignLeft) {
      labelClasses += ` ${classes.alignLeft}`;
    }

    const inputField = this.alignLeft ? (
      <textarea
        ref={input => { this.textarea = input; }}
        rows={1}
        defaultValue={value}
        className={[classes.input, classes.alignLeft].join(' ')}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        onChange={onChange}
        onKeyDown={onKeyDown}
        required={required}
        name={name}
      />
    ) : (
      <input
        value={value}
        className={classes.input}
        name={name}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        onChange={onChange}
        onKeyDown={onKeyDown}
        required={required}
        type={type}
      />
    );

    return (
      <Fragment>
        <label
          htmlFor={name}
          className={[classes.label, classes[inputSize], className, errorClass].join(' ')}
        >
          <p className={classes.mockPlaceholder}>
            {mockPlaceholder}
          </p>
          <p className={labelClasses}>
            {label}
          </p>
          {inputField}
          <div className={classes.inputBorderBox} />
          <ValidationMessage message={error} />
        </label>
      </Fragment>
    );
  }
}

InputText.propTypes = {
  value: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  required: PropTypes.bool,
  mockPlaceholder: PropTypes.string,
  type: PropTypes.oneOf([
    'date', 'email', 'number', 'password', 'text', 'time', 'textAutosize',
  ]),
  inputSize: PropTypes.oneOf([
    'small', 'medium', 'large', 'xlarge', 'addressSm', 'addressLg',
  ]),
  error: PropTypes.string,
};

InputText.defaultProps = {
  value: '',
  classes: {},
  className: '',
  label: '',
  name: '',
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  onKeyDown: () => {},
  required: false,
  mockPlaceholder: '',
  type: 'text',
  inputSize: 'medium',
  error: '',
};

export { InputText as InputTextPlain };
export default injectSheet(styles)(InputText);
