/* eslint-disable max-len */
import React from 'react';

function SvgReise(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g fill="none" fillRule="evenodd">
        <g fill="#1D1D1B" fillRule="nonzero">
          <path d="M22.742 14.775a7.33 7.33 0 10-7.33 7.327 7.337 7.337 0 007.33-7.327zm-2.786 3.938a5.179 5.179 0 00-1.047-.788c.196-.841.309-1.7.338-2.563h2.152a6 6 0 01-1.443 3.35zm-3.887-6.302a7.136 7.136 0 001.603-.291c.137.635.222 1.28.255 1.93h-1.858v-1.64zm0-1.313V9c.46.324.898.971 1.235 1.864a5.839 5.839 0 01-1.235.23v.004zm-1.313 0a6.41 6.41 0 01-1.235-.227c.337-.894.778-1.543 1.235-1.875v2.102zm0 1.313v1.64H12.9c.033-.648.117-1.293.253-1.927.523.152 1.06.247 1.603.285v.002zm0 2.953v1.743a7.688 7.688 0 00-1.61.29c-.141-.67-.226-1.35-.253-2.033h1.863zm0 3.063v2.129c-.463-.329-.905-.987-1.245-1.896.406-.12.823-.199 1.245-.234v.002zm1.313 0c.423.033.84.113 1.247.237-.34.91-.782 1.57-1.247 1.897v-2.133zm0-1.312v-1.753h1.864c-.026.683-.111 1.363-.254 2.032a7.294 7.294 0 00-1.61-.29v.011zm3.172-3.054a13.087 13.087 0 00-.34-2.46 5.265 5.265 0 001.034-.777 6 6 0 011.44 3.225l-2.134.012zm-.75-3.75a7.091 7.091 0 00-.425-.936c.316.156.619.34.903.55-.147.136-.304.26-.47.372l-.008.014zm-6.187.035a4.108 4.108 0 01-.505-.375c.302-.227.623-.427.96-.596a7.79 7.79 0 00-.448.958l-.007.013zm-1.463.529c.325.292.684.544 1.069.75-.185.8-.297 1.617-.332 2.437H9.443a6.013 6.013 0 011.406-3.2l-.008.013zm.731 4.5c.029.855.14 1.705.33 2.539a5.39 5.39 0 00-1.076.767 5.985 5.985 0 01-1.406-3.306h2.152zm.582 3.922c.043-.03.092-.061.142-.091.128.337.28.664.456.978a5.938 5.938 0 01-.971-.6 3.77 3.77 0 01.38-.3l-.007.013zm6.35-.063c.171.112.333.237.484.375a5.873 5.873 0 01-.937.562c.176-.305.33-.623.46-.95l-.007.013z" />
          <path d="M24.973 3.75H5.861a2.38 2.38 0 00-2.375 2.368v25.915a2.38 2.38 0 002.375 2.375h17a1.313 1.313 0 000-2.625H6.112V6.375h18.613v22.536a1.313 1.313 0 002.625 0V6.118a2.38 2.38 0 00-2.376-2.368z" />
          <path d="M11.676 26.543h-.13v.286h.295c.639 0 1.067-.393 1.067-.922s-.402-.75-.92-.75h-1.587a.84.84 0 00-.02.229l.239.095a.154.154 0 01.088.146v1.126l-.011.508a.233.233 0 01-.143.227l-.176.04c-.002.077.002.152.011.228h1.397a1.34 1.34 0 00.015-.242l-.375-.077c-.067-.011-.078-.056-.078-.13l-.014-.562v-1.271h.308c.409.01.562.223.562.514 0 .29-.123.543-.528.555zM14.599 27.444l-.188.07c-.007.08.002.16.027.236h1.16c.02-.07.03-.143.026-.216a.803.803 0 01-.165-.22l-.857-2.22-.577.12-.9 2.094a.624.624 0 01-.176.224.799.799 0 00.018.22h1.004a.868.868 0 00.022-.238l-.266-.077a.17.17 0 01-.135-.188c0-.048.01-.095.026-.14l.131-.349h.82l.125.375a.375.375 0 01.03.135.16.16 0 01-.125.174zm-.735-.982l.21-.563.103-.305h.02l.089.317.187.547-.61.004zM16.901 27.46a.619.619 0 01-.65-.554h-.265l-.071.666c.29.15.612.225.938.221.53 0 1.154-.25 1.154-.812 0-.46-.425-.65-.808-.817-.382-.167-.58-.235-.58-.418 0-.184.205-.268.404-.268a.594.594 0 01.625.406h.238v-.562a2.2 2.2 0 00-.835-.188c-.532 0-1.018.39-1.018.869 0 .478.467.684.842.849.28.125.478.217.478.364 0 .187-.191.243-.452.243zM19.346 27.46a.619.619 0 01-.65-.554h-.265l-.07.666c.29.15.612.226.938.221.53 0 1.153-.25 1.153-.812 0-.46-.425-.65-.808-.817-.382-.167-.58-.235-.58-.418 0-.184.205-.268.404-.268a.594.594 0 01.625.406h.24v-.562a2.214 2.214 0 00-.835-.188c-.534 0-1.018.39-1.018.869 0 .478.473.691.844.856.281.126.478.218.478.364-.004.18-.195.236-.456.236zM43.061 22.988h-1.48V11.175a1.241 1.241 0 00-1.24-1.238h-7.4a1.241 1.241 0 00-1.239 1.24v11.812h-1.479c-.71 0-1.287.576-1.288 1.287v16.65c.001.71.577 1.286 1.288 1.286h1.09v.808a1.072 1.072 0 102.144 0v-.808h6.387v.808a1.074 1.074 0 102.147 0v-.808h1.08c.71 0 1.287-.576 1.288-1.286v-16.65a1.288 1.288 0 00-1.298-1.288zM33.44 11.679h6.407v11.309h-6.407V11.679zm8.334 27.957H31.51V25.562h10.264v14.074z" />
        </g>
        <path d="M0 0h47.835v47.835H0z" />
      </g>
    </svg>
  );
}

export default SvgReise;
