/* eslint-disable max-len */
import React from 'react';

const SvgCarDiebstahl = props => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <path
      d="M2 2c6.64 0 11.892 3.122 12.062 8.306l.005.26v4.049l4.804 9.074a1.25 1.25 0 01-.983 1.83l-.121.005h-3.275v3.581h1.207a1.25 1.25 0 011.24 1.411l-.023.124-2.415 10.365 3.729 5.562h-2.682l-3.426-4.62a1.25 1.25 0 01-.204-.835l.025-.146 2.181-9.36H2v-2.5l9.992-.001v-4.831c0-.647.492-1.18 1.122-1.244l.128-.006h2.448l-.94-1.775L2 21.25v-2.5l11.426-.001-1.714-3.239a1.25 1.25 0 01-.136-.433l-.01-.152V12.75H9.344a3.25 3.25 0 11-4.689 0l-2.65.001v-2.5h9.551c-.223-3.387-4.018-5.66-9.24-5.747L2 4.5V2zm43 8.098v2.859h-8.587c-1.07 0-2.009.714-2.295 1.745l-1.507 5.433h-2.43a.476.476 0 00-.415.712l1.325 2.33-.903.767a5.225 5.225 0 00-1.853 3.999v5.5c0 .263.213.476.476.476H45v2.857h-8.565v1.431a3.811 3.811 0 01-3.811 3.811h-1.906a3.811 3.811 0 01-3.811-3.81V36.18a3.331 3.331 0 01-1.43-2.737v-5.5c0-1.998.729-3.878 2.004-5.334l-.199-.35a3.335 3.335 0 012.9-4.982h.256l.927-3.339a5.24 5.24 0 015.05-3.84h8.585zM33.574 37.254h-3.81v.953c0 .526.426.953.952.953h1.906a.953.953 0 00.952-.953v-.953zm.08-10.48l.163.004c1.403.084 2.526 1.237 2.61 2.68l.006.174-.005.168c-.081 1.442-1.203 2.598-2.605 2.685l-.17.005h-3.889v-2.288l3.89.001.09-.007c.235-.04.42-.23.458-.472l.008-.092-.008-.093a.564.564 0 00-.458-.472l-.09-.007h-3.89v-2.287h3.89zm11.344 1.904v2.859h-4.754V28.68L45 28.678zm0-6.669v2.858h-8.522l-1.949-2.858H45zM7 14.25a.75.75 0 100 1.5.75.75 0 000-1.5z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

export default SvgCarDiebstahl;
