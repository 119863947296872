import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Input from '@eg/elements/Input';
import FormMessage from '../FormMessage';

const ErgoInputText = ({
  name,
  value,
  type,
  description,
  placeholder,
  error,
  adornmentLeft,
  adornmentRight,
  onChange,
}) => (
  <Fragment>
    <Input
      error={!!error}
      name={name}
      type={type}
      value={value}
      placeholder={placeholder}
      adornmentLeft={adornmentLeft}
      adornmentRight={adornmentRight}
      onChange={onChange}
    />
    <FormMessage error={error} description={description} />
  </Fragment>
);

ErgoInputText.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  type: PropTypes.oneOf(['text', 'number', 'email']),
  description: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  adornmentLeft: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  adornmentRight: PropTypes.any,
};

ErgoInputText.defaultProps = {
  name: '',
  onChange: () => {},
  value: '',
  type: 'text',
  description: '',
  placeholder: '',
  error: '',
  adornmentLeft: '',
  adornmentRight: '',
};

export { ErgoInputText as ErgoInputTextPlain };
export default ErgoInputText;
