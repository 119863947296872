/* eslint-disable max-len */
import React from 'react';

function SvgRechtsschutzVerkehr(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g fill="none" fillRule="evenodd">
        <g
          transform="translate(10.313 3.938)"
          fill="#1D1D1B"
          fillRule="nonzero"
        >
          <path d="M9.324 5.228a4.236 4.236 0 100 8.47 4.236 4.236 0 000-8.47zm2.549 4.235a2.548 2.548 0 11-5.097 0 2.548 2.548 0 015.097 0zM9.324 15.799a4.234 4.234 0 10.004 8.467 4.234 4.234 0 00-.004-8.467zm2.549 4.233a2.548 2.548 0 11-5.097.004 2.548 2.548 0 015.097-.004z" />
          <circle cx={9.324} cy={30.602} r={4.234} />
          <path d="M26.141 18.968c-1.301.352-3.905-.145-4.082-.458l-.187-.593-1.24.023-.187.563c-.178.316-2.78.817-4.082.463l-1.065-.291v1.104c0 10.217 5.5 11.755 5.734 11.813l.08.02h.265l.103-.026c.234-.06 5.734-1.597 5.734-11.812v-1.105l-1.073.299zm-.641 1.77c-.264 7.05-3.442 8.812-4.253 9.151-.81-.34-3.986-2.139-4.252-9.151 1.155.061 3.126-.04 4.252-.75 1.13.712 3.1.813 4.253.75z" />
          <path d="M16.022 29.387v7.903H2.625V2.668h13.393v14.269h2.625V2.293a2.25 2.25 0 00-2.25-2.25H2.25A2.25 2.25 0 000 2.293v35.372a2.25 2.25 0 002.25 2.25h14.143a2.25 2.25 0 002.25-2.25v-5.18a8.869 8.869 0 01-2.621-3.098z" />
        </g>
        <path d="M0 0h47.835v47.835H0z" />
      </g>
    </svg>
  );
}

export default SvgRechtsschutzVerkehr;
