/* eslint-disable max-len */
import React from 'react';

const SvgFeuer = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <title>ICONS/sonst_aussenflaeche</title>
    <g id="ICONS/sonst_aussenflaeche" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect id="Rectangle" x="0" y="0" width="48" height="48" />
      <g id="ERGO_Icon_black_sonst_aussenflaeche">
        <rect id="Rectangle" x="0.013125" y="0.013125" width="47.80875" height="47.80875" />
        <rect id="Rectangle" x="0.013125" y="0.013125" width="47.80875" height="47.80875" />
        <path d="M44.559375,42.106875 L44.5725,17.896875 C45.1224787,17.7580701 45.5083952,17.264097 45.51,16.696875 L45.51,12.4725 C45.5118265,11.76109 45.0901729,11.1168149 44.4375,10.83375 L24.789375,2.3475 C24.4860684,2.22279262 24.1458066,2.22279262 23.8425,2.3475 L4.2,10.65 C3.54075436,10.9289658 3.11192957,11.5747863 3.110625,12.290625 L3.110625,16.6725 C3.110625,17.2891112 3.5635209,17.8121385 4.17375,17.900625 L4.160625,42.088125 C3.57886705,42.2173191 3.17063071,42.7411831 3.1875,43.336875 C3.22355801,44.0143491 3.7859602,44.5437174 4.464375,44.53875 L6.328125,44.53875 C7.00626337,44.5457839 7.56979244,44.0177853 7.606875,43.340625 C7.62628913,42.7447894 7.22060178,42.218811 6.639375,42.08625 L6.639375,25.5225 L15.7125,17.92125 L33.03375,17.9325 L42.084375,25.543125 L42.084375,42.105 C41.5038602,42.2374284 41.0983747,42.7623593 41.116875,43.3575 C41.1520234,44.0361409 41.7161099,44.5663161 42.395625,44.5594646 L44.25,44.5594646 C44.9285141,44.5673752 45.4926072,44.0388481 45.52875,43.36125 C45.5479796,42.764849 45.1413496,42.2386682 44.559375,42.106875 Z M24.309375,4.839375 L37.996875,10.4925 L10.621875,10.4775 L24.309375,4.839375 Z M6.650625,22.276875 L6.650625,17.9175 L11.85,17.9175 L6.650625,22.276875 Z M42.088125,22.295625 L36.89625,17.934375 L42.088125,17.934375 L42.088125,22.295625 Z M43.025625,15.45 L5.596875,15.43125 L5.596875,12.96 L43.025625,12.980625 L43.025625,15.45 Z" id="Shape" fill="#1D1D1B" fillRule="nonzero" />
        <path d="M18.3292666,36.0093446 C18.8408385,36.0066532 19.3320535,36.2095903 19.6925267,36.5725932 C20.0379913,36.9204821 20.2356046,37.3874202 20.2459129,37.8754919 L20.246192,37.9392652 L20.178692,43.6973902 C20.1732298,44.1633485 19.791068,44.5366543 19.3251098,44.531192 C18.8724646,44.5258859 18.5072531,44.1650983 18.4917607,43.717337 L18.491308,43.6776098 L18.5588145,37.9189462 C18.5595419,37.8600947 18.5365909,37.8034192 18.4951192,37.7616565 C18.4595681,37.7258559 18.4129574,37.7035833 18.3638672,37.6981439 L18.3391552,37.6968424 L13.9115625,37.725 L13.9462363,43.7895654 C13.9488167,44.2422343 13.5944464,44.6136758 13.1470229,44.6369665 L13.1073096,44.6381253 C12.6546407,44.6406917 12.2831992,44.2863214 12.2599085,43.8388979 L12.2587637,43.7991846 L12.2145825,36.0485437 L18.3292666,36.0093446 Z" id="Path" fill="#1D1D1B" fillRule="nonzero" />
        <path d="M13.063125,27.418125 C13.5158013,27.418125 13.8852167,27.7746068 13.9059566,28.2221558 L13.906875,28.261875 L13.906875,36.894375 C13.906875,37.3603653 13.5291153,37.738125 13.063125,37.738125 C12.6104487,37.738125 12.2410333,37.3816432 12.2202934,36.9340942 L12.219375,36.894375 L12.219375,28.261875 C12.219375,27.7958847 12.5971347,27.418125 13.063125,27.418125 Z" id="Path" fill="#1D1D1B" fillRule="nonzero" />
        <path d="M30.826875,31.5825 C31.2928653,31.5825 31.670625,31.9602597 31.670625,32.42625 C31.670625,32.8789263 31.3141432,33.2483417 30.8665942,33.2690816 L30.826875,33.27 L18.16125,33.27 C17.6952597,33.27 17.3175,32.8922403 17.3175,32.42625 C17.3175,31.9735737 17.6739818,31.6041583 18.1215308,31.5834184 L18.16125,31.5825 L30.826875,31.5825 Z" id="Path" fill="#1D1D1B" fillRule="nonzero" />
        <path d="M24.391875,31.580625 C24.8445513,31.580625 25.2139667,31.9371068 25.2347066,32.3846558 L25.235625,32.424375 L25.235625,43.75125 C25.235625,44.2172403 24.8578653,44.595 24.391875,44.595 C23.9391987,44.595 23.5697833,44.2385182 23.5490434,43.7909692 L23.548125,43.75125 L23.548125,32.424375 C23.548125,31.9583847 23.9258847,31.580625 24.391875,31.580625 Z" id="Path" fill="#1D1D1B" fillRule="nonzero" />
        <path d="M30.6297802,36.1424753 L36.7439119,36.1816711 L36.6974841,43.7451792 C36.6946237,44.2111607 36.3145523,44.5865945 35.8485708,44.5837341 C35.3959031,44.5809555 35.0286828,44.2222127 35.0106904,43.7745449 L35.0100159,43.7348208 L35.046,37.858125 L30.6199163,37.829973 C30.5690764,37.8297044 30.5199721,37.8469313 30.4806452,37.8783319 L30.4618337,37.8951979 C30.4259777,37.9312423 30.403794,37.9783566 30.3985628,38.0273815 L30.3973751,38.0520107 L30.4686848,43.7876357 C30.4744779,44.2535899 30.1014436,44.6360167 29.6354893,44.6418764 C29.1828481,44.6474374 28.8090294,44.2955756 28.7827273,43.848319 L28.7813152,43.8086143 L28.7099857,38.0712485 C28.7046828,37.5598381 28.9047789,37.0676705 29.2654687,36.7050857 C29.6261918,36.3424674 30.1173585,36.1397937 30.6297802,36.1424753 Z" id="Path" fill="#1D1D1B" fillRule="nonzero" />
        <path d="M35.895,27.5475 C36.3476763,27.5475 36.7170917,27.9039818 36.7378316,28.3515308 L36.73875,28.39125 L36.73875,37.02375 C36.73875,37.4897403 36.3609903,37.8675 35.895,37.8675 C35.4423237,37.8675 35.0729083,37.5110182 35.0521684,37.0634692 L35.05125,37.02375 L35.05125,28.39125 C35.05125,27.9252597 35.4290097,27.5475 35.895,27.5475 Z" id="Path" fill="#1D1D1B" fillRule="nonzero" />
      </g>
    </g>
  </svg>
);

export default SvgFeuer;
