/* eslint-disable max-len */
import React from 'react';

function SvgLeitungswasser(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g id="icons/05_other/Leitungswasser/48" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="ERGO_Icon_black_Leitungswasser">
          <g id="Leitungswasser" transform="translate(7.500000, 4.125000)" fill="#1D1D1B" fillRule="nonzero">
            <g id="Leitungswasser_Plus">
              <path d="M31.348125,14.128125 L26.8125,14.128125 C26.0876263,14.128125 25.5,14.7157513 25.5,15.440625 L25.5,30.0225 C25.5,30.7473737 26.0876263,31.335 26.8125,31.335 L31.348125,31.335 C32.0729987,31.335 32.660625,30.7473737 32.660625,30.0225 L32.660625,15.440625 C32.660625,14.7157513 32.0729987,14.128125 31.348125,14.128125 L31.348125,14.128125 Z M30.035625,28.71 L28.125,28.71 L28.125,16.753125 L30.035625,16.753125 L30.035625,28.71 Z" id="Shape" />
              <path d="M14.22,26.35125 C13.592216,26.3533816 12.9652513,26.3057323 12.345,26.20875 L14.154375,28.974375 L14.218125,28.974375 L24.073125,28.93875 L24.073125,26.31375 L14.22,26.35125 Z" id="Path" />
              <path d="M5.33625,24.961875 L6.9675,22.6875 C5.505,20.274375 5.48625,17.50875 5.48625,17.469375 L5.48625,7.335 L12.140625,7.335 L12.140625,15.268125 C12.11625,15.643125 12.106875,17.05875 13.11,18.166875 C13.6275,18.729375 14.61,19.4175 16.3125,19.4175 L24.0225,19.4175 L24.0225,16.7925 L16.3125,16.7925 C15.72,16.7925 15.3,16.665 15.06375,16.4175 C14.73,16.059375 14.76,15.45 14.758125,15.45 L14.758125,15.403125 L14.758125,7.340625 L16.070625,7.340625 C16.7954987,7.340625 17.383125,6.75299873 17.383125,6.028125 L17.383125,1.486875 C17.383125,0.762001266 16.7954987,0.174375 16.070625,0.174375 L1.486875,0.174375 C0.762001266,0.174375 0.174375,0.762001266 0.174375,1.486875 L0.174375,6.0225 C0.174375,6.37059664 0.312655853,6.70443615 0.55879735,6.95057765 C0.804938846,7.19671915 1.13877836,7.335 1.486875,7.335 L2.86125,7.335 L2.86125,17.469375 C2.859375,17.656875 2.865,21.70125 5.33625,24.961875 Z M2.799375,2.799375 L14.75625,2.799375 L14.75625,4.71 L2.799375,4.71 L2.799375,2.799375 Z" id="Shape" />
            </g>
            <path d="M13.179375,30.609375 L8.338125,23.57625 L3.714375,31.09875 L3.69,31.141875 C3.63375,31.245 2.315625,33.699375 3.22125,36.065625 C3.765,37.486875 4.9425,38.525625 6.729375,39.1575 C7.29861009,39.328328 7.89006723,39.4136343 8.484375,39.410625 C10.1403283,39.4304075 11.7197968,38.7148396 12.796875,37.456875 C13.783125,36.34875 15.125625,33.493125 13.179375,30.609375 Z M5.985,32.41875 L8.461875,28.3875 L11.01,32.08875 C12.2925,33.99 10.899375,35.65125 10.8225,35.7225 C9.51,37.2225 7.71375,36.71625 7.561875,36.66 C6.560625,36.305625 5.923125,35.788125 5.66625,35.1225 C5.236875,33.9975 5.89125,32.60625 5.985,32.41875 Z" id="Shape" />
          </g>
          <rect id="Rectangle" x="0" y="0" width="47.835" height="47.835" />
        </g>
      </g>
    </svg>
  );
}

export default SvgLeitungswasser;
