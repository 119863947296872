/* eslint-disable max-len */
import React from 'react';

const SvgFragezeichen1 = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <g id="icons/01_UI/question/48" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect id="bouncing-area" x="0" y="0" width="48" height="48" />
      <g id="ERGO_Icon_black_Fragezeichen1">
        <path d="M23.52,29.349375 C22.5331362,29.349375 21.733125,30.1493862 21.733125,31.13625 C21.733125,32.1231138 22.5331362,32.923125 23.52,32.923125 C24.5068638,32.923125 25.306875,32.1231138 25.306875,31.13625 C25.306875,30.1493862 24.5068638,29.349375 23.52,29.349375 L23.52,29.349375 Z" id="Path" fill="#1D1D1B" fillRule="nonzero" />
        <path d="M29.76,18.795 C29.76,15.25125 26.806875,13.33875 24.03,13.33875 C20.821875,13.33875 18.448125,15.676875 18.2175,18.975 L20.86125,18.975 C21.0675,17.158125 22.29375,15.975 24.04875,15.975 C25.291875,15.975 27.1425,16.725 27.1425,18.7875 C27.1425,20.03625 26.3925,20.585625 25.08,21.448125 C23.79375,22.291875 22.1925,23.34 22.1925,25.550625 L22.1925,27.6825 L24.8175,27.6825 L24.8175,25.558125 C24.8175,24.808125 25.39875,24.39 26.505,23.660625 C27.8775,22.760625 29.76,21.52875 29.76,18.795 Z" id="Path" fill="#1D1D1B" fillRule="nonzero" />
        <path d="M23.983125,42.605625 C13.6619586,42.605625 5.295,34.2386664 5.295,23.9175 C5.295,13.5963336 13.6619586,5.229375 23.983125,5.229375 C34.3042914,5.229375 42.67125,13.5963336 42.67125,23.9175 C42.6598831,34.2339545 34.2995795,42.5942581 23.983125,42.605625 Z M23.983125,7.854375 C15.111706,7.854375 7.92,15.046081 7.92,23.9175 C7.92,32.788919 15.111706,39.980625 23.983125,39.980625 C32.854544,39.980625 40.04625,32.788919 40.04625,23.9175 C40.0359177,15.0503643 32.8502607,7.86470726 23.983125,7.854375 Z" id="Shape" fill="#1D1D1B" fillRule="nonzero" />
        <rect id="Rectangle" x="0" y="0" width="47.835" height="47.835" />
      </g>
    </g>
  </svg>
);

export default SvgFragezeichen1;
