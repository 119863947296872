const styles = () => ({
  logo: {
    cursor: 'pointer',
  },
  buttonsWrapper: {
    paddingTop: 40,
    justifyContent: 'space-between',
  },
});

export default styles;
