import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { startsWith } from 'lodash';
import injectSheet from 'react-jss';

import Input from '@eg/elements/Input';
import ArrowDownIcon from '@eg/elements/Icons/ArrowDownIcon';
import Autocomplete from '@eg/elements/Autocomplete';

import { getTranslationArray } from '../../helpers/polyglot';
import FormMessage from '../FormMessage';

import styles from './styles.jss';

class ErgoCountryAutocomplete extends PureComponent {
  state = {
    currentSuggestions: [],
    initialFlatCountryList: [],
    countryName: 'Deutschland',
  }

  componentDidMount() {
    const {
      name,
      value,
      onChange,
    } = this.props;

    const countryList = getTranslationArray('dropdowns.countries');
    const flatCountryList = this.flatCountryList(countryList);
    const countryName = value || 'Deutschland';
    onChange(name)(countryName);

    this.setState({
      initialFlatCountryList: flatCountryList,
      currentSuggestions: flatCountryList,
      countryName,
    });
  }

  searchSuggestions = value => {
    const { initialFlatCountryList } = this.state;
    const { name, onChange } = this.props;

    const currentSuggestions = initialFlatCountryList.filter(
      this.filterSuggestions,
      value,
    );

    this.setState({
      currentSuggestions,
      countryName: value,
    });

    onChange(name)(value);
  }

  getInputWithArrow = (props) => (
    <Input
      {...props}
      ref={el => el && props.ref(el.input)}
      adornmentRight={ArrowDownIcon}
    />
  );

  flatCountryList = countryList => countryList.reduce(
    (aggr, val) => {
      aggr.push(val.name);
      return aggr;
    },
    [],
  );

  // don't refactor this one to fat arrow fn - it gets the "wrong" this
  filterSuggestions(haystack) {
    return startsWith(haystack.toLowerCase(), this.toLowerCase());
  }

  render() {
    const {
      countryName,
      currentSuggestions,
    } = this.state;

    const {
      name,
      description,
      error,
      classes,
    } = this.props;

    return (
      <div className={classes.wrapperForAutocompleteSuggestions}>
        <Autocomplete
          value={countryName}
          name={name}
          onChange={this.searchSuggestions}
          suggestions={currentSuggestions}
          renderInput={this.getInputWithArrow}
        />
        <FormMessage
          error={error}
          description={description}
        />
      </div>
    );
  }
}

ErgoCountryAutocomplete.propTypes = {
  name: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  onChange: PropTypes.func,
  value: PropTypes.string,
  description: PropTypes.string,
  placeholder: PropTypes.string,
};

ErgoCountryAutocomplete.defaultProps = {
  name: '',
  classes: {},
  onChange: () => { },
  value: '',
  description: '',
  placeholder: '',
};

export { ErgoCountryAutocomplete as ErgoCountryAutocompletePlain };
export default injectSheet(styles)(ErgoCountryAutocomplete);
