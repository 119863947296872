import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@eg/elements/Checkbox';
import FormMessage from '../FormMessage';

const ErgoCheckbox = ({
  name,
  label,
  error,
  checked,
  onChange,
  onClick,
  displayError,
  className,
}) => (
  <Fragment>
    <Checkbox
      className={className}
      name={name}
      error={!!error}
      checked={checked}
      onClick={onClick}
      onChange={onChange}
      label={label}
    />
    { displayError && <FormMessage error={error} />}
  </Fragment>
);

ErgoCheckbox.propTypes = {
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.shape(Object)]),
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  checked: PropTypes.bool,
  error: PropTypes.string,
  displayError: PropTypes.bool,
  className: PropTypes.string,
};

ErgoCheckbox.defaultProps = {
  name: '',
  label: '',
  onChange: () => {},
  onClick: () => {},
  checked: false,
  error: '',
  displayError: true,
  className: '',
};

export { ErgoCheckbox as ErgoCheckboxPlain };
export default ErgoCheckbox;
