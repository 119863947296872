/* eslint-disable max-len */
import React from 'react';

const SvgDerSchadenDesUnfallbeteiligten = props => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <g fill="currentColor" fillRule="nonzero">
      <path d="M14.372 8.57a5.372 5.372 0 014.415 4.773l.341 3.533.26.046a3.418 3.418 0 012.04 5.543l-.263.318a8.271 8.271 0 011.074 5.738l-.978 5.55a3.412 3.412 0 01-1.93 2.506l-.36 2.046a3.906 3.906 0 01-4.527 3.167l-1.924-.34a3.906 3.906 0 01-3.17-4.523l.254-1.443-5.737-1.011v-2.974l13.942 2.458a.488.488 0 00.566-.396l.978-5.55a5.352 5.352 0 00-1.159-4.363l-.776-.934 1.752-2.116a.488.488 0 00-.291-.792l-2.453-.433-.556-5.75a2.442 2.442 0 00-2.007-2.169L3.864 9.691V6.717L14.372 8.57zm1.882 28.582l-3.848-.678-.17.961a.977.977 0 00.793 1.131l1.924.34a.977.977 0 001.131-.792l.17-.962zm1.864-10.574l-.406 2.307-3.928-.693a.578.578 0 00-.663.478c-.05.287.11.56.37.652l.09.024 3.927.691-.407 2.309-3.928-.693c-1.494-.263-2.504-1.68-2.322-3.208l.025-.17c.27-1.537 1.66-2.586 3.149-2.414l.165.024 3.928.693zM33.724 8.57a5.372 5.372 0 00-4.415 4.773l-.342 3.533-.259.046a3.418 3.418 0 00-2.04 5.543l.262.318a8.271 8.271 0 00-1.074 5.738l.979 5.55a3.412 3.412 0 001.929 2.506l.361 2.046a3.906 3.906 0 004.526 3.167l1.924-.34a3.906 3.906 0 003.17-4.523l-.254-1.444 5.737-1.011v-2.974l-13.942 2.46a.488.488 0 01-.565-.397l-.979-5.55a5.352 5.352 0 011.16-4.363l.775-.934-1.752-2.116a.488.488 0 01.292-.792l2.453-.433.555-5.75a2.442 2.442 0 012.007-2.169l9.999-1.764V6.717L33.724 8.57zm-1.882 28.582l3.847-.678.17.961a.977.977 0 01-.793 1.131l-1.923.34a.977.977 0 01-1.132-.792l-.17-.962zm-1.865-10.574l.407 2.307 3.928-.693c.31-.054.606.16.663.478a.581.581 0 01-.37.652l-.09.024-3.928.691.407 2.309 3.928-.693c1.494-.263 2.504-1.68 2.322-3.208l-.025-.17c-.27-1.537-1.66-2.586-3.148-2.414l-.166.024-3.928.693z" />
    </g>
  </svg>
);

export default SvgDerSchadenDesUnfallbeteiligten;
