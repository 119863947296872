import {
  claimsKeys,
  getDate,
} from './formData';
import { getSummaryData } from '../pdfHelpers';

export const userFlows = {
  PRIVATE: 'Rechtsschutz Privat',
  OCCUPATIONAL: 'Rechtsschutz Beruf',
  TRAFFIC: 'Rechtsschutz Verkehr',
  LIVING_AND_RENTING: 'Rechtsschutz Wohnen und Miete',
};

export const relationships = {
  POLICYHOLDER: 'Versicherungsnehmer',
  PARTNER: 'Ehegatte/Lebenspartner',
  OWN_CHILD: 'Eigenes Kind',
  PARENTS: 'Eltern (selbe Anschrift)',
  DIFFERENT: 'Andere Person',
};

// Traffic flow
export const trafficOptions = {
  DAMAGES: 'Schadenersatzforderung',
  OFFENCE: 'Ordnungswidrigkeit/Verkehrsstraftat',
  CONTACT_LAW: 'Vertragsrecht',
  DIFFERENT: 'Sonstiges',
};

export const trafficVehicleNumbers = {
  POLICYHOLDER: 'Versicherungsnehmer',
  SPOUSE: 'Ehepartner',
  CHILD: 'Kind',
  DIFFERENT: 'Sonstiges',
};

export const trafficDamageOptions = {
  PROPERTY: 'Sachschaden',
  PERSONAL_INJURY: 'Personenschaden',
  BOTH: 'Beides (Sach- und Personenschaden)',
  DIFFERENT: 'Sonstiges',
};

export const trafficOffenceOptions = {
  SPEED_VIOLATION: 'Geschwindigkeitsverstoß',
  ACCIDENT: 'Unfall',
  RED_LIGHT_VIOLATION: 'Rotlichtverstoß',
  DISTANCE: 'Abstand',
  UNAUTHORIZED_REMOVAL: 'Unerlaubtes Entfernen vom Unfallort',
  PHYSICAL_INJURY: 'Fahrlässige Körperverletzung',
  DRINK_DRIVING: 'Trunkenheit im Verkehr',
  DIFFERENT: 'Sonstiges',
};

export const trafficContractOptions = {
  CONTRACT_OF_SALE: 'Kaufvertrag',
  REPAIR: 'Reparatur',
  DIFFERENT: 'Sonstiges',
};

// Property flow
export const propertyOptions = {
  TENANT_OR_LANDLORD: 'Mieter oder Vermieter',
  OWNER: 'Eigentümer',
};

export const propertyTenantLandlordOptions = {
  SERVICE_CHARGES: 'Nebenkosten',
  REDUCTION: 'Mietminderung bzw. Mängel',
  RENT_PAYMENT: 'Mietzahlung',
  RENT_INCREASE: 'Mieterhöhung',
  DAMAGES: 'Schadenersatzansprüche (Beendigung Mietverhältnis)',
  NEIGHBORHOOD_LAW: 'Nachbarschaftsrecht',
  DIFFERENT: 'Sonstiges',
};

export const propertyOwnerOptions = {
  ASSOCIATION: 'Wohnungseigentümergemeinschaft',
  NEIGHBORHOOD_LAW: 'Nachbarschaftsrecht',
  SALE: 'Verkauf der selbstgenutzten Immobilie',
  DIFFERENT: 'Sonstiges',
};

// Private flow
export const privateOptions = {
  CONTRACT_LAW: 'Vertragsrecht',
  TRAVEL_LAW: 'Reiserecht',
  SOCIAL_LAW: 'Sozialrecht',
  LAW_PENSION: 'Familien- und Erbrecht, Vorsorgeverfügungen',
  DIFFERENT: 'Sonstiges',
};

export const privateContractLawOptions = {
  CAR_PURCHES: 'KFZ-Kauf',
  INTERNET_PURCHES: 'Kauf im Internet oder vor Ort',
  CAPITAL_INVESTMENT: 'Kapitalanlage',
  VOYAGE: 'Reise',
  TELECOMMUNICATIONS: 'Telekommunikation',
  IMMOVABLE: 'Immobilien',
  DIFFERENT: 'Sonstiges',
};

export const privateTravelLawOptions = {
  DELAY: 'Verspätung',
  CANCELLATION: 'Annulierung',
  OVERBOOKING: 'Überbuchung',
  LACK_OF_TRAVEL: 'Reisemangel/Rücktritt',
  DIFFERENT: 'Sonstige Reiseangelegenheit',
};

export const privateSocialLawOptions = {
  PENSION_MATTER: 'Rentenangelegenheit',
  DEGREE_OF_DISABILITY: 'Grad der Behinderung',
  HEALTH_INSURANCE: 'Krankenversicherung',
  HARTZ4: 'Hartz IV',
  DISPUTE: 'Streitigkeit mit Berufsgenossenschaft',
  UNEMPLOYMENT_ALLOWANCE: 'Arbeitslosengeld',
  NURSING_INSURANCE: 'Pflegeversicherung',
  DIFFERENT: 'Sonstiges',
};

export const privateLawPensionOptions = {
  DIVORCE_OR_SEPARATION: 'Scheidung oder Trennung',
  CHILDREN: 'Kinder',
  ALIMONY: 'Unterhalt',
  OTHER_FAMILY_MATTERS: 'Sonstige Familiensache',
  SUCCESSION: 'Erbfall',
  WILL: 'Vorsorgeverfügung/Beratung bei Testamentserrichtng',
  DIFFERENT: 'Sonstige Erbsache',
};

export const occupationWorkOptions = {
  EMPLOYEES: 'Arbeitnehmer',
  EMPLOYER: 'Arbeitgeber',
};

export const occupationEmployeeEmployerOptions = {
  DISMISSAL: 'Kündigung',
  WAGE_CLAIM: 'Lohnforderung',
  CERTIFICATE: 'Zeugnis',
  WARNING_LETTER: 'Abmahnung',
  DIFFERENT: 'Sonstiges',
};

const isLivingAndRentingFlow = (currentFlow) => currentFlow === userFlows.LIVING_AND_RENTING;

const getFullName = ({
  [claimsKeys.owner.FIRST_NAME]: firstName,
  [claimsKeys.owner.LAST_NAME]: lastName,
}) => ({ firstName, lastName });

const getEmail = ({
  [claimsKeys.owner.EMAIL]: email,
}) => ({ email });

const getPhone = ({
  [claimsKeys.owner.PHONE]: phone,
}) => ({ phone });

export const getHeadingData = data => {
  const { firstName, lastName } = getFullName(data);
  return `${firstName} ${lastName}`;
};

export const getContactData = data => ({
  ...getFullName(data),
  ...getEmail(data),
  ...getPhone(data),
  insuranceNumber: data[claimsKeys.POLICY_NUMBER],
});

// Affected Details
const getCategory = (whatHappened, category) => (
  [userFlows.OCCUPATIONAL, userFlows.LIVING_AND_RENTING].includes(whatHappened)
    ? category : false
);

const getIncident = (whatHappened, kindOfDamage, category) => {
  const incident = [];

  if ([userFlows.PRIVATE, userFlows.TRAFFIC].includes(whatHappened)) {
    incident.push(category);
  }

  if (![privateOptions.DIFFERENT, trafficDamageOptions.DIFFERENT].includes(category)) {
    incident.push(kindOfDamage);
  }

  return incident.join(', ');
};

const getDateOfIncident = (date, kindOfDamage) => (
  kindOfDamage !== privateLawPensionOptions.WILL ? getDate(date) : false
);

const getDescription = (kindOfDamage, circumstances, heritageAdvice) => (
  kindOfDamage !== privateLawPensionOptions.WILL ? circumstances : heritageAdvice
);

export const getAffectedAreaOfLifeDetails = ({
  [claimsKeys.WHAT_HAPPENED]: areaOfLife,
  [claimsKeys.KIND_OF_DAMAGE]: kindOfDamage,
  [claimsKeys.legalProtection.RELATION]: relevantPerson,
  [claimsKeys.legalProtection.PRIMARY_RESIDENCE]: primaryResidence,
  [claimsKeys.legalProtection.COUNTRY]: country,
  [claimsKeys.legalProtection.ADDRESS]: address,
  [claimsKeys.legalProtection.POST_CODE]: postCode,
  [claimsKeys.legalProtection.CITY]: city,
  [claimsKeys.legalProtection.CATEGORY]: category,
  [claimsKeys.DATE]: date,
  [claimsKeys.CIRCUMSTANCES]: circumstances,
  [claimsKeys.legalProtection.HERITAGE_ADVICE]: heritageAdvice,
}) => ({
  areaOfLife,
  relevantPerson,
  isLivingAndRentingFlow: isLivingAndRentingFlow(areaOfLife),
  primaryResidence,
  relevantPrimaryResidence: {
    country, address, postCode, city,
  },
  category: getCategory(areaOfLife, category),
  incident: getIncident(areaOfLife, kindOfDamage, category),
  date: getDateOfIncident(date, kindOfDamage),
  description: getDescription(kindOfDamage, circumstances, heritageAdvice),
});

export const getYourMessageDetails = ({
  [claimsKeys.YOUR_MESSAGE]: message,
}) => message;

export const getPageType = () => ({ [claimsKeys.PAGE_TYPE]: claimsKeys.DAMAGE_REPORT });

export const getAdditionalData = data => ({
  additionalData: {
    ...getFullName(data),
    ...getEmail(data),
    isLiability: false,
  },
  summary: getSummaryData(),
});
