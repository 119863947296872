/* eslint-disable max-len */
import React from 'react';

function SvgPferd(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g fill="none" fillRule="evenodd">
        <g fill="#1D1D1B" fillRule="nonzero">
          <path d="M9.547 24.785c.253-2.039.43-3.514 1.118-4.587.62-.977 2.26-2.498 6.233-1.706 6.517 1.297 10.98.798 13.265-1.487 1.867-1.869 2.296-2.73 2.712-3.561.142-.295.298-.583.469-.862.614-.983 2.082-1.538 2.592-1.668.327-.08.61-.282.792-.565l.6-.941 1.797-3.112a10.12 10.12 0 00-.81-.687 1.318 1.318 0 00-1.903.341l-1.641 2.566c-.902.307-2.673 1.086-3.663 2.67-.215.35-.413.711-.591 1.081-.35.704-.653 1.318-2.218 2.875-1.564 1.556-5.443 1.85-10.885.766-2.282-.454-4.273-.34-5.918.34a6.558 6.558 0 00-3.057 2.538c-.998 1.574-1.222 3.388-1.506 5.675l-.189 1.515c-.41 3.003-4.14 4.808-4.173 4.823a1.32 1.32 0 101.13 2.387c.205-.098 5.058-2.42 5.664-6.852.058-.546.122-1.062.182-1.55z" />
          <path d="M44.533 12.84a9.564 9.564 0 00-2.737-2.071c-.074-.077-.31-.51-.804-1.81a6.304 6.304 0 00-.753-1.433l-1.583 2.744c.544 1.585 1.163 2.48 1.973 2.86 1.347.63 2.648 2.117 2.575 2.535-.134.358-.448 1.193-.693 1.39-.11.086-.478.082-.984-.012a22.345 22.345 0 00-5.098-.26c-.365.028-1.167.233-1.402 1.453-.062.313-.098.691-.143 1.171-.141 1.506-.356 3.793-1.521 5.625-.516.809-2.18 1.978-3.108 2.526-.383.226-.627.63-.647 1.074 0 .189-.151 10.598-.151 10.598H27.75l-.064-9.976a1.318 1.318 0 00-1.527-1.292c-1.371.203-2.755.31-4.141.32-1.6 0-3.09-.984-3.364-1.178a1.333 1.333 0 00-.725-.301c-.274-.025-1.191 0-1.464 1.248-.053.24-.13.645-.232 1.2-.276 1.527-.924 1.91-1.743 2.393-.58.343-1.236.73-1.622 1.506-.147.294-.377.774-.27 6.899h-1.731V32.94c0-.504.437-1.252.645-1.53.03-.04.057-.081.083-.123.045-.077 1.109-1.882 1.453-3.599 0-.03.592-3.032.565-3.706a1.318 1.318 0 10-2.635.072c-.019.329-.277 1.938-.512 3.119-.228 1.142-.953 2.447-1.105 2.716-.25.348-1.13 1.664-1.13 3.051v8.424c0 .727.59 1.317 1.318 1.317h4.4a1.318 1.318 0 001.317-1.35c-.07-2.823-.077-6.172.019-7.068a4.88 4.88 0 01.547-.353c.885-.524 2.33-1.378 2.905-3.756a8.171 8.171 0 003.283.753c1.13 0 2.213-.079 3.04-.164l.062 9.81a1.318 1.318 0 001.318 1.308h4.368a1.318 1.318 0 001.318-1.352c0-.04.023-9.698.083-11.07.928-.602 2.613-1.798 3.377-2.997 1.506-2.368 1.767-5.137 1.922-6.793.01-.089.017-.188.026-.277 1.177-.026 2.354.06 3.514.258 3.012.565 3.878-1.052 4.624-3.047.299-.8.346-2.101-1.14-3.744z" />
          <path d="M38.212 32.593s-2.733-3.99-2.805-4.058l-2.012 1.571c.032.08 2.035 3.072 2.035 3.072l-1.941 1.992v3.153c.216-.06.798-.177.966-.35l3.61-3.727a1.318 1.318 0 00.147-1.653z" />
        </g>
        <path d="M0 0h48.023v48.023H0z" />
      </g>
    </svg>
  );
}

export default SvgPferd;
