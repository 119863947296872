/* eslint-disable max-len */
import React from 'react';

function SvgRechtsberatung(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(4.125 5.25)" fill="#1D1D1B" fillRule="nonzero">
          <path d="M17.214 12.114a1.224 1.224 0 001.24-1.237 1.249 1.249 0 00-1.24-1.238 1.237 1.237 0 100 2.475z" />
          <circle cx={12.285} cy={10.896} r={1.238} />
          <path d="M6.791 31.971h2.663v5.306H6.791z" />
          <path d="M22.11 21c-.513 0-1.019.115-1.481.337a13.597 13.597 0 01-11.852-.056 3.328 3.328 0 00-3.954.788c-2.89 3.3-4.671 7.294-4.671 14.625v.581h2.66v-.581c0-7.294 1.93-10.5 4.013-12.882a.675.675 0 01.506-.225.684.684 0 01.281.057 16.215 16.215 0 0014.175.075.611.611 0 01.32-.075.703.703 0 01.562.262c2.625 3.431 4.069 7.95 4.069 12.769 0 .188 0 .394-.02.581h2.663v-.581c0-5.587-1.764-10.65-4.612-14.381A3.311 3.311 0 0022.11 21z" />
          <path d="M20.085 31.971h2.663v5.306h-2.663zM4.429 13.442a13.5 13.5 0 01-.732 2.062 9.484 9.484 0 01-.826 1.557c-.711 1.125 4.951 1.125 6.245 1.125a9.782 9.782 0 0011.363 0c1.256 0 6.937 0 6.204-1.125a9.802 9.802 0 01-.823-1.557 13.247 13.247 0 01-1.162-4.875c-.02-.243-.038-.45-.038-.58v-.02c0-5.512-4.425-9.975-9.883-9.975-5.458 0-9.88 4.463-9.88 9.975v.057c-.018.13-.018.318-.037.543-.02.447-.061.884-.124 1.313-.72-.737-.843-1.346-.85-1.38a.656.656 0 00-1.3.167c.013.116.204 1.432 1.843 2.713zm3.258-4.928a.066.066 0 01.038-.018c1.669-.825 3.075-.863 4.688-.9 1.387-.038 2.943-.075 4.704-.675 1.427-.47 2.308-1.2 2.89-1.932a7.312 7.312 0 01-5.234 12.357 7.194 7.194 0 01-4.71-1.754c1.525.233 3.065.344 4.607.332a.656.656 0 100-1.312c-2.563 0-4.52-.26-6.013-.65a7.313 7.313 0 01-1.138-3.929 6.62 6.62 0 01.168-1.519zM39.116 11.479c-1.301.352-3.904-.145-4.082-.458l-.187-.592-1.241.022-.188.563c-.187.319-2.78.817-4.084.463l-1.065-.289v1.103c0 10.216 5.5 11.754 5.734 11.812l.08.02h.265l.103-.026c.235-.06 5.734-1.597 5.734-11.812V11.19l-1.069.289zm-.64 1.77c-.264 7.05-3.442 8.812-4.25 9.152-.808-.342-3.99-2.132-4.254-9.152 1.155.062 3.125-.04 4.25-.75 1.13.712 3.1.813 4.255.75z" />
        </g>
        <path d="M0 0h47.835v47.835H0z" />
      </g>
    </svg>
  );
}

export default SvgRechtsberatung;
