import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import ContentWrapper from '../components/ContentWrapper/ContentWrapper';
import HeadingForm from '../layout/HeadingForm';
import withDynamicButtonCaption from './withDynamicButtonCaption';

const withMultiFieldValidation = (WrappedComponent) => {
  class WithMultiFieldValidation extends React.Component {
    constructor(props) {
      super(props);
      const { name, values } = this.props;
      this.state = { ...values[name] };

      this.handleChange = this.handleChange.bind(this);
      this.getValidationProps = this.getValidationProps.bind(this);
    }

    getValidationProps(inputName) {
      const {
        name,
        values,
        onChange,
        errors,
      } = this.props;
      return {
        key: `${name}.${inputName}`,
        name: `${name}.${inputName}`,
        value: values && values[name] && values[name][inputName],
        error: get(errors, `${name}.${inputName}`),
        onChange,
      };
    }

    handleChange(e) {
      const { onChange } = this.props;
      this.setState({
        [e.target.name]: e.target.value,
      }, () => {
        onChange({ ...this.state });
      });
    }

    render() {
      const {
        isValid,
        localePath,
        onClick,
        buttonText,
        displayMultipleSelectInformation,
      } = this.props;

      const { t } = this.context;

      return (
        <ContentWrapper
          isButtonDisabled={!isValid}
          onClick={onClick}
          title={buttonText}
        >
          <HeadingForm
            commentValue={t(`${localePath}.comment`)}
            value={t(`${localePath}.heading`)}
            displayMultipleSelectInformation={displayMultipleSelectInformation}
            textMultipleSelect={t('share.textMultipleSelect')}
          />
          <WrappedComponent getValidationProps={this.getValidationProps} {...this.props} />
        </ContentWrapper>
      );
    }
  }

  WithMultiFieldValidation.propTypes = {
    name: PropTypes.string,
    values: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.object,
    ]),
    errors: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.object,
    ]),
    isValid: PropTypes.bool,
    localePath: PropTypes.string,
    onClick: PropTypes.func,
    buttonText: PropTypes.string,
    displayMultipleSelectInformation: PropTypes.bool,
    onChange: PropTypes.func,
  };

  WithMultiFieldValidation.defaultProps = {
    name: '',
    values: [],
    errors: [],
    isValid: false,
    localePath: '',
    onClick: () => {},
    buttonText: '',
    displayMultipleSelectInformation: false,
    onChange: () => {},
  };

  WithMultiFieldValidation.contextTypes = {
    t: PropTypes.func,
  };

  return withDynamicButtonCaption(WithMultiFieldValidation);
};

export default withMultiFieldValidation;
