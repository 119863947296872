import { addSegments } from '../../../helpers/flow/flow';
import flow from './main.yaml';

// paths
import liability from './segments/liability.yaml';

// segments for Liability
import eventCircumstancesLiability from './segments/event-circumstances-liability.yaml';
import policyHolderLiability from './segments/policy-holder-liability.yaml';
import contactDetailsLiability from './segments/contact-details-liability.yaml';

// segments for Ergo
import keyLoss from './segments/key-loss.yaml';
import personDamage from './segments/person-damage.yaml';
import animalDamage from './segments/animal-damage.yaml';
import eventCircumstances from './segments/event-circumstances.yaml';
import policeReportOptional from './segments/police-report-optional.yaml';
import policyHolder from './segments/policy-holder.yaml';
import vehicleDamage from './segments/vehicle-damage.yaml';
import itemDamage from './segments/item-damage.yaml';
import animalItemDamage from './segments/animal-item-damage.yaml';
import business from './segments/business.yaml';
import contactDetailsCoinsured from './segments/contact-details-coinsured.yaml';

// segments for both
import contactDetails from './segments/contact-details.yaml';
import uploadDocuments from './segments/upload-documents.yaml';

const segments = {
  // Liability
  liability,
  eventCircumstancesLiability,
  policyHolderLiability,
  contactDetailsLiability,
  // Ergo
  keyLoss,
  personDamage,
  animalDamage,
  eventCircumstances,
  policeReportOptional,
  policyHolder,
  vehicleDamage,
  itemDamage,
  animalItemDamage,
  business,
  contactDetailsCoinsured,
  // Common
  contactDetails,
  uploadDocuments,
};

export default addSegments(flow, segments);
