import {
  NO_VALUE,
  YES_VALUE,
  claimsKeys,
  compareValues,
  getDate,
  getCountryName,
  getLicenseNumber,
  userTypes,
} from './formData';
import { getTranslationArray } from '../polyglot';
import { getSummaryData } from '../pdfHelpers';
import { getCarMapValues } from '../../services/claimData/claimData.helpers';
import { getSourceFromSession } from '../../services';

const CATEGORY_VALUE = 'Verkehrsunfall mit Beteiligung eines anderes KFZ';

export const userFlows = {
  ANIMAL: 'KFZ Tiere',
  COLLISION: 'KFZ Unfall',
  FIRE_OR_EXPLOSION: 'KFZ Brand / Explosion / Kurzschluss',
  GLASS: 'KFZ Glasbruch',
  THEFT: 'KFZ Diebstahl',
  WEATHER: 'KFZ Unwetter',
  VANDALISM: 'KFZ Vandalismus',
};

export const whatHappenedValues = {
  ACCIDENT: 'KFZ Unfall',
  PERSONAL_INJURY: 'KFZ Personenschaden',
  LIABILITY: 'KFZ Haftpflicht',
  ACCIDENT_LIABILITY: 'KFZ Unfall/Haftpflicht',
  ACCIDENT_PERSONAL_INJURY: 'KFZ Unfall/Personenschaden',
  LIABILITY_PERSONAL_INJURY: 'KFZ Haftpflicht/Personenschaden',
  ACCIDENT_LIABILITY_PERSONAL_INJURY: 'KFZ Unfall/Haftpflicht/Personenschaden',
};

export const typeOfDrivers = {
  OWNER: 'VN',
  OTHER: 'Anderer Fahrer',
};

export const collisionTypes = {
  OWN_CAR: 'Schaden am VN Fahrzeug',
  OTHER_CAR_OBJECT_PERSON: 'Schaden des Unfallgegners / Geschädigten',
  BOTH: 'Schaden am VN Fahrzeug und Schaden des Unfallgegners',
};

export const animalDamages = {
  WILD_ANIMALS: 'Unfall mit Wildtieren',
  OTHER_ANIMALS: 'Unfall mit anderen Tieren',
  BITE: 'Tierbiss',
};

export const weatherDamages = {
  LIGHTNING: 'Blitz',
  FLOOD: 'Überschwemmung',
  AVALANCHE: 'Lawine',
  LANDSLIDE: 'Erdrutsch',
  EARTHQUAKE: 'Erdbeben',
  STORM: 'Sturm',
  HAIL: 'Hagel',
  MUREN: 'Muren',
  ERDFALL: 'Erdfall',
  VULKANAUSBRUCH: 'Vulkanausbruch',
};

export const fireDamages = {
  FIRE: 'Brand',
  EXPLOSION: 'Explosion',
  SHORT_CIRCUIT: 'Kurzschluss',
};

export const theftDamages = {
  CAR: 'Totaldiebstahl',
  PARTS: 'Teilentwendung sonstige Fahrzeugteile',
};

export const collisionAndLiabilityDamages = {
  INVADE: 'Auffahren durch versichertes Fahrzeug',
  ENTRY: 'Ein- und Ausparken',
  DISREGARD: 'Vorfahrtsmissachtung',
  TURN: 'Abbiegen',
  MANEUVERING: 'Rangieren oder Parken',
  OVERTAKING: 'Überholen',
  LANE_CHANGE: 'Spurwechsel',
  DIFFERENT: 'Sonstiges',
};

export const accidentGuilt = {
  me: 'VN',
  'accident-opponents': 'Unfallgegner',
  'accident-opponents-and-me': 'Unfallgegner und VN',
};

const isLiabilityFlow = who => compareValues(who, NO_VALUE);

export const isCollisionFlow = (who, whatHappened) => (
  !isLiabilityFlow(who) && Object.values(whatHappenedValues).includes(whatHappened)
);

const isAnimalFlow = whatHappened => compareValues(whatHappened, userFlows.ANIMAL);

const isWeatherFlow = whatHappened => compareValues(whatHappened, userFlows.WEATHER);

const isGlassFlow = whatHappened => compareValues(whatHappened, userFlows.GLASS);

const isHailDamage = (whatHappened, kindOfDamage) => (
  isWeatherFlow(whatHappened)
  && compareValues(kindOfDamage, weatherDamages.HAIL)
);

const isTheftFlow = whatHappened => compareValues(whatHappened, userFlows.THEFT);

const isWholeCarTheftFlow = (whatHappened, kindOfDamage) => (
  isTheftFlow(whatHappened)
  && compareValues(kindOfDamage, theftDamages.CAR)
);

const isOwnCarCollisionType = collisionType => compareValues(collisionType, collisionTypes.OWN_CAR);

const isOtherCarCollisionType = collisionType => (
  compareValues(collisionType, collisionTypes.OTHER_CAR_OBJECT_PERSON)
);

const isBothCollisionType = collisionType => compareValues(collisionType, collisionTypes.BOTH);

const isCompany = who => {
  const companyValue = getTranslationArray('common.salutation').company;
  return compareValues(who, companyValue);
};

const getWhatHappenedForCollision = ({
  isOtherCar,
  isBoth,
  isPersonalInjury,
  isItemDamage,
  isVehicleDamage,
}) => {
  if (isOtherCar) {
    const liabilityArray = [
      !isPersonalInjury && isVehicleDamage && !isItemDamage,
      !isPersonalInjury && !isVehicleDamage && isItemDamage,
      !isPersonalInjury && isVehicleDamage && isItemDamage,
    ];
    const liabilityPersonalArray = [
      isPersonalInjury && isVehicleDamage && !isItemDamage,
      isPersonalInjury && !isVehicleDamage && isItemDamage,
      isPersonalInjury && isVehicleDamage && isItemDamage,
    ];

    if (liabilityArray.includes(true)) {
      return whatHappenedValues.LIABILITY;
    }
    if (liabilityPersonalArray.includes(true)) {
      return whatHappenedValues.LIABILITY_PERSONAL_INJURY;
    }
    // Only PersonalInjury
    return whatHappenedValues.PERSONAL_INJURY;
  }

  if (isBoth) {
    const accidentLiabilityArray = [
      !isPersonalInjury && isVehicleDamage && !isItemDamage,
      !isPersonalInjury && !isVehicleDamage && isItemDamage,
      !isPersonalInjury && isVehicleDamage && isItemDamage,
    ];
    const accidentLiabilityPersonalArray = [
      isPersonalInjury && isVehicleDamage && !isItemDamage,
      isPersonalInjury && !isVehicleDamage && isItemDamage,
      isPersonalInjury && isVehicleDamage && isItemDamage,
    ];

    if (accidentLiabilityArray.includes(true)) {
      return whatHappenedValues.ACCIDENT_LIABILITY;
    }
    if (accidentLiabilityPersonalArray.includes(true)) {
      return whatHappenedValues.ACCIDENT_LIABILITY_PERSONAL_INJURY;
    }
    // Only PersonalInjury
    return whatHappenedValues.ACCIDENT_PERSONAL_INJURY;
  }
  // Only for collisionType option A;
  return whatHappenedValues.ACCIDENT;
};

const getWhatHappenedForLiability = (
  { isPersonalInjury, isItemDamage, isVehicleDamage },
) => {
  const conditionsArray = [
    isPersonalInjury && isVehicleDamage && !isItemDamage,
    isPersonalInjury && !isVehicleDamage && isItemDamage,
    isPersonalInjury && isVehicleDamage && isItemDamage,
  ];
  if (conditionsArray.includes(true)) {
    return whatHappenedValues.LIABILITY_PERSONAL_INJURY;
  }
  if (isPersonalInjury && !isVehicleDamage && !isItemDamage) {
    return whatHappenedValues.PERSONAL_INJURY;
  }
  return whatHappenedValues.LIABILITY;
};

export const getCategoryForCollision = ({
  [claimsKeys.WHO_FILLS]: filler,
  [claimsKeys.SUB_SECTOR_INSURED_PERSON]: collisionType,
  [claimsKeys.WHAT_HAPPENED]: whatHappened,
  [claimsKeys.thirdParty.VEHICLE_DAMAGE]: vehicleDamage,
}) => {
  const isVehicleDamage = compareValues(vehicleDamage);
  const categoryValue = {};

  if (
    isCollisionFlow(filler, whatHappened)
    && isBothCollisionType(collisionType)
    && isVehicleDamage
  ) {
    categoryValue[claimsKeys.CATEGORY] = CATEGORY_VALUE;
  }
  return categoryValue;
};

export const getPageType = ({
  [claimsKeys.WHO_FILLS]: filler,
  [claimsKeys.WHAT_HAPPENED]: whatHappened,
  [claimsKeys.KIND_OF_DAMAGE]: kindOfDamage,
  [claimsKeys.thirdParty.PERSONAL_INJURY]: personalInjury,
}) => {
  let pageTypeVal = claimsKeys.DAMAGE_REPORT;
  const isPersonalInjury = compareValues(personalInjury);

  if (isPersonalInjury && (isLiabilityFlow(filler) || isCollisionFlow(filler, whatHappened))) {
    pageTypeVal = claimsKeys.PERSON_DAMAGE_REPORT;
  }
  if (isWeatherFlow(whatHappened) && isHailDamage(whatHappened, kindOfDamage)) {
    pageTypeVal = claimsKeys.HAIL_DAMAGE_REPORT;
  }

  const output = { [claimsKeys.PAGE_TYPE]: pageTypeVal };
  const source = getSourceFromSession();
  if (source) {
    output[claimsKeys.LANDING_PAGE_SRC] = source;
  }
  return output;
};

export const getWhatHappened = ({
  [claimsKeys.WHO_FILLS]: filler,
  [claimsKeys.WHAT_HAPPENED]: whatHappened,
  [claimsKeys.SUB_SECTOR_INSURED_PERSON]: collisionType,
  [claimsKeys.thirdParty.PERSONAL_INJURY]: personalInjury,
  [claimsKeys.thirdParty.ITEM_DAMAGE]: itemDamage,
  [claimsKeys.thirdParty.VEHICLE_DAMAGE]: vehicleDamage,
}) => {
  const data = {
    isOtherCar: isOtherCarCollisionType(collisionType),
    isBoth: isBothCollisionType(collisionType),
    isPersonalInjury: compareValues(personalInjury),
    isItemDamage: compareValues(itemDamage),
    isVehicleDamage: compareValues(vehicleDamage),
  };
  const whatHappenedValue = {};
  // We need to set a new GeVoSTitel for Liability & Collision
  // ECM-1794
  if (isLiabilityFlow(filler)) {
    whatHappenedValue[claimsKeys.WHAT_HAPPENED] = getWhatHappenedForLiability(data);
  }
  if (isCollisionFlow(filler, whatHappened)) {
    whatHappenedValue[claimsKeys.WHAT_HAPPENED] = getWhatHappenedForCollision(data);
  }
  return whatHappenedValue;
};

export const getHeadingData = ({
  [claimsKeys.WHO_FILLS]: filler,
  [claimsKeys.owner.SALUTATION]: who,
  [claimsKeys.owner.FIRST_NAME]: firstName,
  [claimsKeys.owner.LAST_NAME]: lastName,
  [claimsKeys.thirdParty.SALUTATION]: tWho,
  [claimsKeys.thirdParty.FIRST_NAME]: tFirstName,
  [claimsKeys.thirdParty.LAST_NAME]: tLastName,
}) => {
  const isLiability = isLiabilityFlow(filler);

  if (isCompany(who) || isCompany(tWho)) {
    return isLiability ? tFirstName : firstName;
  }
  return isLiability ? `${tFirstName} ${tLastName}` : `${firstName} ${lastName}`;
};

export const getContactData = ({
  [claimsKeys.WHO_FILLS]: filler,
  [claimsKeys.WHAT_HAPPENED]: whatHappened,
  [claimsKeys.KIND_OF_DAMAGE]: kindOfDamage,
  [claimsKeys.owner.FIRST_NAME]: firstName,
  [claimsKeys.owner.LAST_NAME]: lastName,
  [claimsKeys.owner.PHONE]: phone,
  [claimsKeys.owner.EMAIL]: email,
  [claimsKeys.DRIVER]: driver,
  [claimsKeys.thirdParty.DRIVER_FIRST_NAME]: driverFirstName,
  [claimsKeys.thirdParty.DRIVER_LAST_NAME]: driverLastName,
  [claimsKeys.thirdParty.DRIVER_PHONE]: driverPhone,
}) => ({
  isLiabilityFlow: isLiabilityFlow(filler),
  isAnimalFlow: isAnimalFlow(whatHappened),
  firstName,
  lastName,
  phone,
  email,
  isAnimalDamage: !compareValues(kindOfDamage, animalDamages.BITE),
  ownerIsDriver: compareValues(driver, typeOfDrivers.OWNER),
  driver: {
    firstName: driverFirstName,
    lastName: driverLastName,
    phone: driverPhone,
  },
});

export const getIncidentData = ({
  [claimsKeys.WHO_FILLS]: filler,
  [claimsKeys.WHAT_HAPPENED]: whatHappened,
  [claimsKeys.KIND_OF_DAMAGE]: kindOfDamage,
  [claimsKeys.PRIVATE_VEHICLE]: privateVehicle,
  [claimsKeys.THIRD_PARTY_PRIVATE_VEHICLE]: thirdPartyPrivateVehicle,
  [claimsKeys.POLICY_NUMBER]: policyNumber,
  [claimsKeys.owner.LICENSE_NUMBER]: licenseNumber,
  [claimsKeys.DATE]: date,
  [claimsKeys.CIRCUMSTANCES]: circumstance,
  [claimsKeys.owner.DESCRIBE_DAMAGE]: damageDescription,
  [claimsKeys.LOCATION]: location,
  [claimsKeys.COUNTRY_CODE]: countryCode,
}) => ({
  isLiabilityFlow: isLiabilityFlow(filler),
  isCollisionFlow: isCollisionFlow(filler, whatHappened),
  isPrivateVehicle: compareValues(privateVehicle),
  isThirdPartyPrivateVehicle: compareValues(thirdPartyPrivateVehicle),
  kindOfDamage,
  policyNumber,
  licenseNumber: getLicenseNumber(licenseNumber),
  date: getDate(date),
  circumstance: circumstance || '',
  damageDescription,
  location,
  countryName: getCountryName(countryCode),
});

export const getPoliceData = ({
  [claimsKeys.WHO_FILLS]: filler,
  [claimsKeys.WHAT_HAPPENED]: whatHappened,
  [claimsKeys.POLICE]: police,
  [claimsKeys.ANY_WITNESS]: anyWitness,
  [claimsKeys.DEPARTMENT]: department,
  [claimsKeys.CASE_NUMBER]: caseNumber,
  [claimsKeys.WAS_REPORTED]: wasReported,
}) => ({
  isLiabilityFlow: isLiabilityFlow(filler),
  isCollisionFlow: isCollisionFlow(filler, whatHappened),
  isPolice: compareValues(police),
  isAnyWitness: compareValues(anyWitness),
  department,
  caseNumber,
  wasReported,
});

export const getWitnessesDetails = data => {
  const arrayOfWitnessKey = ['firstWitness', 'secondWitness', 'thirdWitness'];
  const witnesses = [];
  arrayOfWitnessKey.forEach(k => {
    if (data[claimsKeys[k].FIRST_NAME]) {
      const witness = claimsKeys[k];
      witnesses.push({
        firstName: data[witness.FIRST_NAME],
        lastName: data[witness.LAST_NAME],
        phone: data[witness.PHONE],
      });
    }
  });
  return witnesses;
};

export const getVehicleDamageData = ({
  [claimsKeys.WHO_FILLS]: filler,
  [claimsKeys.WHAT_HAPPENED]: whatHappened,
  [claimsKeys.owner.LICENSE_NUMBER]: licenseNumber,
  [claimsKeys.owner.DESCRIBE_DAMAGE]: damageDescription,
  [claimsKeys.PRIVATE_VEHICLE]: privateVehicle,
  [claimsKeys.THIRD_PARTY_PRIVATE_VEHICLE]: thirdPartyPrivateVehicle,
  [claimsKeys.IS_REPAIRED]: isRepaired,
  [claimsKeys.IS_READY_FOR_DRIVING]: isReady,
  [claimsKeys.AIRBAGS_WERE_DEPLOYED]: airbagsDeployed,
  [claimsKeys.WARNING_LIGHTS_ACTIVATED]: warningLights,
  [claimsKeys.FLUIDS_LOST]: fluidsLost,
}) => ({
  isLiabilityFlow: isLiabilityFlow(filler),
  isTheftFlow: isTheftFlow(whatHappened),
  licenseNumber: getLicenseNumber(licenseNumber),
  isPrivateVehicle: compareValues(privateVehicle),
  isThirdPartyPrivateVehicle: compareValues(thirdPartyPrivateVehicle),
  damageDescription,
  isRepaired: compareValues(isRepaired),
  isReady,
  airbagsDeployed,
  warningLights,
  fluidsLost,
});

export const getCarMapDamages = (data, userType = userTypes.OWNER) => (
  Object.keys(claimsKeys[userType].CAR_MAP)
    .filter(key => data[key] === YES_VALUE)
    .map(key => getCarMapValues(claimsKeys[userType].CAR_MAP[key]))
    .join(', ')
);

export const getContactOfAccidentData = ({
  [claimsKeys.WHO_FILLS]: filler,
  [claimsKeys.WHAT_HAPPENED]: whatHappened,
  [claimsKeys.thirdParty.FIRST_NAME]: firstName,
  [claimsKeys.thirdParty.LAST_NAME]: lastName,
  [claimsKeys.thirdParty.PHONE]: phone,
  [claimsKeys.thirdParty.EMAIL]: email,
  [claimsKeys.thirdParty.ADDRESS]: address,
  [claimsKeys.thirdParty.POST_CODE]: postCode,
  [claimsKeys.thirdParty.CITY]: city,
}) => ({
  isLiabilityFlow: isLiabilityFlow(filler),
  isCollisionFlow: isCollisionFlow(filler, whatHappened),
  firstName,
  lastName,
  phone,
  email,
  address,
  postCode,
  city,
});

export const getDamageOfAccidentData = ({
  [claimsKeys.WHO_FILLS]: filler,
  [claimsKeys.WHAT_HAPPENED]: whatHappened,
  [claimsKeys.SUB_SECTOR_INSURED_PERSON]: collisionType,
  [claimsKeys.thirdParty.PERSONAL_INJURY]: personalInjury,
  [claimsKeys.thirdParty.PERSONAL_INJURY_DESC]: personalInjuryDesc,
  [claimsKeys.PRIVATE_VEHICLE]: privateVehicle,
  [claimsKeys.THIRD_PARTY_PRIVATE_VEHICLE]: thirdPartyPrivateVehicle,
  [claimsKeys.thirdParty.ITEM_DAMAGE]: itemDamage,
  [claimsKeys.thirdParty.ITEM_DAMAGE_DESC]: itemDamageDesc,
  [claimsKeys.thirdParty.VEHICLE_TYPE]: vehicleType,
  [claimsKeys.thirdParty.LICENSE_NUMBER]: licenseNumber,
  [claimsKeys.thirdParty.DESCRIBE_DAMAGE]: describeDamage,
  [claimsKeys.thirdParty.IS_READY_FOR_DRIVING]: isReady,
  [claimsKeys.thirdParty.AIRBAGS_WERE_DEPLOYED]: airbagsDeployed,
  [claimsKeys.thirdParty.WARNING_LIGHTS_ACTIVATED]: warningLights,
  [claimsKeys.thirdParty.FLUIDS_LOST]: fluidsLost,
}) => ({
  isLiabilityFlow: isLiabilityFlow(filler),
  isCollisionFlow: isCollisionFlow(filler, whatHappened),
  isPrivateVehicle: compareValues(privateVehicle),
  isThirdPartyPrivateVehicle: compareValues(thirdPartyPrivateVehicle),
  isOwnCar: isOwnCarCollisionType(collisionType),
  isPersonalInjury: compareValues(personalInjury),
  isItemDamage: compareValues(itemDamage),
  personalInjuryDesc,
  itemDamageDesc,
  vehicleType,
  licenseNumber: getLicenseNumber(licenseNumber),
  describeDamage,
  isReady,
  airbagsDeployed,
  warningLights,
  fluidsLost,
});

export const getPayingOutData = ({
  [claimsKeys.WHO_FILLS]: filler,
  [claimsKeys.WHAT_HAPPENED]: whatHappened,
  [claimsKeys.KIND_OF_DAMAGE]: kindOfDamage,
  [claimsKeys.RECOMMEND_REPAIR_SHOP]: recommendRepairShop,
  [claimsKeys.BANK_ACCOUNT]: bankAccount,
  [claimsKeys.IBAN_NAME]: ibanName,
  [claimsKeys.IBAN]: iban,
  [claimsKeys.INVOICES]: invoices,
  [claimsKeys.REPAIR_SHOP_NAME]: repairShopName,
  [claimsKeys.USE_CALCULATION_APP]: calcAppValue,
  [claimsKeys.USE_KVA_SERVICE]: kvaServiceValue,
  [claimsKeys.WORKSHOP_ADDRESS]: workshopAddress,
  [claimsKeys.WORKSHOP_CITY]: workshopCity,
  [claimsKeys.WORKSHOP_PHONE]: workshopPhone,
  [claimsKeys.KVA_SERVICE_WORKSHOP_NAME]: kvaWorkshopName,
  [claimsKeys.KVA_SERVICE_WORKSHOP_ADDRESS]: kvaWorkshopAddress,
  [claimsKeys.KVA_SERVICE_WORKSHOP_CITY]: kvaWorkshopCity,
  [claimsKeys.KVA_SERVICE_WORKSHOP_PLZ]: kvaWorkshopPLZ,
}) => ({
  isLiabilityFlow: isLiabilityFlow(filler),
  isWholeCarTheftFlow: isWholeCarTheftFlow(whatHappened, kindOfDamage),
  recommendRepairShop,
  calcAppValue,
  kvaServiceValue,
  bankAccount,
  ibanName,
  iban,
  invoices,
  repairShopName,
  workshopAddress,
  workshopCity,
  workshopPhone,
  kvaWorkshopName,
  kvaWorkshopAddress,
  kvaWorkshopCity,
  kvaWorkshopPLZ,
});

export const getRepairData = ({
  [claimsKeys.WHAT_HAPPENED]: whatHappened,
  [claimsKeys.RECOMMEND_REPAIR_SHOP]: recommendRepairShop,
  [claimsKeys.REPAIR_SHOP_NAME]: name,
  [claimsKeys.WORKSHOP_ADDRESS]: address,
  [claimsKeys.WORKSHOP_CITY]: city,
  [claimsKeys.WORKSHOP_PHONE]: phone,
}) => ({
  isGlassFlow: isGlassFlow(whatHappened),
  recommendRepairShop,
  name,
  address,
  city,
  phone,
});

export const getAdditionalData = ({
  [claimsKeys.WHO_FILLS]: filler,
  [claimsKeys.owner.FIRST_NAME]: firstName,
  [claimsKeys.owner.LAST_NAME]: lastName,
  [claimsKeys.owner.EMAIL]: email,
  [claimsKeys.thirdParty.FIRST_NAME]: tFirstName,
  [claimsKeys.thirdParty.LAST_NAME]: tLastName,
  [claimsKeys.thirdParty.EMAIL]: tEmail,
}) => {
  const isLiability = isLiabilityFlow(filler);

  return {
    additionalData: {
      firstName: isLiability ? tFirstName : firstName,
      lastName: isLiability ? tLastName : lastName,
      email: isLiability ? tEmail : email,
      isLiability,
    },
    summary: getSummaryData(),
  };
};

// Only for Broker Module
export const getBrokerHeadingData = ({
  [claimsKeys.owner.FIRST_NAME]: firstName,
  [claimsKeys.owner.LAST_NAME]: lastName,
}) => (`${firstName} ${lastName}`);

export const getBrokerData = ({
  [claimsKeys.owner.FIRST_NAME]: firstName,
  [claimsKeys.owner.LAST_NAME]: lastName,
  [claimsKeys.owner.PHONE]: phone,
  [claimsKeys.owner.EMAIL]: email,
  [claimsKeys.broker.REFERENCE]: reference,
}) => ({
  firstName, lastName, phone, email, reference,
});

export const getBrokerContactData = ({
  [claimsKeys.WHO_FILLS]: filler,
  [claimsKeys.WHAT_HAPPENED]: whatHappened,
  [claimsKeys.KIND_OF_DAMAGE]: kindOfDamage,
  [claimsKeys.customer.FIRST_NAME]: firstName,
  [claimsKeys.customer.LAST_NAME]: lastName,
  [claimsKeys.customer.PHONE]: phone,
  [claimsKeys.customer.EMAIL]: email,
  [claimsKeys.DRIVER]: driver,
  [claimsKeys.thirdParty.DRIVER_FIRST_NAME]: driverFirstName,
  [claimsKeys.thirdParty.DRIVER_LAST_NAME]: driverLastName,
  [claimsKeys.thirdParty.DRIVER_PHONE]: driverPhone,
}) => ({
  isLiabilityFlow: isLiabilityFlow(filler),
  isAnimalFlow: isAnimalFlow(whatHappened),
  firstName,
  lastName,
  phone,
  email,
  isAnimalDamage: !compareValues(kindOfDamage, animalDamages.BITE),
  ownerIsDriver: compareValues(driver, typeOfDrivers.OWNER),
  driver: {
    firstName: driverFirstName,
    lastName: driverLastName,
    phone: driverPhone,
  },
});

export const getBrokerAdditionalData = ({
  [claimsKeys.WHO_FILLS]: filler,
  [claimsKeys.owner.FIRST_NAME]: firstName,
  [claimsKeys.owner.LAST_NAME]: lastName,
  [claimsKeys.owner.EMAIL]: email,
  [claimsKeys.customer.FIRST_NAME]: customerFirstName,
  [claimsKeys.customer.LAST_NAME]: customerLastName,
  [claimsKeys.thirdParty.FIRST_NAME]: tCustomerFirstName,
  [claimsKeys.thirdParty.LAST_NAME]: tCustomerLastName,
}) => {
  const isLiability = isLiabilityFlow(filler);

  return {
    additionalData: {
      firstName,
      lastName,
      email,
      customerFirstName: isLiability ? tCustomerFirstName : customerFirstName,
      customerLastName: isLiability ? tCustomerLastName : customerLastName,
      isLiability,
    },
    summary: getSummaryData(),
  };
};
