/* eslint-disable max-len */
import React from 'react';

const SvgDokument = props => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M3.99 3.99h40.02v40.02H3.99z" />
      <path
        d="M27.488 8.326c.436 0 .855.171 1.167.477l7.517 7.37c.32.314.5.743.5 1.19v19.643c0 1-.5 1.834-1.333 2.334-.667.334-1.168.334-1.334.334H14.162c-.167 0-.667 0-1.334-.334-.834-.333-1.334-1.334-1.334-2.334V11.161c0-.167 0-.667.334-1.334.333-.834 1.167-1.5 2.334-1.5h13.326zM26 10.827H14.829a.834.834 0 00-.834.834v24.678c0 .46.374.834.834.834h18.342c.46 0 .834-.374.834-.834V18.831h-5.003a2.989 2.989 0 01-3.001-3.001v-5.003zm5.502 18.676v2.5H16.497v-2.5h15.006zm0-5.003v2.501H16.497v-2.5h15.006zm-6.67-5.002v2.501h-8.336v-2.501h8.337zm3.836-7.17v3.668c0 .167.167.334.333.334h3.502l-3.835-4.002z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default SvgDokument;
