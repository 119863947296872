
/* eslint-disable max-len */
import React from 'react';

function SvgEntlassungsbericht(props) {
  return (
    <svg
      id="Ebene_6"
      data-name="Ebene 6"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 255.12 255.12"
      {...props}
    >
      <defs />
      <title>ERGO_Icon_black_Fabrik</title>
      <rect style={{ fill: '#1d1d1b' }} x="166.76" y="153.62" width="40.39" height="13.99" />
      <path style={{ fill: '#1d1d1b' }} d="M194,35.61h25.6V71.5l.53-.19a19.54,19.54,0,0,1,12.94,0l.52.19V29a7.41,7.41,0,0,0-7.41-7.41H187.38A7.42,7.42,0,0,0,180,29V94.89l14-8.4Z" transform="translate(0 0)" />
      <rect style={{ fill: '#1d1d1b' }} x="107.36" y="153.62" width="40.39" height="13.99" />
      <rect style={{ fill: '#1d1d1b' }} x="166.76" y="180.02" width="40.39" height="13.99" />
      <rect style={{ fill: '#1d1d1b' }} x="47.96" y="153.62" width="40.39" height="13.99" />
      <path style={{ fill: '#1d1d1b' }} d="M229.8,83.84a7.33,7.33,0,0,0-7.47.1L167.56,116.8V90.29a7.41,7.41,0,0,0-11.23-6.35L101.56,116.8V90.29a7.41,7.41,0,0,0-11.23-6.35L25.16,123a7.45,7.45,0,0,0-3.6,6.36V213A7.42,7.42,0,0,0,29,220.41H226.14a7.41,7.41,0,0,0,7.41-7.41V90.29A7.35,7.35,0,0,0,229.8,83.84Zm-138.48,51a7.35,7.35,0,0,0,7.47-.1l54.77-32.86v26.51a7.41,7.41,0,0,0,11.23,6.35l54.77-32.86v104.5h-184v-73.3l52-31.2v26.51A7.35,7.35,0,0,0,91.32,134.88Z" transform="translate(0 0)" />
      <rect style={{ fill: '#1d1d1b' }} x="47.96" y="180.02" width="40.39" height="13.99" />
      <rect style={{ fill: '#1d1d1b' }} x="107.36" y="180.02" width="40.39" height="13.99" />
      <rect style={{ fill: 'none' }} width="255.12" height="255.12" />
    </svg>
  );
}

export default SvgEntlassungsbericht;
