import { addSegments } from '../../../helpers/flow/flow';
import flow from './main.yaml';

import typeOfAccusation from './segments/type-of-accusation.yaml';
import whatDocuments from './segments/what-documents.yaml';

const segments = {
  typeOfAccusation,
  whatDocuments,
};

export default addSegments(flow, segments);
