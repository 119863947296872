/* eslint-disable max-len */
import React from 'react';

function SvgDocumentHands(props) {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      {...props}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          fill="#1D1D1B"
          fillRule="nonzero"
          d="M91.694 9.133H50.31a4.939 4.939 0 00-4.935 4.935v2.63h5.452v-2.113h40.35V72.99h-2.591v5.453h3.116a4.947 4.947 0 004.923-4.94V14.069a4.939 4.939 0 00-4.93-4.935M64.636 71.018H58.82c-.05.736-.163 1.466-.34 2.183a13.327 13.327 0 01-1.322 3.336h16.894v-5.52h-9.415zM56.052 61.932a13.601 13.601 0 011.96 3.48c0 .036.027.067.04.102h15.984v-5.503H54.194c.169.145.333.294.482.435.496.46.956.956 1.376 1.486"
        />
        <path
          fill="#1D1D1B"
          fillRule="nonzero"
          d="M50.8270618 48.9220759L46.5075259 48.9220759 46.5075259 54.4371276 50.8270618 54.4371276 60.3010348 54.4371276 60.3010348 48.9220759z"
        />
        <path
          fill="#1D1D1B"
          fillRule="nonzero"
          d="M66.604 35.932v-9.184l10.709 9.184H66.604zm19.238-.047L66.545 19.344l-15.679.043H39.577a4.947 4.947 0 00-4.966 4.99v21.268h-7.957a2.744 2.744 0 00-1.568.486L4.536 60.45a2.72 2.72 0 00-1.177 2.226v18.286a2.72 2.72 0 002.717 2.716h11.351a2.7 2.7 0 001.313-.34l3.528-1.96 2.724-1.506 2.744-1.509.306-.168h16.016c2.105.06 4.178-.524 5.942-1.674A7.8 7.8 0 0053.383 71a7.33 7.33 0 00.05-.83v-.055c.001-.234-.012-.469-.039-.702a7.84 7.84 0 00-.443-1.964 8.098 8.098 0 00-.548-1.195 7.404 7.404 0 00-.474-.74 8.126 8.126 0 00-.98-1.134 8.365 8.365 0 00-1.266-.987c-.13-.083-.31-.192-.475-.286a12.167 12.167 0 00-.784-.392 10.573 10.573 0 00-.423-.173 9.897 9.897 0 00-1.568-.435 10.984 10.984 0 00-2.187-.204H29.465v5.429h14.777a5.378 5.378 0 011.666.24c.15.048.298.106.443.172.168.075.33.166.482.27a2.536 2.536 0 011.129 1.8c.005.096.005.193 0 .29.002.304-.06.606-.18.885a2.599 2.599 0 01-1.333 1.29c-.768.336-1.6.5-2.438.478H27.309c-.46 0-.91.117-1.313.337l-1.016.596-2.724 1.505-5.527 3.057H8.8V64.095l18.714-13.021h7.098v5.401h5.449V24.89h10.767l10.32-.062v11.625a4.935 4.935 0 004.932 4.931h14.33v41.91H47.691a18.09 18.09 0 01-3.665.369H34.62v.149a4.935 4.935 0 004.931 4.93h41.38a4.935 4.935 0 004.932-4.93v-47.88h-.071l.051-.047z"
        />
        <path d="M0 0H100V100H0z" />
      </g>
    </svg>
  );
}

export default SvgDocumentHands;
