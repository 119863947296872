/* eslint-disable max-len */
import React from 'react';

function SvgAnwalt(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 100 100"
      {...props}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path d="M42 42H58V58H42z" />
        <g
          fill="#2F2F2F"
          fillRule="nonzero"
          transform="translate(6.074 48.328)"
        >
          <path d="M83.29 3.985a3.126 3.126 0 013.076 2.564l.05.561v32.458a3.125 3.125 0 01-6.2.562l-.05-.562-.003-29.333h-3.487l.003 29.333a3.126 3.126 0 01-2.563 3.075l-.562.05a3.126 3.126 0 01-3.074-2.563l-.05-.562V7.11a3.126 3.126 0 012.563-3.074l.561-.05h9.737z" />
          <path d="M65.173 3.697c.544 0 1.076.142 1.544.407l.444.307 8.382 6.914-3.977 4.822-7.521-6.206-18.012.006c-3.063 0-4.808.875-5.817 2.398l-.31.53-.036.072 13.997.005v6.25h-17.81a3.126 3.126 0 01-3.045-2.425l-.075-.539-.005-.124a12.673 12.673 0 011.413-6.091c1.875-3.656 5.393-5.977 10.485-6.29l1.203-.036h19.14z" />
          <path d="M1.017 5.39C6.387.48 11.38-1.267 15.822.942l1.48.767 2.737 1.522 3.36 1.97 2.588 1.564 4.408 2.722 1.61 1.008-3.324 5.293-5.88-3.645-2.518-1.523-3.25-1.908-2.61-1.456-1.383-.718c-.976-.484-2.314-.334-4.056.637l-.627.371 31.738 22.231 23.554-6.221a3.125 3.125 0 012.22.239l.487.309 9.108 7.03-3.82 4.947-7.887-6.091-23.466 6.203a3.125 3.125 0 01-2.118-.189l-.474-.273L1.332 10.255a3.125 3.125 0 01-.315-4.866z" />
        </g>
        <g fill="#2F2F2F" fillRule="nonzero" transform="translate(40.625 12.5)">
          <path d="M16.624 4.018l-.383.568c-.396.622-.694 1.008-.892 1.157-.199.149-.411.155-.638.02a10.419 10.419 0 00-2.062-.954 7.197 7.197 0 00-2.275-.345c-1.275 0-2.197.23-2.763.69-.397.325-.596.717-.596 1.177 0 .514.29.913.872 1.197.581.285 1.304.548 2.168.792.865.243 1.807.507 2.828.791 1.02.284 1.963.663 2.827 1.137a6.725 6.725 0 012.168 1.866c.581.772.872 1.766.872 2.983 0 1.218-.276 2.266-.83 3.146a6.736 6.736 0 01-2.104 2.13c.482.46.872 1.022 1.17 1.685.297.663.446 1.44.446 2.333 0 1.055-.227 2.01-.68 2.861a6.615 6.615 0 01-1.85 2.172c-.78.595-1.708 1.048-2.785 1.36-1.077.31-2.239.466-3.486.466-1.417 0-2.714-.176-3.89-.528-1.177-.351-2.218-.852-3.125-1.501-.227-.136-.305-.325-.234-.568.07-.244.319-.717.744-1.42l.383-.569c.396-.622.694-1.008.892-1.157.199-.149.411-.155.638-.02.68.406 1.368.724 2.062.954.695.23 1.453.345 2.275.345 1.275 0 2.197-.23 2.763-.69.397-.325.596-.717.596-1.177 0-.514-.29-.913-.872-1.197-.581-.285-1.304-.548-2.168-.792-.865-.243-1.807-.507-2.828-.791a13.274 13.274 0 01-2.827-1.137 6.725 6.725 0 01-2.168-1.866C.29 18.364 0 17.37 0 16.153c0-1.218.27-2.266.808-3.146a6.53 6.53 0 012.083-2.13 5.079 5.079 0 01-1.148-1.664c-.283-.65-.425-1.434-.425-2.354 0-1.055.227-2.01.68-2.861a6.615 6.615 0 011.85-2.172c.78-.595 1.708-1.048 2.785-1.36C7.71.157 8.872 0 10.119 0c1.417 0 2.714.176 3.89.528 1.177.351 2.218.852 3.125 1.501.227.136.305.325.234.568-.07.244-.319.717-.744 1.42zm-5.06 14.164a3.103 3.103 0 001.085-.934c.27-.378.404-.784.404-1.217 0-.433-.17-.785-.51-1.055-.34-.27-.787-.514-1.34-.73a15.361 15.361 0 00-1.892-.59 53.006 53.006 0 01-2.168-.588c-.425.27-.773.589-1.042.954-.27.365-.404.764-.404 1.197 0 .433.17.792.51 1.076.34.284.787.527 1.34.73.552.203 1.176.392 1.87.568.695.176 1.41.372 2.148.589z" />
        </g>
      </g>
    </svg>
  );
}


export default SvgAnwalt;
