import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import styles from './styles.jss';

const CircleBadge = (props) => {
  const {
    classes,
    text,
  } = props;

  return (
    <div className={classes.circle}>
      {text}
    </div>
  );
};

CircleBadge.propTypes = {
  classes: PropTypes.objectOf(Object),
  text: PropTypes.string,
};

CircleBadge.defaultProps = {
  classes: {},
  text: '',
};

export default injectSheet(styles)(CircleBadge);
