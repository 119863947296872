/* eslint-disable max-len */
import React from 'react';

const SvgCamera = props => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <g fill="currentColor" fillRule="nonzero">
      <path d="M11 31c0 4.964 4.038 9 9 9 4.96 0 9-4.036 9-9 0-4.962-4.038-9-9-9-4.964 0-9 4.042-9 9zm15.346 0c0 3.482-2.847 6.316-6.347 6.316-3.5 0-6.345-2.83-6.345-6.315 0-3.485 2.847-6.317 6.345-6.317 3.499 0 6.347 2.837 6.347 6.317z" />
      <circle cx={32.5} cy={24.5} r={1.5} />
      <path d="M35.77 17.618h-3.314v2.637h2.915v21.112H3.622v-2.669H6.66a2.375 2.375 0 002.368-2.374V25.263a2.373 2.373 0 00-2.368-2.375H3.622v-2.633h26.202v-2.637H11.826V15H6.523v2.618H3.227a2.252 2.252 0 00-2.226 2.269v4.28a.3.3 0 000 .035v13.171a.27.27 0 000 .034v4.323A2.252 2.252 0 003.227 44h32.546A2.252 2.252 0 0038 41.73V19.887a2.252 2.252 0 00-2.23-2.269zM6.398 25.525V36.06H3.615V25.525h2.783z" />
      <path d="M46.835 16.142a1.32 1.32 0 00-1.154-.686h-3.373l2.233-8.299A1.337 1.337 0 0043.9 5.64a1.31 1.31 0 00-1.61.275l-5.831 6.522-4.006-6.787a1.314 1.314 0 00-1.484-.602A1.33 1.33 0 0030 6.332v9.02h2.639v-4.187l1.738 2.946a2.362 2.362 0 003.802.376l2.37-2.653-.876 3.26c-.194.72-.044 1.49.404 2.082a2.367 2.367 0 001.883.943h1.38l-2.819 4.738V28l6.286-10.522a1.34 1.34 0 00.028-1.336zM15 31h2.537A1.455 1.455 0 0119 29.554V27c-2.197-.007-3.986 1.782-4 4z" />
    </g>
  </svg>
);

export default SvgCamera;
