/* eslint-disable max-len */
import React from 'react';

const SvgTuerWandBodenDamage = props => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <g fill="currentColor" fillRule="nonzero">
      <path d="M21 26.501h-1.129v1.515h-1.744v-4.032h1.744v.512H21V23h-4v6h4z" />
      <path d="M19 25h4v1h-4zM14 13H9v9h4.991L14 13zm-.985 7.898H9.991v-6.782h3.024v6.782z" />
      <path d="M10 19h2v1h-2zM11 15h1v3h-1zM9 22h1v1H9zM11 22h1v1h-1zM13 22h1v1h-1z" />
      <path
        d="M33.733 37.158V10h-16.52c-.466.001-.913.19-1.242.526-.33.335-.513.79-.511 1.263v28.094H6.45L1 47h1.294l4.422-6.044h34.61L44.892 47H46l-4.183-7.117h-8.084v-2.725zm-1.058 2.721H16.511V11.073h16.164V39.88z"
        stroke="currentColor"
        strokeWidth={0.933}
        strokeLinejoin="round"
      />
      <path d="M43.87 9.334L37.664 20a4.343 4.343 0 01-.46-1.734c-.003-.502.03-1.004.102-1.501l4.047-6.944H40.35a1.686 1.686 0 01-.449-.064 1.803 1.803 0 01-1.212-2.203l.64-2.443-1.722 1.987a1.688 1.688 0 01-2.76-.282l-1.26-2.209-.66.972L31 6.745l.657-5.761c.007-.34.181-.655.465-.837a.936.936 0 011.32.328l2.91 5.088L40.583.676a.93.93 0 01.948-.29c.519.149.824.692.686 1.22L40.597 7.83h2.45c.348.005.666.2.832.511.165.31.161.685-.01.992z" />
    </g>
  </svg>
);

export default SvgTuerWandBodenDamage;
