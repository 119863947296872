/* eslint-disable max-len */
import React from 'react';

const SvgVandalismus = props => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <path
      d="M45 10.098v2.859h-8.587c-1.07 0-2.009.714-2.295 1.745l-1.507 5.433h-2.43a.476.476 0 00-.415.712l1.325 2.33-.903.767a5.225 5.225 0 00-1.853 3.999v5.5c0 .263.213.476.476.476H45v2.857h-8.565v1.431a3.811 3.811 0 01-3.811 3.811h-1.906a3.811 3.811 0 01-3.811-3.81V36.18a3.331 3.331 0 01-1.43-2.737v-5.5c0-1.998.729-3.878 2.004-5.334l-.199-.35a3.335 3.335 0 012.9-4.982h.256l.927-3.339a5.24 5.24 0 015.05-3.84h8.585zM33.574 37.254h-3.81v.953c0 .526.426.953.952.953h1.906a.953.953 0 00.952-.953v-.953zM11.086 8.167l3.961.557-1.383 3.944a7.002 7.002 0 014.833 7.406l-.03.238-2.365 16.835a2 2 0 01-2.26 1.702L3.94 37.457A2 2 0 012.238 35.2l2.366-16.835.037-.238a7.002 7.002 0 016.687-5.786l-.242-4.173zm-4.354 13.02L4.783 35.052l8.913 1.253 1.948-13.864-8.912-1.252zm26.923 5.586l.163.005c1.403.084 2.526 1.237 2.61 2.68l.006.174-.005.168c-.081 1.442-1.203 2.598-2.605 2.685l-.17.005h-3.889v-2.288l3.89.001.09-.007c.235-.04.42-.23.458-.472l.008-.092-.008-.093a.564.564 0 00-.458-.472l-.09-.007h-3.89v-2.287h3.89zm11.344 1.905v2.859h-4.754V28.68L45 28.678zm0-6.669v2.858h-8.522l-1.949-2.858H45zM7.16 18.291l-.045.212-.034.209 8.912 1.252.025-.21a4.5 4.5 0 00-8.858-1.463zm21.33-10.65l.633 1.897-9.162 3.054 7.715 3.867-.896 1.788-9.87-4.948.131-1.843L28.49 7.64zm1.532 4.246v2h-6.835v-2h6.835z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

export default SvgVandalismus;
