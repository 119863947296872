/* eslint-disable max-len */
import React from 'react';

const SvgFeuer = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <title>ICONS/Backofen Kaminscheibe</title>
    <g id="ICONS/Backofen_Kaminscheibe" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect id="Rectangle" x="0" y="0" width="48" height="48" />
      <g id="ERGO_Icon_black_Backofen_Kaminscheibe">
        <rect id="Rectangle" x="0.013125" y="0.013125" width="47.80875" height="47.80875" />
        <path d="M2.6438486,2.83500003 L45.4334432,2.85751283 C45.6866797,2.86047512 45.9279454,2.96576489 46.1023275,3.14941789 C46.2658106,3.32159258 46.3574699,3.54890847 46.3600515,3.77905308 L46.359375,3.82510422 L46.3367424,44.2973246 C46.3462343,44.5495763 46.2536001,44.7949971 46.0797969,44.9780639 C45.9168564,45.1496891 45.6954852,45.2529136 45.4581792,45.2681349 L45.4105182,45.27 L2.62278862,45.245612 C2.36997488,45.2426301 2.12908299,45.1376618 1.95477166,44.9545246 C1.79135479,44.7828334 1.69944539,44.5560862 1.69631275,44.3259595 L1.69687502,44.2799045 L1.71755885,3.80532221 C1.70481909,3.29694522 2.09522611,2.87199824 2.59773258,2.83704126 L2.6438486,2.83500003 Z M3.5623125,4.76625 L3.5473125,43.3126875 L44.4825,43.3366875 L44.499375,4.788375 L3.5623125,4.76625 Z" id="Combined-Shape" fill="#1D1D1B" fillRule="nonzero" />
        <path d="M42.676875,13.696875 L5.345625,13.67625 C5.15317403,13.6782289 4.96939264,13.7565828 4.83471587,13.8940727 C4.7000391,14.0315626 4.62550056,14.2169242 4.6275,14.409375 L4.61243858,41.5425 C4.60999747,41.7349524 4.68405718,41.920515 4.81838355,42.0583577 C4.95270992,42.1962005 5.13629715,42.2750296 5.32875,42.2775 L42.661875,42.298125 C42.854326,42.2961461 43.0381074,42.2177922 43.1727841,42.0803023 C43.3074609,41.9428124 43.3819994,41.7574508 43.38,41.565 L43.395156,14.4375 C43.3990157,14.2437605 43.3253717,14.0564635 43.1904773,13.9173426 C43.0555828,13.7782217 42.8706462,13.6988363 42.676875,13.696875 Z M41.94375,40.83 L6.04875,40.81125 L6.061875,15.1425 L41.95875,15.163125 L41.94375,40.83 Z" id="Shape" fill="#1D1D1B" fillRule="nonzero" />
        <path d="M8.22,6.493125 C7.02769646,6.46721584 5.93830735,7.16549239 5.46396752,8.2596865 C4.98962769,9.35388061 5.22469977,10.6263192 6.05867355,11.4788136 C6.89264734,12.331308 8.15961656,12.5942677 9.26396384,12.144072 C10.3683111,11.6938764 11.0903435,10.620085 11.090625,9.4275 C11.099625,8.65754836 10.8021126,7.91561348 10.2636492,7.36519207 C9.72518582,6.81477067 8.98996359,6.50103625 8.22,6.493125 L8.22,6.493125 Z M8.22,10.891875 C7.6243786,10.9040642 7.08059245,10.5546557 6.84416226,10.0078338 C6.60773208,9.46101188 6.72566465,8.8254951 7.14254443,8.39990765 C7.55942421,7.9743202 8.19236759,7.84327693 8.7439596,8.068354 C9.2955516,8.29343107 9.65612529,8.8298789 9.65625,9.425625 C9.66074857,9.81099617 9.51157807,10.1822906 9.24171255,10.4574338 C8.97184703,10.732577 8.60351102,10.8889098 8.218125,10.891875 L8.22,10.891875 Z" id="Shape" fill="#1D1D1B" fillRule="nonzero" />
        <path d="M15.399375,6.496875 C14.2070715,6.47096584 13.1176823,7.16924239 12.6433425,8.2634365 C12.1690027,9.35763061 12.4040748,10.6300692 13.2380486,11.4825636 C14.0720223,12.335058 15.3389916,12.5980177 16.4433388,12.147822 C17.5476861,11.6976264 18.2697185,10.623835 18.27,9.43125 C18.279,8.66129836 17.9814876,7.91936348 17.4430242,7.36894207 C16.9045608,6.81852067 16.1693386,6.50478625 15.399375,6.496875 L15.399375,6.496875 Z M15.399375,10.895625 C14.8035829,10.9078173 14.2596747,10.5582072 14.0233519,10.0111529 C13.787029,9.46409867 13.9053053,8.82843039 14.3225768,8.40298782 C14.7398482,7.97754526 15.3731035,7.84696527 15.9246374,8.07263598 C16.4761712,8.29830669 16.8362623,8.83533354 16.835625,9.43125 C16.8396216,9.8162982 16.6902288,10.1871095 16.4204175,10.4618456 C16.1506062,10.7365818 15.7825575,10.8926568 15.3975,10.895625 L15.399375,10.895625 Z" id="Shape" fill="#1D1D1B" fillRule="nonzero" />
        <path d="M22.57875,6.500625 C21.3864465,6.47471584 20.2970573,7.17299239 19.8227175,8.2671865 C19.3483777,9.36138061 19.5834498,10.6338192 20.4174236,11.4863136 C21.2513973,12.338808 22.5183666,12.6017677 23.6227138,12.151572 C24.7270611,11.7013764 25.4490935,10.627585 25.449375,9.435 C25.458375,8.66504836 25.1608626,7.92311348 24.6223992,7.37269207 C24.0839358,6.82227067 23.3487136,6.50853625 22.57875,6.500625 L22.57875,6.500625 Z M22.57875,10.899375 C21.9829579,10.9115673 21.4390497,10.5619572 21.2027269,10.0149029 C20.966404,9.46784867 21.0846803,8.83218039 21.5019518,8.40673782 C21.9192232,7.98129526 22.5524785,7.85071527 23.1040124,8.07638598 C23.6555462,8.30205669 24.0156373,8.83908354 24.015,9.435 C24.0184968,9.81990011 23.8689412,10.1904231 23.599237,10.4650502 C23.3295329,10.7396772 22.9617754,10.8959097 22.576875,10.899375 L22.57875,10.899375 Z" id="Shape" fill="#1D1D1B" fillRule="nonzero" />
        <path d="M41.9625,6.51 L33.3375,6.51 C32.9404196,6.51717786 32.6228827,6.8422354 32.6249391,7.239375 L32.6249391,11.64 C32.6224993,11.8330956 32.697072,12.019223 32.8322077,12.1571741 C32.9673435,12.2951252 33.1518939,12.3735192 33.345,12.375 L41.95875,12.375 C42.3598075,12.3708773 42.6818723,12.0429415 42.6787904,11.641875 L42.6787904,7.245 C42.6829251,6.84464929 42.3628249,6.51616956 41.9625,6.51 L41.9625,6.51 Z M41.25,10.910625 L34.070625,10.910625 L34.070625,7.9725 L41.25,7.9725 L41.25,10.910625 Z" id="Shape" fill="#1D1D1B" fillRule="nonzero" />
        <rect id="Rectangle" fill="#1D1D1B" fillRule="nonzero" x="16.824375" y="26.8875" width="14.35875" height="1.46625" />
        <rect id="Rectangle" fill="#1D1D1B" fillRule="nonzero" x="11.08125" y="26.88" width="4.306875" height="1.46625" />
        <rect id="Rectangle" fill="#1D1D1B" fillRule="nonzero" x="8.20875" y="26.878125" width="1.43625" height="1.46625" />
        <rect id="Rectangle" fill="#1D1D1B" fillRule="nonzero" x="32.6175" y="26.893125" width="4.306875" height="1.46625" />
        <rect id="Rectangle" fill="#1D1D1B" fillRule="nonzero" x="38.3625" y="26.895" width="1.43625" height="1.46625" />
      </g>
    </g>
  </svg>
);

export default SvgFeuer;
