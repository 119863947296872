/* eslint-disable max-len */
import React from 'react';

const SvgFotos = props => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <path
      d="M35.26 9.846a6.368 6.368 0 00-2.477 2.93H6.919v19.012L16.963 16.68l7.305 10.989 4.436-6.311 10.33 14.696c.035.048.066.097.095.147h.976V21.39a6.332 6.332 0 002.929-1.597v17.384a1.952 1.952 0 01-1.953 1.952H5.943a1.952 1.952 0 01-1.952-1.952V11.799c0-1.078.874-1.952 1.952-1.952H35.26zm-18.126 12.69L7.914 36.2h27.409l-6.67-9.346-4.422 6.198-7.097-10.517zm21.507-11.713a4.392 4.392 0 110 8.785 4.392 4.392 0 010-8.785zm0 2.928a1.464 1.464 0 100 2.929 1.464 1.464 0 000-2.929z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

export default SvgFotos;
