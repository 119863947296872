const styles = ({ breakpoints, colors }) => ({
  formWrapper: {
    margin: '0 auto',
    backgroundColor: colors.themeWhite,
  },
  info: {
    paddingLeft: '40px',
    [`@media (max-width: ${breakpoints.breakpointM})`]: {
      paddingLeft: 0,
    },
  },
});

export default styles;
