import React from 'react';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';

import store from '../store';
import adobeAnalytics from '../services/adobeAnalytics';

import Form from './Form/Form';
import ClaimStart from './ClaimStart/ClaimStart';
import NotFound from '../panels/NotFound/NotFound';
import Layout from '../layout/Layout/Layout';

import { polyglot } from '../helpers/polyglot';
import paths from '../helpers/paths';
import handleOnUnload from '../helpers/handleOnUnload';
import { retrieveSessionFromURL } from '../services';
import withNavigation from '../hocs/withNavigation';

const NotFoundScene = withNavigation(NotFound);
const FormScene = withNavigation(Form);

const ShareScene = () => {
  if (retrieveSessionFromURL()) {
    return null;
  }

  return <NotFoundScene />;
};

class App extends React.Component {
  getChildContext() {
    return { t: polyglot.t.bind(polyglot), store };
  }

  componentDidMount = () => {
    window.addEventListener('beforeunload', handleOnUnload);
    adobeAnalytics.pushPageLoadEvent(
      window.location.pathname,
      undefined,
    );
  }

  componentWillUnmount = () => {
    window.removeEventListener('beforeunload', handleOnUnload);
  }

  render() {
    return (
      <Layout>
        <Switch>
          <Redirect from={paths.UPLOAD_SCREEN_FROM} to={paths.UPLOAD_SCREEN_TO} />
          <Redirect exact from={paths.UPLOAD_FROM} to={paths.UPLOAD_TO} />
          <Route exact path={paths.NOT_FOUND} component={NotFoundScene} />
          <Route exact path={paths.SOURCE} component={NotFoundScene} />
          <Route exact path={paths.HOME} component={NotFoundScene} />
          <Route exact path={paths.CLAIM_START} component={ClaimStart} />
          <Route path={paths.CLAIM} component={FormScene} />
          <Route path={paths.SHARE} component={ShareScene} />
          <Route component={NotFoundScene} />
        </Switch>
      </Layout>
    );
  }
}

App.childContextTypes = {
  t: PropTypes.func,
  store: PropTypes.shape(Object),
};


export default hot(module)(App);
