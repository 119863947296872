import { defaultConfig } from './utils';
import { getMessage, VALIDATION_KEYS } from './messages';

const {
  REQUIRED,
} = VALIDATION_KEYS;
export const carMap = (config = defaultConfig) => (value) => {
  const { localePath } = config;

  return (value && value.carMap && value.carMap.length > 0) ? undefined : getMessage(localePath, REQUIRED);
};

export default {
  carMap,
};
