/* eslint-disable max-len */
import React from 'react';

const SvgFensterGlas = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <g
      id="icons/12_buildings/Fenster/48"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="ERGO_Icon_black_Fenster_Glas">
        <path
          d="M41.165625,2.74124868 L6.665625,2.74124868 C5.9321638,2.7422845 5.338125,3.33716307 5.338125,4.070625 L5.338125,37.108125 L7.996875,37.108125 L7.996875,5.4 L39.838125,5.4 L39.838125,42.435 L7.996875,42.435 L7.996875,39.916875 L5.34,39.916875 L5.34,43.764375 C5.34,44.497533 5.93434199,45.0918763 6.6675,45.0918763 L41.1675,45.0918763 C41.5198996,45.0923727 41.8580364,44.9527313 42.1073966,44.7037228 C42.3567568,44.4547143 42.496875,44.116775 42.496875,43.764375 L42.496875,4.070625 C42.496875,3.71772772 42.3565592,3.37931153 42.1068474,3.12995139 C41.8571356,2.88059125 41.5185219,2.74075226 41.165625,2.74124868 Z"
          id="Path"
          fill="#1D1D1B"
          fillRule="nonzero"
        />
        <rect
          id="Rectangle"
          fill="#1D1D1B"
          fillRule="nonzero"
          transform="translate(23.917213, 23.920323) rotate(-22.660000) translate(-23.917213, -23.920323) "
          x="20.8712754"
          y="22.5918858"
          width="6.091875"
          height="2.656875"
        />
        <rect
          id="Rectangle"
          fill="#1D1D1B"
          fillRule="nonzero"
          transform="translate(23.770376, 19.382555) rotate(-22.660000) translate(-23.770376, -19.382555) "
          x="20.724439"
          y="18.0541173"
          width="6.091875"
          height="2.656875"
        />
        <rect
          id="Rectangle"
          fill="#1D1D1B"
          fillRule="nonzero"
          transform="translate(24.064765, 28.450448) rotate(-22.660000) translate(-24.064765, -28.450448) "
          x="21.0188276"
          y="27.1220108"
          width="6.091875"
          height="2.656875"
        />
        <path
          d="M10.640625,7.918125 L10.640625,39.915 L37.194375,39.915 L37.194375,7.918125 L10.640625,7.918125 Z M13.2975,37.258125 L13.2975,10.576875 L34.535625,10.576875 L34.535625,22.588125 L30.75,22.588125 L30.75,25.246875 L34.53375,25.246875 L34.53375,37.258125 L13.2975,37.258125 Z"
          id="Shape"
          fill="#1D1D1B"
          fillRule="nonzero"
        />
        <rect id="Rectangle" x="0" y="0" width="47.835" height="47.835" />
      </g>
    </g>
  </svg>
);

export default SvgFensterGlas;
