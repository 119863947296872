/* eslint-disable max-len */
import React from 'react';

const SvgSmartphone = props => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <g fill="none" fillRule="nonzero">
      <path
        d="M13.03 35.793h12.005v2.16H13.03v-2.16zm9.603-19.45H15.43v2.161h7.203v-2.161zm-3.602 22.811a1.201 1.201 0 10-.003 2.402 1.201 1.201 0 00.003-2.402M29.836 15.38v25.935a3.846 3.846 0 01-3.842 3.842H12.07a3.846 3.846 0 01-3.843-3.842V15.38a3.846 3.846 0 013.843-3.837h13.925a3.846 3.846 0 013.842 3.837m-2.402 0a1.443 1.443 0 00-1.44-1.44H12.07a1.445 1.445 0 00-1.434 1.44v25.935a1.447 1.447 0 001.442 1.442h13.926a1.445 1.445 0 001.44-1.442l-.009-25.935z"
        fill="currentColor"
      />
      <path
        d="M39.793 12.434a1.133 1.133 0 00-.98-.576h-2.885l1.907-7.032a1.128 1.128 0 00-1.923-1.05l-4.983 5.52-3.426-5.747a1.13 1.13 0 00-2.091.578v5.19h2.259V8.22l1.487 2.494a2.027 2.027 0 003.249.32l2.027-2.246-.753 2.76a2.028 2.028 0 001.956 2.56h1.18l-4.764 7.843c-.083.561-.125 1.128-.121 1.694.038.686.222 1.354.542 1.96l7.306-12.047a1.13 1.13 0 00.013-1.124"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default SvgSmartphone;
