const styles = (theme) => ({
  message: {
    marginLeft: '12px',
    fontSize: '12px',
    textAlign: 'left',
    letterSpacing: '0.25px',
    lineHeight: '24px',
    minHeight: 24,
  },
  description: {
    color: theme.colors.themeDarkGray,
  },
  error: {
    color: theme.colors.themeErrorColor,
  },
});

export default styles;
