/* eslint-disable max-len */
import React from 'react';

const SvgTiere = props => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <g fill="currentColor" fillRule="nonzero">
      <path d="M13.45 11.065l6.296 11.166c.493.874.269 1.96-.505 2.561l-.134.096-2.286 1.496a2.037 2.037 0 01-2.151.042l-.14-.09-1.514-1.072-5.287 9.159-5.367-.029-.016-3.022 3.61.019 4.558-7.895-1.222-.864 1.745-2.457 4.67 3.301.928-.607-5.096-9.04-4.975-.752-1.133-2.996 8.02.984zm-3.495 3.819l.004.97c.217 4.839-2.71 7.285-7.994 6.938l-.295-.023.223-3.012c3.672.309 5.093-.757 5.033-3.646l-.007-.215-.006-1.028 3.042.016zM21.158 4.521c-.786 1.615-1.293 3.128-1.52 4.548a32.74 32.74 0 013.505-1.454l.394-.13-1.557 3.602c-1.287.41-1.287.619-2.333 1.297.324 1.773 1.193 3.38 2.607 4.847.486.504.604.76 1.133 1.185l.192.149-1.273 2.699c-5.296-3.946-6.997-9.097-5.08-15.133l.112-.342 3.82-1.268zM34.154 9.364c-2.475.436-4.359 2.45-4.605 4.921l-.363 3.638-.27.048c-1.935.341-3.231 2.168-2.894 4.08.106.6.367 1.161.76 1.63l.272.326a8.453 8.453 0 00-1.13 5.908l1.007 5.71a3.5 3.5 0 002.003 2.576l.371 2.104c.386 2.186 2.492 3.64 4.704 3.25l2.003-.353c2.212-.39 3.694-2.477 3.308-4.662l-.26-1.485 5.972-1.053.007-3.061-14.515 2.56a.505.505 0 01-.588-.406l-1.007-5.71a5.48 5.48 0 011.215-4.494l.81-.963-1.82-2.174a.497.497 0 01-.108-.233.505.505 0 01.414-.583l2.554-.45.59-5.92a2.538 2.538 0 012.093-2.237l10.41-1.836.006-3.06-10.94 1.929zm-2.019 29.423l4.006-.707.175.99a1.01 1.01 0 01-.827 1.165l-2.003.353a1.01 1.01 0 01-1.176-.812l-.175-.99zm-1.918-10.88l.419 2.373 4.089-.721a.598.598 0 01.689.49.6.6 0 01-.386.672l-.094.025-4.09.72.42 2.375 4.089-.721c1.555-.274 2.61-1.734 2.423-3.307l-.025-.176c-.279-1.58-1.723-2.657-3.272-2.477l-.173.025-4.089.721z" />
    </g>
  </svg>
);

export default SvgTiere;
