/* eslint-disable max-len */
import React from 'react';

function SvgErgoIconBlackDokument9(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M36.576 13.892l.017-.023-9.265-7.943v.01l-7.53.02h-5.421a2.376 2.376 0 00-2.366 2.372v28.321c0 1.301 1.066 2.604 2.368 2.604h19.863a2.372 2.372 0 002.368-2.359V13.892h-.034zm-9.224-4.41l5.152 4.41H27.36l-.008-4.41zm6.632 22.211v4.934h-19.37V8.58h5.17l4.96-.015v5.575a2.37 2.37 0 002.362 2.368h6.88l-.002 15.185z"
          fill="#1D1D1B"
          fillRule="nonzero"
        />
        <path
          d="M25.27 21.333c.735 0 1.27.165 2.003.6.166.1.234.066.584-.517l.188-.302c.316-.517.35-.683.05-.884a4.936 4.936 0 00-2.971-.883c-2.538 0-4.141 1.619-4.672 4.188H19.3c-.168 0-.234.017-.234.753v.017c0 .734.066.753.234.753h.967c-.017.2-.017.376-.017.583v.418h-.94c-.168 0-.234.015-.234.753v.015c0 .736.066.753.233.753h1.101c.467 2.604 1.935 4.123 4.573 4.123a5.647 5.647 0 003.12-.885c.285-.188.25-.316 0-.834l-.15-.316c-.267-.565-.377-.603-.584-.484a4.002 4.002 0 01-2.02.516c-1.334 0-2.102-.766-2.446-2.12h2.737c.167 0 .233-.015.233-.753v-.015c0-.736-.066-.753-.233-.753H22.7c-.016-.15-.016-.318-.016-.483 0-.166 0-.352.015-.518h2.922c.167 0 .233-.017.233-.753v-.023c0-.734-.066-.753-.233-.753H22.9c.35-1.43 1.167-2.196 2.37-2.196z"
          fill="#000"
          fillRule="nonzero"
        />
        <path d="M0 0h48.023v48.023H0z" />
      </g>
    </svg>
  );
}

export default SvgErgoIconBlackDokument9;
