/* eslint-disable max-len */
import React from 'react';

const SvgCar = props => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <path
      d="M32.53 6.997c2.473 0 4.641 1.674 5.302 4.092l.973 3.558h.27c1.934 0 3.501 1.59 3.501 3.553 0 .616-.157 1.221-.457 1.757l-.209.372a8.681 8.681 0 012.104 5.684v5.861a3.565 3.565 0 01-1.5 2.916v2.161c0 2.243-1.792 4.062-4.002 4.062H36.51c-2.21 0-4.002-1.819-4.002-4.062v-1.524H15.5v1.524c0 2.243-1.792 4.062-4.002 4.062h-2c-2.21 0-4.002-1.819-4.002-4.062v-2.16a3.565 3.565 0 01-1.501-2.917v-5.861c0-2.129.765-4.133 2.104-5.684l-.209-.372a3.595 3.595 0 01-.457-1.757c0-1.962 1.567-3.553 3.501-3.553h.27l.973-3.558c.66-2.418 2.83-4.092 5.302-4.092h17.05zM12.5 35.936H8.498v1.015c0 .561.448 1.016 1 1.016H11.5c.553 0 1-.455 1-1.016v-1.015zm27.012 0H35.51v1.015c0 .561.448 1.016 1 1.016h2.002c.552 0 1-.455 1-1.016v-1.015zM32.53 10.043H15.48c-1.124 0-2.11.761-2.41 1.86l-1.583 5.79H8.935c-.276 0-.5.227-.5.507 0 .088.023.175.065.251l1.391 2.484-.948.817a5.602 5.602 0 00-1.946 4.26v5.862c0 .28.224.508.5.508h33.016c.276 0 .5-.227.5-.508v-5.861a5.602 5.602 0 00-1.946-4.261l-.948-.817 1.39-2.484a.514.514 0 00.066-.25.504.504 0 00-.5-.508h-2.552l-1.583-5.79a2.507 2.507 0 00-2.41-1.86zm6.982 14.724v2.436h-4.085a.597.597 0 00-.584.61c0 .303.212.554.49.601l.094.008h4.085v2.436h-4.085c-1.554 0-2.824-1.267-2.913-2.866l-.005-.18c0-1.622 1.215-2.948 2.746-3.04l.172-.005h4.085zm-26.929 0l.172.005c1.531.092 2.746 1.418 2.746 3.04l-.005.18c-.089 1.599-1.359 2.867-2.913 2.867H8.5V28.42h4.084l.095-.007a.602.602 0 00.489-.601l-.008-.1a.592.592 0 00-.576-.51H8.5v-2.436h4.084zm15.924 2.03v3.046h-9.004v-3.046h9.004zm6.003-7.107l-2.001 3.046H15.547L13.5 19.69h21.01z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

export default SvgCar;
