/* eslint-disable max-len */
import React from 'react';

const SvgMoebel2 = props => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M41.23 33.309a.892.892 0 00-.784.885v11.51c0 .041-.033.074-.074.074h-2.343a.074.074 0 01-.073-.074l-.008-10.938v-.006l-.002-3.986a.56.56 0 00-.002-.05c-.004-.04-.003-.105-.002-.163v-.09a2.79 2.79 0 01.945-2.09.613.613 0 00.213-.173 2.997 2.997 0 011.918-.585c1.48.068 2.692 1.262 2.76 2.717a2.882 2.882 0 01-2.548 2.969zm-23.26 12.47h-2.342a.073.073 0 01-.074-.075v-11.51a.892.892 0 00-.784-.885 2.882 2.882 0 01-2.548-2.97c.068-1.454 1.28-2.648 2.76-2.716l.137-.003c.642 0 1.265.206 1.781.588.057.073.13.133.213.174l.067.06a2.79 2.79 0 01.878 2.12 2.02 2.02 0 01-.004.212l-.006 9.603-.001 2.303v.002l-.003 3.022c0 .04-.032.074-.073.074zm1.3-10.538c2.112-.6 4.278-.983 6.468-1.141l.031.002a.595.595 0 00.116-.012 31.852 31.852 0 0110.845 1.14l.003 4.537H19.267l.003-4.526zm-1.279-12.22a.793.793 0 01.786-.8h18.446c.437.005.788.362.786.8v4.51l-.03.027a4.003 4.003 0 00-1.255 2.913l-.001.074c0 .078-.002.164.004.252l.002 3.165a33.103 33.103 0 00-5.307-.991l1.124-3.102a.609.609 0 10-1.146-.418l-1.236 3.414a33.031 33.031 0 00-4.02-.016l-1.565-3.369a.609.609 0 00-1.105.516l1.371 2.953a33.406 33.406 0 00-5.574 1.024l.002-3.176c.006-.088.004-.175.004-.252l-.001-.074a4.002 4.002 0 00-1.255-2.913l-.03-.028v-4.51zm23.083 3.38a4.212 4.212 0 00-1.846.335V23.02A2.015 2.015 0 0037.223 21H18.777a2.015 2.015 0 00-2.005 2.02v3.716a4.213 4.213 0 00-1.846-.334c-2.103.098-3.825 1.803-3.922 3.88-.094 2.026 1.35 3.814 3.331 4.195v11.227c.001.716.58 1.295 1.293 1.296h2.343c.713 0 1.29-.58 1.292-1.295l.002-2.413h17.47l.002 2.413c.001.715.58 1.294 1.292 1.295h2.343c.713 0 1.292-.58 1.293-1.296V34.477c1.982-.38 3.425-2.169 3.33-4.194-.097-2.079-1.819-3.783-3.921-3.881z"
        fill="currentColor"
        fillRule="nonzero"
      />
      <path
        d="M41.23 33.309a.892.892 0 00-.784.885v11.51c0 .041-.033.074-.074.074h-2.343a.074.074 0 01-.073-.074l-.008-10.938v-.006l-.002-3.986a.56.56 0 00-.002-.05c-.004-.04-.003-.105-.002-.163v-.09a2.79 2.79 0 01.945-2.09.613.613 0 00.213-.173 2.997 2.997 0 011.918-.585c1.48.068 2.692 1.262 2.76 2.717a2.882 2.882 0 01-2.548 2.969zm-23.26 12.47h-2.342a.073.073 0 01-.074-.075v-11.51a.892.892 0 00-.784-.885 2.882 2.882 0 01-2.548-2.97c.068-1.454 1.28-2.648 2.76-2.716l.137-.003c.642 0 1.265.206 1.781.588.057.073.13.133.213.174l.067.06a2.79 2.79 0 01.878 2.12 2.02 2.02 0 01-.004.212l-.006 9.603-.001 2.303v.002l-.003 3.022c0 .04-.032.074-.073.074h0zm1.3-10.538c2.112-.6 4.278-.983 6.468-1.141l.031.002a.595.595 0 00.116-.012 31.852 31.852 0 0110.845 1.14l.003 4.537H19.267l.003-4.526zm-1.279-12.22a.793.793 0 01.786-.8h18.446c.437.005.788.362.786.8v4.51l-.03.027a4.003 4.003 0 00-1.255 2.913l-.001.074c0 .078-.002.164.004.252l.002 3.165a33.103 33.103 0 00-5.307-.991l1.124-3.102a.609.609 0 10-1.146-.418l-1.236 3.414a33.031 33.031 0 00-4.02-.016l-1.565-3.369a.609.609 0 00-1.105.516l1.371 2.953a33.406 33.406 0 00-5.574 1.024l.002-3.176c.006-.088.004-.175.004-.252l-.001-.074a4.002 4.002 0 00-1.255-2.913l-.03-.028v-4.51zm23.083 3.38a4.212 4.212 0 00-1.846.335V23.02A2.015 2.015 0 0037.223 21H18.777a2.015 2.015 0 00-2.005 2.02v3.716a4.213 4.213 0 00-1.846-.334c-2.103.098-3.825 1.803-3.922 3.88-.094 2.026 1.35 3.814 3.331 4.195v11.227c.001.716.58 1.295 1.293 1.296h2.343c.713 0 1.29-.58 1.292-1.295l.002-2.413h17.47l.002 2.413c.001.715.58 1.294 1.292 1.295h2.343c.713 0 1.292-.58 1.293-1.296V34.477c1.982-.38 3.425-2.169 3.33-4.194-.097-2.079-1.819-3.783-3.921-3.881z"
        stroke="currentColor"
        strokeWidth={1.073}
      />
      <path
        d="M16.804 15.728c.002-2.278 1.638-4.124 3.658-4.127h.07c2.02.003 3.656 1.849 3.659 4.127v.302a.656.656 0 00-.025.087h-7.362v-.389zm23.122-9.897v10.833l-4.397-3.044a.493.493 0 00-.713-.02l-2.945 2.798-3.509-4.213a.495.495 0 00-.769-.016l-2.395 2.672a5.674 5.674 0 00-.925-2.376V5.831h15.653zM32.69 17.38l-.082-.099 2.535-2.41 3.89 2.509H32.69zm-8.417-.053h.454c.297 0 .538-.27.538-.606v-.25l2.691-3.004 3.26 3.913h-6.943v-.053zm-3.677.488c-.31-.006-.595-.19-.756-.488h1.512c-.161.298-.447.482-.756.488zm-1.917-.488c.238.978 1.011 1.7 1.917 1.7.905 0 1.678-.722 1.916-1.7h.687v.659c0 .334.24.605.537.605h16.727c.297 0 .537-.27.537-.605V5.226c0-.335-.24-.606-.537-.606H23.736c-.296 0-.537.27-.537.606v6.096a4.386 4.386 0 00-2.179-.903C20.774 5.176 17.087 1 12.59 1c-4.64 0-8.418 4.447-8.443 9.924l-.002.042v34.822H2.537c-.297 0-.537.272-.537.606 0 .335.24.606.537.606h4.85c.297 0 .538-.271.538-.606 0-.334-.24-.606-.537-.606H5.219v-34.78l.001-.03c0-4.834 3.307-8.767 7.37-8.767 3.907 0 7.113 3.635 7.354 8.211-2.397.3-4.212 2.585-4.215 5.306v.994c0 .335.24.606.537.606h2.413z"
        fill="currentColor"
        fillRule="nonzero"
      />
      <path
        d="M16.804 15.728c.002-2.278 1.638-4.124 3.658-4.127h.07c2.02.003 3.656 1.849 3.659 4.127v.302a.656.656 0 00-.025.087h-7.362v-.389zm23.122-9.897v10.833l-4.397-3.044a.493.493 0 00-.713-.02l-2.945 2.798-3.509-4.213a.495.495 0 00-.769-.016l-2.395 2.672a5.674 5.674 0 00-.925-2.376V5.831h15.653zM32.69 17.38l-.082-.099 2.535-2.41 3.89 2.509H32.69zm-8.417-.053h.454c.297 0 .538-.27.538-.606v-.25l2.691-3.004 3.26 3.913h-6.943v-.053zm-3.677.488c-.31-.006-.595-.19-.756-.488h1.512c-.161.298-.447.482-.756.488zm-1.917-.488c.238.978 1.011 1.7 1.917 1.7.905 0 1.678-.722 1.916-1.7h.687v.659c0 .334.24.605.537.605h16.727c.297 0 .537-.27.537-.605V5.226c0-.335-.24-.606-.537-.606H23.736c-.296 0-.537.27-.537.606v6.096a4.386 4.386 0 00-2.179-.903C20.774 5.176 17.087 1 12.59 1c-4.64 0-8.418 4.447-8.443 9.924l-.002.042v34.822H2.537c-.297 0-.537.272-.537.606 0 .335.24.606.537.606h4.85c.297 0 .538-.271.538-.606 0-.334-.24-.606-.537-.606H5.219v-34.78l.001-.03c0-4.834 3.307-8.767 7.37-8.767 3.907 0 7.113 3.635 7.354 8.211-2.397.3-4.212 2.585-4.215 5.306v.994c0 .335.24.606.537.606h2.413z"
        stroke="currentColor"
        strokeWidth={1.073}
      />
      <path
        d="M19.909 12.204a.5.5 0 00-.688-.115 4.337 4.337 0 00-1.14 1.163.478.478 0 00.143.67.5.5 0 00.683-.142c.234-.352.535-.658.885-.901a.478.478 0 00.117-.675"
        fill="currentColor"
        fillRule="nonzero"
      />
      <path
        d="M19.909 12.204a.5.5 0 00-.688-.115 4.337 4.337 0 00-1.14 1.163.478.478 0 00.143.67.5.5 0 00.683-.142c.234-.352.535-.658.885-.901a.478.478 0 00.117-.675z"
        stroke="currentColor"
        strokeWidth={0.5}
      />
      <path
        d="M35.27 9.136c.542 0 1.44.387 1.44.864s-.898.864-1.44.864c-.54 0-.98-.387-.98-.864s.44-.863.98-.864m0 2.864c1.255 0 2.73-.895 2.73-2s-1.475-2-2.73-2C34.018 8 33 8.895 33 10c.001 1.104 1.017 1.999 2.27 2"
        fill="currentColor"
        fillRule="nonzero"
      />
      <path
        d="M35.27 9.136c.542 0 1.44.387 1.44.864s-.898.864-1.44.864c-.54 0-.98-.387-.98-.864s.44-.863.98-.864zm0 2.864c1.255 0 2.73-.895 2.73-2s-1.475-2-2.73-2C34.018 8 33 8.895 33 10c.001 1.104 1.017 1.999 2.27 2z"
        stroke="currentColor"
        strokeWidth={0.5}
      />
      <path fill="#FFF" fillRule="nonzero" d="M19 42v-1h17v1z" />
    </g>
  </svg>
);

export default SvgMoebel2;
