/* eslint-disable max-len */
import React from 'react';

function SvgDokument7(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g fill="none" fillRule="evenodd">
        <g fill="#1D1D1B" fillRule="nonzero">
          <path d="M16.841 19.735h6.57v2.625h-6.57zM16.841 25.012h13.14v2.625h-13.14z" />
        </g>
        <path
          d="M33.066 33.521v4.46H13.764V10.045h5.149l4.939-.015v5.545a2.363 2.363 0 002.36 2.359h6.854v4.89l2.608-3.535v-3.962h-.036l.019-.022-9.22-7.915v.01l-7.5.022h-5.399A2.366 2.366 0 0011.155 9.8v28.194c0 1.298 1.063 2.595 2.359 2.595h19.798a2.363 2.363 0 002.36-2.359v-8.25l-2.607 3.542zm-6.606-22.59l5.124 4.395H26.46v-4.395z"
          fill="#1D1D1B"
          fillRule="nonzero"
        />
        <path
          d="M29.58 30.684l-.212 1.905c-.028.257.049.375.12.424.054.04.12.06.188.055a.7.7 0 00.264-.062c.354-.15 1.751-.75 1.751-.75h.012l10.485-14.237-2.117-1.562L29.58 30.684zM44.987 13.573a1.312 1.312 0 00-2.353-.587l-1.5 2.046-.028.037 2.116 1.562.029-.037 1.5-2.046c.204-.28.29-.631.236-.975zM17.044 35.303a.53.53 0 01-.478-.765c1.687-3.475 2.5-3.355 2.812-3.31.724.109 1.031.866 1.301 1.536.111.27.312.765.447.853.176-.062.528-.61.72-.908.442-.69.937-1.473 1.725-1.387.703.077.991.774 1.246 1.387.1.238.297.713.437.807.27-.118 1.092-.904 2.1-2.736a.535.535 0 01.938.514c-.583 1.06-2.063 3.5-3.199 3.281-.703-.135-1.007-.868-1.25-1.457-.096-.229-.27-.647-.387-.735-.176.068-.521.608-.71.9-.443.69-.938 1.472-1.723 1.393-.74-.075-1.049-.838-1.313-1.513-.098-.242-.293-.722-.433-.853-.223.161-.84.851-1.735 2.692a.533.533 0 01-.498.3z"
          fill="#1D1D1B"
          fillRule="nonzero"
        />
        <path d="M0 0h47.835v47.835H0z" />
      </g>
    </svg>
  );
}

export default SvgDokument7;
