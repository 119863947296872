import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Textarea from '@eg/elements/Textarea';
import FormMessage from '../FormMessage';

const ErgoTextArea = ({
  name, value, rows, minRows, maxRows, description, placeholder, error, onChange,
}) => (
  <Fragment>
    <Textarea
      error={!!error}
      name={name}
      value={value}
      placeholder={placeholder}
      rows={rows}
      minRows={minRows}
      maxRows={maxRows}
      onChange={onChange}
    />
    <FormMessage error={error} description={description} />
  </Fragment>
);

ErgoTextArea.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  rows: PropTypes.number,
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
  description: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
};

ErgoTextArea.defaultProps = {
  value: '',
  rows: undefined,
  minRows: 3,
  maxRows: 15,
  description: '',
  placeholder: '',
  error: '',
};

export { ErgoTextArea as ErgoTextAreaPlain };
export default ErgoTextArea;
