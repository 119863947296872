import iban from './iban.yaml';
import ibanComprehensive from './iban-comprehensive.yaml';
import documentUpload from './document-upload.yaml';
import eventCircumstances from './event-circumstances.yaml';

export default {
  iban,
  ibanComprehensive,
  documentUpload,
  eventCircumstances,
};
