/* eslint-disable max-len */
import React from 'react';

function SvgKrankenhaus1(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#1D1D1B"
          fillRule="nonzero"
          d="M8.791 34.264h2.712v3.659H8.791zM14.249 34.264h2.712v3.659h-2.712zM8.791 26.195h2.712v3.659H8.791zM14.249 26.195h2.712v3.659h-2.712zM8.791 18.234h2.712v3.659H8.791zM14.249 18.234h2.712v3.659h-2.712zM16.964 7.475h-2.715V9.43h-1.957v2.714h1.957v1.958h2.715v-1.958h1.957V9.43h-1.957zM19.55 18.234h2.712v3.659H19.55z"
        />
        <path
          d="M4.87 5.82h21.41v13.497h2.635V5.387a2.216 2.216 0 00-2.21-2.208H4.445a2.216 2.216 0 00-2.21 2.214v37.901H18.16V40.66H4.87V5.82zM28.666 21.416h.057c.134-.025.27-.037.406-.038h.166c.136 0 .272.013.407.038h.056v-1.39h-1.092v1.39z"
          fill="#1D1D1B"
          fillRule="nonzero"
        />
        <path
          d="M26.929 22.878c.119-.307.3-.586.535-.819l.04-.041-1.177-.753-.593.915 1.174.753.02-.055zM31.657 24.013h1.34v1.092h-1.34zM29.13 22.103a1.628 1.628 0 00-1.627 1.624v1.376h1.34v-1.376a.286.286 0 01.286-.284h.166c.157 0 .285.127.286.284v1.376h1.34v-1.376a1.628 1.628 0 00-1.626-1.624h-.166zM31.497 22.884l.021.052 1.184-.764-.593-.915-1.184.767.042.04c.232.234.412.513.53.82zM37.928 37.498a2.927 2.927 0 10-.015 5.854 2.927 2.927 0 00.015-5.854zm0 4.155a1.227 1.227 0 110-2.455 1.227 1.227 0 010 2.455zM32.553 31.684h-2.492v-2.492h-1.698v2.492h-2.492v1.698h2.492v2.494h1.698v-2.494h2.492zM25.427 24.013h1.34v1.092h-1.34zM27.136 37.498a2.927 2.927 0 10-.004 5.854 2.927 2.927 0 00.004-5.854zm0 4.155a1.227 1.227 0 110-2.455 1.227 1.227 0 010 2.455z"
          fill="#1D1D1B"
          fillRule="nonzero"
        />
        <path
          d="M45.429 35.268a2.568 2.568 0 00-1.382-2.191l-2.212-1.045a2.244 2.244 0 01-.873-.91l-1.634-3.828a2.483 2.483 0 00-2.168-1.432H21.487a1.848 1.848 0 00-1.847 1.846v11.746a1.848 1.848 0 001.847 1.847h1.864v-.023a3.972 3.972 0 01-.095-.853c0-.268.027-.537.083-.8v-.022h-1.85a.149.149 0 01-.147-.149V27.716c0-.082.066-.148.147-.149H37.16c.254.031.479.18.607.401l1.628 3.821a3.89 3.89 0 001.709 1.779l2.214 1.045a.887.887 0 01.414.655v4.186a.15.15 0 01-.147.149h-1.858v.022c.114.546.11 1.11-.011 1.653v.023h1.87a1.848 1.848 0 001.845-1.847l-.003-4.186z"
          fill="#1D1D1B"
          fillRule="nonzero"
        />
        <path
          d="M34.106 39.622h-3.15a3.863 3.863 0 01-.012 1.66h3.176a3.89 3.89 0 01-.014-1.66z"
          fill="#1D1D1B"
          fillRule="nonzero"
        />
        <path d="M0 0h48.023v48.023H0z" />
      </g>
    </svg>
  );
}

export default SvgKrankenhaus1;
