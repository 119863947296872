/* eslint-disable max-len */
import React from 'react';

const SvgRechtsanwalt = props => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <path
      d="M33.004 38.006v3.002H14.996v-3.002h18.008zm-10.506-21.35a6.457 6.457 0 003.003 0v19.67h-3.002l-.001-19.67zM18.08 9.993a6.043 6.043 0 00.26 3.003l-5.79-.002 5.447 14.007a7.003 7.003 0 01-14.002.241l-.004-.24 5.446-14.008-2.445.001V9.994H18.08zm11.84 0h11.088v3.002h-2.445l5.446 14.006a7.003 7.003 0 01-14.002.241l-.004-.24 5.445-14.007h-5.787a5.993 5.993 0 00.259-3.002zm11.088 17.008h-8.004a4.002 4.002 0 007.999.2l.005-.2zm-26.012 0H6.992a4.002 4.002 0 007.999.2l.005-.2zm-4.002-9.727L8.378 24h5.232l-2.616-6.726zm26.012 0L34.39 24h5.232l-2.616-6.726zM24 6.992a4.002 4.002 0 110 8.004 4.002 4.002 0 010-8.004zm0 2.401a1.6 1.6 0 100 3.202 1.6 1.6 0 000-3.202z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

export default SvgRechtsanwalt;
