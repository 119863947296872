import * as ErgoBreakpoints from '@eg/elements/styles/breakpoints.json';
import { objectsForTheme } from './format';

export const mapSizesToProps = ({ width }) => {
  const { breakpointM: mobileBreakpoint } = objectsForTheme(ErgoBreakpoints);
  return {
    isMobile: width < parseInt(mobileBreakpoint, 10),
  };
};

export default {
  mapSizesToProps,
};
