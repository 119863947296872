import React from 'react';
import PropTypes from 'prop-types';

import Modal from '@eg/elements/Modal';
import Button from '@eg/elements/Button';

import buttonActions from './buttonActions';

const inlinePaddingForContent = {
  padding: '20px 0 10px',
};

const inlineSpacingForButtons = {
  display: 'flex',
  justifyContent: 'space-between',
};

class CommunicationModal extends React.PureComponent {
  state = {
    showDialog: false,
    errorCode: 0,
    buttonOptions: [],
  }

  showDialog = (error, buttonOptions) => {
    this.setState({
      showDialog: true,
      errorCode: error,
      buttonOptions,
    });
  };

  hideDialog = () => {
    this.setState({
      showDialog: false,
      errorCode: 0,
      buttonOptions: [],
    });
  };

  launchButtonAction = (func, opts) => ev => {
    ev.preventDefault();
    if (buttonActions[func]) {
      buttonActions[func](opts);
    }
  };

  getButtons = () => {
    const {
      errorCode,
      buttonOptions,
    } = this.state;

    const { t } = this.context;

    if (buttonOptions && buttonOptions.length) {
      return buttonOptions.map(buttonConfig => (
        <Button
          size="auto"
          variant={buttonConfig.variant}
          key={buttonConfig.name}
          onClick={this.launchButtonAction(buttonConfig.func, buttonConfig.opts)}
        >
          {t(`communication.errors.${errorCode}.${buttonConfig.name}`)}
        </Button>
      ));
    }

    return null;
  }

  render() {
    const {
      showDialog,
      errorCode,
    } = this.state;

    const { t } = this.context;

    return (
      <Modal
        open={showDialog}
        dismissible
        onDismiss={this.hideDialog}
        backdropDismissesModal
      >
        <div>
          <h2>
            {t(`communication.errors.${errorCode}.header`)}
          </h2>
        </div>
        <div className="esc_grid">
          <div className="esc_grid__wrapper">
            <div className="esc_col esc_col-12">
              <div style={inlinePaddingForContent}>
                {t(`communication.errors.${errorCode}.message`)}
              </div>
            </div>
            <div className="esc_col esc_col-12" style={inlineSpacingForButtons}>
              {this.getButtons()}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

CommunicationModal.propTypes = {
};

CommunicationModal.defaultProps = {
};

CommunicationModal.contextTypes = {
  t: PropTypes.func,
};

export { CommunicationModal as CommunicationModalPlain };

export default CommunicationModal;

/* sample call
window.communicationModal.showDialog(404, [
    {
        func: 'goToUrl',
        name: 'cancel',
        variant: 'primary',
        opts: {
            location: '/',
        }
    },
    {
        func: 'ownCallback',
        name: 'cancel',
        variant: 'secondary',
        opts: {
            location: '/',
            callbackData: {
                'test': 'yooy',
            },
            callback: (data) => {
                console.log('callback called');
                console.log(data);
            }
        }
    }
]);
*/
