/* eslint-disable max-len */
import React from 'react';

const SvgChart = props => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <path
      d="M5.355 7.174c.754 0 1.364.61 1.364 1.364v29.56h35.926a1.364 1.364 0 110 2.728H3.99V8.538c0-.753.61-1.364 1.364-1.364zm32.288 2.729a4.548 4.548 0 11-.782 9.028l-2.48 4.296a4.548 4.548 0 11-8.519 1.504l-3.091-2.785a4.556 4.556 0 01-3.532.55l-3.618 6.266a4.548 4.548 0 11-2.321-1.439l3.617-6.266a4.548 4.548 0 117.629-1.184l2.568 2.313a4.534 4.534 0 013.253-1.37c.713 0 1.389.165 1.99.458l2.078-3.6a4.548 4.548 0 013.208-7.771zM12.176 29.912a1.819 1.819 0 100 3.638 1.819 1.819 0 000-3.638zm18.19-6.367a1.819 1.819 0 100 3.638 1.819 1.819 0 000-3.638zM20.363 16.27a1.819 1.819 0 100 3.638 1.819 1.819 0 000-3.638zm17.28-3.638a1.819 1.819 0 100 3.638 1.819 1.819 0 000-3.638z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

export default SvgChart;
