export const types = {
  NAVIGATE_TO_PATH: 'NAVIGATE_TO_PATH',
  FIELD_UPDATE: 'FIELD_UPDATE',
  FIELD_UPDATE_MULTI: 'FIELD_UPDATE_MULTI',
  FIELD_REMOVE: 'FIELD_REMOVE',
  SET_VISIBILITY_FILTER: 'SET_VISIBILITY_FILTER',
  FILE_UPLOAD_ADD: 'FILE_UPLOAD_ADD',
  LOADER_START_LOADING: 'LOADER_START_LOADING',
  LOADER_STOP_LOADING: 'LOADER_STOP_LOADING',
  FILE_UPLOAD_REMOVE: 'FILE_UPLOAD_REMOVE',
  ADD_POLICE_REPORT_TODO_ITEM: 'ADD_POLICE_REPORT_TODO_ITEM',
  SHOW_MODAL: 'SHOW_MODAL',
  HIDE_MODAL: 'HIDE_MODAL',
  NAVIGATION_UPDATE_START: 'NAVIGATION_UPDATE_START',
  NAVIGATION_UPDATE_END: 'NAVIGATION_UPDATE_END',
  NAVIGATION_UPDATE_STATE: 'NAVIGATION_UPDATE_STATE',
  NAVIGATION_SET_SUMMARY_PAGE: 'NAVIGATION_SET_SUMMARY_PAGE',
  RESET_FORM_DATA: 'RESET_FORM_DATA',
  NAVIGATION_THROUGH_SECTION: 'NAVIGATION_THROUGH_SECTION',
  REDIRECT_TO_BEGINNING: 'REDIRECT_TO_BEGINNING',
  FETCH_WORKSHOPS: 'FETCH_WORKSHOPS',
  FETCH_WORKSHOPS_SUCCESS: 'FETCH_WORKSHOPS_SUCCESS',
  FETCH_WORKSHOPS_FAILURE: 'FETCH_WORKSHOPS_FAILURE',
  UPDATE_WORKSHOP_POSTAL_CODE: 'UPDATE_WORKSHOP_POSTAL_CODE',
};

export const NAV_DIRECTIONS = {
  NEXT: 'next',
  PREVIOUS: 'previous',
};

export const VISIBILITY_FILTERS = {
  SHOW_ALL: 'SHOW_ALL',
  SHOW_COMPLETED: 'SHOW_COMPLETED',
  SHOW_ACTIVE: 'SHOW_ACTIVE',
};
