import React from 'react';
import PropTypes from 'prop-types';
import { hasEmptyValue } from '../helpers/validation/utils';

const withDynamicButtonCaption = (WrappedComponent) => {
  class WithDynamicButtonCaption extends React.Component {
    constructor(props) {
      super(props);

      this.getButtonText = this.getButtonText.bind(this);
    }

    getButtonText() {
      const {
        name,
        values,
        isValid,
        isMandatory,
      } = this.props;
      const { t } = this.context;

      if (isMandatory) {
        return undefined;
      }

      return isValid && hasEmptyValue(values[name]) ? t('button.skip') : undefined;
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          buttonText={this.getButtonText()}
        />
      );
    }
  }

  WithDynamicButtonCaption.propTypes = {
    name: PropTypes.string,
    values: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.object,
    ]),
    isValid: PropTypes.bool,
    isMandatory: PropTypes.bool,
  };

  WithDynamicButtonCaption.defaultProps = {
    name: '',
    values: {},
    isValid: false,
    isMandatory: false,
  };

  WithDynamicButtonCaption.contextTypes = {
    t: PropTypes.func,
  };

  return WithDynamicButtonCaption;
};

export default withDynamicButtonCaption;
