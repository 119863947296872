/* eslint-disable max-len */
import React from 'react';

const SvgSeitenscheiben = props => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <g fill="currentColor" fillRule="nonzero">
      <path d="M27.184 3.99h14.602v40.02H14.833L6.214 27.08l1.322-6.864A20.01 20.01 0 0127.184 3.991zM38.45 7.327H27.184a16.674 16.674 0 00-16.374 13.52l-1.105 5.74 7.173 14.088H38.45V7.326z" />
      <path d="M29.558 33.449h6.909v-3.335H29.558zM16.495 23.444c.193-6.676 6.012-10.004 18.335-10.004h1.667v-3.335H34.83c-14.926 0-22.492 5.059-21.61 15.152l.133 1.522h23.144v-9.846l-3.335 1.509v5.002H16.495z" />
    </g>
  </svg>
);

export default SvgSeitenscheiben;
