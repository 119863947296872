import { inRange } from 'lodash';
import { getMessage, VALIDATION_KEYS } from './messages';
import { defaultConfig } from './utils';

const {
  RANGE,
} = VALIDATION_KEYS;

export const range = (config = defaultConfig) => {
  const {
    localePath,
    message,
    min,
    max,
  } = config;
  return (value) => (value !== '' && !inRange(parseFloat(value), min, max)
    ? message || getMessage(localePath, RANGE)
    : undefined);
};

export default {
  range,
};
