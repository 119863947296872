import { addSegments } from '../../../helpers/flow/flow';
import flow from './main.yaml';

import eventCircumstances from './segments/event-circumstances.yaml';
import contact from './segments/contact.yaml';
import policeReport from './segments/police-report.yaml';
import areThereAnyWitnesses from './segments/are-there-any-witnesses.yaml';
import witness from './segments/witness.yaml';
import repairComprehensive from './segments/repair-comprehensive.yaml';
import damageRepair from './segments/damage-repair.yaml';
import documentUpload from './segments/upload-documents.yaml';

const segments = {
  eventCircumstances,
  contact,
  policeReport,
  areThereAnyWitnesses,
  witness,
  repairComprehensive,
  damageRepair,
  documentUpload,
};

export default addSegments(flow, segments);
