import { addSegments } from '../../../helpers/flow/flow';
import flow from './main.yaml';

import fire from './segments/fire.yaml';
import fireBusiness from './segments/fire-business.yaml';
import fireDamageCircumstances from './segments/fire-damage-circumstances.yaml';
import fireDamageCircumstancesBusiness from './segments/fire-damage-circumstances-business.yaml';
import theft from './segments/theft.yaml';
import theftBusiness from './segments/theft-business.yaml';
import theftDamageCircumstances from './segments/theft-damage-circumstances.yaml';
import theftBusinessDamageCircumstances from './segments/theft-business-damage-circumstances.yaml';
import theftDamageCircumstancesBuilding from './segments/theft-damage-circumstances-building.yaml';
import theftWhatDamagedBuilding from './segments/theft-what-damaged-building.yaml';
import theftWhatDamagedHousehold from './segments/theft-what-damaged-household.yaml';
import bicycleTheft from './segments/bicycle-theft.yaml';
import bicycleTheftBusiness from './segments/bicycle-theft-business.yaml';
import water from './segments/water.yaml';
import waterDamageCircumstances from './segments/water-damage-circumstances.yaml';
import stormHail from './segments/storm-hail.yaml';
import stormHailBusiness from './segments/storm-hail-business.yaml';
import stormDamageCircumstances from './segments/storm-damage-circumstances.yaml';
import stormWhatDamagedBuilding from './segments/storm-what-damaged-building.yaml';
import naturalHazards from './segments/natural-hazards.yaml';
import naturalHazardsBusiness from './segments/natural-hazards-business.yaml';
import naturalDamageCircumstances from './segments/natural-damage-circumstances.yaml';
import glassBreakage from './segments/glass-breakage.yaml';
import glassBreakageBusiness from './segments/glass-breakage-business.yaml';
import otherPrivate from './segments/other-private.yaml';
import otherBusiness from './segments/other-business.yaml';
// Common
import affected from './segments/affected.yaml';
import eventCircumstances from './segments/event-circumstances.yaml';
import policeReport from './segments/police-report.yaml';
import whatDamagedBuilding from './segments/what-damaged-building.yaml';
import whatDamagedBuildingBusiness from './segments/what-damaged-building-business.yaml';
import theftBusinessWhatDamagedBuilding from './segments/theft-business-what-damaged-building.yaml';
import whatDamagedHousehold from './segments/what-damaged-household.yaml';
import whatDamagedHouseholdBusiness from './segments/what-damaged-household-business.yaml';
import haveYouDamage from './segments/have-you-damage.yaml';
import haveYouDamageNoArtisanWork from './segments/have-you-damage-no-artisan-work.yaml';
import haveYouDamageNoFix from './segments/have-you-damage-no-fix.yaml';
import glassBreakageHaveYouDamageNoFix from './segments/glass-breakage-business-have-you-damage-no-fix.yaml';
import haveYouDamageWithPartner from './segments/have-you-damage-with-partner.yaml';
import singlePolicyNumber from './segments/single-policy-number.yaml';
import doublePolicyNumber from './segments/double-policy-number.yaml';
import iban from './segments/iban.yaml';
import documentUpload from './segments/upload-documents.yaml';
import whichFloorBusiness from './segments/which-floor-business.yaml';
import operationsBusiness from './segments/operations-business.yaml';
import damageBuildingInsideOutside from './segments/damage-building-inside-outside.yaml';
import waterBusiness from './segments/water-business.yaml';

const segments = {
  fire,
  fireBusiness,
  fireDamageCircumstances,
  fireDamageCircumstancesBusiness,
  theft,
  theftBusiness,
  theftDamageCircumstances,
  theftBusinessDamageCircumstances,
  theftDamageCircumstancesBuilding,
  theftWhatDamagedBuilding,
  theftWhatDamagedHousehold,
  bicycleTheft,
  bicycleTheftBusiness,
  water,
  waterBusiness,
  waterDamageCircumstances,
  stormHail,
  stormHailBusiness,
  stormDamageCircumstances,
  stormWhatDamagedBuilding,
  naturalHazards,
  naturalHazardsBusiness,
  naturalDamageCircumstances,
  glassBreakage,
  glassBreakageBusiness,
  otherPrivate,
  otherBusiness,
  glassBreakageHaveYouDamageNoFix,
  // Common
  affected,
  eventCircumstances,
  policeReport,
  whatDamagedBuilding,
  whatDamagedBuildingBusiness,
  theftBusinessWhatDamagedBuilding,
  whatDamagedHousehold,
  whatDamagedHouseholdBusiness,
  haveYouDamage,
  haveYouDamageNoArtisanWork,
  haveYouDamageNoFix,
  haveYouDamageWithPartner,
  singlePolicyNumber,
  doublePolicyNumber,
  iban,
  documentUpload,
  whichFloorBusiness,
  operationsBusiness,
  damageBuildingInsideOutside,
};

export default addSegments(flow, segments);
