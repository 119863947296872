/* eslint-disable max-len */
import React from 'react';

function SvgJewellery(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect id="Rectangle" x="0" y="0" width="48" height="48" />
        <g id="ERGO_Icon_black_Hand4" transform="translate(3.000000, 3.000000)">
          <g id="Wertaschen" transform="translate(4.000000, 2.000000)" fill="#1D1D1B" fillRule="nonzero">
            <path d="M30.9395122,21.2142857 L28.2431707,21.2142857 C27.2217686,21.2164527 26.3589508,21.9714797 26.2229268,22.9821429 L23.958,21.375 C23.7791876,21.2480671 23.567148,21.1760125 23.3479024,21.1676786 L15.7418049,20.8928571 L15.6758049,20.8928571 C14.9246742,20.9300014 14.1880695,21.1134442 13.5074634,21.4328571 C12.005561,22.1528571 11.1346829,23.4867857 11.0574146,25.1903571 C11.0433346,25.4975325 11.1558084,25.7970745 11.3686752,26.0193109 C11.581542,26.2415474 11.8762472,26.3671072 12.1842439,26.3667857 L18.1403415,26.3667857 L18.1403415,24.1071429 L13.6829268,24.1071429 C14.2704878,23.2633929 15.3812195,23.1428571 15.7031707,23.1428571 L22.9325854,23.4144643 L26.208439,25.7335714 L26.208439,28.8225 L25.3874634,28.0542857 C25.1179157,27.8027486 24.7420999,27.6988917 24.3813659,27.77625 L14.8918537,29.8028571 L2.93941463,21.5694643 C4.1064878,20.7048214 4.90009756,21.0873214 5.04980488,21.1757143 C5.06838465,21.1899728 5.08833611,21.2023549 5.10936585,21.2126786 L9.63117073,23.9158929 L10.7901951,21.9873214 L6.29092683,19.2969643 C5.71359059,18.9417657 5.04697567,18.7578245 4.36887805,18.7666071 L4.34634146,18.7666071 C3.36117073,18.7666071 1.86087805,19.15875 0.304243902,20.9844643 C0.0975464139,21.226634 0.00322533387,21.5448611 0.0446406709,21.8603318 C0.0860560079,22.1758025 0.25933443,22.4590128 0.521560976,22.6398214 L14.004878,31.9291071 C14.2603931,32.1048717 14.5771966,32.1676644 14.8805854,32.1026786 L24.2702927,30.0985714 L26.202,31.9017857 L26.202,34.8444643 C26.202,35.4657846 26.7064986,35.9694643 27.3288293,35.9694643 C27.9511599,35.9694643 28.4556585,35.4657846 28.4556585,34.8444643 L28.4556585,23.4642857 L30.7238049,23.4642857 L30.7238049,34.8573214 C30.7238049,35.4786417 31.2283036,35.9823214 31.8506341,35.9823214 C32.4729647,35.9823214 32.9774634,35.4786417 32.9774634,34.8573214 L32.9774634,23.25375 C32.9774634,22.1292652 32.0658223,21.2169441 30.9395122,21.2142857 Z" id="Path" />
            <path d="M18.0920488,0.03375 L14.7662927,0.03375 L12.9440488,2.78035714 L15.8416098,6.55232143 L17.1294146,6.55232143 L20.0269756,2.71285714 L18.0920488,0.03375 Z M17.3129268,2.60517857 C17.3120415,2.20612502 16.988239,1.88284813 16.5885366,1.88196429 L15.2798049,1.88196429 L15.5438049,1.48339286 L17.351561,1.48339286 L18.228878,2.69678571 L16.4806829,5.00625 L15.192878,3.32517857 L16.5933659,3.32517857 C16.9911811,3.32165681 17.3120604,2.99914835 17.3129268,2.60196429 L17.3129268,2.60517857 Z" id="Shape" />
            <path d="M20.2829268,4.22035714 L18.9001463,6.05089286 C21.5118045,7.18967894 22.9655267,10.0010302 22.3829025,12.78619 C21.8002783,15.5713498 19.3410258,17.5668062 16.4911463,17.5668062 C13.6412669,17.5668062 11.1820144,15.5713498 10.5993902,12.78619 C10.0167659,10.0010302 11.4704882,7.18967894 14.0821463,6.05089286 L12.6816585,4.22839286 C9.29271874,5.97847121 7.54223343,9.81556957 8.44426629,13.5168403 C9.34629916,17.2181111 12.6668431,19.8233634 16.4822927,19.8233634 C20.2977423,19.8233634 23.6182862,17.2181111 24.5203191,13.5168403 C25.4223519,9.81556957 23.6718666,5.97847121 20.2829268,4.22839286 L20.2829268,4.22035714 Z" id="Path" />
          </g>
          <rect id="Rectangle" x="0" y="0" width="41" height="41" />
        </g>
      </g>
    </svg>
  );
}

export default SvgJewellery;
