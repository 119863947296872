/* eslint-disable max-len */
import React from 'react';

const SvgBildschirm = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <g
      id="icons/05_other/Bildschirm/48"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="ERGO_Icon_black_Bildschirm"
        transform="translate(4.125000, 9.187500)"
        fill="#1D1D1B"
        fillRule="nonzero"
      >
        <polygon
          id="Path"
          points="21.2925 26.805 21.2925 22.704375 18.6675 22.704375 18.6675 26.805 11.934375 26.805 11.934375 29.43 28.02375 29.43 28.02375 26.805"
        />
        <path
          d="M37.5225,21.07125 L2.4375,21.07125 C1.12633477,21.0691852 0.063939831,20.0067902 0.061875,18.695625 L0.061875,2.405625 C0.063939831,1.09445977 1.12633477,0.032064831 2.4375,0.03 L37.5225,0.03 C38.8340929,0.0310339721 39.897091,1.09403207 39.898125,2.405625 L39.898125,18.695625 C39.8960602,20.0067902 38.8336652,21.0691852 37.5225,21.07125 Z M2.685,18.44625 L37.273125,18.44625 L37.273125,2.655 L2.685,2.655 L2.685,18.44625 Z"
          id="Shape"
        />
      </g>
      <rect id="Rectangle" x="0" y="0" width="48" height="48" />
    </g>
  </svg>
);

export default SvgBildschirm;
