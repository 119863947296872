/* eslint-disable max-len */
import React from 'react';

const SvgFloor = props => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect id="Rectangle" x="0" y="0" width="48" height="48" />
      <g transform="translate(3.000000, 6.000000)" fill="#1D1D1B" fillRule="nonzero">
        <path d="M39.2488768,0 L2.74763192,0 C1.23039022,0.00388430777 0.00191926911,1.24655997 5.70225918e-07,2.77940139 L5.70225918e-07,32.2153079 C-0.000966533777,33.7502145 1.22834694,34.9961095 2.74763192,35 L39.2488768,35 C40.7695259,34.9980532 42.0009637,33.751589 42.0000006,32.2153079 L42.0000006,2.77940139 C41.9980754,1.24518604 40.7674821,0.001943277 39.2488768,0 Z M39.5561087,2.77940139 L39.5561087,16.2654943 L22.2219451,16.2654943 L22.2219451,2.46901139 L39.2488768,2.46901139 C39.4185563,2.46901139 39.5561087,2.60797773 39.5561087,2.77940139 L39.5561087,2.77940139 Z M2.74763192,2.46901139 L19.7745637,2.46901139 L19.7745637,16.2654943 L2.44389128,16.2654943 L2.44389128,2.77940139 C2.44389128,2.60934577 2.57931742,2.47094384 2.74763192,2.46901139 Z M2.44384626,32.2153079 L2.44384626,18.7345057 L19.7780549,18.7345057 L19.7780549,32.5309886 L2.74763192,32.5309886 C2.66584043,32.5300616 2.58779141,32.4962218 2.53077742,32.4369665 C2.47376343,32.3777113 2.44249188,32.2979332 2.44384626,32.2153079 Z M39.2488768,32.5256979 L22.2219451,32.5256979 L22.2219451,18.7345057 L39.5561534,18.7345057 L39.5561534,32.2153079 C39.5575132,32.2985413 39.5257654,32.3788562 39.4679953,32.438215 C39.4102252,32.4975737 39.3312753,32.5309886 39.2488768,32.5309886 L39.2488768,32.5256979 Z" />
      </g>
    </g>
  </svg>
);

export default SvgFloor;
