/* eslint-disable max-len */
import React from 'react';

const SvgEuro = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <g
      id="icons/05_other/Euro/48"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="ERGO_Icon_black_Euro">
        <path
          d="M18.12,21.496875 C18.75,17.728125 20.825625,14.71875 25.228125,14.71875 C26.896875,14.71875 28.415625,15.148125 29.604375,15.9825 C29.75625,16.08375 29.705625,16.464375 29.5275,16.843125 L29.375625,17.146875 C29.173125,17.551875 28.921875,17.805 28.77,17.728125 C27.680625,17.09625 26.625,16.76625 25.5,16.76625 C23.173125,16.76625 21.25125,18.26625 20.593125,21.496875 L26.64375,21.496875 C26.83125,21.496875 26.8725,21.699375 26.8725,22.179375 L26.8725,22.509375 C26.8725,23.015625 26.821875,23.218125 26.64375,23.218125 L20.39625,23.218125 C20.371875,23.52 20.371875,23.85 20.371875,24.178125 C20.371875,24.50625 20.371875,24.83625 20.39625,25.14 L26.315625,25.14 C26.491875,25.14 26.5425,25.3425 26.5425,25.8225 L26.5425,26.1525 C26.5425,26.656875 26.491875,26.859375 26.315625,26.859375 L20.625,26.859375 C21.129375,29.338125 22.47,31.336875 25.38,31.336875 C26.5194624,31.330455 27.6398614,31.0439162 28.6425,30.5025 C28.794375,30.4275 29.0475,30.60375 29.25,31.065 L29.401875,31.393125 C29.578125,31.768125 29.62875,32.143125 29.476875,32.25375 C28.0955932,33.0563304 26.5217039,33.4673048 24.924375,33.4425 C20.87625,33.4425 18.70125,30.83625 18.069375,26.865 L16.096875,26.865 C15.91875,26.865 15.868125,26.6625 15.868125,26.158125 L15.868125,25.828125 C15.868125,25.348125 15.91875,25.145625 16.096875,25.145625 L17.893125,25.145625 L17.893125,24.335625 C17.893125,23.960625 17.893125,23.6025 17.9175,23.22375 L16.4175,23.22375 C16.24125,23.22375 16.190625,23.02125 16.190625,22.515 L16.190625,22.185 C16.190625,21.705 16.24125,21.5025 16.4175,21.5025 L18.12,21.496875 Z"
          id="Path"
          fill="#1D1D1B"
          fillRule="nonzero"
        />
        <path
          d="M23.9175,42.605625 C13.5963336,42.605625 5.229375,34.2386664 5.229375,23.9175 C5.229375,13.5963336 13.5963336,5.229375 23.9175,5.229375 C34.2386664,5.229375 42.605625,13.5963336 42.605625,23.9175 C42.5942581,34.2339545 34.2339545,42.5942581 23.9175,42.605625 Z M23.9175,7.854375 C15.046081,7.854375 7.854375,15.046081 7.854375,23.9175 C7.854375,32.788919 15.046081,39.980625 23.9175,39.980625 C32.788919,39.980625 39.980625,32.788919 39.980625,23.9175 C39.9702927,15.0503643 32.7846357,7.86470726 23.9175,7.854375 Z"
          id="Shape"
          fill="#1D1D1B"
          fillRule="nonzero"
        />
        <rect id="Rectangle" x="0" y="0" width="47.835" height="47.835" />
      </g>
    </g>
  </svg>
);

export default SvgEuro;
