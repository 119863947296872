import {
  isEmpty,
  isArray,
  isPlainObject,
  isUndefined,
} from 'lodash';
import moment from 'moment';

export const isDefined = (value) => !isUndefined(value);

export const defaultConfig = {
  message: '',
  localePath: '',
};


export const checkLength = (str, num) => str.trim().length === num;

export const isToday = (obj) => obj.isSame(moment(), 'day');

export const isTimeValid = (str) => moment(str, 'HH-mm').isValid();

export const isHourValid = (str) => /0[0-9]|1[0-9]|2[0-3]/.test(str);

export const isMinValid = (str) => moment(str, 'mm').isValid() && /\d{2}/.test(str);

export const isEmptyValue = value => ['', undefined, null].includes(value);

export const isObjectEmpty = value => isEmpty(value) || Object.values(value).every(isEmptyValue);

export const hasEmptyValue = (value) => {
  const conditions = [
    isEmptyValue(value),
    isArray(value) ? isEmpty(value) : false,
    isPlainObject(value) ? isObjectEmpty(value) : false,
  ];

  return conditions.some(result => result);
};

export const isMandatory = (validator) => {
  const mandatory = ['required', 'requiredTruthy'];
  const findRequiredInArray = (item) => (
    isPlainObject(item) ? mandatory.includes(item.name) : mandatory.includes(item)
  );
  const findRequiredInMap = (fields) => Object.keys(fields)
    .map(key => fields[key])
    .filter(isMandatory)
    .join('') !== '';

  const conditions = [
    mandatory.includes(validator),
    isArray(validator) ? !!validator.find(findRequiredInArray) : false,
    isPlainObject(validator) ? !!findRequiredInMap(validator) : false,
  ];

  return conditions.some(result => result);
};
