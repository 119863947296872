import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import injectJss from 'react-jss';
import { compose } from 'recompose';
import { Swipeable } from 'react-swipeable';

import { debounce } from 'lodash';
import StepNavigation from '@eg/elements/StepNavigation';
import LoadingSpinner from '@eg/elements/LoadingSpinner';
import { navigateToPathThroughSection } from '../../actions/navigation';
import flowConfig from '../../flow';
import paths, { getRoot } from '../../helpers/paths';
import { getSections, isNavigationVisible, isStepperVisible } from '../../helpers/sections';
import { trimFlow, NAV_DELAY } from '../../helpers/navigation';
import styles from './styles.jss';
import PanelProvider from './PanelProvider/PanelProvider';
import TopBar from '../../layout/TopBar/TopBar';
import Navigation from '../../layout/Navigation/Navigation';
import Footer from '../../layout/Footer/Footer';
import FormFooterInfo from '../../components/FormFooterInfo';
import CommunicationModal from '../../components/CommunicationModal';
/* eslint-disable import/no-extraneous-dependencies */

const redirectTo404 = () => {
  global.location = `${global.location.origin}/404`;
};

const getDirection = ({ current, next }) => {
  let direction = 1;

  if (!current || !next) {
    return direction;
  }

  const path = flowConfig[current.params.pathName];
  if (path) {
    const currentIndex = path.findIndex(i => i.name === current.params.name);
    const nextIndex = path.findIndex(i => i.name === next.params.name);

    if (currentIndex > nextIndex) {
      direction = -1;
    }
  }

  return direction;
};

class Form extends React.Component {
  constructor() {
    super();

    this.handleSectionClick = this.handleSectionClick.bind(this);
    this.bindFormSubmit = this.bindFormSubmit.bind(this);
    this.doFormSubmit = this.doFormSubmit.bind(this);
  }

  setDocumentTitle() {
    const { match } = this.props;
    if (match.params && match.params.pathName === 'upload') {
      document.title = process.env.UPLOAD_TITLE;
    } else {
      document.title = process.env.TITLE;
    }
  }

  handleSectionClick(selectedSection) {
    const { navigation, navigateThroughSection } = this.props;
    const currentPanelName = navigation.current.params.name;
    const selectedSectionFirstPanelName = selectedSection.first.name;

    if (currentPanelName !== selectedSectionFirstPanelName) {
      navigateThroughSection(selectedSection.first, navigation.current);
    }
  }

  bindFormSubmit(submitForm) {
    this.submitForm = submitForm;
  }

  doFormSubmit(e) {
    if (this.submitForm) {
      this.submitForm(e);
    }
  }

  render() {
    const {
      formData, navigation, classes, isLoading,
    } = this.props;
    const { t } = this.context;

    const pages = [navigation.current, navigation.next];

    const direction = getDirection(navigation);

    return pages
      .filter(i => i && i.root === getRoot(paths.CLAIM))
      .map((item, index, items) => {
        const currentPath = navigation.current.params.name;
        const isStepsVisible = isStepperVisible(currentPath);

        let className = isStepsVisible ? classes.slide : classes.slideHigher;

        if (items.length > 1) {
          if (index === 0) {
            className = direction > 0 ? classes.slideCurrent : classes.slideCurrentReverse;
          } else if (index === 1) {
            className = direction > 0 ? classes.slideNext : classes.slideNextReverse;
          }
        }

        const flow = flowConfig[item.params.pathName];
        const currentActivePanel = flow.find(el => el.name === item.params.name);
        let currentActiveSection;
        if (currentActivePanel) {
          currentActiveSection = currentActivePanel.section;
        } else {
          // this means we ventured into 404 space probably
          redirectTo404();
          return null;
        }

        const currentPanelName = navigation.current.params.name;
        const trimmedFlow = trimFlow(formData[item.params.pathName] || {}, flow);
        const sections = getSections(
          trimmedFlow,
          currentActiveSection,
          currentPanelName,
          formData[item.params.pathName],
        );
        const isNavVisible = isNavigationVisible(currentPath);
        this.setDocumentTitle();
        return (
          <React.Fragment key={item.params.name}>
            <TopBar
              currentPath={currentPath}
            />
            <Swipeable
              className={className}
              key={`slide-${item.params.name}`}
            >
              { isStepsVisible && (
              <div className={`esc_container esc_container--l esc_box esc_box--around ${classes.stepper}`}>
                <div className="esc_grid">
                  <div className="esc_grid__wrapper">
                    <div className="esc_col esc_col-12">
                      <StepNavigation
                        activeStepIndex={sections.findIndex(section => section.active)}
                        steps={sections.map(section => t(`sections.${section.title}`))}
                        goToStepByIndex={debounce((idx) => this.handleSectionClick(sections[idx]), NAV_DELAY)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              )}
              <div className={`esc_container ${classes.contentContainer}`}>
                <div
                  className={`
                  esc_container--l esc_box esc_box--around ${classes.changeBoxSize} ${classes.addPaddingForIe}
                  `}
                >
                  <PanelProvider
                    formData={formData}
                    params={item.params}
                    flowConfig={flowConfig}
                    bindFormSubmit={this.bindFormSubmit}
                  />
                  { isLoading && (
                    <LoadingSpinner
                      show
                      viewport="relative"
                    />
                  )}
                  { isNavVisible && (
                    <Navigation
                      params={currentActivePanel.navigation}
                      onNext={this.doFormSubmit}
                    />
                  )}
                </div>
                <div className={classes.footerForIe}>
                  <FormFooterInfo
                    params={item.params}
                    flowConfig={flowConfig}
                  />
                  <Footer />
                </div>
              </div>
              <CommunicationModal ref={communicationModal => { window.communicationModal = communicationModal; }} />
            </Swipeable>
          </React.Fragment>
        );
      });
  }
}

Form.propTypes = {
  isLoading: PropTypes.bool,
  formData: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  navigation: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  navigateThroughSection: PropTypes.func,
  classes: PropTypes.objectOf(PropTypes.string),
  match: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

Form.defaultProps = {
  isLoading: false,
  navigation: {},
  navigateThroughSection: () => {},
  formData: {},
  classes: {},
  match: {},
};

Form.contextTypes = {
  t: PropTypes.func,
};

const getState = state => state;

const mapStateToProps = state => ({
  ...state,
  isPreviousActive: state.navigation.isPreviousActive,
  isLoading: state.loader.isLoading,
});

const mapDispatchToProps = dispatch => ({
  navigateThroughSection: (startOfSection, current) => dispatch(navigateToPathThroughSection(startOfSection, current)),
});

export { getState };
export { Form as FormPlain };
export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  injectJss(styles),
)(Form);
