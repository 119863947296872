import {
  claimsKeys,
  isLiabilityFlow,
  isCurrentUserFlow,
  compareValues,
  isCompany,
  isPersonalInjury,
} from './formData';
import privateLiabilityFlows from '../../models/dataMappings/privateLiabilityFlows';
import { getSummaryData } from '../pdfHelpers';

export const userFlows = {
  PERSON_DAMAGE: 'Privathaftpflicht',
  ANIMAL_DAMAGE: 'Tierhalterhaftpflicht',
  KEY_LOSS: 'Schlüsselverlust',
  BUSINESS: 'Gewerbe Haftpflicht',
};

export const isKeyLossFlow = data => isCurrentUserFlow(
  data[claimsKeys.WHAT_HAPPENED], userFlows.KEY_LOSS,
);

export const isAnimalDamageFlow = data => isCurrentUserFlow(
  data[claimsKeys.WHAT_HAPPENED], userFlows.ANIMAL_DAMAGE,
);

export const isBusinessFlow = data => isCurrentUserFlow(
  data[claimsKeys.WHAT_HAPPENED], userFlows.BUSINESS,
);

// PersonDamage and Liability have the same WHAT_HAPPENED value
export const isPersonDamageFlow = data => isCurrentUserFlow(
  data[claimsKeys.WHAT_HAPPENED], userFlows.PERSON_DAMAGE,
) && !isLiabilityFlow(data);

export const getFlowSubName = (data) => {
  if (isKeyLossFlow(data)) {
    return privateLiabilityFlows.KEY_LOSS;
  }
  if (isAnimalDamageFlow(data)) {
    return privateLiabilityFlows.ANIMAL_DAMAGE;
  }
  if (isPersonDamageFlow(data)) {
    return privateLiabilityFlows.PERSON_DAMAGE;
  }
  if (isBusinessFlow(data)) {
    return privateLiabilityFlows.BUSINESS;
  }
  return privateLiabilityFlows.LIABILITY;
};

const filledAsCompany = data => (
  isCompany(claimsKeys.customer.SALUTATION)(data) || isCompany(claimsKeys.owner.SALUTATION)(data)
);

const getOwnerFullName = ({
  [claimsKeys.owner.FIRST_NAME]: firstName,
  [claimsKeys.owner.LAST_NAME]: lastName,
}) => ({ firstName, lastName });

const getOwnerEmail = ({
  [claimsKeys.owner.EMAIL]: email,
}) => ({ email });

const getOwnerPhone = ({
  [claimsKeys.owner.PHONE]: phone,
}) => ({ phone });

const getOwnerAddress = ({
  [claimsKeys.owner.ADDRESS]: address,
  [claimsKeys.owner.POST_CODE]: postCode,
  [claimsKeys.owner.CITY]: city,
}) => ({ address, postCode, city });

export const getHeadingData = data => {
  if (filledAsCompany(data)) {
    return data[claimsKeys.owner.FIRST_NAME];
  }

  const { firstName, lastName } = getOwnerFullName(data);
  return `${firstName} ${lastName}`;
};

export const getContactData = data => ({
  ...getOwnerFullName(data),
  ...getOwnerEmail(data),
  ...getOwnerPhone(data),
  ...getOwnerAddress(data),
});

export const getContactOfAccidentData = ({
  [claimsKeys.WHO_FILLS]: filler,
  [claimsKeys.customer.FIRST_NAME]: firstName,
  [claimsKeys.customer.LAST_NAME]: lastName,
  [claimsKeys.customer.PHONE]: phone,
  [claimsKeys.customer.ADDRESS]: address,
  [claimsKeys.customer.POST_CODE]: postCode,
  [claimsKeys.customer.CITY]: city,
  [claimsKeys.thirdParty.FIRST_NAME]: tFirstName,
  [claimsKeys.thirdParty.LAST_NAME]: tLastName,
  [claimsKeys.thirdParty.PHONE]: tPhone,
  [claimsKeys.thirdParty.ADDRESS]: tAddress,
  [claimsKeys.thirdParty.POST_CODE]: tPostCode,
  [claimsKeys.thirdParty.CITY]: tCity,
}) => {
  const isLiability = !compareValues(filler);

  return {
    firstName: isLiability ? firstName : tFirstName,
    lastName: isLiability ? lastName : tLastName,
    phone: isLiability ? phone : tPhone,
    address: isLiability ? address : tAddress,
    postCode: isLiability ? postCode : tPostCode,
    city: isLiability ? city : tCity,
  };
};

export const getNoteData = ({
  [claimsKeys.NEW_FURTHER_INFORMATION]: notes,
}) => ({ notes });

export const getYourMessageDetails = ({
  [claimsKeys.YOUR_MESSAGE]: message,
}) => message;

export const getWhatHappened = data => {
  const whatHappenedValue = {};
  // We need to set GeVoSTitel for Liability
  if (isLiabilityFlow(data)) {
    whatHappenedValue[claimsKeys.WHAT_HAPPENED] = userFlows.PERSON_DAMAGE;
  }
  return whatHappenedValue;
};

export const getPageType = data => {
  let pageTypeVal = claimsKeys.DAMAGE_REPORT;

  if (isPersonalInjury(data)) {
    pageTypeVal = claimsKeys.PERSON_DAMAGE_REPORT;
  }

  return { [claimsKeys.PAGE_TYPE]: pageTypeVal };
};

export const getAdditionalData = data => ({
  additionalData: {
    ...getOwnerFullName(data),
    ...getOwnerEmail(data),
    isLiability: isLiabilityFlow(data),
  },
  summary: getSummaryData(),
});
