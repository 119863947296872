const buttonActions = {
  goToUrl: options => {
    global.location = options.location;
  },
  ownCallback: options => {
    if (options.callbackData) {
      options.callback(options.callbackData);
    } else {
      options.callback();
    }
  },
};

export default buttonActions;
