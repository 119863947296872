/* eslint-disable max-len */
import React from 'react';

function SvgKleidung1(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g id="icons/05_other/NA_Kleidung1/48" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M5.98828125,12.7411688 L3.34040625,8.62835625 L7.58728125,4.74841875 L7.58728125,11.8646062 L5.98828125,12.7411688 Z M16.0812188,6.49066875 C14.6196563,6.49066875 13.3928438,5.37166875 13.0740938,3.87429375 L19.0883438,3.87429375 C18.7694063,5.37166875 17.5425938,6.49066875 16.0812188,6.49066875 L16.0812188,6.49066875 Z M26.1737812,12.7077938 L24.5747812,11.8312313 L24.5747812,4.71504375 L28.8214687,8.59516875 L26.1737812,12.7077938 Z M17.0346562,25.0160438 L8.99128125,25.0160438 L8.99128125,3.84110625 L11.6438437,3.84110625 C11.9820937,6.19254375 13.8428437,7.99666875 16.0812187,7.99666875 C18.3194063,7.99666875 20.1801562,6.19254375 20.5185937,3.84110625 L23.1709688,3.84110625 L23.1709688,15.6269813 L24.5747812,15.6269813 L24.5747812,13.5522937 L26.0856562,14.3804812 C26.1857812,14.4354188 26.2930312,14.4616687 26.3989687,14.4616687 C26.6211562,14.4616687 26.8375312,14.3461688 26.9730937,14.1357937 L30.3424687,8.90248125 C30.5534062,8.57473125 30.4994062,8.12416875 30.2183437,7.86729375 L24.3248437,2.48285625 C24.2027812,2.36998125 24.0450937,2.30173125 23.8727812,2.30173125 L8.28909375,2.30173125 C8.11678125,2.30173125 7.95909375,2.37016875 7.83684375,2.48341875 L1.94409375,7.86729375 C1.66303125,8.12416875 1.60884375,8.57473125 1.81996875,8.90248125 L5.18915625,14.1357937 C5.32471875,14.3459812 5.54109375,14.4616687 5.76309375,14.4616687 C5.86903125,14.4616687 5.97646875,14.4354188 6.07678125,14.3804812 L7.58728125,13.5522937 L7.58728125,25.7855438 C7.58728125,26.2107938 7.90171875,26.5554188 8.28928125,26.5554188 L17.0346562,26.5554188 L17.0346562,25.0160438 Z" id="Fill-3" fill="#000000" />
        <path d="M5.98828125,12.7411688 L3.34040625,8.62835625 L7.58728125,4.74841875 L7.58728125,11.8646062 L5.98828125,12.7411688 Z M16.0812188,6.49066875 C14.6196563,6.49066875 13.3928438,5.37166875 13.0740938,3.87429375 L19.0883438,3.87429375 C18.7694063,5.37166875 17.5425938,6.49066875 16.0812188,6.49066875 Z M26.1737812,12.7077938 L24.5747812,11.8312313 L24.5747812,4.71504375 L28.8214687,8.59516875 L26.1737812,12.7077938 Z M17.0346562,25.0160438 L8.99128125,25.0160438 L8.99128125,3.84110625 L11.6438437,3.84110625 C11.9820937,6.19254375 13.8428437,7.99666875 16.0812187,7.99666875 C18.3194063,7.99666875 20.1801562,6.19254375 20.5185937,3.84110625 L23.1709688,3.84110625 L23.1709688,15.6269813 L24.5747812,15.6269813 L24.5747812,13.5522937 L26.0856562,14.3804812 C26.1857812,14.4354188 26.2930312,14.4616687 26.3989687,14.4616687 C26.6211562,14.4616687 26.8375312,14.3461688 26.9730937,14.1357937 L30.3424687,8.90248125 C30.5534062,8.57473125 30.4994062,8.12416875 30.2183437,7.86729375 L24.3248437,2.48285625 C24.2027812,2.36998125 24.0450937,2.30173125 23.8727812,2.30173125 L8.28909375,2.30173125 C8.11678125,2.30173125 7.95909375,2.37016875 7.83684375,2.48341875 L1.94409375,7.86729375 C1.66303125,8.12416875 1.60884375,8.57473125 1.81996875,8.90248125 L5.18915625,14.1357937 C5.32471875,14.3459812 5.54109375,14.4616687 5.76309375,14.4616687 C5.86903125,14.4616687 5.97646875,14.4354188 6.07678125,14.3804812 L7.58728125,13.5522937 L7.58728125,25.7855438 C7.58728125,26.2107938 7.90171875,26.5554188 8.28928125,26.5554188 L17.0346562,26.5554188 L17.0346562,25.0160438 Z" id="Stroke-5" stroke="#000000" strokeWidth="0.75" />
        <path d="M30.0084188,44.7166875 L28.9621688,25.8969375 C28.9396688,25.489125 28.6021688,25.17 28.1937938,25.17 L26.7451688,25.17 C26.3367938,25.17 25.9992937,25.4889375 25.9767937,25.8969375 L24.9307313,44.7166875 L20.5019813,44.7166875 L20.5019813,22.398375 L34.4371687,22.398375 L34.4371687,44.7166875 L30.0084188,44.7166875 Z M20.5017938,20.8591875 L22.9287938,20.8591875 L22.9287938,19.136625 L20.5017938,19.136625 L20.5017938,20.8591875 Z M24.4679813,20.8591875 L30.4707938,20.8591875 L30.4707938,19.136625 L24.4679813,19.136625 L24.4679813,20.8591875 Z M32.0101688,20.8591875 L34.4369813,20.8591875 L34.4369813,19.136625 L32.0101688,19.136625 L32.0101688,20.8591875 Z M35.2066688,17.5974375 L19.7321063,17.5974375 C19.3070438,17.5974375 18.9624188,17.9420625 18.9624188,18.3669375 L18.9624188,45.486375 C18.9624188,45.911625 19.3070438,46.25625 19.7321063,46.25625 L25.6586063,46.25625 C26.0669813,46.25625 26.4042938,45.937125 26.4267938,45.529125 L27.4692938,26.77575 L28.5117938,45.529125 C28.5344813,45.937125 28.8717938,46.25625 29.2801688,46.25625 L35.2064813,46.25625 C35.6315438,46.25625 35.9761688,45.911625 35.9761688,45.486375 L35.9761688,18.3669375 C35.9763563,17.941875 35.6317313,17.5974375 35.2066688,17.5974375 L35.2066688,17.5974375 Z" id="Fill-7" fill="#000000" />
        <path d="M30.0084188,44.7166875 L28.9621688,25.8969375 C28.9396688,25.489125 28.6021688,25.17 28.1937938,25.17 L26.7451688,25.17 C26.3367938,25.17 25.9992937,25.4889375 25.9767937,25.8969375 L24.9307313,44.7166875 L20.5019813,44.7166875 L20.5019813,22.398375 L34.4371687,22.398375 L34.4371687,44.7166875 L30.0084188,44.7166875 Z M20.5017938,20.8591875 L22.9287938,20.8591875 L22.9287938,19.136625 L20.5017938,19.136625 L20.5017938,20.8591875 Z M24.4679813,20.8591875 L30.4707938,20.8591875 L30.4707938,19.136625 L24.4679813,19.136625 L24.4679813,20.8591875 Z M32.0101688,20.8591875 L34.4369813,20.8591875 L34.4369813,19.136625 L32.0101688,19.136625 L32.0101688,20.8591875 Z M35.2066688,17.5974375 L19.7321063,17.5974375 C19.3070438,17.5974375 18.9624188,17.9420625 18.9624188,18.3669375 L18.9624188,45.486375 C18.9624188,45.911625 19.3070438,46.25625 19.7321063,46.25625 L25.6586063,46.25625 C26.0669813,46.25625 26.4042938,45.937125 26.4267938,45.529125 L27.4692938,26.77575 L28.5117938,45.529125 C28.5344813,45.937125 28.8717938,46.25625 29.2801688,46.25625 L35.2064813,46.25625 C35.6315438,46.25625 35.9761688,45.911625 35.9761688,45.486375 L35.9761688,18.3669375 C35.9763563,17.941875 35.6317313,17.5974375 35.2066688,17.5974375 Z" id="Stroke-9" stroke="#000000" strokeWidth="0.75" />
        <rect id="Rectangle" x="0" y="0" width="48" height="48" />
      </g>
    </svg>
  );
}

export default SvgKleidung1;
