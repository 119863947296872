/* eslint-disable max-len */
import React from 'react';

const SvgAuszahlung = props => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <g fill="currentColor" fillRule="nonzero">
      <path d="M24 44.01C12.995 44.01 3.99 35.004 3.99 24S12.996 3.99 24 3.99 44.01 12.996 44.01 24 35.004 44.01 24 44.01zm0-36.017C15.196 7.993 7.993 15.196 7.993 24c0 8.804 7.203 16.007 16.007 16.007 8.804 0 16.007-7.203 16.007-16.007 0-8.804-7.203-16.007-16.007-16.007z" />
      <path
        d="M16.6 21.242h1.839c.164-1.01.439-1.956.823-2.84a7.756 7.756 0 011.51-2.298 6.775 6.775 0 012.263-1.54c.888-.38 1.926-.569 3.115-.569.915 0 1.775.113 2.58.338a7.021 7.021 0 012.168 1.014c.091.054.123.172.096.352-.028.18-.087.37-.179.568l-.164.324c-.11.217-.224.383-.343.5-.12.118-.224.158-.316.122-1.19-.685-2.37-1.027-3.54-1.027a5.014 5.014 0 00-3.334 1.216c-.96.812-1.624 2.091-1.99 3.84h6.559c.165 0 .247.243.247.73v.352c0 .504-.082.757-.247.757h-6.778a4.462 4.462 0 00-.028.5v1.054c0 .172.01.338.028.5h6.421c.165 0 .247.244.247.73v.352c0 .505-.082.757-.247.757h-6.174c.128.667.324 1.29.59 1.866a5.525 5.525 0 001.015 1.514c.412.433.91.775 1.496 1.028.585.252 1.271.378 2.058.378 1.226 0 2.406-.297 3.54-.892.091-.036.197-.009.315.081.12.09.234.262.343.514l.165.351c.092.199.151.388.178.568.028.18-.004.298-.096.352-1.445.847-3.091 1.27-4.94 1.27-1.097 0-2.071-.166-2.922-.5a6.33 6.33 0 01-2.195-1.42 7.268 7.268 0 01-1.496-2.216c-.384-.866-.659-1.83-.823-2.894h-2.14c-.165 0-.248-.252-.248-.757v-.351c0-.487.083-.73.247-.73h1.949v-1.46c0-.199.009-.397.027-.595H16.6c-.165 0-.247-.253-.247-.757v-.352c0-.487.082-.73.247-.73z"
        stroke="currentColor"
        strokeWidth={0.7}
      />
    </g>
  </svg>
);

export default SvgAuszahlung;
