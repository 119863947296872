/* eslint-disable max-len */
import React from 'react';

function SvgSteuerberater(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 100 100"
      {...props}
    >
      <g fill="#000" fillRule="nonzero" stroke="none" strokeWidth="1">
        <path d="M77.763 27.665v-11.03a5.221 5.221 0 00-5.213-5.213H27.438a5.217 5.217 0 00-5.213 5.213v66.73a5.217 5.217 0 005.213 5.22H72.55a5.221 5.221 0 005.213-5.212v-50.22h-5.487v49.945H27.724V16.91h44.552v10.755h5.487z" />
        <rect
          width="33.894"
          height="16.667"
          x="33.055"
          y="22.076"
          rx="4"
        />
        <rect
          width="7.777"
          height="7.777"
          x="33.055"
          y="44.026"
          rx="3.888"
        />
        <rect
          width="7.777"
          height="7.777"
          x="59.168"
          y="44.026"
          rx="3.888"
        />
        <rect
          width="7.777"
          height="7.777"
          x="46.112"
          y="44.026"
          rx="3.888"
        />
        <rect
          width="7.777"
          height="7.777"
          x="33.055"
          y="57.087"
          rx="3.888"
        />
        <rect
          width="7.777"
          height="7.777"
          x="59.168"
          y="57.087"
          rx="3.888"
        />
        <rect
          width="7.777"
          height="7.777"
          x="46.112"
          y="57.087"
          rx="3.888"
        />
        <rect
          width="7.777"
          height="7.777"
          x="33.055"
          y="70.147"
          rx="3.888"
        />
        <rect
          width="7.777"
          height="7.777"
          x="59.168"
          y="70.147"
          rx="3.888"
        />
        <rect
          width="7.777"
          height="7.777"
          x="46.112"
          y="70.147"
          rx="3.888"
        />
      </g>
    </svg>
  );
}


export default SvgSteuerberater;
