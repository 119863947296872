/* eslint-disable max-len */
import React from 'react';

function SvgSturm(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g id="icons/04_weather/storm/48" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect id="Rectangle" x="0" y="0" width="47.52" height="47.52" />
        <g id="ERGO_Icon_black_Sturm" transform="translate(1.113750, 5.383125)" fill="#000000" fillRule="nonzero">
          <g id="icons_cmyk_black">
            <g id="storm_1_">
              <path d="M26.9843063,6.48759375 C28.1147625,5.9437125 29.336175,5.6689875 30.6114188,5.6689875 C34.8696563,5.6689875 37.9603125,8.798625 37.9603125,13.11255 L40.4811,13.11255 C40.4811,7.432425 36.2377125,3.1482 30.6114188,3.1482 C29.024325,3.1482 27.5003438,3.4786125 26.0784563,4.1283 C24.3447188,1.5703875 21.4972313,0.05011875 18.421425,0.05011875 C14.1928875,0.05011875 10.5416438,2.8920375 9.4631625,6.9906375 C9.23484375,6.97393125 9.0028125,6.96465 8.77449375,6.96465 C4.02991875,6.96465 0.170775,10.61775 0.170775,15.1080187 C0.170775,19.5982875 4.02991875,23.2513875 8.77449375,23.2513875 C8.78191875,23.2513875 8.7874875,23.2513875 8.7949125,23.2513875 L10.098,23.2513875 L8.5721625,26.5035375 L10.8534938,27.5745938 L12.882375,23.2513875 L15.2955,23.2513875 L14.2095938,25.5661313 L16.490925,26.6371875 L18.079875,23.2513875 L23.4945563,23.2513875 L23.4945563,20.7306 L8.828325,20.7306 C8.81161875,20.7306 8.79305625,20.7306 8.77635,20.7306 L8.75964375,20.7306 C5.41096875,20.7250313 2.68785,18.2042437 2.68785,15.1080188 C2.68785,12.0080813 5.4165375,9.48729375 8.77078125,9.48729375 C8.98981875,9.48729375 9.21256875,9.49843125 9.42975,9.52070625 C10.5527813,9.6339375 11.5941375,8.89700625 11.8540125,7.803675 C12.585375,4.72415625 15.2843625,2.5727625 18.4177125,2.5727625 C20.7027563,2.5727625 22.8151688,3.72920625 24.0699938,5.66713125 C24.69555,6.63238125 25.9485188,6.98506875 26.9843063,6.48759375 Z" id="Path" />
              <path d="M43.5272063,19.9973813 C41.6858063,18.139275 39.1872938,18.31005 39.0814875,18.3193313 C37.3885875,18.4529813 36.2228625,19.3217063 35.6789813,19.8284625 L37.3960125,21.6754313 C37.7004375,21.3932813 38.350125,20.9069438 39.2726813,20.83455 C39.2875313,20.8326938 40.73355,20.7584438 41.7377813,21.7738125 C42.5935125,22.6369688 42.84225,24.0013125 42.3540563,25.1688938 C42.0589125,25.8779812 41.2365938,27.0678375 39.062925,27.0678375 C34.7916938,27.0678375 32.8017938,22.6963687 32.8017938,13.3093125 L30.2810063,13.3093125 C30.2810063,16.1772188 30.405375,19.8711562 31.37805,23.0137875 C32.306175,26.0134875 34.3926,29.5904812 39.062925,29.5904812 C41.6802375,29.5904812 43.7815125,28.3003875 44.6817938,26.1397125 C45.552375,24.04215 45.0901688,21.5733375 43.5272063,19.9973813 Z" id="Path" />
              <path d="M28.70505,23.4203063 C27.7639312,19.9454063 27.7657875,16.1363812 27.7695,13.6155938 L27.7695,13.268475 L25.2487125,13.268475 L25.2487125,13.6137375 C25.245,18.9894375 25.2357187,30.1770562 33.969375,33.3698063 L34.8343875,31.0012313 C31.7901375,29.8893375 29.7853875,27.4093875 28.70505,23.4203063 Z" id="Path" />
              <polygon id="Path" points="9.3814875 29.8800562 9.3814875 29.143125 8.64455625 29.143125 8.10624375 28.6029562 7.56793125 29.143125 6.8607 29.143125 6.8607 29.8485 6.32424375 30.3849562 6.8607 30.9214125 6.8607 31.6620562 7.60134375 31.6620562 8.1081 32.1669562 8.613 31.6620562 9.3814875 31.6620562 9.3814875 30.8935688 9.88824375 30.3868125" />
              <polygon id="Path" points="7.28578125 33.72435 6.54885 33.72435 6.0105375 33.1860375 5.472225 33.72435 4.76499375 33.72435 4.76499375 34.4315813 4.23039375 34.9680375 4.76499375 35.5026375 4.76499375 36.2432812 5.5056375 36.2432812 6.01239375 36.7500375 6.51729375 36.2432812 7.28578125 36.2432812 7.28578125 35.4747937 7.7925375 34.9680375 7.28578125 34.4612812" />
              <polygon id="Path" points="15.020775 28.2038625 14.2838438 28.2038625 13.7455313 27.66555 13.2072188 28.2038625 12.4999875 28.2038625 12.4999875 28.9110938 11.9653875 29.44755 12.4999875 29.98215 12.4999875 30.7227938 13.2406313 30.7227938 13.7473875 31.22955 14.2522875 30.7227938 15.020775 30.7227938 15.020775 29.9543063 15.5275313 29.44755 15.020775 28.9407938" />
              <polygon id="Path" points="12.926925 32.7869438 12.1899937 32.7869438 11.649825 32.2486313 11.1115125 32.7869438 10.4061375 32.7869438 10.4061375 33.4923188 9.86968125 34.028775 10.4061375 34.5652313 10.4061375 35.305875 11.144925 35.305875 11.6516812 35.810775 12.1584375 35.305875 12.926925 35.305875 12.926925 34.5373875 13.4336812 34.0306313 12.926925 33.523875" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgSturm;
