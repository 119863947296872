import { getMessage, VALIDATION_KEYS } from './messages';
import { defaultConfig } from './utils';
import { getTranslationArray } from '../polyglot';

const {
  INVALID_COUNTRY,
} = VALIDATION_KEYS;

const countries = getTranslationArray('dropdowns.countries') || [];

const countryList = countries.reduce(
  (aggr, val) => {
    aggr.push(val.name);
    return aggr;
  },
  [],
);

export const country = (config = defaultConfig) => {
  const { localePath, message } = config;
  return (value) => (countryList.indexOf(value) === -1
    ? message || getMessage(localePath, INVALID_COUNTRY)
    : undefined);
};

export default {
  country,
};
