/* eslint-disable max-len */
import React from 'react';

const SvgDamageUnknown = props => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <g fill="currentColor" fillRule="nonzero">
      <path d="M18.442 33A1.5 1.5 0 1020 34.503 1.545 1.545 0 0018.442 33zM16.168 23.987c-.259.2-.206.414.071.974l.229.45c.327.647.501.738.831.521.502-.324 1.09-.612 1.662-.612.76 0 1.145.477 1.145 1.117 0 .793-.554 1.458-1.058 1.962-.725.739-1.602 1.639-1.602 3.15v.072c0 .324.229.379 1.124.379h.345c.969 0 1.125-.09 1.125-.36 0-.793.588-1.333 1.179-1.905.829-.812 1.781-1.819 1.781-3.44C22.984 24.24 21.477 23 19.245 23a5.04 5.04 0 00-3.077.986z" />
      <path d="M21.872 10.415A2.718 2.718 0 0019.5 9c-.981 0-1.887.54-2.372 1.415L1.358 38.81a2.865 2.865 0 00.011 2.796A2.717 2.717 0 003.731 43h31.538c.973 0 1.873-.53 2.362-1.394a2.865 2.865 0 00.01-2.796L21.872 10.415zM4.253 39.887L19.5 12.423l15.247 27.464H4.253zM46.8 19.856a1.55 1.55 0 00-1.349-.783h-3.97l2.628-9.585a1.533 1.533 0 00-.754-1.751 1.563 1.563 0 00-1.895.317l-6.86 7.524-4.714-7.827a1.559 1.559 0 00-1.746-.696A1.54 1.54 0 0027 8.538v4.434l3.105 5.409v-4.27l2.054 3.402a2.81 2.81 0 004.473.434l2.79-3.063-1.032 3.761a2.748 2.748 0 00.476 2.404 2.803 2.803 0 002.215 1.089h1.625l-5.382 8.766L39.093 34l7.683-12.595c.29-.474.299-1.067.024-1.55z" />
    </g>
  </svg>
);

export default SvgDamageUnknown;
