import { mediaQuery } from '../../styles/helpers'; // eslint-disable-line import/prefer-default-export
import { breakpoints } from '../../styles/constants';

const styles = theme => ({
  validationMessage: {
    color: theme.validationErrorFontColor,
    paddingTop: '12px',
    fontSize: theme.validationFontSize,
    transition: `all 0.4s ${theme.easeInCubic}`,

    ...mediaQuery.max(breakpoints.desktopNav, {
      fontSize: theme.validationMobileFontSize,
    }),
  },
});

export default styles;
