/* eslint-disable max-len */
import React from 'react';

function SvgMotorrad(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M38.88 25.299l2.709 4.126a3.388 3.388 0 11-5.666 3.718l-2.698-4.111a5.982 5.982 0 105.647-3.733h.008zM29.288 26.8H17.46a8.58 8.58 0 011.056 2.636h10.77a1.318 1.318 0 100-2.635zM26.635 31.612H18.71a8.55 8.55 0 01-.522 2.636h8.448a1.318 1.318 0 100-2.636zM10.1 25.295a5.98 5.98 0 105.972 6.317c0-.11.009-.222.009-.335a5.98 5.98 0 00-5.98-5.982zm0 9.327a3.347 3.347 0 11-.003-6.694 3.347 3.347 0 01.004 6.694z"
          fill="#000"
          fillRule="nonzero"
        />
        <path
          d="M35.974 29.446L37.647 32a1.318 1.318 0 102.202-1.446l-1.694-2.573-1.506-2.308-2.27-3.461h4.439c.728 0 1.318-.59 1.318-1.318v-5.321a1.318 1.318 0 00-1.215-1.318c-2.601-.205-5.91-.087-8.99 1.13l-3.595-5.527a1.318 1.318 0 00-1.112-.599h-4.836v2.636h4.128l3.05 4.687c-.679.43-1.315.923-1.9 1.474a11.983 11.983 0 00-2.743 3.838h-6.85A11.106 11.106 0 002.52 23.149l1.46 2.07.078.112a8.47 8.47 0 0110.87-1.034c.15.102.317.173.494.207.085.02.171.029.258.029h8.156a1.318 1.318 0 001.269-.972c.085-.297 2.099-7.041 12.397-6.735v2.75H31.94a1.318 1.318 0 00-1.103 2.04l3.621 5.518 1.516 2.312z"
          fill="#000"
          fillRule="nonzero"
        />
        <path d="M0 0h48.023v48.023H0z" />
      </g>
    </svg>
  );
}

export default SvgMotorrad;
