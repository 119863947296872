const styles = ({ colors, breakpoints, labelFontSize }) => ({
  circle: {
    fontWeight: '400',
    fontSize: '16px',
    color: colors.themeWhite,
    backgroundColor: colors.themePrimary,
    width: '120px',
    height: '120px',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transform: 'rotate(-15deg)',
    borderRadius: '50%',
    [`@media (max-width: ${breakpoints.breakpointS})`]: {
      height: '110px',
      width: '110px',
      fontSize: labelFontSize,
    },
  },
});
export default styles;
