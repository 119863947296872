import vehicle from './paths/Vehicle';
import privateLiability from './paths/PrivateLiability';
import broker from './paths/Broker';
import accident from './paths/Accident';
import legalProtection from './paths/LegalProtection';
import property from './paths/Property';
import upload from './paths/Upload';
import collisionAbroad from './paths/CollisionAbroad';
import assetLiability from './paths/AssetLiability';

export default {
  vehicle,
  'private-liability': privateLiability,
  broker,
  accident,
  'legal-protection': legalProtection,
  property,
  upload,
  'collision-abroad': collisionAbroad,
  'asset-liability': assetLiability,
};
