import { types } from './constants';

export const navigateToPath = (direction, delay = 0) => ({
  type: types.NAVIGATE_TO_PATH,
  direction,
  delay,
});

export const navigateToPathThroughSection = (startOfSection, current) => ({
  type: types.NAVIGATION_THROUGH_SECTION,
  startOfSection,
  current,
});

export const setNavigationState = (isNextActive, isPreviousActive) => ({
  type: types.NAVIGATION_UPDATE_STATE,
  isNextActive,
  isPreviousActive,
});

export const setNavigationSummaryPage = (summaryPage) => ({
  type: types.NAVIGATION_SET_SUMMARY_PAGE,
  summaryPage,
});

export const redirectToBeginning = () => ({
  type: types.REDIRECT_TO_BEGINNING,
});

export default navigateToPath;
