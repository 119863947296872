/* eslint-disable max-len */
import React from 'react';

function SvgHaus9(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g id="icons/12_buildings/haus_grafitti/48" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="ERGO_Icon_black_Haus9">
          <g id="Gebäudebeschädigung" transform="translate(1.875000, 2.250000)" fill="#1D1D1B" fillRule="nonzero">
            <polygon id="Path" points="32.6925 5.38125 35.349375 7.09125 35.349375 0.25125 30.03375 0.25125 30.03375 2.908125 32.6925 2.908125" />
            <path d="M24.72,27.855 C24.7158699,26.5538886 23.6604926,25.5010261 22.359375,25.5 L16.449375,25.5 C15.1467974,25.5010322 14.0908172,26.5561736 14.08875,27.85875 L14.08875,34.79625 L6.11625,34.79625 L6.11625,14.859375 L3.459375,16.5675 L3.459375,35.09625 C3.46142772,36.3959068 4.5128518,37.4498442 5.8125,37.455 L14.386875,37.455 C15.6894526,37.4539678 16.7454328,36.3988264 16.7475,35.09625 L16.7475,28.15875 L22.06125,28.15875 L22.06125,35.09625 C22.0633172,36.3988264 23.1192974,37.4539678 24.421875,37.455 L24.72,37.455 L24.72,34.798125 L24.72,34.798125 L24.72,27.855 Z" id="Path" />
            <path d="M27.375,13.670625 L22.06125,13.670625 C21.7441569,13.670625 21.4400785,13.7967175 21.2160357,14.021112 C20.991993,14.2455064 20.8663772,14.5497823 20.8668735,14.866875 L20.8668735,21.51 C20.8668735,22.1699391 21.4013117,22.7052156 22.06125,22.70625 L27.375,22.70625 C28.0356706,22.70625 28.57125,22.1706706 28.57125,21.51 L28.57125,14.866875 C28.57125,14.5496098 28.4452169,14.2453389 28.2208765,14.0209985 C27.9965361,13.7966581 27.6922652,13.670625 27.375,13.670625 Z M26.17875,20.31375 L23.25,20.31375 L23.25,16.06125 L26.173125,16.06125 L26.17875,20.31375 Z" id="Shape" />
            <path d="M11.4375,13.670625 C10.7768294,13.670625 10.24125,14.2062044 10.24125,14.866875 L10.24125,21.51 C10.24125,22.1706706 10.7768294,22.70625 11.4375,22.70625 L16.7475,22.70625 C17.4081706,22.70625 17.94375,22.1706706 17.94375,21.51 L17.94375,14.866875 C17.94375,14.2062044 17.4081706,13.670625 16.7475,13.670625 L11.4375,13.670625 Z M15.5625,20.31375 L12.628125,20.31375 L12.628125,16.06125 L15.55125,16.06125 L15.5625,20.31375 Z" id="Shape" />
            <polygon id="Path" points="37.288125 14.655 38.724375 12.42 19.404375 0 0.084375 12.42 1.520625 14.655 19.404375 3.1575" />
            <path d="M36.324375,20.56875 C36.4148603,20.3120208 36.2819317,20.0302791 36.02625,19.936875 L35.79375,19.854375 C35.5348665,19.7622125 35.2502875,19.8973665 35.158125,20.15625 C35.0659625,20.4151335 35.2011165,20.6997125 35.46,20.791875 L35.6925,20.874375 C35.8168061,20.9180068 35.9533772,20.9102865 36.0719742,20.8529236 C36.1905712,20.7955607 36.2814091,20.6932879 36.324375,20.56875 L36.324375,20.56875 Z" id="Path" />
            <path d="M34.479375,19.906875 C34.5711482,19.6506226 34.438807,19.3683506 34.183125,19.275 L33.950625,19.1925 C33.7831568,19.1328813 33.5966035,19.1671346 33.4612381,19.282357 C33.3258727,19.3975794 33.2622603,19.5762659 33.2943631,19.751107 C33.3264659,19.9259481 33.4494068,20.0703813 33.616875,20.13 L33.849375,20.2125 C33.9734256,20.2560744 34.109741,20.2483047 34.228037,20.190917 C34.3463329,20.1335294 34.4368127,20.0312765 34.479375,19.906875 L34.479375,19.906875 Z" id="Path" />
            <path d="M32.00625,19.54125 C32.1737182,19.6005338 32.3600928,19.565971 32.4951682,19.4505811 C32.6302435,19.3351912 32.6934985,19.1565047 32.6611057,18.9818311 C32.6287128,18.8071575 32.5055932,18.6630338 32.338125,18.60375 L32.105625,18.52125 C31.8467415,18.4296053 31.5625823,18.565179 31.4709375,18.8240625 C31.3792928,19.082946 31.5148665,19.3671052 31.77375,19.45875 L32.00625,19.54125 Z" id="Path" />
            <path d="M38.07375,20.37 C38.2794268,20.1919458 38.3028731,19.881282 38.12625,19.674375 L37.965,19.486875 C37.8501168,19.3529004 37.6724857,19.2903922 37.4990187,19.3228967 C37.3255517,19.3554012 37.1826025,19.4779801 37.1240187,19.6444592 C37.0654349,19.8109383 37.1001168,19.9960254 37.215,20.13 L37.37625,20.3175 C37.5544689,20.5243948 37.8665691,20.5478862 38.07375,20.37 L38.07375,20.37 Z" id="Path" />
            <path d="M36.795,18.886875 C37.000106,18.7082796 37.0227118,18.39766 36.845625,18.19125 L36.684375,18.00375 C36.5062632,17.7966432 36.1939818,17.7731382 35.986875,17.95125 C35.7797682,18.1293618 35.7562632,18.4416432 35.934375,18.64875 L36.095625,18.83625 C36.1815323,18.935901 36.3035774,18.9972514 36.4348029,19.0067503 C36.5660285,19.0162492 36.6956368,18.973115 36.795,18.886875 Z" id="Path" />
            <path d="M35.51625,17.40375 C35.721356,17.2251546 35.7439618,16.914535 35.566875,16.708125 L35.405625,16.520625 C35.2907418,16.3866504 35.1131107,16.3241422 34.9396437,16.3566467 C34.7661767,16.3891512 34.6232275,16.5117301 34.5646437,16.6782092 C34.5060599,16.8446883 34.5407418,17.0297754 34.655625,17.16375 L34.816875,17.35125 C34.9025186,17.4511723 35.0244271,17.512877 35.1556606,17.5227283 C35.2868942,17.5325796 35.4166485,17.4897665 35.51625,17.40375 L35.51625,17.40375 Z" id="Path" />
            <path d="M33.541875,15.868125 C33.6570932,16.0020996 33.8350337,16.0644291 34.0086682,16.0316345 C34.1823027,15.99884 34.3252519,15.8759037 34.3836682,15.7091345 C34.4420845,15.5423654 34.4070932,15.3570996 34.291875,15.223125 L34.130625,15.035625 C34.0157418,14.9016504 33.8381107,14.8391422 33.6646437,14.8716467 C33.4911767,14.9041512 33.3482275,15.0267301 33.2896437,15.1932092 C33.2310599,15.3596883 33.2657418,15.5447754 33.380625,15.67875 L33.541875,15.868125 Z" id="Path" />
            <path d="M36.8925,22.471875 L37.13625,22.430625 C37.404971,22.3845437 37.5854562,22.129346 37.539375,21.860625 C37.4932937,21.591904 37.238096,21.4114188 36.969375,21.4575 L36.725625,21.500625 C36.4666482,21.5557244 36.2974058,21.8055071 36.3422407,22.0664568 C36.3870757,22.3274066 36.629984,22.5063757 36.8925,22.471875 L36.8925,22.471875 Z" id="Path" />
            <path d="M34.963125,22.80375 L35.205,22.7625 C35.473721,22.7164187 35.6542062,22.461221 35.608125,22.1925 C35.5620437,21.923779 35.306846,21.7432938 35.038125,21.789375 L34.79625,21.8325 C34.6169699,21.8560616 34.4649641,21.9758549 34.4001489,22.144661 C34.3353337,22.313467 34.3681058,22.5042082 34.4855475,22.6416998 C34.6029891,22.7791915 34.7862619,22.841379 34.963125,22.80375 L34.963125,22.80375 Z" id="Path" />
            <path d="M33.031875,23.135625 L33.275625,23.094375 C33.449457,23.0645657 33.5941814,22.9442824 33.6552817,22.7788348 C33.7163821,22.6133871 33.6845759,22.4279105 33.5718442,22.2922723 C33.4591126,22.156634 33.282582,22.0914407 33.10875,22.12125 L32.865,22.1625 C32.596279,22.2085813 32.4157937,22.463779 32.461875,22.7325 C32.5079563,23.001221 32.763154,23.1817063 33.031875,23.135625 L33.031875,23.135625 Z" id="Path" />
            <path d="M31.1775,22.453125 L30.9375,22.5 C30.668779,22.5460813 30.4882938,22.801279 30.534375,23.07 C30.5804563,23.338721 30.835654,23.5192062 31.104375,23.473125 L31.348125,23.431875 C31.616846,23.3857937 31.7973312,23.130596 31.75125,22.861875 C31.7051687,22.593154 31.449971,22.4126688 31.18125,22.45875 L31.1775,22.453125 Z" id="Path" />
            <path d="M44.0625,28.34625 L44.0625,28.34625 L42.770625,25.34625 L43.756875,22.595625 C43.8350386,22.3793358 43.823786,22.1408146 43.7256073,21.9328447 C43.6274285,21.7248747 43.4504191,21.5646042 43.23375,21.4875 L40.17,20.386875 C39.9536859,20.3092334 39.715384,20.3207351 39.5075528,20.4188483 C39.2997216,20.5169616 39.139398,20.6936434 39.061875,20.91 L38.07375,23.660625 L35.25,25.10625 L35.161875,25.155 L35.161875,25.155 C35.0072179,25.2567168 34.8900236,25.4061725 34.828125,25.580625 L30.504375,37.625625 L32.135625,38.2125 L36.166875,26.983125 L42.11625,29.116875 L41.535,30.735 L37.0875,29.139375 L36.5025,30.770625 L40.95,32.36625 L38.83125,38.281875 L34.38375,36.684375 L33.79875,38.3175 L38.25,39.913125 L37.6875,41.4525 L31.734375,39.316875 L31.734375,39.316875 L30.106875,38.731875 L29.8125,39.5475 C29.6507005,39.9984625 29.8848261,40.4952452 30.335625,40.6575 L37.91625,43.378125 C38.010186,43.4114182 38.1090888,43.4287654 38.20875,43.4287654 C38.3383342,43.4295213 38.4664541,43.4013349 38.58375,43.34625 C38.7923224,43.2489194 38.9530938,43.0719358 39.03,42.855 L44.041875,28.891875 C44.1086144,28.717227 44.1158642,28.525438 44.0625,28.34625 Z M39.890625,23.745 L40.408125,22.305 L41.840625,22.81875 L41.323125,24.25875 L39.890625,23.745 Z M41.65125,27.10875 L37.824375,25.734375 L38.844375,25.21125 L41.199375,26.055 L41.65125,27.10875 Z" id="Shape" />
          </g>
          <rect id="Rectangle" x="0" y="0" width="47.835" height="47.835" />
        </g>
      </g>
    </svg>
  );
}

export default SvgHaus9;
