import { getMessage, VALIDATION_KEYS } from './messages';
import { hasEmptyValue, defaultConfig } from './utils';

const emailRegex = /^[_A-Za-z0-9-\\+]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/;
const postalCodeRegex = /^\d{5}$/;
const moneyValueRegex = /^((\d{1,3}(\.(\d){3})*)|\d*)(,\d{2})?$/;
const icdCodesRegex = /^([A-T]|[V-Z])\d[A-Z0-9](\.[A-Z0-9]{0,4})?$/;
const {
  INVALID_EMAIL,
  INVALID_POSTAL_CODE,
  REQUIRED,
  INVALID_LENGTH,
  INVALID_MIN_LENGTH,
  INVALID_MONEY_AMOUNT,
} = VALIDATION_KEYS;

export const requiredTruthy = (config = defaultConfig) => {
  const { localePath, message } = config;
  return value => (value !== true ? message || getMessage(localePath, REQUIRED) : undefined);
};

export const required = (config = defaultConfig) => {
  const { localePath, message } = config;
  return (value) => (hasEmptyValue(value)
    ? message || getMessage(localePath, REQUIRED)
    : undefined);
};

export const icdCodesDuplicate = (config = defaultConfig) => {
  const { localePath, message } = config;
  return () => (true
    ? message || getMessage(localePath, REQUIRED)
    : undefined);
};

export const icdCodesLimit = (config = defaultConfig) => {
  const { localePath, message } = config;
  return (value) => (value && value.length > 10
    ? message || getMessage(localePath, REQUIRED)
    : undefined);
};

export const icdCodes = (config = defaultConfig) => {
  const { localePath, message } = config;
  return (value) => (!hasEmptyValue(value) && !icdCodesRegex.test(value)
    ? message || getMessage(localePath, REQUIRED)
    : undefined);
};

export const moneyValue = (config = defaultConfig) => {
  const { localePath, message } = config;
  return (value) => (!hasEmptyValue(value) && !moneyValueRegex.test(value)
    ? message || getMessage(localePath, INVALID_MONEY_AMOUNT)
    : undefined);
};

export const email = (config = defaultConfig) => {
  const { localePath, message } = config;
  return (value) => (!hasEmptyValue(value) && !emailRegex.test(value)
    ? message || getMessage(localePath, INVALID_EMAIL)
    : undefined);
};

export const postalCode = (config = defaultConfig) => {
  const { localePath, message } = config;
  return (value) => (!hasEmptyValue(value) && !postalCodeRegex.test(value)
    ? message || getMessage(localePath, INVALID_POSTAL_CODE)
    : undefined);
};

export const textLength = (config = defaultConfig) => {
  const { localePath, message, max } = config;

  return (value) => (value && value.length > max
    ? message || getMessage(localePath, INVALID_LENGTH)
    : undefined);
};

export const hasAtLeastLength = (config = defaultConfig) => {
  const { localePath, message, min } = config;
  return (value) => (value && value.length < min
    ? message || getMessage(localePath, INVALID_MIN_LENGTH)
    : undefined);
};


export default {
  requiredTruthy,
  required,
  email,
  postalCode,
  textLength,
  hasAtLeastLength,
  moneyValue,
  icdCodes,
  icdCodesLimit,
  icdCodesDuplicate,
};
