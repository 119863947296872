/* eslint-disable max-len */
import React from 'react';

function SvgSchneeflocke(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#1D1D1B"
          fillRule="nonzero"
          d="M30.419 19.49l4.083-4.085h7.424v-2.667h-6.643V6.097h-2.667v7.422l-4.083 4.085zM17.688 28.446l-4.169 4.17H6.097v2.669h6.64v6.64h2.668v-7.423l4.171-4.17zM30.413 28.527l-1.886 1.886 4.09 4.09v7.423h2.668v-6.641h6.64v-2.668h-7.421zM17.685 19.57l1.886-1.885-4.166-4.166V6.097h-2.667v6.64H6.097v2.668h7.422z"
        />
        <path
          fill="#1D1D1B"
          fillRule="nonzero"
          d="M24.011 8.068l-4.696-4.695-1.886 1.886 5.248 5.248V22.68H10.507l-5.248-5.25-1.886 1.886 4.695 4.696-4.695 4.695 1.886 1.888 5.248-5.248H22.677v12.169l-5.248 5.25 1.886 1.884 4.696-4.696 4.695 4.696 1.888-1.884-5.25-5.25V25.346h12.172l5.247 5.248 1.886-1.888-4.696-4.695 4.696-4.696-1.886-1.886-5.248 5.25H25.344V10.507l5.25-5.248-1.888-1.886z"
        />
        <path d="M0 0h48.023v48.023H0z" />
      </g>
    </svg>
  );
}

export default SvgSchneeflocke;
