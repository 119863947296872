/* eslint-disable max-len */
import React from 'react';

const SvgFeuer = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <title>ICONS/Duschkabine</title>
    <g id="ICONS/Duschkabine" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect id="Rectangle" x="0" y="0" width="47.80875" height="47.80875" />
      <g id="ERGO_Icon_black_Duschkabine">
        <rect id="Rectangle" x="0.013125" y="0.013125" width="47.80875" height="47.80875" />
        <path d="M4.80375,9.975 L4.785,45.646875 L42.4725,45.6675 L42.496875,2.563125 C42.496875,1.93528742 41.9884617,1.42603433 41.360625,1.425 L5.94375,1.40625 C5.31621645,1.40625 4.8075,1.91496645 4.8075,2.5425 L4.8075,6.96 C5.06625,7.651875 6.645,7.98375 7.0575,6.96 L7.0575,3.435 L40.333125,3.451875 L40.32,31.295625 L37.41,31.295625 L37.41,10.190625 C37.41,7.393125 35.1375,5.05125 32.3475,5.049375 C29.5451668,5.05144314 27.2739431,7.32266682 27.271875,10.125 L27.271875,12.75 L25.295625,12.75 L25.295625,15.2775 L31.595625,15.2775 L31.595625,12.75 L29.8125,12.75 L29.8125,10.125 C29.8145674,8.72404793 30.9502968,7.58915739 32.35125,7.588125 C33.758286,7.62775884 34.8775907,8.78115833 34.875,10.18875 L34.875,31.291875 L32.33625,31.291875 L32.33625,43.689375 L7.048125,43.674375 L7.066875,9.586875 C6.856875,8.649375 5.07,8.484375 4.816875,9.586875 L4.816875,9.975 M40.3125,43.6875 L34.3125,43.6875 L34.3125,33.07875 L40.32375,33.07875 L40.3125,43.6875 Z" id="Shape" fill="#1D1D1B" fillRule="nonzero" />
        <polygon id="Path" fill="#1D1D1B" fillRule="nonzero" points="9.418125 28.74 11.955 28.74 11.955 33.815625 9.414375 33.815625" />
        <path d="M16.13625,29.8125964 C16.3706407,29.815824 16.5981176,29.7331051 16.775625,29.58 L27.61875,20.056875 C27.7866097,19.9183176 27.8838173,19.712033 27.8838173,19.494375 C27.8838173,19.276717 27.7866097,19.0704324 27.61875,18.931875 C27.2494328,18.6222559 26.7111922,18.6222559 26.341875,18.931875 L15.49875,28.449375 C15.3302743,28.587557 15.2326067,28.7939797 15.2326067,29.011875 C15.2326067,29.2297703 15.3302743,29.436193 15.49875,29.574375 C15.6754018,29.7283312 15.9019251,29.8129443 16.13625,29.8125964 L16.13625,29.8125964 Z" id="Path" fill="#1D1D1B" fillRule="nonzero" />
        <path d="M17.04375,22.659375 C17.2772631,22.6608748 17.5035293,22.5783542 17.68125,22.426875 L21.29625,19.2525 C21.4641097,19.1139426 21.5613173,18.907658 21.5613173,18.69 C21.5613173,18.472342 21.4641097,18.2660574 21.29625,18.1275 C20.9268871,17.8160638 20.3868629,17.8160638 20.0175,18.1275 L16.404375,21.30375 C16.2365153,21.4423074 16.1393077,21.648592 16.1393077,21.86625 C16.1393077,22.083908 16.2365153,22.2901926 16.404375,22.42875 C16.5828522,22.5799761 16.8098327,22.6618488 17.04375,22.659375 L17.04375,22.659375 Z" id="Path" fill="#1D1D1B" fillRule="nonzero" />
        <path d="M24.530625,26.866875 L19.11,31.6275 C18.9415243,31.765682 18.8438567,31.9721047 18.8438567,32.19 C18.8438567,32.4078953 18.9415243,32.614318 19.11,32.7525 C19.4790388,33.0628836 20.0178362,33.0628836 20.386875,32.7525 L25.809375,27.99 C25.9772347,27.8514426 26.0744423,27.645158 26.0744423,27.4275 C26.0744423,27.209842 25.9772347,27.0035574 25.809375,26.865 C25.4392766,26.5548732 24.8998124,26.5556642 24.530625,26.866875 Z" id="Path" fill="#1D1D1B" fillRule="nonzero" />
      </g>
    </g>
  </svg>
);

export default SvgFeuer;
