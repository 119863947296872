import { getMessage, VALIDATION_KEYS } from './messages';
import { defaultConfig } from './utils';

const {
  INVALID_UPLOAD_CLAIM_NUMBER,
} = VALIDATION_KEYS;

const CLAIM_NUMBER_TEST = /^(CS|HS|KS|SD|SI|SR|SS|TS|US)\d{1,9}-\d{1,4}$|^(KS)\d{3}[A-Z]{3}\d{2}-\d{1,4}$|^(US)\d{1,9}-\d{1,4}[K|G]$/i;//eslint-disable-line

export const claimNumber = (config = defaultConfig) => {
  const { localePath, message } = config;

  return (value) => (!CLAIM_NUMBER_TEST.test(value)
    ? message || getMessage(localePath, INVALID_UPLOAD_CLAIM_NUMBER)
    : undefined);
};

export default {
  claimNumber,
};
