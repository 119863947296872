import React from 'react';
import PropTypes from 'prop-types';
import injectJss from 'react-jss';
import FormFooter from '@eg/elements/FormFooter';
import styles from './styles.jss';

const FormFooterInfo = ({
  classes, params, flowConfig,
}, { t }) => {
  const { pathName } = params;

  if (!flowConfig[pathName]) {
    return null;
  }

  const { name } = params;
  const config = flowConfig[pathName].find(item => item.name === name);

  if (!config.displayRequiredInformation && !config.displayAccidentFootNote) {
    return null;
  }

  const getInfo = () => {
    if (config.displayAccidentFootNote) return t('footer.infoAccidentSummaryFootNote');
    return t('footer.infoRequiredField');
  };

  return (
    <div className={`${classes.formWrapper} esc_container--l esc_box esc_box--around`}>
      <FormFooter>
        <span className={classes.info}>
          {getInfo()}
        </span>
      </FormFooter>
    </div>
  );
};

FormFooterInfo.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  params: PropTypes.objectOf(PropTypes.string),
  flowConfig: PropTypes.objectOf(PropTypes.array),
};

FormFooterInfo.defaultProps = {
  classes: {},
  params: {},
  flowConfig: {},
};

FormFooterInfo.contextTypes = {
  t: PropTypes.func,
};

export { FormFooterInfo as FormFooterInfoPlain };
export default injectJss(styles)(FormFooterInfo);
