/* eslint-disable max-len */
import React from 'react';

function SvgPolice(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g id="icons/08_human/police_witness/48" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="polizei-und-zeugen" fillRule="nonzero">
          <rect id="Rectangle" fill="#FFFFFF" opacity="0" x="0" y="0" width="48" height="48" />
          <g id="Group" transform="translate(3.990000, 3.990000)" fill="#333333" stroke="#333333" strokeWidth="0.75">
            <g id="ERGO_Icon_UI_client_login_new_rgb_black" transform="translate(17.008500, 15.007500)">
              <path d="M11.4451934,14.0924086 C7.7512421,14.0924086 4.72341316,11.0421037 4.72341316,7.3207317 C4.72341316,3.59935976 7.7512421,0.549054878 11.4451934,0.549054878 C15.1391447,0.549054878 18.1669736,3.53835366 18.1669736,7.3207317 C18.1669736,11.0421037 15.1391447,14.0924086 11.4451934,14.0924086 Z M11.4451934,2.92829268 C9.08348684,2.92829268 7.14567632,4.8804878 7.14567632,7.2597256 C7.14567632,9.63896342 9.08348684,11.5911586 11.4451934,11.5911586 C13.8069,11.5911586 15.7447105,9.69996952 15.7447105,7.3207317 C15.7447105,4.8804878 13.8069,2.92829268 11.4451934,2.92829268 Z M2.54337632,25.0125 C2.48281974,24.4634452 2.42226316,23.731372 2.42226316,22.8772866 C2.42226316,21.4131402 2.96727236,18.0578049 7.56957236,17.7527744 C7.56957236,17.7527744 7.56957236,17.7527744 7.56957236,17.7527744 C7.56957236,17.7527744 7.56957236,17.7527744 7.56957236,17.7527744 C8.72014736,18.4848476 10.0523921,18.9118902 11.50575,18.9118902 C12.9591079,18.9118902 14.2913526,18.4848476 15.4419276,17.7527744 C15.4419276,17.7527744 15.4419276,17.7527744 15.4419276,17.7527744 C15.4419276,17.7527744 15.4419276,17.7527744 15.4419276,17.7527744 C20.0442276,18.0578049 20.5892368,21.4131402 20.5892368,22.8772866 C20.5892368,23.731372 20.5286804,24.402439 20.4681236,25.0125 L22.8903868,25.0125 C22.9509434,24.402439 23.0115,23.6703658 23.0115,22.8772866 C23.0115,18.6068598 20.4681236,16.4716463 17.5614079,15.617561 C16.5925026,15.3735366 15.6235974,15.2515244 14.7152486,15.2515244 C13.8674566,15.9835976 12.7168816,16.4716463 11.50575,16.4716463 C10.2946184,16.4716463 9.14404342,16.0446037 8.29625132,15.2515244 L8.29625132,15.2515244 L8.29625132,15.2515244 C7.32734606,15.2515244 6.3584408,15.3735366 5.4500921,15.617561 C2.54337632,16.4106402 0,18.6068598 0,22.8772866 C0,23.6703658 0.060556579,24.402439 0.121113158,25.0125 L2.54337632,25.0125 Z" id="Shape" />
            </g>
            <g id="ERGO_Icon_UI_send_message_rgb_black">
              <path d="M13.50675,8.61456154 C13.50675,9.44061538 12.84642,10.0896577 12.006,10.0896577 C11.16558,10.0896577 10.50525,9.44061538 10.50525,8.61456154 C10.50525,7.78850768 11.16558,7.13946538 12.006,7.13946538 C12.84642,7.13946538 13.50675,7.78850768 13.50675,8.61456154 Z M17.70885,7.13946538 C16.86843,7.13946538 16.2081,7.78850768 16.2081,8.61456154 C16.2081,9.44061538 16.86843,10.0896577 17.70885,10.0896577 C18.54927,10.0896577 19.2096,9.44061538 19.2096,8.61456154 C19.2096,7.78850768 18.54927,7.13946538 17.70885,7.13946538 Z M6.30315,7.13946538 C5.46273,7.13946538 4.8024,7.78850768 4.8024,8.61456154 C4.8024,9.44061538 5.46273,10.0896577 6.30315,10.0896577 C7.14357,10.0896577 7.8039,9.44061538 7.8039,8.61456154 C7.8039,7.78850768 7.14357,7.13946538 6.30315,7.13946538 Z M18.6093,0.0590038462 L17.46873,0.0590038462 L17.46873,2.4191577 L18.6093,2.4191577 C20.29014,2.4191577 21.6108,3.71724232 21.6108,5.36935 L21.6108,11.8597731 C21.6108,13.5118808 20.29014,14.8099654 18.6093,14.8099654 L17.46873,14.8099654 L17.46873,14.8099654 L17.46873,18.4092 L12.36618,14.8099654 L5.4027,14.8099654 C3.72186,14.8099654 2.4012,13.5118808 2.4012,11.8597731 L2.4012,5.36935 C2.4012,3.71724232 3.72186,2.4191577 5.4027,2.4191577 L13.38669,2.4191577 L13.38669,0.0590038462 L5.4027,0.0590038462 C2.4012,0.0590038462 0,2.4191577 0,5.36935 L0,11.8597731 C0,14.8099654 2.4012,17.1701193 5.4027,17.1701193 L11.64582,17.1701193 L19.86993,23.0115 L19.86993,17.0521115 C22.27113,16.5210769 24.012,14.3969385 24.012,11.8597731 L24.012,5.36935 C24.012,2.4191577 21.6108,0.0590038462 18.6093,0.0590038462 Z" id="Shape" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgPolice;
