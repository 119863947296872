/* eslint-disable max-len */
import React from 'react';

const SvgChartB = props => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M4.102 25.89h11.32v16.98H4.102V25.89zm3.396 3.396v10.188h4.528V29.286H7.498zM17.686 17.966h11.32V42.87h-11.32V17.966zm3.396 3.396v18.112h4.528V21.362h-4.528zM31.27 11.174h11.32V42.87H31.27V11.174zm3.396 3.396v24.904h4.528V14.57h-4.528z"
        fill="currentColor"
        fillRule="nonzero"
      />
      <path
        fill="#FFF"
        d="M17.686 25.89h3.396v3.396h-3.396zM4.102 31.55h3.396v3.396H4.102zM31.27 17.966h3.396v3.396H31.27z"
      />
      <path
        d="M3.425 14.24h1.387a8.575 8.575 0 01.621-2.088c.29-.65.67-1.214 1.139-1.69A5.118 5.118 0 018.28 9.327c.67-.279 1.452-.418 2.35-.418.69 0 1.338.083 1.945.249a5.359 5.359 0 011.636.745c.069.04.093.126.072.259-.02.132-.066.272-.135.417l-.124.24c-.083.158-.169.28-.259.367-.09.086-.169.116-.238.09-.897-.504-1.787-.756-2.67-.756a3.84 3.84 0 00-2.515.895c-.725.596-1.225 1.538-1.5 2.824h4.947c.124 0 .186.179.186.537v.258c0 .371-.062.557-.186.557H6.675a3.2 3.2 0 00-.02.368v.775c0 .126.007.249.02.368h4.844c.125 0 .187.18.187.537v.259c0 .371-.062.557-.187.557H6.862c.096.49.245.948.445 1.372.2.424.455.795.766 1.113.31.319.686.57 1.128.756.442.186.96.279 1.553.279.924 0 1.814-.22 2.67-.657.069-.026.148-.006.238.06s.176.192.259.378l.124.258c.069.146.114.285.134.418.021.133-.003.219-.072.259-1.09.623-2.332.934-3.726.934-.828 0-1.563-.122-2.205-.368a4.79 4.79 0 01-1.656-1.044 5.34 5.34 0 01-1.128-1.63 8.225 8.225 0 01-.621-2.128H3.156c-.124 0-.186-.186-.186-.557v-.259c0-.358.062-.537.186-.537h1.47V16.03c0-.145.007-.291.02-.437h-1.22c-.125 0-.187-.186-.187-.557v-.258c0-.358.062-.537.186-.537z"
        stroke="currentColor"
        strokeWidth={0.792}
        fill="currentColor"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default SvgChartB;
