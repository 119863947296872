import { polyglot } from '../polyglot';

export const VALIDATION_KEYS = {
  INVALID_EMAIL: 'INVALID_EMAIL',
  INVALID_POSTAL_CODE: 'INVALID_POSTAL_CODE',
  REQUIRED: 'REQUIRED',
  RANGE: 'RANGE',
  DATE: 'DATE',
  TIME: 'TIME',
  HOUR: 'HOUR',
  MIN: 'MIN',
  INVALID_COUNTRY: 'INVALID_COUNTRY',
  INVALID_IDENTITY: 'INVALID_IDENTITY',
  INVALID_LENGTH: 'INVALID_LENGTH',
  INVALID_ADDRESS: 'INVALID_ADDRESS',
  INVALID_GERMAN_IBAN: 'INVALID_GERMAN_IBAN',
  INVALID_MIN_LENGTH: 'INVALID_MIN_LENGTH',
  INVALID_POLICY_NUMBER: 'INVALID_POLICY_NUMBER',
  INVALID_LEGAL_PROTECTION_POLICY_NUMBER: 'INVALID_LEGAL_PROTECTION_POLICY_NUMBER',
  INVALID_ACCIDENT_POLICY_NUMBER: 'INVALID_ACCIDENT_POLICY_NUMBER',
  INVALID_PROPERTY_POLICY_NUMBER: 'INVALID_PROPERTY_POLICY_NUMBER',
  INVALID_COLLISION_ABROAD_POLICY_NUMBER: 'INVALID_COLLISION_ABROAD_POLICY_NUMBER',
  INVALID_UPLOAD_CLAIM_NUMBER: 'INVALID_UPLOAD_CLAIM_NUMBER',
};

export const defaultValidationRuleTranslations = {
  [VALIDATION_KEYS.INVALID_EMAIL]: 'validation-rules.invalid-email',
  [VALIDATION_KEYS.INVALID_POSTAL_CODE]: 'validation-rules.invalid-postal-code',
  [VALIDATION_KEYS.REQUIRED]: 'validation-rules.required',
  [VALIDATION_KEYS.RANGE]: 'validation-rules.invalid-range',
  [VALIDATION_KEYS.DATE]: 'validation-rules.invalid-date',
  [VALIDATION_KEYS.TIME]: 'validation-rules.invalid-time',
  [VALIDATION_KEYS.HOUR]: 'validation-rules.invalid-hour',
  [VALIDATION_KEYS.MIN]: 'validation-rules.invalid-min',
  [VALIDATION_KEYS.INVALID_COUNTRY]: 'validation-rules.invalid-country',
  [VALIDATION_KEYS.INVALID_IDENTITY]: 'validation-rules.invalid-identity',
  [VALIDATION_KEYS.INVALID_LENGTH]: 'validation-rules.invalid-length',
  [VALIDATION_KEYS.INVALID_ADDRESS]: 'validation-rules.invalid-address',
  [VALIDATION_KEYS.INVALID_GERMAN_IBAN]: 'validation-rules.invalid-german-iban',
  [VALIDATION_KEYS.INVALID_MIN_LENGTH]: 'validation-rules.invalid-min-length',
  [VALIDATION_KEYS.INVALID_POLICY_NUMBER]: 'validation-rules.invalid-policy-number',
  [VALIDATION_KEYS.INVALID_LEGAL_PROTECTION_POLICY_NUMBER]: 'validation-rules.invalid-legal-protection-policy-number',
  [VALIDATION_KEYS.INVALID_ACCIDENT_POLICY_NUMBER]: 'validation-rules.invalid-accident-policy-number',
  [VALIDATION_KEYS.INVALID_PROPERTY_POLICY_NUMBER]: 'validation-rules.invalid-property-policy-number',
  [VALIDATION_KEYS.INVALID_COLLISION_ABROAD_POLICY_NUMBER]: 'validation-rules.invalid-collision-abroad-policy-number',
  [VALIDATION_KEYS.INVALID_UPLOAD_CLAIM_NUMBER]: 'validation-rules.invalid-upload-claim-number',
};

export const getMessage = (namespace, ruleName) => {
  const fullTranslationKey = `${namespace}.validation-rules.${ruleName}`;

  if (polyglot.has(fullTranslationKey)) {
    return fullTranslationKey;
  }

  return defaultValidationRuleTranslations[ruleName];
};
