/* eslint-disable max-len */
import React from 'react';

const SvgBrilleDamage = props => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <g fill="currentColor" fillRule="nonzero">
      <path d="M33.573 11.143l.798 3.38a2.43 2.43 0 003.597 1.536l3.123-1.829-1.85 2.88a2.429 2.429 0 001.311 3.62l1.347.424-1.398.915 2.842 1.558 2.139-2.01A1.352 1.352 0 0045 19.314l-3.293-1.038 4.712-7.337a1.352 1.352 0 00-1.82-1.893l-7.685 4.503-1.84-7.795a1.35 1.35 0 00-2.6-.095l-4.684 14.16 3.391-1.099 2.392-7.577z" />
      <path
        d="M47.027 30.891H45.34a9.181 9.181 0 00-9.078-7.556c-4.501 0-8.267 3.113-9.143 7.238a3.81 3.81 0 00-5.019 0c-.861-4.125-4.583-7.238-9.029-7.238-4.882 0-8.893 3.665-9.29 8.31H2.2a.762.762 0 100 1.513h1.587c.396 4.646 4.407 8.31 9.285 8.31 4.998 0 9.07-3.933 9.206-8.817.66-.971 1.48-1.508 2.332-1.508.851 0 1.672.537 2.331 1.508.137 4.884 4.259 8.818 9.318 8.818 5.06 0 9.217-4.067 9.217-9.067h1.547a.762.762 0 100-1.51h.004zm-33.955 9.067c-4.291 0-7.784-3.39-7.784-7.556s3.497-7.556 7.784-7.556c4.288 0 7.673 3.32 7.673 7.556 0 4.236-3.366 7.556-7.673 7.556zm23.187 0c-4.293 0-7.785-3.39-7.785-7.556s3.492-7.556 7.785-7.556 7.67 3.32 7.67 7.556c0 4.236-3.363 7.556-7.666 7.556h-.004z"
        stroke="currentColor"
        strokeWidth={0.952}
      />
    </g>
  </svg>
);

export default SvgBrilleDamage;
