/* eslint-disable max-len */
import React from 'react';

const SvgMoebelDamage = props => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <g fill="currentColor" fillRule="nonzero">
      <path d="M34.066 9.838l.824 3.492a2.51 2.51 0 003.715 1.585l3.23-1.894-1.91 2.972a2.508 2.508 0 00.751 3.467c.184.12.382.214.59.283l1.393.439-3.612 3.104 2.935 1.216 4.377-3.848a1.395 1.395 0 00-.497-2.377l-3.402-1.072 4.867-7.577a1.395 1.395 0 00-1.878-1.956l-7.932 4.65-1.903-8.05a1.393 1.393 0 00-2.683-.117l-3.067 9.718 1.731 3.783 2.471-7.818z" />
      <path
        d="M10.34 41.858H5.998V14.69c0-4.684 3.616-8.496 8.066-8.496 4.272 0 7.776 3.523 8.04 7.96a5.225 5.225 0 00-4.61 5.183v.924c0 .326.265.59.59.59h2.637a2.164 2.164 0 004.2 0h2.42a.59.59 0 00.59-.59v-.924a5.227 5.227 0 00-4.643-5.19c-.27-5.087-4.304-9.14-9.226-9.14-5.08 0-9.215 4.317-9.246 9.64v27.212H1.078a.59.59 0 100 1.18h9.262a.59.59 0 100-1.18zm12.476-20.541a.984.984 0 01-.819-.465h1.635a.984.984 0 01-.816.465zm3.934-1.981v.332h-8.065v-.332a4.037 4.037 0 118.065 0z"
        stroke="currentColor"
        strokeWidth={0.984}
      />
      <path
        d="M44.544 32.778v-3.919a2.499 2.499 0 00-2.513-2.475H31.188a2.48 2.48 0 00-1.77.734c-.05.05-.095.1-.14.152a2.523 2.523 0 00-1.924-.886H16.446a2.497 2.497 0 00-2.514 2.475v3.934a2.866 2.866 0 00-2.613 2.83v6.906c0 .326.265.59.59.59h4.596a.59.59 0 00.59-.59v-.787H41.56v.787c0 .326.265.59.59.59h4.604a.59.59 0 00.59-.59v-6.91a2.866 2.866 0 00-2.8-2.841h0zm-14.292-4.82c.246-.252.584-.394.936-.393h10.843a1.31 1.31 0 011.328 1.288v4.131a2.847 2.847 0 00-1.794 2.63v.233H29.864v-6.929c-.004-.361.138-.709.394-.964l-.006.004zm-13.806-.393h10.902a1.31 1.31 0 011.328 1.29v6.992H17.1v-.227a2.852 2.852 0 00-1.981-2.697V28.86a1.308 1.308 0 011.328-1.294zm-.533 14.36h-3.407v-6.307a1.704 1.704 0 113.407 0v6.307zm1.18-1.377v-3.521h24.462v3.521H17.093zm29.07 1.377h-3.415v-6.307a1.706 1.706 0 013.409 0l.006 6.307z"
        stroke="currentColor"
        strokeWidth={0.984}
      />
    </g>
  </svg>
);

export default SvgMoebelDamage;
