/* eslint-disable max-len */
import React from 'react';

function SvgWasserhahn(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g id="icons/05_other/Wasserhahn/48" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="ERGO_Icon_black_Wasserhahn">
          <path d="M38.29125,5.38125 L38.29125,7.711875 L27.70875,7.7325 L27.70875,4.98375 L31.6875,4.98375 L31.6875,2.326875 L21.07875,2.326875 L21.07875,4.98375 L25.051875,4.98375 L25.051875,7.738125 L18.975,7.75125 C12.7875,7.764375 9.105,11.791875 9.12,18.525 C9.12,19.4625 9.12,20.025 9.12,20.5725 L9.12,20.8125 L8.874375,20.8125 C7.5544978,20.813534 6.48478398,21.8832478 6.48375,23.203125 L6.48375,26.518125 C6.4847822,27.8383067 7.55419455,28.9085578 8.874375,28.910625 L17.338125,28.910625 C18.6583054,28.9085578 19.7277178,27.8383067 19.72875,26.518125 L19.72875,23.195625 C19.7235899,21.8786777 18.655082,20.813522 17.338125,20.8125 L17.13375,20.8125 C17.13375,20.1975 17.13375,19.385625 17.13375,18.350625 C17.124375,16.3125 19.5,15.76125 20.900625,15.75 C25.978125,15.740625 35.491875,15.710625 35.491875,15.710625 L35.491875,13.05375 C35.491875,13.05375 25.98,13.081875 20.9025,13.093125 C17.113125,13.093125 14.47125,15.25875 14.47875,18.343125 C14.47875,19.374375 14.47875,20.184375 14.47875,20.799375 L11.784375,20.799375 C11.784375,20.716875 11.784375,20.63625 11.784375,20.5575 C11.784375,19.995 11.784375,19.45125 11.784375,18.51375 C11.765625,13.2225 14.25,10.419375 18.980625,10.408125 L38.293125,10.36875 L38.293125,15.718125 L40.95,15.718125 L40.95,5.38125 L38.29125,5.38125 Z M17.071875,26.25 L9.140625,26.25 L9.140625,23.461875 L17.071875,23.461875 L17.071875,26.25 Z" id="Shape" fill="#1D1D1B" fillRule="nonzero" />
          <path d="M14.083125,31.820625 L13.125,30.856875 L12.1875,31.824375 C11.4723925,32.581597 10.8214053,33.3968988 10.24125,34.261875 C8.89875,36.25125 8.218125,38.090625 8.218125,39.7275 C8.218125,42.4437054 10.4200446,44.645625 13.13625,44.645625 C15.8524554,44.645625 18.054375,42.4437054 18.054375,39.7275 C18.054375,38.0775 17.37375,36.234375 16.03125,34.250625 C15.4500061,33.3882818 14.7984083,32.5755092 14.083125,31.820625 L14.083125,31.820625 Z M13.145625,41.9850195 C12.5442815,41.9874932 11.9667123,41.7503561 11.5406159,41.3260189 C11.1145195,40.9016818 10.875,40.3250986 10.875,39.72375 C10.875,38.07375 12.12,36.088125 13.138125,34.785 C14.1525,36.0825 15.388125,38.060625 15.388125,39.72375 C15.3871091,40.9685276 14.3810127,41.9788126 13.13625,41.9850195 L13.145625,41.9850195 Z" id="Shape" fill="#1D1D1B" fillRule="nonzero" />
          <rect id="Rectangle" x="0" y="0" width="48" height="48" />
        </g>
      </g>
    </svg>
  );
}

export default SvgWasserhahn;
