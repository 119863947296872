/* eslint-disable max-len */
import React from 'react';

const SvgVerkehr = props => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <path d="M34.48875,3.61125 C28.2299831,3.61125 23.15625,8.68498307 23.15625,14.94375 C23.15625,21.2025169 28.2299831,26.27625 34.48875,26.27625 C40.7475169,26.27625 45.82125,21.2025169 45.82125,14.94375 C45.82125,11.9381841 44.6272936,9.05571841 42.5020376,6.9304624 C40.3767816,4.80520639 37.4943159,3.61125 34.48875,3.61125 Z" id="Path" stroke="#1D1D1B" strokeWidth="2.625" />
        <path d="M34.6875,14.4056316 C37.0402878,14.4014846 38.9446311,12.4915486 38.9418751,10.1387588 C38.939113,7.78596896 37.0302955,5.88050462 34.6775044,5.88188158 C32.3247133,5.88326004 30.418125,7.79095854 30.418125,10.14375 L30.418125,10.14375 C30.418125,11.2753702 30.8681703,12.3605329 31.6690498,13.1600055 C32.4699292,13.959478 33.5558816,14.4076164 34.6875,14.4056316 Z M31.62375,9.485625 L31.644375,9.485625 C32.36625,9.13875 32.956875,9.121875 33.66375,9.110625 C34.3508159,9.11797146 35.0347733,9.01737089 35.690625,8.8125 C36.177447,8.66644163 36.6100459,8.37934734 36.93375,7.9875 C37.9651282,9.06271264 38.0942154,10.716435 37.2421723,11.9386648 C36.3901293,13.1608946 34.7938445,13.6118225 33.4282405,13.0160467 C32.0626365,12.4202708 31.3073842,10.9434313 31.62375,9.4875 L31.62375,9.485625 Z" id="Shape" fill="#1D1D1B" fillRule="nonzero" />
        <path d="M33.609375,11.0418782 C33.9037612,11.0428789 34.1435123,10.8055999 34.1455594,10.5112191 C34.1476065,10.2168383 33.9111786,9.97624787 33.6168069,9.97315755 C33.3224352,9.97006723 33.0810085,10.2056411 33.076875,10.5 L33.076875,10.5 C33.0743597,10.6428451 33.1293499,10.7807095 33.2294869,10.8826095 C33.329624,10.9845095 33.4665078,11.0418971 33.609375,11.0418782 L33.609375,11.0418782 Z" id="Path" fill="#1D1D1B" fillRule="nonzero" />
        <path d="M35.735625,11.03625 C36.0289807,11.0352098 36.2662368,10.7971032 36.2662368,10.5037457 C36.2662368,10.2103882 36.0289653,9.9722884 35.7356096,9.97125328 C35.4422539,9.97022488 35.2033177,10.2066498 35.2012467,10.5 C35.2007495,10.6418779 35.2568889,10.7780892 35.3572123,10.8784127 C35.4575358,10.9787361 35.5937471,11.0348755 35.735625,11.034375 L35.735625,11.03625 Z" id="Path" fill="#1D1D1B" fillRule="nonzero" />
        <path d="M30.3975,15.41625 C30.226875,15.41625 29.9025,15.65625 29.716875,15.79125 C28.029375,16.940625 27.75375,19.445625 28.291875,22.14375 L29.4525,22.14375 C29.158125,20.78625 29.1,19.60875 29.28375,18.63 C29.31375,18.46875 29.35125,18.316875 29.3925,18.1725 C29.5806535,19.5509674 29.9638641,20.8956769 30.530625,22.16625 L31.726875,22.16625 C31.123125,20.87625 30.380625,18.34875 30.384375,16.340625 L30.3975,15.41625 Z" id="Path" fill="#1D1D1B" fillRule="nonzero" />
        <path d="M39.043125,15.5625 C38.6528906,15.34751 38.2187496,15.2243858 37.77375,15.2025 C37.5497562,15.2009378 37.3284113,15.2509809 37.126875,15.34875 C35.5140218,16.1386549 33.6266032,16.1386549 32.01375,15.34875 C31.8128946,15.250942 31.592148,15.200889 31.36875,15.2025 C31.3212913,15.2053009 31.2737087,15.2053009 31.22625,15.2025 C31.01625,15.166875 30.9225,15.15375 30.905625,15.21375 L30.88875,15.328125 C30.8658917,15.784788 30.9242982,16.2418822 31.06125,16.678125 C31.348125,17.7075 31.93125,18.766875 32.795625,19.828125 L32.82375,19.86 C32.923125,19.965 35.244375,22.404375 35.911875,22.951875 L35.92875,22.93125 L35.94,22.95 C36.106875,22.858125 37.59375,22.02375 38.2125,21.406875 C39.15,20.469375 39.069375,19.329375 38.004375,18.26625 C36.969375,17.23125 35.806875,17.566875 35.315625,17.89125 L33.541875,18.950625 C32.9902396,18.272074 32.553805,17.5075217 32.25,16.6875 C34.0118771,17.3144908 35.9522645,17.2036115 37.63125,16.38 C37.6767193,16.3579127 37.7270071,16.3475972 37.7775,16.35 C38.046572,16.3734995 38.3071428,16.4560883 38.540625,16.591875 C39.234375,16.936875 39.665625,17.60625 39.853125,18.635625 C40.040625,19.614375 39.976875,20.79375 39.684375,22.15125 L40.843125,22.15125 C41.4225,19.254375 41.0625,16.576875 39.043125,15.5625 Z M35.91,18.849375 L35.9475,18.825 C35.971875,18.808125 36.5625,18.403125 37.215,19.05375 C37.640625,19.479375 38.041875,19.99125 37.42125,20.608125 C37.0025456,20.9688458 36.5519448,21.2907932 36.075,21.57 C35.56875,21.076875 34.876875,20.36625 34.32375,19.79625 L35.91,18.849375 Z" id="Shape" fill="#1D1D1B" fillRule="nonzero" />
        <path d="M28.77,39.82875 L19.1175,39.849375 C19.1943977,40.1858785 19.2340114,40.5298258 19.235625,40.875 C19.2344405,41.4213167 19.1353752,41.9630012 18.943125,42.474375 L28.93125,42.45375 C28.6162855,41.6145462 28.5601182,40.7001943 28.77,39.82875 L28.77,39.82875 Z" id="Path" fill="#1D1D1B" fillRule="nonzero" />
        <path d="M41.180625,27.433125 L38.638125,28.59 C39.6225,30.69375 43.123125,37.483125 43.2075,39.80625 L40.284375,39.80625 C40.413665,40.6796345 40.3818908,41.5693139 40.190625,42.43125 L45.8325,42.43125 L45.853125,41.139375 C45.913125,36.961875 42.523125,29.930625 41.180625,27.433125 Z" id="Path" fill="#1D1D1B" fillRule="nonzero" />
        <path d="M22.9275,23.165625 C19.670625,23.165625 15.37125,28.040625 13.67625,29.76375 C10.070625,29.859375 5.42625,30.909375 2.6625,35.115 C1.47568025,36.9232612 0.798169786,39.0179733 0.70125,41.17875 L0.70125,42.49125 L7.704375,42.49125 C7.58027688,41.959536 7.5173685,41.4153785 7.516875,40.869375 C7.51620725,40.5337125 7.54002041,40.1984483 7.588125,39.86625 L3.485625,39.86625 C3.991875,37.411875 6.03,32.36625 14.233125,32.36625 L14.795625,32.36625 L15.1875,31.96875 C16.2761993,30.8413818 17.4214229,29.7700033 18.61875,28.75875 C21.905625,26.000625 23.30625,25.785 23.660625,25.785 C23.713125,25.785 21.598125,25.80375 25.09125,25.820625" id="Path" fill="#1D1D1B" fillRule="nonzero" />
        <path d="M34.584375,36.7762496 C32.1653681,36.7752147 30.2035363,38.7353679 30.2025004,41.1543747 C30.2014653,43.5733816 32.1616184,45.5352136 34.5806253,45.5362496 C36.9996321,45.5372848 38.9614642,43.5771319 38.9625,41.158125 L38.9625,41.158125 C38.9604362,38.7404012 37.0020962,36.7803837 34.584375,36.7762496 L34.584375,36.7762496 Z M34.584375,42.909376 C33.6151161,42.91041 32.8285374,42.1255096 32.827501,41.1562507 C32.8264667,40.1869917 33.6113667,39.4004127 34.5806257,39.399376 C35.5498846,39.3983413 36.3364639,40.1832411 36.3375,41.1525 L36.3375,41.1525 C36.3344123,42.1194424 35.5513174,42.9025373 34.584375,42.905625 L34.584375,42.909376 Z" id="Shape" fill="#1D1D1B" fillRule="nonzero" />
        <path d="M13.235625,36.7762496 C10.8166181,36.7752147 8.85478626,38.7353679 8.85375039,41.1543747 C8.85271534,43.5733816 10.8128684,45.5352136 13.2318753,45.5362496 C15.6508821,45.5372848 17.6127142,43.5771319 17.61375,41.158125 L17.61375,41.158125 C17.61375,38.739546 15.6542031,36.7783198 13.235625,36.7762496 Z M13.235625,42.909376 C12.2663661,42.91041 11.4797874,42.1255096 11.478751,41.1562507 C11.4777167,40.1869917 12.2626167,39.4004127 13.2318757,39.399376 C14.2011346,39.3983413 14.9877139,40.1832411 14.98875,41.1525 L14.98875,41.1525 C14.9866874,42.1198685 14.2029935,42.9035624 13.235625,42.905625 L13.235625,42.909376 Z" id="Shape" fill="#1D1D1B" fillRule="nonzero" />
        <rect id="Rectangle" x="1.35891298e-13" y="-3.46389584e-14" width="48" height="48" />
      </g>
    </g>
  </svg>
);

export default SvgVerkehr;
