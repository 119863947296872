/* eslint-disable max-len */
import React from 'react';

const SvgLaptop = props => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <g fill="currentColor" fillRule="nonzero">
      <path d="M.63 36.335v2.635h40.034v-2.635H.631zm22.707 1.918h-5.383v-1.2h5.383v1.2zM35.938 35.068H5.41c-1.139 0-2.067-1.069-2.067-2.385V16.33c0-1.315.928-2.385 2.067-2.385h30.53c1.139 0 2.067 1.07 2.067 2.385v16.354c-.002 1.316-.928 2.385-2.07 2.385zM5.626 32.433h30.095V16.582H5.626v15.85z" />
      <path d="M47.887 13.94a1.133 1.133 0 00-.98-.576h-2.884l1.906-7.032a1.128 1.128 0 00-1.923-1.05l-4.983 5.52-3.426-5.747a1.13 1.13 0 00-2.091.578v5.19h2.259V9.725l1.487 2.495a2.027 2.027 0 003.249.32l2.027-2.246-.753 2.76a2.028 2.028 0 001.956 2.56h1.18l-4.764 7.843c-.083.561-.124 1.128-.12 1.694.037.685.221 1.354.541 1.96l7.306-12.047a1.13 1.13 0 00.013-1.124" />
    </g>
  </svg>
);

export default SvgLaptop;
