/* eslint-disable max-len */
import React from 'react';

function SvgHausExternes(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect id="Rectangle" x="0" y="0" width="48" height="48" />
        <g>
          <polygon id="Path" fill="#1D1D1B" fillRule="nonzero" points="24 6 3 23.9969992 4.68892826 26 24 9.45086272 24.1004429 9.53525881 43.3110717 26 45 23.9969992" />
          <polygon id="Path" fill="#1D1D1B" fillRule="nonzero" points="9 26.588483 9 42 39 42 39 26.588483 36.0545317 24 36.0545317 39.3432993 11.9473552 39.3432993 11.9473552 24" />
          <polygon id="Path" fill="#1D1D1B" fillRule="nonzero" points="36 6 36 10.9368609 39 13 39 6" />
          <rect id="Rectangle" x="0" y="0" width="48" height="48" />
        </g>
      </g>
    </svg>
  );
}

export default SvgHausExternes;
