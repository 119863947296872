import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Formik } from 'formik';
import FormNavigationProxy from '../FormNavigationProxy/FormNavigationProxy';

const onSubmit = props => (values, actions) => actions.validateForm().then(() => {
  props.onChange({
    value: values[props.name],
  });
});

const onInit = ({ initialValues, validate }) => isEmpty(validate(initialValues));

const FormWrapper = ({ children, formikConfig, ...props }) => (
  <Formik
    onSubmit={onSubmit(props)}
    isInitialValid={onInit}
    {...formikConfig}
    render={p => <FormNavigationProxy renderChildren={children} {...p} />}
  />
);

FormWrapper.propTypes = {
  onChange: PropTypes.func,
  localePath: PropTypes.string,
  formikConfig: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  children: PropTypes.func.isRequired,
};

FormWrapper.defaultProps = {
  localePath: '',
  formikConfig: {},
  onChange: () => {},
};

export default FormWrapper;
