/* eslint-disable max-len */
import React from 'react';

function SvgMannGipsarm4(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g fill="none" fillRule="evenodd">
        <g fill="#1D1D1B" fillRule="nonzero">
          <path d="M21.137 16.024a1.247 1.247 0 100-2.494 1.247 1.247 0 000 2.494m4.97-.013a1.247 1.247 0 10.004-2.494 1.247 1.247 0 00-.004 2.494m-12.436-2.085c0-5.504 4.461-9.966 9.965-9.966s9.966 4.462 9.966 9.966c0 5.503-4.462 9.965-9.966 9.965-5.503 0-9.964-4.462-9.965-9.965m2.656 0a7.313 7.313 0 1012.584-5.052c-.576.732-1.484 1.455-2.909 1.93-1.773.592-3.348.632-4.74.67-1.633.04-3.043.078-4.723.892l-.047.018a7.327 7.327 0 00-.165 1.542M13.624 26.25c-.4 0-1.157.563-1.594.88C8.081 29.812 7.442 35.676 8.698 42h2.715c-.686-3.174-.825-5.93-.394-8.22.067-.36.152-.716.255-1.067.505 3.604 1.558 6.979 2.665 9.34h2.795c-1.412-3.021-3.148-8.931-3.142-13.626l.032-2.177zM33.844 26.61c-1.009-.502-2.018-.84-2.966-.84a3.394 3.394 0 00-1.515.34 13.577 13.577 0 01-11.96 0 3.394 3.394 0 00-1.514-.34c-.109 0-.229 0-.334-.017-.49-.086-.712-.116-.75.02l-.034.273c-.013.125-.116 1.282.407 3.153.671 2.41 2.036 4.89 4.056 7.371l.067.077c.23.242 5.66 5.946 7.223 7.23l.04-.05.023.044c.392-.217 3.87-2.163 5.314-3.61 2.18-2.18 2.005-4.857-.483-7.344-2.423-2.424-5.142-1.636-6.287-.854l-4.161 2.474c-1.654-2.109-2.548-3.937-3.03-5.319a16.247 16.247 0 0012.594-.718.724.724 0 01.343-.067c.299 0 .85.097 1.784.562 1.625.808 2.634 2.372 3.086 4.781.431 2.29.292 5.046-.394 8.22h2.71c1.351-6.767.524-13.027-4.22-15.386zm-7.326 7.688l.088-.057c.058-.041 1.438-.99 2.963.535.995.997 1.937 2.18.483 3.633-.64.644-1.998 1.54-3.15 2.25-1.18-1.149-2.799-2.812-4.095-4.145l3.711-2.217z" />
        </g>
        <path d="M0 0h47.835v47.835H0z" />
      </g>
    </svg>
  );
}

export default SvgMannGipsarm4;
