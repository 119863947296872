import { claimsKeys } from './formData';
import { getSummaryData } from '../pdfHelpers';
import { padClaimNumber } from '../../services/claimData/claimData.helpers';

export const getPageType = () => ({ [claimsKeys.PAGE_TYPE]: claimsKeys.SUBSEQUENT_DAMAGE_REPORT });

export const getAdditionalData = () => ({
  additionalData: {
    isLiability: false,
  },
  summary: getSummaryData(),
});

export const getWhatHappened = () => ({ [claimsKeys.WHAT_HAPPENED]: claimsKeys.SUBSEQUENT_UPLOAD });

export const mapClaimNumber = (mappedData, claimNumber) => ({
  ...mappedData,
  ...(claimNumber && { [claimsKeys.upload.CLAIM_NUMBER]: padClaimNumber(claimNumber) }),
});
