import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import CheckIcon from '@eg/elements/Icons/CheckIcon';
import styles from './styles.jss';

const Toggle = ({
  index, sign, option, classes, onClick, selected, toggleText, complementaryText,
}) => {
  const disabled = option[0] === '!';
  const clickHandler = disabled ? () => {} : onClick;
  let buttonClassName = '';

  if (selected) {
    buttonClassName = classes.toggleCheckSelected;
  } else if (disabled) {
    buttonClassName = classes.toggleCheckDisabled;
  }

  return (
    <label className={`${classes.wrapper}`} htmlFor={`radiobutton-${index}`}>
      <button
        className={`esc_border-radius ${classes.toggleCheck} ${buttonClassName}`}
        value={option}
        onClick={clickHandler}
        type="submit"
        id={`radiobutton-${index}`}
      >
        <div className={`esc_border-radius ${classes.signBox}`}>{sign}</div>
        <div className={classes.containerBox}>
          <p className={classes.mainText}>{toggleText}</p>
          <p className={classes.complementaryText}>{complementaryText}</p>
        </div>
        <CheckIcon className={classes.svg} width={24} height={24} />
      </button>
    </label>
  );
};

Toggle.propTypes = {
  index: PropTypes.number,
  sign: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  option: PropTypes.string,
  toggleText: PropTypes.string,
  complementaryText: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
};

Toggle.defaultProps = {
  index: null,
  sign: '',
  option: '',
  classes: {},
  selected: false,
  onClick: () => {},
  toggleText: '',
  complementaryText: '',
};

export { Toggle as TogglePlain };
export default injectSheet(styles)(Toggle);
