/* eslint-disable max-len */
import React from 'react';

function SvgPflaster(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g fill="none" fillRule="evenodd">
        <circle
          fill="#1D1D1B"
          fillRule="nonzero"
          cx={25.231}
          cy={18.656}
          r={1.129}
        />
        <path
          d="M29.224 22.648a1.13 1.13 0 10-1.597-1.598 1.13 1.13 0 001.597 1.598z"
          fill="#1D1D1B"
          fillRule="nonzero"
        />
        <circle
          fill="#1D1D1B"
          fillRule="nonzero"
          cx={22.037}
          cy={21.848}
          r={1.129}
        />
        <circle
          fill="#1D1D1B"
          fillRule="nonzero"
          cx={25.231}
          cy={25.043}
          r={1.129}
        />
        <circle
          fill="#1D1D1B"
          fillRule="nonzero"
          cx={18.844}
          cy={25.043}
          r={1.129}
        />
        <path
          d="M21.239 27.44a1.13 1.13 0 101.598 1.595 1.13 1.13 0 00-1.598-1.596zM12.456 22.116l1.861-1.864-3.326-3.328a4.33 4.33 0 116.121-6.121l3.328 3.328 1.864-1.864-3.328-3.328a6.965 6.965 0 10-9.848 9.849l3.328 3.328zM34.824 24.777l-1.864 1.864 3.328 3.328a4.33 4.33 0 11-6.121 6.121l-3.328-3.328-1.864 1.864 3.328 3.328a6.965 6.965 0 009.849-9.849l-3.328-3.328z"
          fill="#1D1D1B"
          fillRule="nonzero"
        />
        <path
          d="M38.94 8.141a6.965 6.965 0 00-9.848 0L8.329 28.904a6.965 6.965 0 009.849 9.848L38.94 17.99a6.965 6.965 0 000-9.849zM19.375 33.828l-3.06 3.066a4.33 4.33 0 01-6.122-6.121l3.06-3.063 6.122 6.118zM32.152 21.05L21.235 31.968l-6.118-6.125 10.918-10.918 6.117 6.125zm6.193-7.985a4.296 4.296 0 01-1.263 3.061l-3.06 3.06-6.13-6.12 3.063-3.061a4.33 4.33 0 017.39 3.06z"
          fill="#1D1D1B"
          fillRule="nonzero"
        />
        <path d="M0 0h48.023v48.023H0z" />
      </g>
    </svg>
  );
}

export default SvgPflaster;
