/* eslint-disable max-len */
import React from 'react';

const SvgSeitenspiegel = props => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <g fill="currentColor" fillRule="nonzero">
      <path d="M15.783 10.756l23.262 4.23a5.408 5.408 0 014.407 5.917l-1.11 9.99a5.408 5.408 0 01-4.975 4.795l-19.74 1.462a5.408 5.408 0 01-5.744-4.57L9.471 16.9a5.408 5.408 0 016.312-6.144zm-1.296 3.183a2.163 2.163 0 00-1.809 2.467l2.412 15.68a2.163 2.163 0 002.298 1.829l19.739-1.463a2.163 2.163 0 001.99-1.918l1.11-9.989a2.163 2.163 0 00-1.763-2.367l-23.261-4.23a2.163 2.163 0 00-.716-.01z" />
      <path d="M37.65 23.142l-3.058-3.059-1.53 1.53 3.06 3.059zM36.345 27.027l-6.119-6.119-1.53 1.53 6.12 6.118zM8.331 29.605h6.346l-1.59-10.816-6.08-.722a2.163 2.163 0 00-2.368 2.61l1.579 7.227a2.163 2.163 0 002.113 1.701zm-.198-8.137l2.104.25.683 4.643H9.202l-1.069-4.893z" />
    </g>
  </svg>
);

export default SvgSeitenspiegel;
