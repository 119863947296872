/* eslint-disable max-len */
import React from 'react';

function SvgSchließen(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g id="icons/01_UI/schliessen/48" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="ERGO_Icon_black_Schließen" fill="#1D1D1B" fillRule="nonzero">
          <path d="M24.0819512,47.6487805 C11.1504059,47.6487805 0.667317073,37.1656917 0.667317073,24.2341463 C0.667317073,11.302601 11.1504059,0.819512195 24.0819512,0.819512195 C37.0134966,0.819512195 47.4965854,11.302601 47.4965854,24.2341463 C47.4965854,37.1656917 37.0134966,47.6487805 24.0819512,47.6487805 Z M24.0819512,5.50243902 C13.7367149,5.50243902 5.3502439,13.8889101 5.3502439,24.2341463 C5.3502439,34.5793826 13.7367149,42.9658537 24.0819512,42.9658537 C34.4271875,42.9658537 42.8136585,34.5793826 42.8136585,24.2341463 C42.8136585,19.2661887 40.840145,14.5017056 37.3272685,10.9888291 C33.8143919,7.47595252 29.0499089,5.50243902 24.0819512,5.50243902 Z" id="Shape" />
          <polygon id="Path" points="34.0097561 17.6078049 30.6965854 14.2946341 24.0819512 20.9209756 17.4556098 14.2946341 14.142439 17.6078049 20.7687805 24.2341463 14.142439 30.8604878 17.4556098 34.1619512 24.0819512 27.5473171 30.6965854 34.1619512 34.0097561 30.8604878 27.3834146 24.2341463" />
        </g>
        <rect id="Rectangle" x="0" y="0" width="48" height="48" />
      </g>
    </svg>
  );
}

export default SvgSchließen;
