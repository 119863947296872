/* eslint-disable max-len */
import React from 'react';

const SvgRechnung = props => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <path
      d="M16.366 33.135l.873.952c-.746.677-1.62 1.043-2.547 1.043-1.784 0-3.294-1.336-3.931-3.24H9.159v-1.3h1.329c-.019-.22-.037-.42-.037-.64 0-.22.018-.44.037-.66H9.159v-1.3h1.602c.637-1.903 2.147-3.24 3.93-3.24.983 0 1.93.403 2.676 1.154l-.91.915c-.51-.512-1.129-.787-1.765-.787-1.092 0-2.039.787-2.548 1.94h3.476v1.3h-3.822c-.036.22-.036.421-.036.66 0 .219.018.438.036.64h3.822v1.3h-3.476c.51 1.171 1.456 1.94 2.548 1.94.6.018 1.182-.22 1.674-.677zm-5.55-18.087l24.112 2.984.31-2.58-24.095-2.985c-.11-.018-.219-.018-.31-.018a2.587 2.587 0 00-2.547 2.178c0 .037-.019.074-.019.092l-.436 3.533h2.602l.382-3.204zM16.62 8.33l14.267 4.943.837-2.453-14.267-4.943a2.687 2.687 0 00-.837-.146c-1.11 0-2.093.714-2.44 1.757l-1.11 3.24 2.621.33.929-2.728zm22.202 18.49v-4.028a2.59 2.59 0 00-2.585-2.6H6.575a2.59 2.59 0 00-2.584 2.6v14.26c0 1.428 1.165 2.6 2.584 2.6h17.743a6.633 6.633 0 01-.29-1.923c0-.22.017-.457.036-.677H6.594v-14.26h29.663v4.027c-2.348.476-4.186 2.252-4.841 4.522.873.128 1.71.439 2.438.897.419-1.703 1.93-2.966 3.75-2.966 2.129 0 3.821 1.74 3.821 3.88 0 2.143-1.692 3.882-3.822 3.882a3.33 3.33 0 01-.746-.073c.037.238.037.494.037.75 0 .64-.091 1.263-.273 1.849 0 .018-.019.037-.019.055a.909.909 0 00.219-.037c.255.037.51.055.782.055 3.567 0 6.406-2.91 6.406-6.48-.036-3.112-2.238-5.73-5.186-6.334zm-3.895 10.928c0 2.49-2.002 4.521-4.495 4.521-2.475 0-4.495-2.013-4.495-4.521 0-2.49 2.002-4.522 4.495-4.522 2.493 0 4.495 2.032 4.495 4.522zm-2.311 0a2.166 2.166 0 10-4.331 0c0 1.208.964 2.178 2.165 2.178 1.183 0 2.166-.97 2.166-2.178z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

export default SvgRechnung;
