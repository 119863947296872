/* eslint-disable max-len */
import React from 'react';

function SvgUeberschwemmung(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g id="icons/07_damage/ueberschwemmung/48" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="ERGO_Icon_black_Ueberschwemmung">
          <circle id="Oval" fill="#1D1D1B" fillRule="nonzero" cx="34.72125" cy="5.295" r="1" />
          <circle id="Oval" fill="#1D1D1B" fillRule="nonzero" cx="38.99625" cy="7.665" r="1" />
          <circle id="Oval" fill="#1D1D1B" fillRule="nonzero" cx="43.273125" cy="10.035" r="1" />
          <circle id="Oval" fill="#1D1D1B" fillRule="nonzero" cx="31.074375" cy="7.254375" r="1" />
          <circle id="Oval" fill="#1D1D1B" fillRule="nonzero" cx="35.349375" cy="9.624375" r="1" />
          <circle id="Oval" fill="#1D1D1B" fillRule="nonzero" cx="39.62625" cy="11.9925" r="1" />
          <circle id="Oval" fill="#1D1D1B" fillRule="nonzero" cx="43.90125" cy="14.3625" r="1" />
          <circle id="Oval" fill="#1D1D1B" fillRule="nonzero" cx="31.333125" cy="11.409375" r="1" />
          <circle id="Oval" fill="#1D1D1B" fillRule="nonzero" cx="35.608125" cy="13.779375" r="1" />
          <circle id="Oval" fill="#1D1D1B" fillRule="nonzero" cx="39.885" cy="16.149375" r="1" />
          <circle id="Oval" fill="#1D1D1B" fillRule="nonzero" cx="44.16" cy="18.519375" r="1" />
          <circle id="Oval" fill="#1D1D1B" fillRule="nonzero" cx="27.684375" cy="13.36875" r="1" />
          <circle id="Oval" fill="#1D1D1B" fillRule="nonzero" cx="31.96125" cy="15.73875" r="1" />
          <circle id="Oval" fill="#1D1D1B" fillRule="nonzero" cx="36.238125" cy="18.106875" r="1" />
          <circle id="Oval" fill="#1D1D1B" fillRule="nonzero" cx="40.513125" cy="20.476875" r="1" />
          <path d="M34.820625,39.774375 C32.3177678,41.0344189 29.3659822,41.0344189 26.863125,39.774375 C25.0097128,38.8434059 22.8252872,38.8434059 20.971875,39.774375 C18.4690178,41.0344189 15.5172322,41.0344189 13.014375,39.774375 C11.1614741,38.8440336 8.97790086,38.8440336 7.125,39.774375 C5.89163222,40.3973464 4.52800378,40.7186538 3.14625,40.711875 L3.14625,43.336875 C4.93962428,43.3419723 6.70864583,42.9217253 8.308125,42.110625 C9.41601156,41.5517606 10.7233634,41.5517606 11.83125,42.110625 C15.0789235,43.7457608 18.9092015,43.7457608 22.156875,42.110625 C23.2641084,41.5517908 24.5708916,41.5517908 25.678125,42.110625 C28.9257985,43.7457608 32.7560765,43.7457608 36.00375,42.110625 C37.1116366,41.5517606 38.4189884,41.5517606 39.526875,42.110625 C41.1263542,42.9217253 42.8953757,43.3419723 44.68875,43.336875 L44.68875,40.711875 C43.3069962,40.7186538 41.9433678,40.3973464 40.71,39.774375 C38.8570991,38.8440336 36.6735259,38.8440336 34.820625,39.774375 L34.820625,39.774375 Z" id="Path" fill="#1D1D1B" fillRule="nonzero" />
          <path d="M40.71,34.524375 C40.1137994,34.2244058 39.4751281,34.017628 38.81625,33.91125 C38.2916583,33.7657744 37.7425996,33.7306602 37.20375,33.808125 L37.20375,33.853125 C36.3737162,33.9221834 35.5646788,34.1500634 34.820625,34.524375 C34.2111872,34.8324575 33.5681659,35.0690289 32.904375,35.229375 L32.904375,35.229375 C31.522228,35.4806056 30.1057745,35.4767928 28.725,35.218125 C28.0794863,35.0598972 27.4541768,34.8283943 26.86125,34.528125 C25.0078378,33.5971559 22.8234122,33.5971559 20.97,34.528125 C20.3765564,34.8287091 19.7505893,35.0602226 19.104375,35.218125 C18.4076149,35.3490349 17.7002013,35.414943 16.99125,35.415 C16.2995409,35.4139858 15.6093025,35.3512369 14.92875,35.2275 L14.92875,35.2275 C14.2647682,35.0686472 13.6216478,34.8326686 13.0125,34.524375 C12.2677852,34.150051 11.4581325,33.9221771 10.6275,33.853125 L10.6275,33.808125 C10.0892635,33.7309224 9.54087055,33.7660349 9.016875,33.91125 C8.35857025,34.0175252 7.72050154,34.2243126 7.125,34.524375 C5.89163222,35.1473464 4.52800378,35.4686538 3.14625,35.461875 L3.14625,38.086875 C4.93962428,38.0919723 6.70864583,37.6717253 8.308125,36.860625 C9.4162962,36.3029581 10.7230788,36.3029581 11.83125,36.860625 C15.0789235,38.4957608 18.9092015,38.4957608 22.156875,36.860625 C23.2641084,36.3017908 24.5708916,36.3017908 25.678125,36.860625 C28.9257985,38.4957608 32.7560765,38.4957608 36.00375,36.860625 C37.1119212,36.3029581 38.4187038,36.3029581 39.526875,36.860625 C41.1263542,37.6717253 42.8953757,38.0919723 44.68875,38.086875 L44.68875,35.461875 C43.3069962,35.4686538 41.9433678,35.1473464 40.71,34.524375 L40.71,34.524375 Z" id="Path" fill="#1D1D1B" fillRule="nonzero" />
          <path d="M6.03375,29.8125 L7.9725,28.5675 L7.9725,31.49625 C8.82653328,31.2072767 9.73111533,31.0981141 10.629375,31.175625 L10.629375,26.863125 L23.9175,18.320625 L37.20375,26.86125 L37.20375,31.17375 C38.1026256,31.0962497 39.0078175,31.2054089 39.8625,31.494375 L39.8625,28.56 L41.80125,29.8125 L43.2375,27.5625 L23.925,15.1425 L4.595625,27.5625 L6.03375,29.8125 Z" id="Path" fill="#1D1D1B" fillRule="nonzero" />
          <path d="M30.841875,32.7862536 C31.6012776,32.7869331 32.3575699,32.6892454 33.091875,32.495625 L33.091875,27.954375 C33.091875,27.2937044 32.5562956,26.758125 31.895625,26.758125 L26.574375,26.758125 C25.9137044,26.758125 25.378125,27.2937044 25.378125,27.954375 L25.378125,31.3125 C25.8931226,31.4292018 26.3918507,31.6086684 26.863125,31.846875 C27.157961,31.9956756 27.4609404,32.1277596 27.770625,32.2425 L27.770625,29.150625 L30.69375,29.150625 L30.69375,32.780625 C30.75,32.7825 30.79125,32.7862536 30.841875,32.7862536 Z" id="Path" fill="#1D1D1B" fillRule="nonzero" />
          <path d="M20.971875,31.84125 C21.4426226,31.6033427 21.9406931,31.4238862 22.455,31.306875 L22.455,27.954375 C22.455,27.2944359 21.9205633,26.7591594 21.260625,26.758125 L15.9375,26.758125 C15.2768294,26.758125 14.74125,27.2937044 14.74125,27.954375 L14.74125,32.495625 C15.4755307,32.6893698 16.2318393,32.7870597 16.99125,32.786255 L17.139375,32.786255 L17.139375,29.150625 L20.0625,29.150625 L20.0625,32.236875 C20.3728212,32.1221998 20.6764282,31.9901151 20.971875,31.84125 L20.971875,31.84125 Z" id="Path" fill="#1D1D1B" fillRule="nonzero" />
          <line x1="15.04875" y1="12.459375" x2="16.08375" y2="16.875" id="Path" fill="#1D1D1B" fillRule="nonzero" />
          <path d="M15.763125,17.3925 C15.8999196,17.4782955 16.0655212,17.5053325 16.2225,17.4675 C16.3799288,17.43052 16.5162026,17.3324806 16.6013093,17.1949735 C16.6864159,17.0574665 16.7133753,16.8917696 16.67625,16.734375 L15.64125,12.320625 C15.5916794,12.1086102 15.4327657,11.939146 15.2243702,11.876068 C15.0159747,11.81299 14.7897576,11.8658813 14.6309327,12.014818 C14.4721078,12.1637548 14.4048044,12.3861102 14.454375,12.598125 L15.49125,17.01375 C15.5275068,17.17131 15.625443,17.3077452 15.763125,17.3925 Z" id="Path" fill="#1D1D1B" fillRule="nonzero" />
          <line x1="18.009375" y1="7.543125" x2="19.044375" y2="11.95875" id="Path" fill="#1D1D1B" fillRule="nonzero" />
          <path d="M18.72375,12.47625 C18.8605446,12.5620455 19.0261462,12.5890825 19.183125,12.55125 C19.3417801,12.5143433 19.4790549,12.415453 19.5643077,12.2766528 C19.6495606,12.1378527 19.6756942,11.970698 19.636875,11.8125 L18.601875,7.404375 C18.5236922,7.07662853 18.1946215,6.87431721 17.866875,6.95250002 C17.5391285,7.03068282 17.3368172,7.35975352 17.415,7.6875 L18.451875,12.103125 C18.4894908,12.258606 18.587273,12.3928036 18.72375,12.47625 L18.72375,12.47625 Z" id="Path" fill="#1D1D1B" fillRule="nonzero" />
          <line x1="10.0275" y1="9.41625" x2="11.0625" y2="13.831875" id="Path" fill="#1D1D1B" fillRule="nonzero" />
          <path d="M10.741875,14.349375 C10.8786696,14.4351705 11.0442712,14.4622075 11.20125,14.424375 C11.3597618,14.3879004 11.4970266,14.2893841 11.5823126,14.1508825 C11.6675985,14.0123809 11.6937817,13.8454632 11.655,13.6875 L10.62,9.2775 C10.5412994,8.94975352 10.211809,8.74786192 9.8840625,8.8265625 C9.55631602,8.90526308 9.35442442,9.23475352 9.433125,9.5625 L10.47,13.978125 C10.508064,14.1329125 10.6057955,14.2663666 10.741875,14.349375 L10.741875,14.349375 Z" id="Path" fill="#1D1D1B" fillRule="nonzero" />
          <line x1="2.045625" y1="11.289375" x2="3.080625" y2="15.705" id="Path" fill="#1D1D1B" fillRule="nonzero" />
          <path d="M2.76,16.2225 C2.8967946,16.3082955 3.06239624,16.3353325 3.219375,16.2975 C3.37747637,16.2609981 3.514405,16.1627652 3.59964168,16.0246959 C3.68487836,15.8866266 3.71133866,15.7201964 3.673125,15.5625 L2.638125,11.150625 C2.55890666,10.8228785 2.22899648,10.6214067 1.90125,10.700625 C1.57350352,10.7798433 1.37203166,11.1097535 1.45125,11.4375 L2.488125,15.84375 C2.52398819,16.0014875 2.62202385,16.1380614 2.76,16.2225 L2.76,16.2225 Z" id="Path" fill="#1D1D1B" fillRule="nonzero" />
          <line x1="7.066875" y1="14.3325" x2="8.101875" y2="18.748125" id="Path" fill="#1D1D1B" fillRule="nonzero" />
          <path d="M7.78125,19.265625 C7.9180446,19.3514205 8.08364624,19.3784575 8.240625,19.340625 C8.39790978,19.3040737 8.5341712,19.2064075 8.61931013,19.0692001 C8.70444906,18.9319928 8.73145948,18.7665349 8.694375,18.609375 L7.659375,14.19375 C7.6098044,13.9817352 7.45089073,13.812271 7.24249523,13.749193 C7.03409972,13.686115 6.80788263,13.7390063 6.64905773,13.887943 C6.49023282,14.0368798 6.4229294,14.2592352 6.4725,14.47125 L7.5,18.886875 C7.53948391,19.0455826 7.64073807,19.1819382 7.78125,19.265625 L7.78125,19.265625 Z" id="Path" fill="#1D1D1B" fillRule="nonzero" />
          <line x1="13.040625" y1="3.9075" x2="14.075625" y2="8.323125" id="Path" fill="#1D1D1B" fillRule="nonzero" />
          <path d="M13.755,8.840625 C13.9646719,8.97119586 14.2324244,8.96228104 14.4329469,8.81805277 C14.6334694,8.6738245 14.7270926,8.42281542 14.67,8.1825 L13.635,3.76875 C13.5583705,3.44100353 13.230559,3.237433 12.9028125,3.31406251 C12.575066,3.39069202 12.3714955,3.71850352 12.448125,4.04625 L13.483125,8.461875 C13.5193818,8.619435 13.617318,8.75587022 13.755,8.840625 L13.755,8.840625 Z" id="Path" fill="#1D1D1B" fillRule="nonzero" />
          <line x1="5.05875" y1="5.780625" x2="6.09375" y2="10.19625" id="Path" fill="#1D1D1B" fillRule="nonzero" />
          <path d="M5.773125,10.71375 C5.98248418,10.8438356 6.24959196,10.8349892 6.44988517,10.6913363 C6.65017838,10.5476834 6.74420831,10.2975171 6.688125,10.0575 L5.65125,5.641875 C5.56084135,5.33066497 5.24266483,5.1446587 4.92712506,5.21855092 C4.61158528,5.29244315 4.40907932,5.60038139 4.46625,5.919375 L5.50125,10.335 C5.53750681,10.49256 5.63544299,10.6289952 5.773125,10.71375 Z" id="Path" fill="#1D1D1B" fillRule="nonzero" />
          <rect id="Rectangle" x="0" y="0" width="47.835" height="47.835" />
        </g>
      </g>
    </svg>
  );
}

export default SvgUeberschwemmung;
