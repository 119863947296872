/* global VERSION:false */
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router';
import { ThemeProvider } from 'react-jss';
import { Provider as ErgoProvider } from '@eg/elements/Provider';
import store from './store';
import App from './App/App';
import '@eg/elements/styles/ergo-one/core.css';
import '@eg/elements/styles/ergo-one/styles.css';
import './styles/reset.css';
import './styles/base.css';
import * as theme from './styles/theme.jss';
import history from './browserHistory';

const rootComponent = (
  <Provider store={store}>
    <Router history={history}>
      <ErgoProvider theme="ergo-one">
        <ThemeProvider theme={{ ...theme }}>
          <App />
        </ThemeProvider>
      </ErgoProvider>
    </Router>
  </Provider>
);

ReactDOM.render(rootComponent, document.getElementById('app'));

if (process.env.NODE_ENV === 'development') {
  console.log(`v${process.env.VERSION}`); //eslint-disable-line
}
