import { types } from './constants';

export const fieldUpdate = {
  type: types.FIELD_UPDATE,
};

export const setVisibilityFilter = filter => ({
  type: types.SET_VISIBILITY_FILTER,
  filter,
});

export const fieldRemove = (pathName, fieldName) => ({
  type: types.FIELD_REMOVE,
  pathName,
  fieldName,
});
