import { addSegments } from '../../../helpers/flow/flow';
import flow from './main.yaml';
import commonSegments from '../../segments';

// paths
import collision from './segments/collision.yaml';
import theft from './segments/theft.yaml';
import vandalism from './segments/vandalism.yaml';
import liability from './segments/liability.yaml';
import glassDamage from './segments/glass-damage.yaml';
import weather from './segments/weather.yaml';
import animalDamage from './segments/animal-damage.yaml';
import fireOrExplosion from './segments/fire-or-explosion.yaml';

// segments
import policeWithNoReport from './segments/police-with-no-report.yaml';
import policeWithReport from './segments/police-with-report.yaml';
import vehicleRepairGlass from './segments/vehicle-repair-glass.yaml';
import vehicleDamageDescription from './segments/vehicle-damage-description.yaml';
import vehicleIdErgo from './segments/vehicle-id-ergo.yaml';
import vehicleIdErgoLiability from './segments/vehicle-id-ergo-liability.yaml';
import vehicleIdNonErgoByLiabilityWithoutCarRepair from
  './segments/vehicle-id-non-ergo-by-liability-without-car-repair.yaml';
import vehicleIdNonErgoByLiability from './segments/vehicle-id-non-ergo-by-liability.yaml';
import eventCircumstancesAndAftermathGlass from './segments/event-circumstances-and-aftermath-glass.yaml';
import forestAuthorityReportOptional from './segments/forest-authority-report-optional.yaml';
import theftPart from './segments/theft-part.yaml';
import repairComprehensive from './segments/repair-comprehensive.yaml';
import repairComprehensiveCalculationApp from './segments/repair-comprehensive-calculation-app.yaml';
import repairLiability from './segments/repair-liability.yaml';
import customerServices from './segments/customer-services.yaml';

// branches
import areThereAnyWitnesses from './branches/are-there-any-witnesses.yaml';
import customQuestionBlockAnimal from './branches/custom-question-block-animal.yaml';

const segments = {
  ...commonSegments,
  repairComprehensive,
  repairComprehensiveCalculationApp,
  repairLiability,
  vehicleIdErgo,
  vehicleIdErgoLiability,
  vehicleRepairGlass,
  policeWithReport,
  policeWithNoReport,
  vehicleDamageDescription,
  fireOrExplosion,
  collision,
  theft,
  vandalism,
  weather,
  areThereAnyWitnesses,
  liability,
  vehicleIdNonErgoByLiability,
  vehicleIdNonErgoByLiabilityWithoutCarRepair,
  eventCircumstancesAndAftermathGlass,
  glassDamage,
  animalDamage,
  forestAuthorityReportOptional,
  customQuestionBlockAnimal,
  theftPart,
  customerServices,
};

export default addSegments(flow, segments);
