export const maskTypes = {
  PHONE_NUMBER: 'MLLLLLLLLLLLLLL',
  IBAN: '1111 1111 1111 1111 11',
  LICENSE_NUMBER_PART_1: 'LLL',
  LICENSE_NUMBER_PART_2: 'LL',
  LICENSE_NUMBER_PART_3: '1111',
  POST_CODE: '11111',
  POLICY_NUMBER: 'KR111111111',
};

export const formatCharactersToMask = {
  PHONE_NUMBER: {
    L: {
      validate: (char) => /[0-9()/ -]/.test(char),
    },
    M: {
      validate: (char) => /[0(]/.test(char),
    },
  },
};

export default maskTypes;
