import 'url-search-params-polyfill';
import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import flowConfig from '../../flow';
import { types } from '../../actions/constants';
import { resetSession, setTracking } from '../../services';
import paths from '../../helpers/paths';
import { getCustomerData as getCustomerDataAction } from '../../actions/customerData';

class ClaimStart extends React.PureComponent {
  componentDidMount() {
    const { match: { params }, location: { search }, getCustomerData } = this.props;
    const values = new URLSearchParams(search);

    if (values.get('source')) {
      setTracking(values.get('source'));
    }

    if (params && params.source) {
      const { store } = this.context;
      resetSession(store);
    }
    if (this.isModuleAvailable() && values.get('data')) {
      getCustomerData(params.pathName, values.get('data'));
    }
  }

  isModuleAvailable = () => {
    const { match: { params } } = this.props;
    return params && params.pathName && flowConfig[params.pathName];
  }

  getDefaultUrl = (pathName) => `/claim/${pathName}/${flowConfig[pathName][0].name}`;

  getModuleUrl = (pathName) => {
    const { location: { search }, skipErgoCustomer } = this.props;
    const values = new URLSearchParams(search);
    if (values.get('source') && pathName === 'vehicle') {
      skipErgoCustomer(pathName);
      return `/claim/${pathName}/what-happened`;
    }
    return this.getDefaultUrl(pathName);
  }

  render() {
    const { match } = this.props;
    return this.isModuleAvailable() ? (
      <Redirect to={this.getModuleUrl(match.params.pathName)} />
    ) : (
      <Redirect to={paths.HOME} />
    );
  }
}

ClaimStart.propTypes = {
  match: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  location: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  getCustomerData: PropTypes.func,
  skipErgoCustomer: PropTypes.func,
};

ClaimStart.defaultProps = {
  match: {},
  location: {},
  getCustomerData: () => { },
  skipErgoCustomer: () => { },
};

ClaimStart.contextTypes = {
  store: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

const mapDispatchToProps = (dispatch) => ({
  getCustomerData: (pathName, data) => dispatch(getCustomerDataAction(pathName, data)),
  skipErgoCustomer: (pathName) => dispatch({
    type: types.FIELD_UPDATE,
    fieldName: 'who-fills',
    payload: 'policyholder',
    pathName,
  }),
});

export default connect(undefined, mapDispatchToProps)(ClaimStart);
