/* eslint-disable max-len */
import React from 'react';

function SvgHaus11(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g id="icons/12_buildings/haus_nebengeb/48" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="ERGO_Icon_black_Haus10">
          <g id="Haus_Grund" transform="translate(0.187500, 5.062500)" fill="#1D1D1B" fillRule="nonzero">
            <path d="M43.903125,36.009375 L30.841875,36.009375 L30.841875,25.779375 L29.154375,26.893125 L29.154375,36.853125 C29.154375,37.3191153 29.5321347,37.696875 29.998125,37.696875 L44.746875,37.696875 C45.2124375,37.6958439 45.5895939,37.3186875 45.590625,36.853125 L45.590625,27 L43.903125,25.94625 L43.903125,36.009375 Z" id="Path" />
            <polygon id="Path" points="32.724375 5.50875 35.38125 7.21875 35.38125 0.37875 30.065625 0.37875 30.065625 3.035625 32.724375 3.035625" />
            <path d="M24.6675,27.9825 C24.6654424,26.6821144 23.6128807,25.6278766 22.3125,25.62375 L16.396875,25.62375 C15.0942974,25.6247822 14.0383172,26.6799236 14.03625,27.9825 L14.03625,34.92 L6.06375,34.92 L6.06375,14.986875 L3.406875,16.6875 L3.406875,35.21625 C3.40894218,36.5188264 4.4649224,37.5739678 5.7675,37.575 L14.334375,37.575 C15.6311079,37.5698688 16.6813352,36.5204784 16.6875,35.22375 L16.6875,28.28625 L22.00125,28.28625 L22.00125,35.22375 C22.0022395,35.8516279 22.2531283,36.4532852 22.6985106,36.895853 C23.1438928,37.3384209 23.7471284,37.5854907 24.375,37.5825268 L24.673125,37.5825268 L24.673125,34.925625 L24.673125,34.925625 L24.6675,27.9825 Z" id="Path" />
            <path d="M28.520625,21.6375 L28.520625,15 C28.520625,14.3393294 27.9850456,13.8037485 27.324375,13.8037485 L22.00875,13.8037485 C21.6913322,13.8032513 21.3867715,13.9291243 21.1623229,14.1535729 C20.9378743,14.3780215 20.8120013,14.6825822 20.8124985,15 L20.8124985,21.6375 C20.8124985,22.2974391 21.3469367,22.8327156 22.006875,22.8337515 L27.3225,22.8337515 C27.64009,22.8342478 27.9448427,22.7084345 28.1695888,22.4840401 C28.3943349,22.2596457 28.520625,21.9550904 28.520625,21.6375 Z M26.128125,20.4375 L23.205,20.4375 L23.205,16.190625 L26.128125,16.190625 L26.128125,20.4375 Z" id="Shape" />
            <path d="M11.379375,13.798125 C11.0611328,13.798125 10.7560063,13.9249265 10.5315035,14.1504849 C10.3070008,14.3760434 10.1816286,14.6817613 10.1831118,15 L10.1831118,21.6375 C10.1831118,22.2981706 10.7187044,22.83375 11.379375,22.83375 L16.6875,22.83375 C17.3481706,22.83375 17.8837632,22.2981706 17.8837632,21.6375 L17.8837632,15 C17.8852464,14.6817613 17.7598742,14.3760434 17.5353715,14.1504849 C17.3108687,13.9249265 17.0057422,13.798125 16.6875,13.798125 L11.379375,13.798125 Z M15.504375,20.44125 L12.575625,20.44125 L12.575625,16.190625 L15.49875,16.190625 L15.504375,20.44125 Z" id="Shape" />
            <polygon id="Path" points="37.235625 14.7825 38.67375 12.5475 19.351875 0.1275 0.031875 12.5475 1.468125 14.7825 19.351875 3.286875" />
            <path d="M32.851875,33.75 L41.89125,33.75 C42.3572403,33.75 42.735,33.3722403 42.735,32.90625 C42.735,32.4402597 42.3572403,32.0625 41.89125,32.0625 L32.851875,32.0625 C32.3858847,32.0625 32.008125,32.4402597 32.008125,32.90625 C32.008125,33.3722403 32.3858847,33.75 32.851875,33.75 L32.851875,33.75 Z" id="Path" />
            <path d="M32.851875,30.954375 L41.89125,30.954375 C42.3572403,30.954375 42.735,30.5766153 42.735,30.110625 C42.735,29.6446347 42.3572403,29.266875 41.89125,29.266875 L32.851875,29.266875 C32.3858847,29.266875 32.008125,29.6446347 32.008125,30.110625 C32.008125,30.5766153 32.3858847,30.954375 32.851875,30.954375 L32.851875,30.954375 Z" id="Path" />
            <polygon id="Path" points="37.3725 18.196875 27.435 24.89625 28.3425 26.32125 37.3725 20.205 46.4025 26.32125 47.31 24.89625" />
          </g>
          <rect id="Rectangle" x="0" y="0" width="48" height="48" />
        </g>
      </g>
    </svg>
  );
}

export default SvgHaus11;
