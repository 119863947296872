import { isEqual } from 'lodash';
import {
  claimsKeys,
  compareValues,
  getCountryName,
  isCompany,
  getDate,
} from './formData';
import { getSummaryData } from '../pdfHelpers';

const WHAT_HAPPENED_VALUE = 'Unfall';

export const TYPE_OF_ACCIDENT = 'type-of-accident';

export const userFlows = {
  HOUSEHOLD: 'Unfall im Haushalt oder beim Einkauf',
  OCCUPATIONAL: 'Berufs-/Schulunfall',
  ON_THE_WAY: 'Wegeunfall',
  OTHER: 'Unfallart noch nicht bekannt',
};

export const locationTypes = {
  INNER: 'Innerhalb Haus/Wohnung/Grundstück',
  OUTER: 'Außerhalb Haus/Wohnung/Grundstück',
};

export const accidentSituations = {
  SPORT: 'Sportunfall',
  TRAFFIC: 'Verkehrsunfall',
  OTHER: 'sonstige Ursache',
};

export const sportEventTypes = {
  FOOTBALL: 'Fußball',
  SKI: 'Skiport',
  BIKE: 'Fahrradfahren',
  HORSE: 'Reitsport',
  OTHER_BALL_RELATED: 'Sonstige Ballspiele',
  OTHER: 'Sonstige Sportarten',
};

export const trafficEventTypes = {
  CAR: 'PKW, nicht Taxi',
  MOTORBIKE: 'Motorrad, Moped',
  BIKE: 'Fahrrad',
  PEDESTRIAN: 'Fußgänger',
  E_BIKE: 'E-Bike / Pedelec',
  OTHER_ELECTRIC: 'Sonstige Elektro-Kleinfahrzeuge',
  OTHER: 'Sonstige Verkehrsmittel',
};

export const otherEventTypes = {
  FALL: 'Fall oder Sturz',
  BUMP: 'Stoß an Gegenständen',
  ANIMALS: 'Unfälle durch Tiere',
  MACHINES: 'Unfälle mit Maschinen',
  TOOLS: 'Unfälle mit Werkzeugen',
  HAZARDS: 'Brand, Strom, Explosion',
  OTHER: 'Sonstiges',
};

export const hasHospitalTreatment = data => compareValues(data[claimsKeys.hospital.TREATMENT], 'ja');

export const hasAnotherBankAccount = data => compareValues(
  data[claimsKeys.BANK_ACCOUNT], claimsKeys.ANOTHER_ACCOUNT,
);

export const getOtherSituationType = (flowName) => (
  Object.values(otherEventTypes).includes(flowName) ? flowName : null);

const getFullName = ({
  [claimsKeys.injured.IS_REPORTER]: reporter,
  [claimsKeys.injured.FIRST_NAME]: iFirstName,
  [claimsKeys.injured.LAST_NAME]: iLastName,
  [claimsKeys.owner.FIRST_NAME]: firstName,
  [claimsKeys.owner.LAST_NAME]: lastName,
}) => {
  const isInjured = compareValues(reporter);
  return {
    firstName: isInjured ? firstName : iFirstName,
    lastName: isInjured ? lastName : iLastName,
  };
};

const getEmail = ({
  [claimsKeys.owner.EMAIL]: email,
}) => ({ email });

export const getHeadingData = data => {
  const firstName = data[claimsKeys.owner.FIRST_NAME];
  if (isCompany(claimsKeys.owner.SALUTATION)(data)) {
    return firstName;
  }
  const lastName = data[claimsKeys.owner.LAST_NAME];

  return `${firstName} ${lastName}`;
};

export const getContactData = ({
  [claimsKeys.injured.FIRST_NAME]: firstName,
  [claimsKeys.injured.LAST_NAME]: lastName,
  [claimsKeys.injured.PHONE]: phone,
  [claimsKeys.injured.BIRTH_DATE]: date,
  [claimsKeys.injured.ADDRESS]: address,
  [claimsKeys.injured.POST_CODE]: postCode,
  [claimsKeys.injured.CITY]: city,
  [claimsKeys.injured.COUNTRY]: country,
  [claimsKeys.injured.IS_REPORTER]: isReporter,
  [claimsKeys.injured.HOUSE_COMMUNITY]: sameHousehold,
  [claimsKeys.injured.CONSENT]: releaseConfidentiality,
}) => ({
  firstName,
  lastName,
  phone,
  date,
  address,
  postCode,
  city,
  country,
  sameHousehold: compareValues(sameHousehold),
  isReporter: compareValues(isReporter),
  releaseConfidentiality,
});

export const getClaimantData = ({
  [claimsKeys.owner.FIRST_NAME]: firstName,
  [claimsKeys.owner.LAST_NAME]: lastName,
  [claimsKeys.owner.PHONE]: phone,
  [claimsKeys.owner.EMAIL]: email,
}) => ({
  firstName, lastName, phone, email,
});

const isInnerLocationType = locationType => locationType === locationTypes.INNER;

// Incident Section
const getFirstElement = (type, locationType) => {
  switch (type) {
    case userFlows.HOUSEHOLD:
      return isInnerLocationType(locationType)
        ? 'Unfall im Haushalt oder beim Einkauf - Innerhalb Haus/Wohnung/Grundstück'
        : 'Unfall im Haushalt oder beim Einkauf - Außerhalb Haus/Wohnung/Grundstück';
    case userFlows.OCCUPATIONAL:
      return isInnerLocationType(locationType)
        ? 'Berufs-/Schulunfall (ohne Wegeunfall) im privaten häuslichen Bereich'
        : 'Berufs-/Schulunfall (ohne Wegeunfall)';
    case userFlows.ON_THE_WAY:
      return isInnerLocationType(locationType)
        ? 'Berufs-/Schulunfall (ohne Wegeunfall) im privaten häuslichen Bereich'
        : 'Wegeunfall (Unfall auf dem Weg zur Arbeitsstätte/Schule)';
    default:
      return 'Unfallart noch nicht bekannt';
  }
};

const getTrafficElement = (details, injured) => {
  const firstPartOfSentence = 'Verkehrsunfall:';
  const lastPartOfSentence = isEqual(injured, 'j') ? '(Lenker)' : '(Mitfahrer)';

  switch (details) {
    case trafficEventTypes.CAR:
    case trafficEventTypes.MOTORBIKE:
    case trafficEventTypes.E_BIKE:
    case trafficEventTypes.OTHER_ELECTRIC:
      return `${firstPartOfSentence} ${details} ${lastPartOfSentence}`;
    case trafficEventTypes.BIKE:
      return `${firstPartOfSentence} Fahrradfahren`;
    case trafficEventTypes.PEDESTRIAN:
      return `${firstPartOfSentence} ${details}`;
    default:
      return `${firstPartOfSentence} Sonstige Verkehrsmittel ${lastPartOfSentence}`;
  }
};

const getSportElement = details => {
  const firstPartOfSentence = 'Sportunfall:';

  switch (details) {
    case sportEventTypes.SKI:
      return `${firstPartOfSentence} ${details} (Auch Skibob)`;
    case sportEventTypes.OTHER_BALL_RELATED:
      // eslint-disable-next-line no-irregular-whitespace
      return `${firstPartOfSentence} Sonstige Ballspiele (ohne Polo und Wasserball)`;
    default:
      return `${firstPartOfSentence} ${details}`;
  }
};

const getSecondElement = (flowName, details, injured) => {
  switch (flowName) {
    case accidentSituations.TRAFFIC:
      return getTrafficElement(details, injured);
    case accidentSituations.SPORT:
      return getSportElement(details);
    case getOtherSituationType(flowName):
      return flowName;
    default:
      return `Sonstige Ursache: ${details}`;
  }
};

export const getIncidentDetails = ({
  [claimsKeys.DATE]: date,
  [claimsKeys.accident.TYPE]: type,
  [claimsKeys.accident.LOCATION_TYPE]: locationType,
  [claimsKeys.accident.TYPE_DETAILS]: details,
  [claimsKeys.LOCATION]: location,
  [claimsKeys.COUNTRY_CODE]: country,
  [claimsKeys.accident.SITUATION]: flowName,
  [claimsKeys.accident.INJURY_DESCRIPTION]: description,
  [claimsKeys.CIRCUMSTANCES]: event,
  [claimsKeys.accident.WHO_WAS_INJURED]: injured,
  [claimsKeys.accident.ALCOHOL_INTAKE]: alcohol,
  [claimsKeys.POLICE]: police,
  [claimsKeys.DEPARTMENT]: department,
  [claimsKeys.CASE_NUMBER]: caseNumber,
  [claimsKeys.accident.ICD_CODES.ICD_CODE_1]: icdCode1,
  [claimsKeys.accident.ICD_CODES.ICD_CODE_2]: icdCode2,
  [claimsKeys.accident.ICD_CODES.ICD_CODE_3]: icdCode3,
  [claimsKeys.accident.ICD_CODES.ICD_CODE_4]: icdCode4,
  [claimsKeys.accident.ICD_CODES.ICD_CODE_5]: icdCode5,
  [claimsKeys.accident.ICD_CODES.ICD_CODE_6]: icdCode6,
  [claimsKeys.accident.ICD_CODES.ICD_CODE_7]: icdCode7,
  [claimsKeys.accident.ICD_CODES.ICD_CODE_8]: icdCode8,
  [claimsKeys.accident.ICD_CODES.ICD_CODE_9]: icdCode9,
  [claimsKeys.accident.ICD_CODES.ICD_CODE_10]: icdCode10,
}) => ({
  date: getDate(date),
  firstElement: getFirstElement(type, locationType),
  secondElement: getSecondElement(flowName, details, injured),
  location,
  country: getCountryName(country),
  description,
  event,
  alcohol,
  department,
  caseNumber,
  hasPolice: isEqual(police, 'ja'),
  isTrafficFlow: isEqual(flowName, accidentSituations.TRAFFIC),
  icdCodes: [icdCode1, icdCode2, icdCode3, icdCode4, icdCode5, icdCode6, icdCode7, icdCode8, icdCode9, icdCode10],
});

// Hospital Section
export const getHospitalDetails = ({
  [claimsKeys.hospital.TREATMENT]: hospitalized,
  [claimsKeys.hospital.NAME]: hospitalName,
  [claimsKeys.hospital.ADDRESS]: address,
  [claimsKeys.hospital.POST_CODE]: postCode,
  [claimsKeys.hospital.CITY]: city,
  [claimsKeys.hospital.COUNTRY]: country,
  [claimsKeys.hospital.INITIAL_TREATMENT]: initialTreatment,
  [claimsKeys.hospital.INITIAL_THERAPY_DATE]: therapyStart,
  [claimsKeys.hospital.INITIAL_THERAPY_DATE_END]: therapyEnd,
  [claimsKeys.injured.FIRST_AID_DATE]: therapyInit,
}) => ({
  hospitalized,
  hasTreatment: compareValues(hospitalized, 'ja'),
  hospital: {
    hospitalName,
    address,
    postCode,
    city,
    country: getCountryName(country),
  },
  initialTreatment,
  therapyStart,
  therapyEnd,
  therapyInit,
});

export const getYourMessageDetails = ({
  [claimsKeys.YOUR_MESSAGE]: message,
}) => message;

export const getPageType = () => ({ [claimsKeys.PAGE_TYPE]: claimsKeys.DAMAGE_REPORT });

export const getWhatHappened = () => ({ [claimsKeys.WHAT_HAPPENED]: WHAT_HAPPENED_VALUE });

const getFullNameForPdf = ({
  [claimsKeys.owner.FIRST_NAME]: firstName,
  [claimsKeys.owner.LAST_NAME]: lastName,
}) => ({ firstName, lastName });

const getCustomerFullNameForPdf = data => {
  const { firstName, lastName } = getFullName(data);

  return {
    customerFirstName: firstName,
    customerLastName: lastName,
  };
};

export const getAdditionalData = data => ({
  additionalData: {
    ...getFullNameForPdf(data),
    ...getCustomerFullNameForPdf(data),
    ...getEmail(data),
    isLiability: false,
  },
  summary: getSummaryData(),
});
