import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import styles from './styles.jss';

const FormMessage = ({
  classes,
  error,
  description,
}, { t }) => {
  const className = error ? classes.error : classes.description;
  const message = error ? t(error) : description;

  return (
    <div className={`${classes.message} ${className}`}>
      {message}
    </div>
  );
};

FormMessage.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  description: PropTypes.string,
  error: PropTypes.string,
};

FormMessage.defaultProps = {
  classes: {},
  description: '',
  error: '',
};

FormMessage.contextTypes = {
  t: PropTypes.func,
};

export { FormMessage as FormMessagePlain };
export default injectSheet(styles)(FormMessage);
