/* eslint-disable max-len */
import React from 'react';

const SvgVorfall = props => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <g fill="currentColor" fillRule="nonzero">
      <path d="M33.471 39.801L16.73 36.85l-.26 1.477a4 4 0 01-4.634 3.245l-1.97-.347a4 4 0 01-3.244-4.634l.37-2.095a3.496 3.496 0 01-.979-3.09l1.002-5.685a8.476 8.476 0 013.043-5.147l-.141-.397a3.5 3.5 0 013.904-4.622l.266.047 1.566-3.282A5.5 5.5 0 0121.57 9.27l16.783 2.96a5.5 5.5 0 014.52 4.888l.349 3.62.265.046a3.5 3.5 0 012.089 5.679l-.27.325a8.476 8.476 0 011.1 5.877l-1.003 5.685a3.496 3.496 0 01-1.976 2.569l-.37 2.095a4 4 0 01-4.633 3.245l-1.97-.348a4 4 0 01-3.244-4.633l.26-1.478zm9.795-15.25a.5.5 0 00-.299-.811l-2.511-.443-.568-5.89a2.5 2.5 0 00-2.055-2.222l-16.783-2.96a2.5 2.5 0 00-2.69 1.386l-2.55 5.34-2.51-.443a.5.5 0 00-.558.66l.944 2.65-1.073.628a5.484 5.484 0 00-2.644 3.795l-1.003 5.685a.5.5 0 00.406.58l32.499 5.73a.5.5 0 00.579-.406l1.002-5.685a5.484 5.484 0 00-1.186-4.47l-.794-.957 1.794-2.167zM9.749 36.126l-.174.985a1 1 0 00.812 1.158l1.97.348a1 1 0 001.158-.811l.173-.985-3.939-.695zm26.59 4.689l-.174.984a1 1 0 00.811 1.159l1.97.347a1 1 0 001.158-.811l.174-.985-3.94-.694zm-14.715-8.688l.52-2.954 8.864 1.563-.521 2.954-8.863-1.563zm-2.679-7.58l-1.494-3.31 20.681 3.647-2.49 2.607-16.697-2.944zm22.618 9.675l-.417 2.363-4.021-.709c-1.586-.28-2.64-1.83-2.351-3.46.287-1.632 1.807-2.728 3.393-2.449l4.021.71-.417 2.363-4.02-.71a.592.592 0 00-.68.49.592.592 0 00.47.693l4.022.709zm-30.529-5.383l4.021.709a.592.592 0 00.679-.49.592.592 0 00-.47-.692l-4.022-.71.417-2.363 4.021.71c1.587.279 2.64 1.829 2.352 3.46-.288 1.632-1.807 2.728-3.393 2.448l-4.022-.709.417-2.363z" />
      <path d="M5.257 14.203l3.523 1.92a1.25 1.25 0 11-1.197 2.196l-5.945-3.242c-.944-.514-.841-1.9.168-2.27l3.717-1.365-1.897-8.07C3.341 2.157 4.815 1.32 5.712 2.187l5.334 5.157 2.538-4.507c.562-.999 2.06-.778 2.31.34L17.17 8.9a1.25 1.25 0 01-2.44.545l-.567-2.542-1.733 3.078a1.25 1.25 0 01-1.958.286l-3.44-3.325 1.177 5.006a1.25 1.25 0 01-.786 1.46l-2.167.795z" />
    </g>
  </svg>
);

export default SvgVorfall;
