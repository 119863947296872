/* eslint-disable max-len */
import React from 'react';

const SvgScheinweifer = props => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <g fill="currentColor" fillRule="nonzero">
      <path d="M20.017 40.007c3.657 0 6.093-2.57 7.494-6.37.986-2.676 1.491-6.103 1.491-9.637 0-3.534-.505-6.961-1.491-9.636-1.401-3.801-3.837-6.371-7.494-6.371-8.851 0-16.026 7.166-16.026 16.007 0 8.84 7.175 16.007 16.026 16.007zm0-4.001c-6.638 0-12.02-5.376-12.02-12.006s5.382-12.006 12.02-12.006 6.638 24.012 0 24.012zM31.503 14.996h10.005a1.5 1.5 0 100-3.002H31.503a1.5 1.5 0 100 3.002zM32.504 26h10.004a1.5 1.5 0 100-3H32.504a1.5 1.5 0 100 3zM31.503 37.006h10.005a1.5 1.5 0 100-3.001H31.503a1.5 1.5 0 100 3.001z" />
    </g>
  </svg>
);

export default SvgScheinweifer;
