import React from 'react';
import Icon from '../../components/Icon/Icon';

export default (props) => (
  <Icon {...props}>
    <polygon points="16.9990331 7 10.3390331 13.4313305 8.31699571 11.4092931 7 12.7262888 8.99903313
      14.7253219 10.3390331 16 11.6790331 14.7253219 18.3390331 8.27467811"
    />
  </Icon>
);
