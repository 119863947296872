/* eslint-disable max-len */
import React from 'react';

function SvgEntlassungsbericht(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g>
          <rect id="Rectangle" fill="#1D1D1B" fillRule="nonzero" x="35.795625" y="22.60125" width="4.83" height="2.8125" />
          <rect id="Rectangle" fill="#1D1D1B" fillRule="nonzero" x="35.07375" y="27.8775" width="5.48625" height="2.8125" />
          <rect id="Rectangle" fill="#1D1D1B" fillRule="nonzero" x="35.63625" y="33.155625" width="4.925625" height="2.8125" />
          <path d="M30.826875,41.04375 L43.8,41.04375 C45.1540498,41.042716 46.251466,39.9452998 46.2525,38.59125 L46.2525,15.688125 L46.275,15.654375 L46.2525,15.639375 L46.2525,15.598125 L46.20375,15.598125 L36.973125,7.6875 L36.89625,7.635 L36.864375,7.6725 L24.980625,7.704375 L24.980625,10.5 L25.36875,10.5 L34.245,10.47375 L34.245,15.9375 C34.248093,17.2906951 35.3443049,18.386907 36.6975,18.39 L43.456875,18.39 L43.456875,38.25 L30.826875,38.25 L30.826875,41.04375 Z M37.03875,15.594375 L37.03875,11.4975 L41.8125,15.594375 L37.03875,15.594375 Z" id="Shape" fill="#1D1D1B" fillRule="nonzero" />
          <path d="M29.881875,16.6068722 C27.5438918,16.6052689 25.5603968,18.3228176 25.2277109,20.6370104 C24.8950251,22.9512032 26.314369,25.1579403 28.558125,25.815 L28.558125,33.69 C28.5560584,37.3346503 25.9914522,40.4753843 22.4207942,41.206056 C18.8501361,41.9367277 15.2579508,40.0558716 13.824375,36.705 L13.75125,36.53625 L13.57875,36.5925 C12.8598384,36.8300802 12.0989884,36.9137673 11.345625,36.838125 L11.02875,36.79875 L11.131875,37.093125 C12.8045137,41.8319996 17.6459871,44.6784813 22.6002313,43.8357967 C27.5544755,42.9931122 31.1825733,38.7060119 31.194375,33.680625 L31.194375,25.815 C33.4384756,25.1578424 34.8578598,22.9505619 34.5246613,20.6360809 C34.1914628,18.3215999 32.2070907,16.6043327 29.86875,16.6068722 L29.881875,16.6068722 Z M31.933125,21.305625 C31.933125,22.4384991 31.0147491,23.356875 29.881875,23.356875 C28.7490009,23.356875 27.830625,22.4384991 27.830625,21.305625 C27.830625,20.1727509 28.7490009,19.254375 29.881875,19.254375 C31.0147491,19.254375 31.933125,20.1727509 31.933125,21.305625 L31.933125,21.305625 Z" id="Shape" fill="#1D1D1B" fillRule="nonzero" />
          <path d="M15.459375,31.430625 L15.459375,29.86875 C19.5128671,28.3672684 22.2050305,24.5032661 22.2093771,20.180625 L22.2093771,11.180625 C22.2110933,9.85363728 21.1555263,8.76674222 19.8290557,8.72966044 C18.5025851,8.69257865 17.3879453,9.71880508 17.3155263,11.0438163 C17.2431074,12.3688276 18.2392643,13.5104204 19.5618731,13.618125 L19.5618731,20.180625 C19.6047469,22.9227501 18.1814559,25.4794501 15.8281418,26.8876407 C13.4748278,28.2958314 10.5490152,28.3415752 8.15282925,27.0076407 C5.75664334,25.6737063 4.25412181,23.1627501 4.21125,20.420625 L4.21125,13.618125 C5.53351508,13.509426 6.52871323,12.3674812 6.45565791,11.0427686 C6.38260259,9.71805609 5.26786932,8.69246992 3.94166996,8.72982855 C2.6154706,8.76718718 1.56024015,9.85390056 1.5618731,11.180625 L1.5618731,20.180625 C1.5678522,24.5027524 4.25943043,28.3659148 8.311875,29.86875 L8.311875,31.430625 C8.35636251,33.3729773 9.94370078,34.9243756 11.8865625,34.9243756 C13.8294242,34.9243756 15.4167625,33.3729773 15.46125,31.430625 L15.459375,31.430625 Z M12.81,31.430625 C12.8253323,31.771144 12.6523988,32.092568 12.3597912,32.2674082 C12.0671836,32.4422485 11.7021914,32.4422485 11.4095838,32.2674082 C11.1169762,32.092568 10.9440427,31.771144 10.959375,31.430625 L10.959375,30.463125 C11.575052,30.5174956 12.194323,30.5174956 12.81,30.463125 L12.81,31.430625 Z" id="Shape" fill="#1D1D1B" fillRule="nonzero" />
          <rect id="Rectangle" x="-5.46229728e-13" y="1.35891298e-13" width="48" height="48" />
        </g>
      </g>
    </svg>
  );
}

export default SvgEntlassungsbericht;
