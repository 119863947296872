import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'formik';

import { setNavigationState as setNavigationStateAction } from '../../actions/navigation';

const mapDispatchToProps = dispatch => ({
  setNavigationState: (isNextActive, isPreviousActive) => {
    dispatch(setNavigationStateAction(isNextActive, isPreviousActive));
  },
});

class FormNavigationProxy extends React.Component {
  componentDidMount() {
    const { setNavigationState, isValid } = this.props;
    setNavigationState(isValid, true);
  }

  shouldComponentUpdate(nextProps) {
    const { setNavigationState, isValid } = this.props;
    if (nextProps.isValid !== isValid) {
      setNavigationState(nextProps.isValid, true);
    }
    return true;
  }

  render() {
    const { renderChildren } = this.props;
    return <Form>{renderChildren(this.props)}</Form>;
  }
}

FormNavigationProxy.propTypes = {
  isValid: PropTypes.bool,
  renderChildren: PropTypes.func,
  setNavigationState: PropTypes.func,
};

FormNavigationProxy.defaultProps = {
  isValid: false,
  renderChildren: () => {},
  setNavigationState: () => {},
};

export { FormNavigationProxy as FormNavigationProxyPlain };
export default connect(
  null,
  mapDispatchToProps,
)(FormNavigationProxy);
