/* eslint-disable max-len */
import React from 'react';

const SvgFeuer = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <title>ICONS/Dach</title>
    <g id="ICONS/Dach" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect id="Rectangle" x="0" y="0" width="48" height="48" />
      <g id="ERGO_Icon_black_Dach">
        <rect id="Rectangle" x="0.013125" y="0.013125" width="47.80875" height="47.80875" />
        <path d="M2.24097152,31.6268653 L24.1068249,13.1802817 L45.5954793,31.5891936 C46.3818949,32.2629006 46.4732633,33.4465637 45.7995564,34.2329793 C45.1380987,35.0050964 43.98506,35.107211 43.1990546,34.4730457 L43.1557707,34.4370564 L24.088125,18.1021875 L4.65902848,34.4931347 C3.88166393,35.148939 2.72671619,35.0638654 2.05312412,34.3107686 L2.01686527,34.2690285 C1.361061,33.4916639 1.44613457,32.3367162 2.19923145,31.6631241 L2.24097152,31.6268653 Z" id="Path" fill="#1D1D1B" fillRule="nonzero" />
        <path d="M8.4375,16.621829 L12.031875,16.66875 C12.572041,16.6769344 13.0059138,17.1166479 13.006875,17.656875 L13.006875,20.656875 L7.5,25.185 L7.441875,17.625 C7.43730863,17.358885 7.54049193,17.102221 7.72798387,16.9133167 C7.91547582,16.7244124 8.17135818,16.6193058 8.4375,16.621829 L8.4375,16.621829 Z" id="Path" fill="#1D1D1B" fillRule="nonzero" />
      </g>
    </g>
  </svg>
);

export default SvgFeuer;
