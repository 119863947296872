const styles = ({ colors }) => ({
  label: {
    width: '100%',
    height: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    padding: '25px 16px',
    borderRadius: '9px',
    backgroundColor: colors.themeWhite,
    boxShadow: '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)',
  },
  text: {
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '21px',
  },
  icon: {
    color: colors.extendedIconBlack,
    '& svg': {
      width: '38px',
      height: '38px',
    },
  },
  input: {
    opacity: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    cursor: 'pointer',
  },
  inner: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
    text: {
      paddingLeft: '20px',
    },
  },
});

export default styles;
