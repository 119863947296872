import { addSegments } from '../../../helpers/flow/flow';
import flow from './main.yaml';
import commonSegments from '../../segments';

// segments
import eventCircumstances from './segments/event-circumstances.yaml';
import eventOther from './segments/event-other.yaml';
import eventTraffic from './segments/event-traffic.yaml';
import eventSport from './segments/event-sport.yaml';
import eventTrafficDetails from './segments/event-traffic-details.yaml';
import hospital from './segments/hospital.yaml';
import injured from './segments/injured.yaml';
import iban from './segments/iban.yaml';
import uploadDocuments from './segments/upload-documents.yaml';
import icdCodes from './segments/icd-codes.yaml';

const segments = {
  ...commonSegments,
  eventCircumstances,
  eventOther,
  eventTraffic,
  eventSport,
  eventTrafficDetails,
  hospital,
  injured,
  iban,
  uploadDocuments,
  icdCodes,
};


export default addSegments(flow, segments);
