/* eslint-disable max-len */
import React from 'react';

function SvgImmobilienverwalter(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 100 100"
      {...props}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#1D1D1B" fillRule="nonzero" transform="translate(7.21 2.973)">
          <path d="M74.21 13.627a15.938 15.938 0 00-30.495-3.783 9.905 9.905 0 001.043 19.65h.627c.55-.001 1.1-.05 1.642-.145a19.4 19.4 0 00-.09 1.858 19.277 19.277 0 007.538 15.247l-15.589 39.59a1.96 1.96 0 01-2.544 1.097L6.73 75.421a1.96 1.96 0 01-1.098-2.544L30.643 9.781a1.98 1.98 0 012.52-1.113l2.654 1.094a2.753 2.753 0 002.085-5.096l-2.685-1.105a7.447 7.447 0 00-9.674 4.198L.528 70.839a7.447 7.447 0 004.178 9.678L34.32 92.276a7.389 7.389 0 002.743.525 7.447 7.447 0 006.934-4.704L56.682 55.85V94.05h12.543l6.604-11.547v-8.118L72.09 71.11l1.983-1.728 1.756-1.529v-7.667l-1.76-1.544-1.979-1.725 3.74-3.257v-5.715a19.265 19.265 0 00-1.62-34.317zm-11.7 17.72a15.847 15.847 0 007.083-4.029 4.41 4.41 0 11-7.573 4.108c.166-.011.33-.037.49-.078zm-4.072-25.87a10.474 10.474 0 019.681 6.511 19.544 19.544 0 00-1.862-.09 19.289 19.289 0 00-6.663 1.176l-9.247-3.767a10.43 10.43 0 018.09-3.818v-.012zm-8.624 15.636a7.056 7.056 0 01-1.54-2.838 10.425 10.425 0 01-.263-2.351c0-.538.041-1.076.122-1.608l5.554 2.266a19.407 19.407 0 00-3.892 4.543l.02-.012zm-8.82-1.799a4.406 4.406 0 011.537-3.069c.019 1.091.15 2.177.392 3.242a12.543 12.543 0 002.144 4.511 4.425 4.425 0 01-4.092-4.672l.02-.012zm30.967 24.44l-1.615.729v6.663l-6.609 5.77 2.38 2.07 2.104 1.838 2.125 1.846v2.693l-4.23 3.68-2.379 2.066 6.609 5.758v4.179l-4.312 7.514h-3.865V44.494l-1.61-.729a13.801 13.801 0 018.195-26.14 10.45 10.45 0 01-5.448 7.557 2.744 2.744 0 00-4.84-1.11 9.91 9.91 0 1014.36-1.285c.473-.98.842-2.008 1.102-3.065a13.77 13.77 0 01-1.96 24.043l-.007-.011z" />
        </g>
        <path d="M0 0H100V100H0z" />
      </g>
    </svg>
  );
}


export default SvgImmobilienverwalter;
