import { isEmpty } from 'lodash';
import { getValidators } from '../validation/Validator';
import { isMandatory } from '../validation/utils';
import { TYPE_OF_ACCIDENT } from '../formData/accidentFormData';

const THANK_YOU_PAGE_NAME = 'thank-you';
const WHO_FILLS_PAGE_NAME = 'who-fills';
const LEGAL_CONSENT = 'summary-page';
const CALL_PAGE_NAME = 'hotline_call';
const UPLOAD_CLAIM_NUMBER = 'claim-module';
const CLAIMANT_ROLE_PAGE = 'claimant-role';

const urlsWithHiddenNavigation = [
  THANK_YOU_PAGE_NAME,
  WHO_FILLS_PAGE_NAME,
  LEGAL_CONSENT,
  TYPE_OF_ACCIDENT,
  UPLOAD_CLAIM_NUMBER,
  CLAIMANT_ROLE_PAGE,
];

const GENERIC_SECTIONS = ['event', 'damage', 'compensation'];
const UPLOAD_SECTIONS = ['claim-number', 'document', 'your-message'];

export const isNavigationVisible = (currentPath) => !urlsWithHiddenNavigation.includes(currentPath);

export const isStepperVisible = (currentPath) => ![THANK_YOU_PAGE_NAME, WHO_FILLS_PAGE_NAME,
  CALL_PAGE_NAME, UPLOAD_CLAIM_NUMBER, CLAIMANT_ROLE_PAGE].includes(currentPath);

export const getSign = (index, yesNoQuestion, t) => {
  if (yesNoQuestion) {
    return [t('button.yes-y'), t('button.no-n')][index];
  }
  return String.fromCharCode(index + 65);
};

export const getNumber = (index, optionsLength) => {
  if (optionsLength && index === optionsLength - 1) {
    return 0;
  }
  return index + 1;
};

export const isDone = (isValid, currentPanelName) => (
  isValid || (currentPanelName === THANK_YOU_PAGE_NAME)
);

export const isActive = (sectionName, activeSection, currentPanelName) => (
  (activeSection === sectionName) && currentPanelName !== THANK_YOU_PAGE_NAME
);

export const isDisabled = (previousSection, currentPanelName) => {
  if (currentPanelName === THANK_YOU_PAGE_NAME) {
    return true;
  }

  if (!previousSection) {
    return false;
  }

  if (previousSection.isValid) {
    return false;
  }

  return true;
};

export const getFirstFromSection = (sectionName, flow) => flow.find(el => el.section === sectionName);

export const isSectionValid = (sectionName, flow, formData) => {
  const sectionSteps = flow.filter(step => step.section === sectionName);

  if ((isEmpty(formData) || !sectionSteps.length)) {
    return false;
  }

  return sectionSteps
    .map(step => {
      const stepValidators = getValidators(step);

      return {
        ...step,
        stepValidators,
        hasData: !!formData[step.name],
        isMandatoryPanel: isMandatory(stepValidators),
      };
    })
    .every(step => !step.isMandatoryPanel || (step.isMandatoryPanel && step.hasData));
};

export const getSections = (flow, currentActiveSection, currentPanelName, formData) => {
  const isUploadModule = flow.some(f => f.section === 'claim-number');
  const sections = isUploadModule ? UPLOAD_SECTIONS : GENERIC_SECTIONS;

  return sections.reduce((currentSections, sectionName, index) => {
    const isValid = isSectionValid(sectionName, flow, formData);

    const section = {
      title: sectionName,
      active: isActive(sectionName, currentActiveSection, currentPanelName),
      done: isDone(isValid, currentPanelName),
      first: getFirstFromSection(sectionName, flow),
      isValid,
      isDisabled: isDisabled(currentSections[index - 1], currentPanelName),
    };

    return [...currentSections, section];
  }, []);
};
