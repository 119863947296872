import { mediaQuery } from '../../styles/helpers'; // eslint-disable-line import/prefer-default-export
import { breakpoints } from '../../styles/constants';

const styles = theme => ({
  button: {
    backgroundColor: theme.secondaryButtonColor,
    borderRadius: theme.basicBorderRadius,
    border: 'none',
    color: theme.primaryFontColor,
    cursor: 'pointer',
    fontFamily: theme.boldFont,
    fontSize: theme.buttonFontSize,
    marginTop: theme.buttonMargin,
    marginRight: theme.buttonMargin,
    outline: 'none',
    padding: theme.buttonPadding,
    textAlign: 'center',
    textTransform: 'uppercase',
    transition: theme.hoverTransition,
    lineHeight: '16px',
    fontWeight: '700',
    letterSpacing: '1px',
    '&:hover': {
      backgroundColor: theme.thirdBasicColor,
      transition: theme.hoverTransition,

      ...mediaQuery.max(breakpoints.desktopNav, {
        backgroundColor: theme.secondaryButtonColor,
      }),
    },
    '&:hover svg': {
      fill: theme.eightFontColor,
    },
    '&[disabled]': {
      backgroundColor: theme.disabledButtonColor,
      color: theme.disabledButtonFontColor,
      cursor: 'not-allowed',

      '&:hover': {
        backgroundColor: theme.disabledButtonColor,
        color: theme.disabledButtonFontColor,
      },
    },
  },
  primary: {
    backgroundColor: theme.primaryButtonColor,
    '&:hover': {
      backgroundColor: theme.primaryHoverButtonColor,

      ...mediaQuery.max(breakpoints.desktopNav, {
        backgroundColor: theme.primaryButtonColor,
      }),
    },

    ...mediaQuery.max(breakpoints.desktopNav, {
      fontSize: '12px',
      width: '100%',
      position: 'absolute',
      bottom: 0,
      left: 0,
      borderRadius: 0,
    }),
  },
  secondary: {
    backgroundColor: theme.secondaryButtonColor,
    '&:hover': {
      backgroundColor: theme.secondaryHoverButtonColor,
      color: theme.secondaryFontColor,

      ...mediaQuery.max(breakpoints.desktopNav, {
        backgroundColor: theme.secondaryButtonColor,
      }),
    },
  },
  buttonMenu: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    backgroundColor: theme.primaryBasicColor,
    padding: '12px',
    margin: '0!important',
    width: '48px',
    height: '48px',
    '& svg': {
      width: '24px',
      height: '24px',
    },

    ...mediaQuery.max(breakpoints.desktopNav, {
      display: 'none',
    }),
  },
  buttonDialog: {
    backgroundColor: theme.primaryButtonColor,

    ...mediaQuery.max(breakpoints.desktopNav, {
      margin: '0 1px !important',
      borderRadius: 0,
      width: '48vw',
      height: '64px',
    }),
    '&:hover': {
      backgroundColor: theme.primaryHoverButtonColor,

      ...mediaQuery.max(breakpoints.desktopNav, {
        backgroundColor: theme.primaryButtonColor,
      }),
    },
  },
});

export default styles;
