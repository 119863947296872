/* eslint-disable max-len */
import React from 'react';

function SvgDokument3(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M36.075 15.896l.017-.022-9.265-7.946v.01l-6.505.019c.444.803.502 1.765.158 2.616l3.765-.011v5.583a2.372 2.372 0 002.368 2.368h6.88V38.64H14.118V16.504a3.089 3.089 0 01-.676-.353l-.66-.458-.665.461c-.193.133-.4.243-.618.328v22.17c0 1.303 1.066 2.605 2.368 2.605h19.874a2.372 2.372 0 002.368-2.368V15.896h-.034zm-9.223 0v-4.414L32 15.896h-5.148z"
          fill="#1D1D1B"
          fillRule="nonzero"
        />
        <path
          fill="#1D1D1B"
          fillRule="nonzero"
          d="M17.218 20.843h6.596v2.635h-6.596zM17.218 26.142H30.41v2.635H17.218zM17.218 31.441H30.41v2.635H17.218zM14.504 14.616c.384.266.887.289 1.295.059.406-.236.642-.684.606-1.152l-.173-2.095 1.893-.887a1.212 1.212 0 000-2.197l-1.901-.897.173-2.101a1.212 1.212 0 00-1.9-1.09L12.77 5.453l-1.724-1.195a1.212 1.212 0 00-1.902 1.094l.172 2.095-1.884.886a1.214 1.214 0 000 2.197l1.9.898-.172 2.102a1.212 1.212 0 001.89 1.088l1.727-1.197 1.727 1.195zm-3.185-2.635l.145-1.764-1.602-.753v-.013l1.602-.753-.145-1.765 1.455.973 1.457-1.01h.01l-.138 1.763 1.601.753v.013l-1.601.753.146 1.764-1.468-.975-1.462 1.014z"
        />
        <path d="M0 0h48.023v48.023H0z" />
      </g>
    </svg>
  );
}

export default SvgDokument3;
