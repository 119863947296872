import { addSegments } from '../../../helpers/flow/flow';
import flow from './main.yaml';

// paths
import hotline from './segments/hotline.yaml';

// private
import privateProtection from './segments/private-protection.yaml';
import contractLaw from './segments/contract-law.yaml';
import travelLaw from './segments/travel-law.yaml';
import socialLaw from './segments/social-law.yaml';
import pensionLaw from './segments/pension-law.yaml';

// property
import property from './segments/property.yaml';
import tenantOrLandlord from './segments/tenant-or-landlord.yaml';
import owner from './segments/owner.yaml';

// traffic
import traffic from './segments/traffic.yaml';
import trafficDamages from './segments/traffic-damages.yaml';
import trafficOffence from './segments/traffic-offence.yaml';
import trafficContract from './segments/traffic-contract.yaml';

// occupation
import occupation from './segments/occupation.yaml';

// segments for all
import documentUpload from './segments/upload-documents.yaml';

const segments = {
  hotline,
  privateProtection,
  contractLaw,
  travelLaw,
  socialLaw,
  pensionLaw,
  property,
  tenantOrLandlord,
  owner,
  traffic,
  trafficDamages,
  trafficOffence,
  trafficContract,
  occupation,
  documentUpload,
};

export default addSegments(flow, segments);
