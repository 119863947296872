import { isEqual } from 'lodash';
import {
  claimsKeys, compareValues, getCountryName, getDate,
} from './formData';
import { getSummaryData } from '../pdfHelpers';
import { accidentSituations } from './accidentFormData';

const WHAT_HAPPENED_VALUE = 'Vermoegensschaden';

export const jobDescriptions = {
  LAWYER: 'Rechtsanwalt / Notar',
  TAX: 'Steuerberater / Buchhalter',
  INVESTMENT: 'Anlagevermittler / Versicherungsvertreter (Makler, Agent)',
  PROPERTY: 'Immobilienverwalter / Immobilienmakler',
  ASSOCIATION: 'Verband / Kammer / Verein',
  SOFTWARE: 'IT-Unternehmen / Softwareentwicklung',
  OTHER: 'Sonstiges',
};

export const accusationsAreCorrect = {
  YES: 'Ja',
  NO: 'Nein',
  PARTLY: 'Teilweise',
  NOT_SPECIFIED: 'Keine Angabe',
};

export const ownOrThirdParty = {
  OWN: 'Eigenschaden',
  THIRD_PARTY: 'Drittschaden',
};

export const causerOfDamage = {
  POLICYHOLDER: 'Versicherungsnehmer',
  THIRD_PARTY: 'Mitversicherte Person',
  NOT_SPECIFIED: 'Keine Angabe',
};

export const getWhatHappened = () => ({ [claimsKeys.WHAT_HAPPENED]: WHAT_HAPPENED_VALUE });

export const getPageType = () => ({ [claimsKeys.PAGE_TYPE]: claimsKeys.DAMAGE_REPORT });

const getFullNameForPdf = ({
  [claimsKeys.owner.FIRST_NAME]: firstName,
  [claimsKeys.owner.LAST_NAME]: lastName,
}) => ({ firstName, lastName });

// TODO ANPASSEN NACH MAPPING
const getFullName = ({
  [claimsKeys.injured.IS_REPORTER]: reporter,
  [claimsKeys.injured.FIRST_NAME]: iFirstName,
  [claimsKeys.injured.LAST_NAME]: iLastName,
  [claimsKeys.owner.FIRST_NAME]: firstName,
  [claimsKeys.owner.LAST_NAME]: lastName,
}) => {
  const isInjured = compareValues(reporter);
  return {
    firstName: isInjured ? firstName : iFirstName,
    lastName: isInjured ? lastName : iLastName,
  };
};

export const getContactData = ({
  [claimsKeys.owner.FIRST_NAME]: firstName,
  [claimsKeys.owner.LAST_NAME]: lastName,
  [claimsKeys.owner.PHONE]: phone,
  [claimsKeys.owner.EMAIL]: email,

}) => ({
  firstName,
  lastName,
  phone,
  email,
});

export const getContactDataPolicyholder = ({
  [claimsKeys.customer.FIRST_NAME]: firstName,
  [claimsKeys.customer.LAST_NAME]: lastName,
  [claimsKeys.customer.PHONE]: phone,
  [claimsKeys.customer.EMAIL]: email,

}) => ({
  firstName,
  lastName,
  phone,
  email,
});

const getCustomerFullNameForPdf = data => {
  const { firstName, lastName } = getFullName(data);

  return {
    customerFirstName: firstName,
    customerLastName: lastName,
  };
};

const getEmail = ({
  [claimsKeys.owner.EMAIL]: email,
}) => ({ email });

export const getAdditionalData = data => ({
  additionalData: {
    ...getFullNameForPdf(data),
    ...getCustomerFullNameForPdf(data),
    ...getEmail(data),
    isLiability: false,
  },
  summary: getSummaryData(),
});

export const getContactOfAccidentData = ({
  [claimsKeys.thirdParty.FIRST_NAME]: tFirstName,
  [claimsKeys.thirdParty.LAST_NAME]: tLastName,
  [claimsKeys.thirdParty.PHONE]: tPhone,
  [claimsKeys.thirdParty.ADDRESS]: tAddress,
  [claimsKeys.thirdParty.POST_CODE]: tPostCode,
  [claimsKeys.thirdParty.CITY]: tCity,
}) => ({
  firstName: tFirstName,
  lastName: tLastName,
  phone: tPhone,
  address: tAddress,
  postCode: tPostCode,
  city: tCity,
});


export const getIncidentDetails = ({
  [claimsKeys.DATE]: date,
  [claimsKeys.CIRCUMSTANCES]: typeOfAccusation,
  [claimsKeys.assetLiability.STATEMENT_ON_ACCUSATION_KZ]: opinionAvailable,
  [claimsKeys.CAUSER_OF_DAMAGE]: whoDamaged,
}) => ({
  date: getDate(date),
  typeOfAccusation,
  opinionAvailable,
  whoDamaged,
});

export const getDamageDetails = ({
  [claimsKeys.assetLiability.OWN_OR_THIRD_PARTY]: kindOfDamage,
  [claimsKeys.assetLiability.AMOUNT_OF_DAMAGE]: damageValue,
  [claimsKeys.assetLiability.DUNNING_NOTICE]: dunningNotice,
  [claimsKeys.assetLiability.DUNNING_NOTICE_DATE]: dunningNoticeDate,
  [claimsKeys.assetLiability.ASSERTED_IN_WRITING]: assertedInWriting,
}) => ({
  kindOfDamage,
  damageValue,
  dunningNotice,
  assertedInWriting,
  dunningNoticeDate: getDate(dunningNoticeDate),
});

export const getYesNo = (value) => {
  if (value === 'j') {
    return 'Ja';
  }
  return 'Nein';
};

export const getSMelder = ({
  [claimsKeys.WHO_FILLS]: sMelder,
}) => sMelder === 'j';
