/* eslint-disable max-len */
import React from 'react';

function SvgAuto4(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group" transform="translate(0.750000, 11.812500)" fill="#1D1D1B" fillRule="nonzero">
          <path d="M33.8785153,14.6025047 C31.9287545,14.6025047 30.1709797,15.7770123 29.4248385,17.5783564 C28.6786974,19.3797005 29.0911296,21.4531373 30.4698187,22.8318263 C31.8485078,24.2105154 33.9219445,24.6229476 35.7232886,23.8768065 C37.5246327,23.1306653 38.6991403,21.3728905 38.6991403,19.4231297 C38.6970723,16.7616291 36.5400159,14.6045727 33.8785153,14.6025047 L33.8785153,14.6025047 Z M33.8785153,21.5850047 C32.6847893,21.5839694 31.7178256,20.615607 31.7185153,19.4218808 C31.7192057,18.2281546 32.6872884,17.2609108 33.8810148,17.2612555 C35.0747411,17.2616004 36.0422653,18.2294033 36.0422653,19.4231297 C36.0401985,20.6169751 35.072362,21.5839729 33.8785153,21.5850047 Z" id="Shape" />
          <path d="M46.5535153,12.1368797 L45.8035153,9.98062972 C45.8035153,9.95625472 45.7847653,9.93000472 45.7735153,9.90375472 C45.4353815,9.08681467 44.6736608,8.52345874 43.7935153,8.43937972 L43.7222653,8.43937972 L33.9572653,8.02312972 L26.9091403,0.425629718 C26.6578218,0.15384662 26.3043116,-0.000474191944 25.9341403,2.35080425e-06 L14.2922653,2.35080425e-06 C13.9233627,-0.000691304068 13.5708067,0.152150878 13.3191403,0.421879718 L6.2466403,8.02312972 L1.2347653,8.35875472 C0.859572367,8.38602151 0.513429841,8.57075373 0.281932445,8.86727045 C0.0504350485,9.16378718 -0.0448043191,9.54440523 0.0197652981,9.91500472 L1.1841403,19.1025047 C1.26137323,20.3655941 2.30744289,21.350903 3.5728903,21.3525047 L5.5678903,21.3525047 C5.39937021,20.7232432 5.31426676,20.0745657 5.3147653,19.4231297 C5.3147653,19.1775047 5.3278903,18.9356297 5.3503903,18.6956297 L3.8053903,18.6956297 L2.8791403,10.9125047 L6.8391403,10.6462547 L6.8391403,10.6650047 L33.3403903,10.6650047 L33.3403903,10.6650047 L43.3791403,11.0868797 L44.0447653,13.0200047 L42.2241403,18.5400047 C42.2075225,18.589022 42.1943651,18.6391452 42.1847653,18.6900047 L40.0078903,18.6900047 C40.0320526,18.9330007 40.0439427,19.1770608 40.0435153,19.4212547 C40.0442216,20.0733212 39.959116,20.7226454 39.7903903,21.3525047 L42.4153903,21.3525047 C43.6381462,21.3517012 44.6635237,20.4290232 44.7928903,19.2131297 L46.5910153,13.7568797 C46.7668617,13.228886 46.7536044,12.6561713 46.5535153,12.1368797 Z M14.8660153,2.65687972 L25.3491403,2.65687972 L30.3010153,8.00250472 L22.2797653,8.00250472 L22.2797653,5.58562972 L19.6210153,5.58562972 L19.6210153,8.00250472 L9.8953903,8.00250472 L14.8660153,2.65687972 Z" id="Shape" />
          <path d="M11.5078903,14.6025047 C8.84602176,14.6045754 6.68963576,16.7637588 6.69101528,19.4256278 C6.69239614,22.0874968 8.85102008,24.2444428 11.5128893,24.2437533 C14.1747586,24.2430634 16.3322653,22.0849991 16.3322653,19.4231297 C16.3291623,16.7605943 14.1704273,14.6035373 11.5078903,14.6025047 L11.5078903,14.6025047 Z M11.5078903,21.5850047 C10.3139197,21.5850047 9.3460153,20.6171003 9.3460153,19.4231297 C9.3460153,18.2291591 10.3139197,17.2612547 11.5078903,17.2612547 C12.7018609,17.2612547 13.6697653,18.2291591 13.6697653,19.4231297 C13.6687315,20.6166718 12.7014324,21.5839709 11.5078903,21.5850047 L11.5078903,21.5850047 Z" id="Shape" />
          <path d="M27.7528903,18.6656297 L17.8153903,18.6656297 C17.9084108,19.5448363 17.8449298,20.4335702 17.6278903,21.2906297 L27.9535153,21.2906297 C27.7948185,20.6783213 27.715419,20.0481665 27.7172653,19.4156297 C27.7153903,19.1681297 27.7285153,18.9150047 27.7528903,18.6656297 Z" id="Path" />
        </g>
        <rect id="Rectangle" x="0" y="0" width="48" height="48" />
      </g>
    </svg>
  );
}

export default SvgAuto4;
