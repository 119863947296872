/* eslint-disable max-len */
import React from 'react';

const SvgPolizeiUndZeugen = props => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <g
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.5}
      fillRule="nonzero"
    >
      <path d="M32.443 33.09c-3.693 0-6.721-3.05-6.721-6.772 0-3.721 3.028-6.771 6.721-6.771 3.694 0 6.722 2.989 6.722 6.771 0 3.721-3.028 6.772-6.722 6.772zm0-11.164c-2.361 0-4.299 1.952-4.299 4.331 0 2.38 1.938 4.331 4.3 4.331 2.361 0 4.299-1.89 4.299-4.27 0-2.44-1.938-4.392-4.3-4.392zm-8.901 22.083a19.409 19.409 0 01-.121-2.135c0-1.464.545-4.82 5.147-5.124 0 0 0 0 0 0s0 0 0 0a7.27 7.27 0 003.936 1.159 7.27 7.27 0 003.936-1.16s0 0 0 0 0 0 0 0c4.602.306 5.147 3.661 5.147 5.125 0 .854-.06 1.525-.121 2.135h2.422c.06-.61.121-1.342.121-2.135 0-4.27-2.543-6.405-5.45-7.26a11.752 11.752 0 00-2.846-.365c-.847.732-1.998 1.22-3.21 1.22-1.21 0-2.36-.427-3.208-1.22h0c-.97 0-1.938.122-2.847.366-2.906.793-5.45 2.989-5.45 7.259 0 .793.061 1.525.122 2.135h2.422zM17.497 12.605c0 .826-.66 1.475-1.5 1.475-.841 0-1.501-.649-1.501-1.475 0-.826.66-1.475 1.5-1.475s1.501.649 1.501 1.475zm4.202-1.475c-.84 0-1.5.649-1.5 1.475 0 .826.66 1.475 1.5 1.475s1.5-.649 1.5-1.475c0-.826-.66-1.475-1.5-1.475zm-11.405 0c-.84 0-1.501.649-1.501 1.475 0 .826.66 1.475 1.5 1.475.841 0 1.501-.649 1.501-1.475 0-.826-.66-1.475-1.5-1.475zm12.305-7.08h-1.14v2.36h1.14c1.681 0 3.002 1.298 3.002 2.95v6.49c0 1.652-1.32 2.95-3.002 2.95h-1.14 0v3.6l-5.103-3.6H9.393c-1.68 0-3.001-1.298-3.001-2.95V9.36c0-1.652 1.32-2.95 3.001-2.95h7.984V4.05H9.393c-3.001 0-5.402 2.36-5.402 5.31v6.49c0 2.95 2.4 5.31 5.402 5.31h6.243l8.224 5.841v-5.959a5.287 5.287 0 004.142-5.192V9.36c0-2.95-2.401-5.31-5.403-5.31z" />
    </g>
  </svg>
);

export default SvgPolizeiUndZeugen;
