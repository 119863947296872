import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { SvgAngehaengteDokumente } from '../../../helpers/IconLoader';
import styles from './styles.jss';

const Input = ({
  name, text, accept, classes, onChange,
}) => (
  <label htmlFor="file" className={classes.label}>
    <span className={classes.icon}>
      <SvgAngehaengteDokumente />
    </span>
    <span className={classes.text}>{text}</span>
    <input type="file" name={name} title={text} accept={accept} onChange={onChange} className={classes.input} />
  </label>
);

Input.propTypes = {
  name: PropTypes.string,
  text: PropTypes.string,
  onChange: PropTypes.func,
  accept: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
};

Input.defaultProps = {
  name: '',
  text: '',
  accept: '',
  classes: {},
  onChange: () => {},
};

export { Input as InputPlain };
export default injectSheet(styles)(Input);
