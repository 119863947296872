/* eslint-disable max-len */
import React from 'react';

function SvgDoktorhut(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g fill="none" fillRule="evenodd">
        <g
          transform="translate(3.563 10.125)"
          fill="#1D1D1B"
          fillRule="nonzero"
        >
          <circle cx={36.709} cy={24.589} r={1.686} />
          <path d="M40.22 9.071L21.029.304a1.313 1.313 0 00-1.087 0L.866 8.95a1.313 1.313 0 00-.033 2.374l7.792 3.8v7.938c0 3.136 4.21 4.865 11.856 4.865 3.039 0 5.893-.35 8.03-.988 2.697-.803 4.063-1.988 4.063-3.523v-8.348l2.954-1.395V21.7a3.296 3.296 0 012.37 0v-9.137l2.338-1.104a1.313 1.313 0 00-.017-2.38l.002-.007zM20.48 2.94l16.082 7.344-16.186 7.647L4.5 10.189 20.48 2.94zm9.471 20.406c-.39.602-3.532 1.955-9.468 1.955-6.299 0-9.233-1.337-9.233-2.238v-6.657l8.537 4.163a1.313 1.313 0 001.138.007l9.026-4.264v7.034z" />
        </g>
        <path d="M0 0h47.835v47.835H0z" />
      </g>
    </svg>
  );
}

export default SvgDoktorhut;
