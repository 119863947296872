/* eslint-disable max-len */
import React from 'react';

function SvgKontaktdatenDesUnfallbeteiligten(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      style={{
        background: '#fff',
      }}
      {...props}
    >
      <g fill="currentColor" fillRule="nonzero">
        <path d="M20.804 4.246c.68 0 1.201.516 1.201 1.19v9.126c0 .674-.52 1.19-1.2 1.19h-1.301v-1.587h.9V5.833H7.598v.914H5.997v-1.31c0-.647.478-1.148 1.116-1.188l.085-.003h13.606z" />
        <path d="M16.402 9.835v8.332H3.596V9.835h12.806zM3.196 8.248c-.68 0-1.201.516-1.201 1.19v9.126c0 .674.52 1.19 1.2 1.19h13.607c.68 0 1.201-.516 1.201-1.19V9.438c0-.674-.52-1.19-1.2-1.19H3.195zm10.445 3.055l-3.642 3.412-3.682-3.412h-1.08v1.15l3.681 3.413a1.553 1.553 0 002.162 0l3.681-3.412v-1.15h-1.12z" />
      </g>
    </svg>
  );
}

export default SvgKontaktdatenDesUnfallbeteiligten;
