const styles = theme => ({
  preview: {
    width: '100%',
    height: '100px',
    display: 'flex',
    justifyContent: 'space-evenly',
    padding: '25px 16px',
    borderRadius: '9px',
    backgroundColor: theme.colors.themeWhite,
    boxShadow: '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)',
  },
  closeButton: {
    position: 'absolute',
    top: 18,
    right: 12,
    background: theme.colors.themeWhite,
    width: '14%',
    margin: 0,
    padding: 0,
    borderRadius: 0,
  },
  fileWrap: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '21px',
  },
  fileName: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  svg: {},
});

export default styles;
