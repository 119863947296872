import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import styles from './styles.jss';
import * as IconLoader from '../../helpers/IconLoader';

const Checklist = ({ columns, classes, iconClass }) => {
  if (!columns.length) {
    return null;
  }

  const generateColumn = (column, customClass = '') => (
    column.map(({ icon, label }) => {
      const Icon = IconLoader[icon];
      const iconStyle = iconClass ? classes[iconClass] : '';
      return (
        <div key={label} className={`${classes.item} ${customClass}`}>
          <Icon className={iconStyle} />
          <span className={classes.text}>{label}</span>
        </div>
      );
    })
  );

  return (
    <div className={classes.container}>

      <div className="esc_grid">
        <div className={`esc_grid__wrapper ${classes.wrapper}`}>
          <div className="esc_col-12 esc_col-s-6">
            {generateColumn(columns[0])}
          </div>
          <div className="esc_col-12 esc_col-s-6">
            {generateColumn(columns[1], `${classes.rightColItem}`)}
          </div>
        </div>
      </div>
    </div>
  );
};

Checklist.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  columns: PropTypes.arrayOf(PropTypes.array),
  iconClass: PropTypes.string,
};

Checklist.defaultProps = {
  columns: [],
  classes: {},
  iconClass: '',
};

export { Checklist as ChecklistPlain };
export default injectSheet(styles)(Checklist);
