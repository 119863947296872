/* eslint-disable max-len */
import React from 'react';

function SvgGutachten(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g>
          <rect id="Rectangle" fill="#1D1D1B" fillRule="nonzero" x="15.669375" y="20.90625" width="6.57" height="2.625" />
          <rect id="Rectangle" fill="#1D1D1B" fillRule="nonzero" x="15.67125" y="26.180625" width="13.14" height="2.625" />
          <rect id="Rectangle" fill="#1D1D1B" fillRule="nonzero" x="15.673125" y="31.45875" width="13.14" height="2.625" />
          <path d="M40.5825,26.6925 L29.030625,40.933125 L24.41625,35.360625 L22.35375,37.065 L27.669375,43.485 C28.0048442,43.8942471 28.505211,44.1327461 29.034375,44.135625 L29.034375,44.135625 C29.5670642,44.13123 30.0693157,43.8866494 30.40125,43.47 L42.526875,28.520625 L42.643125,28.37625 L40.5825,26.6925 Z" id="Path" fill="#1D1D1B" fillRule="nonzero" />
          <path d="M34.46625,15.915 L34.485,15.8925 L25.250625,7.98375 L25.250625,7.98375 L18.34875,8.00625 C18.1596201,8.34853033 17.9150546,8.65708841 17.625,8.919375 C17.3459533,9.16276197 17.0054632,9.32490011 16.640625,9.388125 C16.640625,9.530625 16.651875,9.684375 16.651875,9.85125 C16.6568924,10.112527 16.6431068,10.3738264 16.610625,10.633125 L17.735625,10.633125 L22.6875,10.61625 L22.6875,16.168125 C22.6895527,17.4667463 23.7401373,18.5198443 25.03875,18.525 L31.891875,18.525 L31.891875,34.6125 L34.505625,31.39875 L34.505625,15.916875 L34.46625,15.915 Z M25.27875,15.915 L25.27875,11.525625 L30.405,15.916875 L25.27875,15.915 Z" id="Shape" fill="#1D1D1B" fillRule="nonzero" />
          <path d="M12.6,38.58 L12.6,18.973125 C11.6523964,18.8147067 10.7574026,18.4286561 9.991875,17.848125 L9.991875,38.593125 L9.991875,38.593125 C9.991875,39.88875 11.053125,41.184375 12.350625,41.184375 L23.51625,41.184375 L21.36,38.578125 L12.6,38.58 Z" id="Path" fill="#1D1D1B" fillRule="nonzero" />
          <polygon id="Path" fill="#1D1D1B" fillRule="nonzero" points="8.533125 16.254375 12.658125 16.254375 12.658125 13.276875 15.635625 13.276875 15.635625 9.15 12.658125 9.15 12.658125 6.1725 8.533125 6.1725 8.533125 9.15 5.555625 9.15 5.555625 13.276875 8.533125 13.276875" />
          <rect id="Rectangle" x="0" y="0" width="48" height="48" />
        </g>
      </g>
    </svg>
  );
}

export default SvgGutachten;
