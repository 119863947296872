/* eslint-disable max-len */
import React from 'react';

function SvgSchaden(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g id="icons/07_damage/claim/48" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="ERGO_Icon_black_Schaden">
          <path d="M39.62625,29.919375 C39.3902109,29.50744 38.9516432,29.253457 38.476875,29.25375 L29.4375,29.23875 L32.89875,13.321875 C33.023819,12.746019 32.7548598,12.1565485 32.2379469,11.8736112 C31.721034,11.590674 31.0795432,11.6818009 30.661875,12.0975 L19.100625,23.625 L10.359375,8.604375 C10.0619132,8.07562545 9.44384062,7.8158122 8.85794061,7.97323137 C8.2720406,8.13065054 7.86740845,8.66524333 7.87489496,9.271875 L7.87489496,37.145625 L10.539375,37.145625 L10.539375,14.195625 L16.963125,25.228125 C17.3560821,25.8843868 18.0343118,26.3181262 18.7948792,26.3995629 C19.5554466,26.4809996 20.3101324,26.2006877 20.833125,25.6425 L29.334375,17.165625 L26.791875,28.861875 C26.6084016,29.589118 26.7699497,30.3603354 27.2298656,30.9528067 C27.6897815,31.545278 28.3968496,31.8930263 29.146875,31.895625 L36.1725,31.906875 L29.409375,43.599375 L31.71,44.930625 L39.624375,31.243125 C39.8606501,30.833683 39.8613642,30.3294846 39.62625,29.919375 Z" id="Path" fill="#1D1D1B" fillRule="nonzero" />
          <path d="M30.489375,35.535 C30.3251605,34.9694599 29.8088853,34.5790125 29.22,34.575 L24.5625,34.550625 C24.2358422,34.5484962 23.9282979,34.396311 23.7284554,34.1379067 C23.5286129,33.8795023 23.4586612,33.5435698 23.53875,33.226875 L24.52875,29.30625 C24.6661536,28.7317724 24.4080166,28.135531 23.8950816,27.8426113 C23.3821466,27.5496916 22.7374515,27.6303563 22.3125,28.040625 L19.51875,31.010625 C19.2970777,31.232651 18.9885244,31.3452131 18.6759576,31.3180805 C18.3633908,31.290948 18.0788487,31.126902 17.89875,30.87 L15.811875,27.710625 C15.4384334,27.18107 14.7382917,26.9967389 14.1525,27.27375 C13.6836943,27.511709 13.3923135,27.996858 13.4025,28.5225 L13.4025,37.168125 L16.051875,37.168125 L16.051875,32.679375 L17.034375,34.07625 C17.3256199,34.5259574 17.8068833,34.8174038 18.3403508,34.8671307 C18.8738182,34.9168576 19.4006564,34.7193809 19.77,34.33125 L20.776875,33.324375 L20.353125,35.000625 C20.2155595,35.5244096 20.3283331,36.0823744 20.6585209,36.5116185 C20.9887087,36.9408627 21.4990785,37.1929843 22.040625,37.194375 L26.88375,37.220625 L24.66375,40.94625 L26.945625,42.305625 L30.335625,36.616875 C30.535285,36.2933914 30.5910066,35.9013014 30.489375,35.535 L30.489375,35.535 Z" id="Path" fill="#1D1D1B" fillRule="nonzero" />
          <rect id="Rectangle" x="0" y="0" width="47.835" height="47.835" />
        </g>
      </g>
    </svg>
  );
}

export default SvgSchaden;
