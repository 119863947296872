/* eslint-disable max-len */
import React from 'react';

const SvgVorfallLiability = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      d="M4.597 8.697l2.958 5.405c.425.777 1.492.91 2.097.261l5.15-5.516-1.744 7.05a1.294 1.294 0 001.131 1.595l4.31.437-2.34 3.753h2.803l2.846-4.148a1.292 1.292 0 00-.974-1.968l-4.892-.496 2.586-10.454c.32-1.296-1.305-2.166-2.218-1.188l-7.334 7.857-4.537-8.29c-.644-1.178-2.444-.723-2.444.618v13.88l2.602 1.404v-10.2z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

export default SvgVorfallLiability;
