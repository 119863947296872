import { mediaQuery } from '../../styles/helpers';
import { breakpoints } from '../../styles/constants'; // eslint-disable-line import/prefer-default-export

const styles = theme => ({
  label: {
    color: theme.primaryFontColor,
    marginTop: theme.inputMargin,
    display: 'inline-block',
    position: 'relative',
    margin: '0 auto',
    borderBottom: `2px solid ${theme.thirdBasicColor}`,
    height: '80px',
    paddingLeft: '8px',
    paddingRight: '8px',
    boxSizing: 'border-box',
    '&:focus': {
      borderBottomColor: theme.thirdAccentColor,
    },

    ...mediaQuery.max(breakpoints.desktopNav, {
      padding: '0 8px',
      maxWidth: theme.gridBigMobile,
      margin: '0 auto',
    }),
  },
  labelText: {
    position: 'relative',
    zIndex: 10,
    top: 0,
    left: 0,
    marginLeft: 0,
    textAlign: 'left',
    lineHeight: '32px',
    fontWeight: '800',
    opacity: '1',
    transition: `all 0.4s ${theme.easeInCubic}`,
    fontSize: theme.inputFontSize,

    ...mediaQuery.max(breakpoints.desktopNav, {
      fontSize: '20px',
    }),
  },
  placeholderLabel: {
    margin: 0,
    position: 'relative',
    zIndex: 10,
    top: `calc(${theme.inputMargin} + 6px + 32px)`,
    left: 0,
    pointerEvents: 'none',
    fontSize: theme.inputFontSize,
    fontWeight: '400',
    transform: 'translate(0%,0) scale(1)',
    opacity: theme.inActiveOpacity,

    ...mediaQuery.max(breakpoints.desktopNav, {
      fontSize: '20px',
    }),
  },
  topLabel: {
    transform: 'translate(-25%,0) scale(0.5)',
    left: '-8px',
    margin: 0,
    position: 'relative',
    zIndex: 10,
  },
  labelPushRight: {
    marginLeft: '72px',

    ...mediaQuery.max(breakpoints.desktopNav, {
      marginLeft: '48px',
    }),
  },
  input: {
    borderTop: 'none',
    borderRight: 'none',
    borderBottom: 'none',
    borderLeft: 'none',
    fontSize: theme.inputFontSize,
    marginTop: theme.inputMargin,
    marginBottom: theme.inputMargin,
    outline: 'none',
    width: '100%',
    padding: theme.inputPadding,
    color: theme.secondaryFontColor,
    lineHeight: '32px',
    borderStyle: 'none none solid',
    letterSpacing: '0.2px',
    display: 'flex',
    margin: '0 auto',
    backgroundColor: theme.secondaryBasicColor,

    ...mediaQuery.max(breakpoints.desktopNav, {
      fontSize: '18px',
      padding: theme.inputPadding,
    }),
  },
  xlarge: {
    width: '100%',
    textAlign: 'left',
  },
  large: {
    width: '75%',
    textAlign: 'left',
  },
  medium: {
    width: '50%',
    textAlign: 'left',
    ...mediaQuery.max(breakpoints.mediumMobile, {
      width: '100%',
    }),
  },
  small: {
    width: '25%',
    textAlign: 'left',
  },
  addressLg: {
    width: '100%',
    textAlign: 'left',
    margin: '0 0 35px 0',

    ...mediaQuery.min(breakpoints.desktopNav, {
      width: '66%',
      display: 'inline-block',
    }),
  },
  addressSm: {
    width: '45%',
    display: 'inline-block',
    margin: 0,

    ...mediaQuery.min(breakpoints.desktopNav, {
      width: '28%',
    }),
  },
  mockPlaceholder: {
    textAlign: 'left',
    position: 'relative',
    top: '68px',
    left: '4px',
    color: theme.forthBasicColor,
    opacity: theme.inActiveOpacity,
    fontSize: theme.inputFontSize,

    ...mediaQuery.max(breakpoints.desktopNav, {
      fontSize: '18px',
      top: '65px',
    }),
  },
  inputBorderBox: {
    height: '2px',
    margin: '-2px auto 0',
  },
  alignLeft: {
    textAlign: 'left',
  },
  error: {
    borderBottomColor: theme.validationErrorFontColor,
  },
});

export default styles;
