import { CustomerDataService } from '../services';
import { types } from './constants';
import { fieldName } from '../models/panels/claimNumberSelector';
import { moduleNames } from '../services/claimData/claimData';
import {
  customerDataUrl,
  buildContactData,
  buildClaimNumber,
  buildAddressData,
  buildSpecialClaimNumber,
} from '../helpers/customerData';

export const setCustomerData = (pathName, name, payload) => ({
  type: types.FIELD_UPDATE,
  fieldName: name,
  pathName,
  payload,
});

export const getCustomerData = (pathName, searchData) => async (dispatch) => {
  const preFillQuestions = (urls, data) => {
    urls.forEach(url => {
      dispatch(setCustomerData(pathName, url, data));
    });
  };

  try {
    const { data } = await CustomerDataService.create({ data: searchData, moduleName: pathName });

    switch (pathName) {
      case moduleNames.VEHICLE: {
        preFillQuestions(customerDataUrl[moduleNames.VEHICLE].contact, buildContactData(data));
        preFillQuestions(customerDataUrl[moduleNames.VEHICLE].specialClaimNumber, buildSpecialClaimNumber(data));
        break;
      }
      case moduleNames.PRIVATE_LIABILITY: {
        preFillQuestions(customerDataUrl[moduleNames.PRIVATE_LIABILITY].contact, buildContactData(data));
        preFillQuestions(customerDataUrl[moduleNames.PRIVATE_LIABILITY].claimNumber, buildClaimNumber(data));
        break;
      }
      case moduleNames.LEGAL_PROTECTION: {
        preFillQuestions(customerDataUrl[moduleNames.LEGAL_PROTECTION].contact, buildContactData(data));
        preFillQuestions(customerDataUrl[moduleNames.LEGAL_PROTECTION].claimNumber, buildClaimNumber(data));
        break;
      }
      case moduleNames.ACCIDENT: {
        preFillQuestions(customerDataUrl[moduleNames.ACCIDENT].contact, buildContactData(data));
        preFillQuestions(customerDataUrl[moduleNames.ACCIDENT].claimNumber, buildClaimNumber(data));
        break;
      }
      case moduleNames.PROPERTY: {
        preFillQuestions(customerDataUrl[moduleNames.PROPERTY].contact, buildContactData(data));
        preFillQuestions(customerDataUrl[moduleNames.PROPERTY].claimNumber, buildClaimNumber(data));
        preFillQuestions(customerDataUrl[moduleNames.PROPERTY].claimNumbers, { policyNumber: buildClaimNumber(data) });
        break;
      }
      case moduleNames.UPLOAD: {
        dispatch(setCustomerData(pathName, fieldName.CLAIM_NUMBER, buildClaimNumber(data).toUpperCase()));
        break;
      }
      case moduleNames.COLLISION_ABROAD: {
        preFillQuestions(customerDataUrl[moduleNames.COLLISION_ABROAD].contact, buildContactData(data));
        preFillQuestions(customerDataUrl[moduleNames.COLLISION_ABROAD].address, buildAddressData(data));
        break;
      }
      default:
        break;
    }
  } catch (err) {
    console.log('ERROR: ', err);
  }
};
