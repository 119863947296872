/* eslint-disable max-len */
// eslint-disable-next-line import/prefer-default-export
export const logoIcon = `<path
fill="#BF1528"
d="M89.597 43.614c-14.02 0-20.943-9.628-20.943-21.417 0-13.265 9.41-22.013 21.715-22.013 6.055 0 9.954.996 13.398
2.66l-3.488 8.016c-2.648-1.089-5.693-1.728-9.293-1.728-9.013 0-12.077 5.398-12.077 12.795 0 7.982 4.099 13.2 11.27
13.2 2.575 0 4.526-.547 5.197-.983v-7.553h-7.2l.011-8.14h16.222l.01 21.282c-2.505 1.654-7.722 3.88-14.822
3.88M.114.876H28.78l.006 8.775H9.732v7.454H25.86l.01 8.318H9.731v4.631c0 2.907 1.73 4.152 3.967
4.15l15.867-.001.011 8.719H9.981c-7.313 0-9.867-3.338-9.867-9.905V.876m128.9 7.863c-7.023 0-10.693 6.022-10.693
13.208 0 8.086 4.53 13.214 10.693 13.214 7.74 0 10.697-7.055 10.697-13.214
0-6.079-2.957-13.208-10.697-13.208zm-.168 34.917c-11.74 0-20.472-8.319-20.472-21.709 0-14.375 10.367-21.763
20.866-21.763 10.068 0 20.471 7.002 20.471 21.763 0 12.644-8.445 21.709-20.865 21.709zM53.75
10.269c-1.307-1.007-3.072-1.515-5.21-1.546a55.566 55.566 0 0
0-1.706.025c-.543.016-1.996.146-2.541.244v12.241c.756.091 2.119.151 3.23.151 2.37 0 4.595-.635 6-1.742 1.432-1.128
2.179-2.963 2.179-5.141 0-1.798-.659-3.236-1.953-4.232zm2.733 32.655c-1.407-5.063-3.74-8.916-5.67-11.387 0
0-1.658-2.116-2.966-2.577a43.589 43.589 0 0 1-3.554-.027v13.989h-9.618V1.289c2.018-.324 3.491-.5 5.629-.7A73.019
73.019 0 0 1 47.06.285c3.31 0 6.116.364 8.417 1.089 2.303.726 4.169 1.728 5.631 2.977a11.42 11.42 0 0 1 3.207
4.531 14.487 14.487 0 0 1 1.035 5.381c0 2.519-.618 4.955-1.958 7.039-1.194 1.85-3.201 3.753-5.332 5.091v.315c4.655
2.122 7.285 8.85 9.842 16.217h-11.42z"
/>`;

export const uploadFileIcon = '<path fill="#8E0038" d="M21.5526316,6.91052632 L14.5736842,15.6684211 C14.1631579,16.1473684 13.6157895,16.4210526 13,16.4210526 C13,16.4210526 13,16.4210526 13,16.4210526 C12.3842105,16.4210526 11.8368421,16.1473684 11.4263158,15.6684211 L4.51578947,6.91052632 L8.00526316,6.91052632 L11.6315789,11.4947368 L11.6315789,0 L14.3684211,0 L14.3684211,11.4947368 L17.9947368,6.91052632 L21.5526316,6.91052632 Z M26,20.5263158 L26,15.7368421 L23.2631579,15.7368421 L23.2631579,20.5263158 L2.73684211,20.5263158 L2.73684211,15.7368421 L0,15.7368421 L0,20.5263158 C0,22.0315789 1.23157895,23.2631579 2.73684211,23.2631579 L23.2631579,23.2631579 C24.7684211,23.2631579 26,22.0315789 26,20.5263158 Z" />';

export const personIcon = '<path fill="#8E0038" d="M13.1970075,15.5012469 C8.93765586,15.5012469 5.44638404,12.0099751 5.44638404,7.75062344 C5.44638404,3.49127182 8.93765586,0 13.1970075,0 C17.4563591,0 20.9476309,3.42144638 20.9476309,7.75062344 C20.9476309,12.0099751 17.4563591,15.5012469 13.1970075,15.5012469 Z M13.1970075,2.72319202 C10.4738155,2.72319202 8.2394015,4.95760599 8.2394015,7.680798 C8.2394015,10.40399 10.4738155,12.638404 13.1970075,12.638404 C15.9201995,12.638404 18.1546135,10.4738155 18.1546135,7.75062344 C18.1546135,4.95760599 15.9201995,2.72319202 13.1970075,2.72319202 Z M2.93266833,28 C2.86284289,27.3715711 2.79301746,26.5336658 2.79301746,25.5561097 C2.79301746,23.8802993 3.42144638,20.0399002 8.72817955,19.6907731 C8.72817955,19.6907731 8.72817955,19.6907731 8.72817955,19.6907731 C8.72817955,19.6907731 8.72817955,19.6907731 8.72817955,19.6907731 C10.0548628,20.5286783 11.5910224,21.0174564 13.2668329,21.0174564 C14.9426434,21.0174564 16.478803,20.5286783 17.8054863,19.6907731 C17.8054863,19.6907731 17.8054863,19.6907731 17.8054863,19.6907731 C17.8054863,19.6907731 17.8054863,19.6907731 17.8054863,19.6907731 C23.1122195,20.0399002 23.7406484,23.8802993 23.7406484,25.5561097 C23.7406484,26.5336658 23.6708229,27.3017456 23.6009975,28 L26.394015,28 C26.4638404,27.3017456 26.5336658,26.4638404 26.5336658,25.5561097 C26.5336658,20.6683292 23.6009975,18.2244389 20.2493766,17.2468828 C19.1321696,16.967581 18.0149626,16.8279302 16.967581,16.8279302 C15.9900249,17.6658354 14.6633416,18.2244389 13.2668329,18.2244389 C11.8703242,18.2244389 10.5436409,17.7356608 9.56608479,16.8279302 L9.56608479,16.8279302 L9.56608479,16.8279302 C8.44887781,16.8279302 7.33167082,16.967581 6.28428928,17.2468828 C2.93266833,18.1546135 0,20.6683292 0,25.5561097 C0,26.4638404 0.0698254364,27.3017456 0.139650873,28 L2.93266833,28 Z" id="path-1"></path>';

export const homeIcon = '<path fill="#8E0038" d="M23.8591549,6.11267606 L21.2300469,3.94366197 L21.2300469,0 L23.8591549,0 L23.8591549,6.11267606 Z M14,0.920187793 L0,12.8169014 L1.70892019,14.7887324 L14,4.33802817 L26.2910798,14.7887324 L28,12.8169014 L14,0.920187793 Z M21.2300469,23.6619718 L6.76995305,23.6619718 L6.76995305,13.5399061 L4.14084507,16.0375587 L4.14084507,26.2910798 L23.8591549,26.2910798 L23.8591549,15.971831 L21.2300469,13.4741784 L21.2300469,23.6619718 Z" id="path-1"></path>';

export const carIcon = `<path id="path-1_1_" fill="#8E0038" class="st3" d="M24.7,3.4c0.3-3-2.2-5.4-5.5-4.9l-0.5,0.1l-0.2-0.5c-0.7-2-3-3.3-6.4-3
c-0.4,0-0.7,0.1-1.4,0.2c-0.1,0-0.1,0-0.1,0C7.9-4.2,6.3-4.5,3.7-6.6l0.8-1.1c2.3,1.8,3.5,2,5.8,1.6c0.1,0,0.1,0,0.1,0
c0.7-0.1,1.1-0.2,1.5-0.2c3.7-0.3,6.5,1.2,7.5,3.4c4.3-0.3,7.4,3.1,6.4,7.3c0.8,1,1.1,2.5,0.8,3.9c-0.2,1-0.8,1.8-1.6,2.1
l-0.4-1.4c0.4-0.2,0.7-0.6,0.9-1.2c0.3-1.2-0.3-2.6-1.2-3.1c-0.1-0.1-0.3-0.1-0.4-0.2l0.2-1.4C24.3,3.2,24.5,3.3,24.7,3.4
z M15.2,1.9c0-0.5,0-1,0.2-1.5c0.1-0.3,0.2-0.6,0.3-0.8l1.2,0.7c-0.1,0.2-0.1,0.3-0.2,0.5c-0.4,1.6,0.4,3.5,1.7,4.3
c0,0,0,0,0,0c0.2-0.5,0.5-0.9,0.9-1.2l1,1.1c-0.2,0.2-0.4,0.5-0.5,0.8c-0.2,0.8,0,1.7,0.5,2.1l-1,1.1
c-0.7-0.6-1.1-1.5-1.1-2.4c-0.1,0-0.2-0.1-0.3-0.1c-1.1-0.6-2-1.7-2.4-3c-1.6,0.8-3.7,0.7-5.7-0.3c-1.6-0.8-3-2-3.9-3.4
L7-1.2C7.8,0.1,9,1.1,10.3,1.7C12.2,2.6,14,2.6,15.2,1.9L15.2,1.9z M5,16.9h10.3c0.3-1.7,1.8-2.9,3.5-2.9
c1.5,0,2.8,0.9,3.4,2.3h3.2v-2.6l-0.5-1.6c-0.1,0-0.1,0-0.2-0.1c-0.4-0.1-0.9-0.3-1.5-0.4c-1.6-0.4-3.3-0.9-5.2-1.3
c-0.3-0.1-0.7-0.1-1-0.2c-0.2,0-0.3-0.1-0.4-0.2c-1.5-1.8-4.3-3.2-5.7-3.2H5.3C4.7,6.7,3.9,6.9,3,7.3
C2.3,7.5,1.5,7.9,0.7,8.4C0,8.8-1.9,10-2,10c-0.1,0.1-0.2,0.1-0.3,0.1h-2.1l0,2.7c0,0.1,0,0.2-0.1,0.3l-0.6,1.2v1.2
c0,0.4,0.3,0.7,0.7,0.7h2.5C-1.3,15,0,14,1.5,14C3.2,14,4.7,15.3,5,16.9z M5,18.4c-0.3,1.7-1.8,2.9-3.5,2.9
c-2,0-3.6-1.6-3.6-3.6h-2.3c-1.1,0-2.1-0.9-2.1-2v-1.4c0-0.1,0-0.2,0.1-0.3l0.6-1.2l0-2.6c0-0.8,0.6-1.4,1.4-1.4h1.9
C-2.1,8.5-0.7,7.6,0,7.2C0.9,6.7,1.7,6.3,2.5,6c1-0.4,2-0.6,2.8-0.6h5.5c1.8,0,4.8,1.5,6.6,3.5C17.7,8.9,18,8.9,18.3,9
c1.9,0.4,3.7,0.8,5.3,1.3c0.6,0.2,1.1,0.3,1.5,0.4c0.3,0.1,0.4,0.1,0.5,0.2c0.2,0.1,0.4,0.2,0.5,0.4l0.7,2
c0,0.1,0,0.1,0,0.2V17c0,0.4-0.3,0.7-0.7,0.7h-3.6c0,2-1.6,3.6-3.6,3.6c-1.7,0-3.2-1.2-3.5-2.9H5z M16.7,17.6v0.1
c0,1.2,1,2.1,2.2,2.1c1.2,0,2.2-1,2.2-2.2s-1-2.2-2.2-2.2C17.7,15.5,16.7,16.4,16.7,17.6z M7.6,11.9H0.5
c-0.2,0-0.4-0.1-0.5-0.2l-0.7-0.8l1-1.1l0.5,0.6H6L5.1,8l1.3-0.5l1.1,2.7H8v0.2h5.3l1.3-0.6l0.6,1.2l-1.4,0.7
c-0.1,0-0.2,0.1-0.3,0.1H7.6z M1.5,19.8c1.2,0,2.2-1,2.2-2.2s-1-2.2-2.2-2.2s-2.2,1-2.2,2.2S0.3,19.8,1.5,19.8z"/>`;
