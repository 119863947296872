import { addSegments } from '../../../helpers/flow/flow';
import flow from './main.yaml';

import uploadDocumentsAccident from './segments/upload-documents-accident.yaml';
import uploadDocumentsLegalProtection from './segments/upload-documents-legal-protection.yaml';
import uploadDocumentsVehicle from './segments/upload-documents-vehicle.yaml';
import uploadDocumentsPrivateLiability from './segments/upload-documents-private-liability.yaml';
import uploadDocumentsProperty from './segments/upload-documents-property.yaml';

const segments = {
  uploadDocumentsAccident,
  uploadDocumentsLegalProtection,
  uploadDocumentsVehicle,
  uploadDocumentsPrivateLiability,
  uploadDocumentsProperty,
};

export default addSegments(flow, segments);
