/* eslint-disable max-len */
import React from 'react';

function SvgPhotoCopy4(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g id="Photo" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Photo-Copy-4" fillRule="nonzero">
          <rect id="Rectangle" fill="#FFFFFF" opacity="0" x="0" y="0" width="48" height="48" />
          <g id="Group" transform="translate(3.995000, 8.874268)" fill="#333333">
            <path d="M31.2624305,0.974826058 C30.1629327,1.66863641 29.2914308,2.69090197 28.7857174,3.90383026 L2.92756098,3.90341463 L2.92756098,22.912068 L12.9692255,7.80682927 L20.2727989,18.7940125 L24.7082973,12.4840786 L35.0367898,27.1773782 C35.0706011,27.225478 35.1019362,27.2745761 35.130815,27.324521 L36.1065854,27.3239024 L36.1074041,12.5161721 C37.2271582,12.251509 38.2322129,11.6900618 39.0349461,10.9194523 L39.0341463,28.2997561 C39.0341463,29.3776543 38.1603372,30.2514634 37.082439,30.2514634 L1.95170732,30.2514634 C0.873809129,30.2514634 0,29.3776543 0,28.2997561 L0,2.92756098 C0,1.84966279 0.873809129,0.975853659 1.95170732,0.975853659 L31.2624305,0.974826058 Z M13.1408227,13.6619512 L3.92170633,27.3239024 L31.3254527,27.3239024 L24.6578008,17.9800111 L20.2360745,24.1765147 L13.1408227,13.6619512 Z M34.6428049,1.95170732 C37.0680758,1.95170732 39.0341463,3.91777786 39.0341463,6.34304878 C39.0341463,8.7683197 37.0680758,10.7343902 34.6428049,10.7343902 C32.2175339,10.7343902 30.2514634,8.7683197 30.2514634,6.34304878 C30.2514634,3.91777786 32.2175339,1.95170732 34.6428049,1.95170732 Z M34.6428049,4.87926829 C33.8343813,4.87926829 33.1790244,5.53462514 33.1790244,6.34304878 C33.1790244,7.15147242 33.8343813,7.80682927 34.6428049,7.80682927 C35.4512285,7.80682927 36.1065854,7.15147242 36.1065854,6.34304878 C36.1065854,5.53462514 35.4512285,4.87926829 34.6428049,4.87926829 Z" id="Combined-Shape" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgPhotoCopy4;
