import { useState, useEffect, useRef } from 'react';

const useRect = () => {
  const ref = useRef();
  const [position, setRect] = useState({});

  const set = () => setRect(ref && ref.current ? ref.current.getBoundingClientRect() : {});

  useEffect(() => {
    set();
    window.addEventListener('resize', set);
    return () => window.removeEventListener('resize', set);
  }, []);

  return [position, ref];
};

export default useRect;
