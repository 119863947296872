/* eslint-disable max-len */
import React from 'react';

function SvgTuer(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g id="icons/07_damage/Tuer/48" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <polygon id="Path" fill="#1D1D1C" fillRule="nonzero" points="21.8578824 27.3008241 20.7811765 27.3008241 20.7811765 28.9554124 19.1171765 28.9554124 19.1171765 24.5507065 20.7811765 24.5507065 20.7811765 25.1097653 21.8578824 25.1097653 21.8578824 23.4758829 18.0423529 23.4758829 18.0423529 30.0302359 21.8578824 30.0302359" />
        <rect id="Rectangle" fill="#1D1D1C" fillRule="nonzero" x="19.6762353" y="25.6669418" width="3.83623529" height="1.07670588" />
        <path d="M14.7331765,13.4316477 L9.26494118,13.4316477 L9.26494118,22.1751771 L14.7237647,22.1751771 L14.7331765,13.4316477 Z M13.6564706,21.1041182 L10.3491765,21.1041182 L10.3491765,14.5158829 L13.6564706,14.5158829 L13.6564706,21.1041182 Z" id="Shape" fill="#1D1D1C" fillRule="nonzero" />
        <rect id="Rectangle" fill="#1D1D1C" fillRule="nonzero" x="10.9082353" y="19.4608241" width="2.19105882" height="1.07670588" />
        <rect id="Rectangle" fill="#1D1D1C" fillRule="nonzero" x="11.4654118" y="15.6132947" width="1.07670588" height="2.74070588" />
        <rect id="Rectangle" fill="#1D1D1C" fillRule="nonzero" x="9.81270588" y="22.7474124" width="1.09552941" height="1.07670588" />
        <rect id="Rectangle" fill="#1D1D1C" fillRule="nonzero" x="11.456" y="22.7474124" width="1.09552941" height="1.07670588" />
        <rect id="Rectangle" fill="#1D1D1C" fillRule="nonzero" x="13.0992941" y="22.7474124" width="1.09552941" height="1.07670588" />
        <path d="M34.4602353,37.2848241 L34.4602353,9.99070648 L17.6395294,9.99070648 C17.164106,9.99170211 16.7086062,10.1817775 16.3734914,10.519011 C16.0383765,10.8562446 15.8511757,11.3129333 15.8531765,11.7883535 L15.8531765,40.0236477 L6.67858824,40.0236477 L1.12941176,47.1765888 L2.44705882,47.1765888 L6.94964706,41.1022359 L42.1929412,41.1022359 L45.8221176,47.1765888 L46.9515294,47.1765888 L42.6917647,40.0236477 L34.4602353,40.0236477 L34.4602353,37.2848241 Z M33.3835294,40.0198829 L16.9242353,40.0198829 L16.9242353,11.0692947 L33.3835294,11.0692947 L33.3835294,40.0198829 Z" id="Shape" stroke="#1D1D1C" strokeWidth="0.941176471" fill="#1D1D1C" fillRule="nonzero" strokeLinejoin="round" />
        <rect id="Rectangle" x="0" y="-0.375" width="48" height="48" />
      </g>
    </svg>
  );
}

export default SvgTuer;
