/* eslint-disable max-len */
import React from 'react';

const SvgHeckscheibe = props => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <g fill="currentColor" fillRule="nonzero">
      <path d="M34.876 10.66a4.758 4.758 0 014.517 3.278l4.52 13.757a1.91 1.91 0 01-1.051 2.35c-6.232 2.704-11.98 4.183-17.245 4.436l2.32-4.031c3.649-.457 7.587-1.531 11.811-3.237l-3.969-12.08a.952.952 0 00-.903-.655H13.124a.952.952 0 00-.903.656L8.25 27.213c3.9 1.574 7.556 2.611 10.966 3.12l-2.03 3.53c-3.793-.706-7.809-1.978-12.049-3.818a1.91 1.91 0 01-1.051-2.35l4.52-13.757a4.758 4.758 0 014.517-3.277h21.752z" />
      <path d="M11.587 18.854l2.695-2.695 1.347 1.348-2.695 2.695zM12.738 22.276l5.39-5.39 1.347 1.348-5.39 5.39zM19.19 34.46l9.766-16.917 2.475 1.43-9.766 16.916a1.43 1.43 0 11-2.476-1.43z" />
    </g>
  </svg>
);

export default SvgHeckscheibe;
