/* eslint-disable max-len */
import React from 'react';

const SvgBett = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
    <g
      id="icons/05_other/Bett/48"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="ERGO_Icon_black_Bett"
        transform="translate(4.875000, 6.937500)"
        fill="#1D1D1B"
        fillRule="nonzero"
      >
        <path
          d="M38.173125,19.515 L35.173125,10.719375 L35.173125,5.638125 L35.173125,5.638125 L35.173125,1.38 C35.173125,0.655126266 34.5854987,0.0675 33.860625,0.0675 L4.48875,0.0675 C3.76387627,0.0675 3.17625,0.655126266 3.17625,1.38 L3.17625,10.5225 L0.24,19.67625 L0.24,19.67625 C0.221488046,19.7377259 0.207081313,19.8003638 0.196875,19.86375 L0.196875,19.90125 C0.187922727,19.9633708 0.182912705,20.0259961 0.181875,20.08875 L0.181875,32.716875 C0.181875,33.4417487 0.769501266,34.029375 1.494375,34.029375 C2.21924873,34.029375 2.806875,33.4417487 2.806875,32.716875 L2.806875,29.971875 L31.978125,29.971875 C32.7029987,29.971875 33.290625,29.3842487 33.290625,28.659375 C33.290625,27.9345013 32.7029987,27.346875 31.978125,27.346875 L2.80125,27.346875 L2.80125,21.39 L35.625,21.39 L35.625,32.64 C35.625,33.3648737 36.2126263,33.9525 36.9375,33.9525 C37.6623737,33.9525 38.25,33.3648737 38.25,32.64 L38.25,19.93875 C38.2481595,19.7942038 38.2221766,19.6509813 38.173125,19.515 Z M5.80125,2.6925 L32.55,2.6925 L32.55,5.049375 L32.55,5.049375 L32.55,6.2475 C32.0219903,5.93696048 31.4327368,5.74502122 30.823125,5.685 L23.323125,5.685 C21.579375,5.685 20.259375,6.170625 19.4025,7.12875 C19.3025484,7.23755213 19.2098489,7.35280005 19.125,7.47375 C18.12,5.7975 16.190625,5.679375 16.164375,5.6775 L8.664375,5.6775 C7.520625,5.6775 6.55875,5.8875 5.79,6.30375 L5.80125,2.6925 Z M32.55,7.995 L32.55,9.41625 L19.753125,9.41625 C19.8204525,8.89187793 20.0394106,8.3985707 20.383125,7.996875 C20.983125,7.329375 21.973125,6.99 23.326875,6.99 L30.774375,6.99 C31.4831481,7.05434478 32.1299966,7.42045966 32.55,7.995 Z M5.80125,7.929375 C6.406875,7.30875 7.36875,6.991875 8.675625,6.991875 L16.125,6.991875 C16.3125,7.003125 18.163125,7.179375 18.39375,9.418125 L5.80125,9.418125 L5.80125,7.929375 Z M3.28875,18.765 L5.446875,12.04125 L32.851875,12.04125 L35.145,18.765 L3.28875,18.765 Z"
          id="Shape"
        />
      </g>
    </g>
  </svg>
);

export default SvgBett;
