import * as R from 'ramda';

export const hashName = (name, id) => `${id}_${name}`;

export const hashObj = (step, segmentId) => R.mapObjIndexed(
  (v, k) => {
    switch (k) {
      case 'name':
        return hashName(v, segmentId);
      case 'options':
      case 'sections':
        return v.map(s => hashObj(s, segmentId));
      case 'exclude':
      case 'include':
        return v.map(name => hashName(name, segmentId));
      default:
        return v;
    }
  },
  step,
);

let section = 'event';

const updateCurrentSection = (step) => {
  section = step.sectionStart ? step.sectionStart : section;
};

export const addSegments = (main, segments) => R.reduce((accumulatedPath, step) => {
  updateCurrentSection(step);
  const updatedStep = { ...step, section };
  if (updatedStep.segment) {
    return R.concat(
      accumulatedPath,
      addSegments(
        segments[updatedStep.segment],
        segments,
      ).map(s => hashObj(s, step.id)),
    );
  }
  return R.append(updatedStep, accumulatedPath);
}, [], Array.isArray(main) ? main : [main]);

export default addSegments;
