// based on https://github.com/intesso/decode-html

const entities = {
  amp: '&',
  apos: '\'',
  lt: '<',
  gt: '>',
  quot: '"',
  nbsp: '\xa0',
};
const entityPattern = /&([a-z]+);/ig;

export default function decodeHTMLEntities(text) {
  if (!text) {
    return '';
  }
  return text.replace(entityPattern, (match, entity) => {
    if (entities[entity.toLowerCase()]) {
      return entities[entity];
    }
    return match;
  });
}
