/* eslint-disable max-len */
import React from 'react';

function SvgParagraph(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M24.054 42.461c-10.32-.004-18.683-8.373-18.68-18.693.003-10.32 8.37-18.684 18.69-18.683 10.32.001 18.686 8.368 18.686 18.688-.011 10.32-8.376 18.681-18.696 18.688zm0-34.75C15.184 7.717 7.996 14.91 8 23.782c.003 8.87 7.196 16.06 16.067 16.057 8.87-.002 16.06-7.194 16.059-16.065-.01-8.87-7.2-16.056-16.07-16.061z"
          fill="#1D1D1B"
          fillRule="nonzero"
        />
        <path
          d="M31.181 24.236c0-3.125-2.299-4.515-5.748-5.64-2.554-.853-4.125-1.455-4.125-3.063 0-1.352 1.366-2.297 3.324-2.297a7.534 7.534 0 013.986 1.06l.25.155.89-2.421-.187-.1c-1.34-.75-3.06-1.16-4.845-1.16-3.874 0-6.58 2.1-6.58 5.111.032 1.063.48 2.18 1.125 2.843-1.5 1.055-2.355 2.512-2.355 4.038 0 3.29 2.897 4.546 6.124 5.642 3.08 1.06 4.02 1.84 4.02 3.338 0 1.562-1.412 2.57-3.598 2.57a7.961 7.961 0 01-4.386-1.36l-.244-.173-1.01 2.402.152.112c1.28.938 3.294 1.485 5.522 1.485 3.36 0 6.75-1.663 6.75-5.38a4.402 4.402 0 00-1.176-3c1.425-1.173 2.111-2.536 2.111-4.162zm-2.98.413A2.929 2.929 0 0127.127 27c-.865-.52-2.295-1.044-3.812-1.579-3.079-1.125-3.475-2.214-3.475-3.032 0-.926.432-1.687 1.352-2.386.91.511 2.19.978 3.326 1.395l.794.292c1.972.694 2.89 1.635 2.89 2.959z"
          fill="#1D1D1B"
          fillRule="nonzero"
        />
        <path d="M0 0h47.835v47.835H0z" />
      </g>
    </svg>
  );
}

export default SvgParagraph;
