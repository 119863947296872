/* eslint-disable max-len */
import React from 'react';

function SvgHaus(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M37.262 7.884h-2.659V5.228h5.316v6.84l-2.657-1.709V7.884zm-21.26 19.799a1.196 1.196 0 01-1.19-1.197v-6.643c0-.66.536-1.196 1.197-1.196h5.315c.661 0 1.197.535 1.197 1.196v6.643c0 .66-.536 1.197-1.197 1.197h-5.323zm1.196-2.391h2.923v-4.253h-2.924v4.253zM4.653 17.396l1.436 2.235L23.974 8.136 41.857 19.63l1.439-2.235-19.313-12.42-19.33 12.42zm32.608 22.378h-7.973v-6.937a2.364 2.364 0 00-2.36-2.36h-5.91a2.364 2.364 0 00-2.36 2.36v6.937h-7.972V19.836l-2.656 1.71v18.526a2.363 2.363 0 002.358 2.36h8.567a2.363 2.363 0 002.36-2.36v-6.937h5.309v6.938a2.363 2.363 0 002.36 2.358h8.57a2.363 2.363 0 002.358-2.358V21.546l-2.657-1.71.006 19.938zM26.625 27.683a1.194 1.194 0 01-1.194-1.197v-6.643c0-.66.534-1.195 1.194-1.196h5.316c.66 0 1.196.535 1.196 1.196v6.643c0 .66-.536 1.197-1.196 1.197h-5.316zm1.196-2.391h2.929v-4.253h-2.923l-.006 4.253z"
          fill="#1D1D1B"
          fillRule="nonzero"
        />
        <path d="M0 0h47.835v47.835H0z" />
      </g>
    </svg>
  );
}

export default SvgHaus;
