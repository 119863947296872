/* eslint-disable max-len */
import React from 'react';

function SvgDokumente(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#1D1D1B"
          fillRule="nonzero"
          d="M10.258 18.593h6.758v2.813h-6.758zM10.258 23.869h13.328v2.813H10.258zM10.258 29.147h13.328v2.813H10.258z"
        />
        <path
          d="M43.125 17.837v-.041h-.049l-9.23-7.913-.08-.052-.031.039-6.643.019 2.741 2.35h1.292v5.898a2.456 2.456 0 002.453 2.452h6.75v19.862h-11.4l-.023.013a4.11 4.11 0 01-2.089.563h-8.34l.027.114c.28 1.197 1.312 2.096 2.379 2.096h19.805a2.456 2.456 0 002.438-2.445V17.89l.02-.033-.02-.02zm-9.214-.041v-4.097l4.778 4.097H33.91z"
          fill="#1D1D1B"
          fillRule="nonzero"
        />
        <path
          d="M29.278 13.688v-.042h-.049L20 5.734l-.079-.053-.032.038-12.864.037a2.462 2.462 0 00-2.45 2.457v28.284c.055 1.245 1.087 2.595 2.45 2.595h19.8a2.456 2.456 0 002.454-2.453V13.736l.02-.034-.02-.014zm-9.215-.046V9.546l4.777 4.097h-4.777zm-.342 2.794h6.76v19.862H7.369V8.548l9.902-.03v5.466a2.456 2.456 0 002.452 2.452h-.002z"
          fill="#1D1D1B"
          fillRule="nonzero"
        />
        <path d="M0 0h47.835v47.835H0z" />
      </g>
    </svg>
  );
}

export default SvgDokumente;
