import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import Button from '@eg/elements/Button';
import { NAV_DIRECTIONS } from '../../actions/constants';
import { navigateToPath } from '../../actions/navigation';
import { NAV_DELAY } from '../../helpers/navigation';

const ErgoNavButton = ({
  title,
  variant,
  disabled,
  width,
  size,
  loading,
  colorScheme,
  shouldNavigateAfterClick,
  onClick,
  navigateTo,
  next,
  previous,
  navigation,
  type,
  iconLeft,
  className,
}, { t }) => {
  const handleClick = event => {
    if (onClick) {
      onClick(event);
    }
    if (shouldNavigateAfterClick) {
      navigateTo(previous ? NAV_DIRECTIONS.PREVIOUS : NAV_DIRECTIONS.NEXT);
    }
  };

  const getLabel = () => {
    if (title) return t(title);
    if (next) return t('button.next');
    if (previous) return t('button.prev');
    if (navigation.summaryPage) return t('button.back-to-summary');
    return t('button.ok');
  };

  return (
    <Button
      className={className}
      type={type}
      colorScheme={colorScheme}
      loading={loading}
      disabled={disabled}
      variant={variant}
      width={width}
      size={size}
      iconLeft={iconLeft}
      onClick={debounce(handleClick, NAV_DELAY)}
    >
      {getLabel()}
    </Button>
  );
};

const mapStateToProps = ({ navigation }) => ({ navigation });
const mapDispatchToProps = dispatch => ({
  navigateTo: direction => dispatch(navigateToPath(direction)),
});

ErgoNavButton.propTypes = {
  navigation: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  navigateTo: PropTypes.func.isRequired,
  title: PropTypes.string,
  variant: PropTypes.oneOf(
    ['primary', 'secondary', 'tertiary', 'disabled', 'text-link']),
  previous: PropTypes.bool,
  next: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  width: PropTypes.oneOf(['auto', 'stretch', 'fix']),
  size: PropTypes.oneOf(['normal', 'large', 'auto']),
  colorScheme: PropTypes.oneOf(['regular', 'inverted']),
  shouldNavigateAfterClick: PropTypes.bool,
  iconLeft: PropTypes.func,
  type: PropTypes.oneOf(['submit', 'button']),
  onClick: PropTypes.func,
  className: PropTypes.string,
};

ErgoNavButton.defaultProps = {
  title: '',
  variant: 'primary',
  previous: false,
  next: false,
  loading: false,
  disabled: false,
  width: 'stretch',
  size: 'large',
  colorScheme: 'regular',
  shouldNavigateAfterClick: true,
  type: 'submit',
  iconLeft: () => null,
  onClick: () => {},
  className: '',
};

ErgoNavButton.contextTypes = {
  t: PropTypes.func,
};

export { ErgoNavButton as ErgoNavButtonPlain };
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ErgoNavButton);
