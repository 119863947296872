/* eslint-disable max-len */
import React from 'react';

function SvgHaus16(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g id="ERGO_Icon_black_Haus16" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(4.125000, 6.562500)">
          <polygon id="Path" fill="#1D1D1B" fillRule="nonzero" points="25.906875 13.899375 25.906875 9.399375 21.406875 9.399375 21.406875 4.899375 16.906875 4.899375 16.906875 0.035625 0.10875 10.83375 1.52625 13.040625 14.285625 4.8375 14.285625 7.5225 18.785625 7.5225 18.785625 12.020625 23.28375 12.020625 23.28375 16.520625 27.78375 16.520625 27.78375 21.020625 27.969375 21.020625 30.219375 21.020625 30.405 21.020625 30.405 13.899375" />
          <path d="M14.47125,19.783125 C15.1329562,19.783125 15.669375,19.2467062 15.669375,18.585 L15.669375,12.96 C15.669375,12.2982938 15.1329562,11.761875 14.47125,11.761875 L9.97125,11.761875 C9.30997196,11.7629074 8.77415744,12.298722 8.773125,12.96 L8.773125,18.585 C8.77415744,19.246278 9.30997196,19.7820926 9.97125,19.783125 L14.47125,19.783125 Z M11.17125,17.386875 L11.17125,14.16 L13.273125,14.16 L13.273125,17.386875 L11.17125,17.386875 Z" id="Shape" fill="#1D1D1B" fillRule="nonzero" />
          <path d="M19.2225,21.774375 L14.22,21.774375 C13.0147625,21.7754069 12.0376919,22.7516388 12.035625,23.956875 L12.035625,29.6475 L5.660625,29.6475 L5.660625,12.615 L3.035625,14.3025 L3.035625,30.088125 C3.03665688,31.2933625 4.01288879,32.2704331 5.218125,32.2725 L12.474375,32.2725 C13.6796112,32.2704331 14.6558431,31.2933625 14.656875,30.088125 L14.656875,24.395625 L18.781875,24.395625 L18.781875,30.08625 C18.7829069,31.2914875 19.7591388,32.2685581 20.964375,32.270625 L21.403125,32.270625 L21.403125,23.956875 C21.4010642,22.7530997 20.4262731,21.7774704 19.2225,21.774375 Z" id="Path" fill="#1D1D1B" fillRule="nonzero" />
          <rect id="Rectangle" fill="#1D1D1B" fillRule="nonzero" x="23.47125" y="29.835" width="6.75" height="4.5" />
          <rect id="Rectangle" fill="#1D1D1B" fillRule="nonzero" x="32.469375" y="29.835" width="6.75" height="4.5" />
          <rect id="Rectangle" fill="#1D1D1B" fillRule="nonzero" x="27.969375" y="23.085" width="6.75" height="4.5" />
          <rect id="Rectangle" x="15.375" y="12.9375" width="9" height="9" />
        </g>
        <rect id="Rectangle" x="0" y="0" width="48" height="48" />
      </g>
    </svg>
  );
}

export default SvgHaus16;
