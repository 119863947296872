/* eslint-disable max-len */
import React from 'react';

function SvgWohnmobil(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M25.14 18.556a1.318 1.318 0 000-2.635h-5.835v13.93h2.636V18.555h3.2zM13.847 29.466a3.576 3.576 0 100 7.153 3.576 3.576 0 000-7.153zm.94 3.577a.941.941 0 11-.94-.941.941.941 0 01.937.945l.004-.004zM35.304 29.466a3.576 3.576 0 100 7.153 3.576 3.576 0 000-7.153zm.94 3.577a.941.941 0 11-.94-.941.941.941 0 01.937.945l.004-.004zM16.105 18.556a1.318 1.318 0 000-2.635H8.011v11.67h8.094a1.318 1.318 0 000-2.635h-5.458v-6.4h5.458z"
          fill="#1D1D1B"
          fillRule="nonzero"
        />
        <path
          d="M44.286 15.569L42.84 12.68a2.31 2.31 0 00-2.07-1.285H5.815a2.327 2.327 0 00-2.323 2.324v18.323a2.327 2.327 0 002.323 2.325h2.733l-.045-.232a5.504 5.504 0 010-2.181l.047-.23H6.135V14.031h34.432l.94 1.882h-2.166l.409.341c.79.662 1.503 1.41 2.129 2.229l.056.073h1.333a1.26 1.26 0 001.257-1.257v-.69a2.35 2.35 0 00-.239-1.04zM30.007 31.725H19.145l.047.23c.146.72.146 1.462 0 2.181l-.047.232h10.862l-.047-.232a5.504 5.504 0 010-2.181l.047-.23z"
          fill="#1D1D1B"
          fillRule="nonzero"
        />
        <path
          d="M34.176 15.913h-5.835v13.93h2.635v-2.259h5.459a1.318 1.318 0 100-2.635h-5.459v-6.4h3.2c1.623 0 3.484 1.468 4.98 3.926 1.548 2.543 2.502 5.819 2.694 9.243h-1.248l.047.23c.146.72.146 1.461 0 2.18l-.047.232h3.929v-1.317c0-4.395-1.107-8.635-3.115-11.936-2.009-3.302-4.65-5.194-7.24-5.194z"
          fill="#1D1D1B"
          fillRule="nonzero"
        />
        <path d="M0 0h48.023v48.023H0z" />
      </g>
    </svg>
  );
}

export default SvgWohnmobil;
