/* eslint-disable max-len */
import React from 'react';

function SvgHand6(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h48v48H0z" />
        <g fill="#1D1D1B" fillRule="nonzero">
          <path d="M16.845 12.047c.289-1.328 1.155-2.164 2.524-2.164a2.749 2.749 0 011.603.456c.163.105.144.187-.026.457l-.1.156c-.187.302-.225.319-.315.266a2.004 2.004 0 00-1.082-.31c-.648 0-1.09.396-1.28 1.139h1.47c.09 0 .125 0 .125.386 0 .375-.035.388-.125.388h-1.577a2.527 2.527 0 000 .266 2.184 2.184 0 000 .252h1.577c.09 0 .125 0 .125.388 0 .375-.035.388-.125.388H18.16c.188.697.604 1.095 1.324 1.095a2.25 2.25 0 001.091-.268c.116-.06.17-.043.315.25l.08.164c.136.266.155.336 0 .431a3.165 3.165 0 01-1.687.456c-1.423 0-2.216-.784-2.467-2.128h-.593c-.09 0-.127-.01-.127-.388 0-.375.037-.388.127-.388h.514v-.214-.302h-.514c-.09 0-.127-.01-.127-.388 0-.375.037-.386.127-.386l.621-.002zM40.725 23.419h-3.14a2.38 2.38 0 00-2.354 2.062l-2.638-1.875a1.324 1.324 0 00-.71-.24l-8.86-.333h-.077a6.718 6.718 0 00-2.525.63c-1.75.843-2.764 2.4-2.854 4.387a1.312 1.312 0 001.312 1.372h6.938v-2.625h-5.192c.684-.986 1.978-1.125 2.353-1.125l8.42.317 3.81 2.698v3.6l-.955-.894a1.313 1.313 0 00-1.172-.324l-11.053 2.364L8.11 23.828c1.36-1.01 2.284-.563 2.458-.458.022.016.046.03.07.043l5.267 3.154 1.35-2.25-5.241-3.13a4.164 4.164 0 00-2.239-.618H9.75c-1.147 0-2.895.457-4.708 2.587a1.313 1.313 0 00.253 1.931L21 35.914c.297.205.666.28 1.02.204l10.937-2.34 2.25 2.104v3.433a1.313 1.313 0 002.625 0V26.044h2.642v13.29a1.312 1.312 0 002.625 0v-13.54a2.378 2.378 0 00-2.374-2.375zM29.306 15.14a2.824 2.824 0 100 5.648 2.824 2.824 0 000-5.647zm1.296 2.824a1.296 1.296 0 11-2.591.004 1.296 1.296 0 012.59-.004z" />
          <path d="M34.528 11.214V8.782a1.644 1.644 0 00-1.642-1.642H14.554a1.644 1.644 0 00-1.643 1.642v8.768a1.644 1.644 0 001.643 1.642h11.023l-.019-.06a3.964 3.964 0 01-.176-1.168c0-.135 0-.272.02-.408v-.051H14.602V8.829h18.242v2.385a4.001 4.001 0 00-3 2.813l-.015.05h.054c.53.078 1.037.265 1.49.548l.057.034.015-.062a2.342 2.342 0 111.817 2.86l-.062-.014v.064a3.938 3.938 0 01-.137 1.579l-.03.1h.071c.04 0 .086-.01.126-.018.16.021.32.032.482.032a4.033 4.033 0 00.811-7.978l.006-.008z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgHand6;
