/* eslint-disable max-len */
import React from 'react';

function SvgHaende1(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 48 48" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M42.915 15.176l1.875 1.875-7.256 7.257-1.875-1.875 7.256-7.257zm-28.852 9.15a3.684 3.684 0 014.918.22 3.643 3.643 0 01.963 3.375c.521.175.995.468 1.384.857.962.97 1.3 2.396.878 3.695l7.074 7.074c.204.205.481.32.77.321a.937.937 0 00.959-.97 1.09 1.09 0 00-.32-.76l-5.365-5.365 1.875-1.875 5.364 5.364c.205.206.483.322.773.321a.937.937 0 00.958-.97 1.09 1.09 0 00-.32-.76l-5.365-5.365 1.875-1.875 5.364 5.365c.205.205.483.32.773.32a.964.964 0 00.684-.274c.38-.418.36-1.063-.047-1.456L22.974 17.265l-2.062 2.063a3.75 3.75 0 01-2.655 1.11 3.604 3.604 0 01-2.563-1.056 3.69 3.69 0 01.056-5.218l7.224-7.226a3.326 3.326 0 014.7 0l1.874 1.875 3.495-3.495 1.875 1.875-4.355 4.357c-.562.56-1.472.56-2.033 0l-2.736-2.737a.666.666 0 00-.937 0l-7.232 7.23a1 1 0 101.41 1.41l5.818-5.828 1.875 1.875-1.875 1.875 14.293 14.293a3.698 3.698 0 01.047 5.215c-.603.601-1.4.97-2.25 1.038a3.581 3.581 0 01-3.289 3.287 3.589 3.589 0 01-3.601 3.304 3.75 3.75 0 01-2.652-1.099l-6.776-6.767-3.476 3.477c-.2.199-.315.467-.32.75-.009.26.09.513.273.699.418.38 1.064.36 1.457-.047l3.476-3.476 1.875 1.875-3.472 3.487a3.75 3.75 0 01-2.65 1.103 3.615 3.615 0 01-2.565-1.056 3.58 3.58 0 01-1.04-2.25 3.594 3.594 0 01-3.29-3.289 3.577 3.577 0 01-2.25-1.038 3.692 3.692 0 01.05-5.215l5.37-5.34zm-3.75 0L3.04 17.062l1.875-1.875 7.271 7.264-1.874 1.875zm1.67 8.625l5.08-5.07a1.044 1.044 0 00.046-1.457.958.958 0 00-.682-.271c-.29 0-.567.114-.773.318l-5.08 5.074a1.044 1.044 0 00-.048 1.457c.182.18.429.279.685.275h.015c.282-.005.551-.12.75-.322m7.47-2.302a.962.962 0 00-.685-.272 1.1 1.1 0 00-.772.318l-4.125 4.135a.997.997 0 101.408 1.41l4.125-4.135a1.046 1.046 0 00.047-1.457"
          fill="#1D1D1B"
          fillRule="nonzero"
        />
        <path d="M0 0h47.835v47.835H0z" />
      </g>
    </svg>
  );
}

export default SvgHaende1;
