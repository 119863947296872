/* eslint-disable max-len */
import React from 'react';

function SvgSpinacz(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      style={{
        background: '#fff',
      }}
      {...props}
    >
      <path
        d="M28.028 36.062c-3.806 3.83-10.007 3.83-13.813 0-3.805-3.83-3.805-10.069 0-13.898L26.647 9.656a5.834 5.834 0 018.287 0 5.87 5.87 0 011.72 4.17 5.87 5.87 0 01-1.72 4.169L23.19 29.803c-.763.768-2 .768-2.763 0a1.974 1.974 0 010-2.78l7.032-7.075-2.763-2.78-7.031 7.076c-2.29 2.303-2.29 6.045 0 8.339a5.832 5.832 0 004.143 1.72 5.832 5.832 0 004.144-1.72l11.746-11.818a9.793 9.793 0 002.859-6.95 9.793 9.793 0 00-2.86-6.948c-3.805-3.83-10.006-3.83-13.812 0L11.443 19.375c-5.332 5.365-5.332 14.092 0 19.467a13.597 13.597 0 009.669 4.023c3.497 0 7.003-1.34 9.669-4.023l7.717-7.766-2.762-2.78-7.708 7.766z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  );
}

export default SvgSpinacz;
