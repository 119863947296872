import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose, lifecycle } from 'recompose';
import { types } from '../actions/constants';
import { NAV_DELAY } from '../helpers/navigation';

const withNavigation = lifecycle({
  componentDidMount() {
    this.props.updateNavigation(this.props.match);
  },
  componentDidUpdate() {
    this.props.updateNavigation(this.props.match);
  },
});

const mapDispatchToProps = dispatch => ({
  updateNavigation: match => dispatch({
    type: types.NAVIGATION_UPDATE_START,
    delay: NAV_DELAY,
    match,
  }),
});

export default compose(
  connect(null, mapDispatchToProps),
  withRouter,
  withNavigation,
);
