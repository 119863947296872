/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
import React from 'react';

function SvgOffice(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 255.12 255.12" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#000"
          fillRule="nonzero"
          d="M163.1 99.31h14.17v28.19H163.1zM134.94 99.31h14.17v28.19h-14.17zM106.6 99.31h14.17v28.19H106.6zM122.76 49.86h14.17v14.38h-14.17zM93.68 49.86h14.17v14.38H93.68zM150.76 49.86h14.17v14.38h-14.17zM78.16 99.31h14.17v28.19H78.16zM163.1 141.81h14.17V170H163.1zM134.94 141.81h14.17V170h-14.17zM106.6 141.81h14.17V170H106.6zM78.16 141.81h14.17V170H78.16zM163.1 184.3h14.17v28.19H163.1zM78.16 184.3h14.17v28.19H78.16z"
        />
        <path
          fill="#000"
          fillRule="nonzero"
          d="M219.5 226.92h-14V83.07c0-6.32-5.25-11.5-11.87-11.93v-30a12.76 12.76 0 00-12.75-12.75H76.33a12.77 12.77 0 00-12.76 12.74v30h-1.19c-7 0-12.75 5.37-12.75 12v143.79h-14v14.17l78.05-.05a7.1 7.1 0 007.08-7.09v-35.54h14.16V234a7.08 7.08 0 007.08 7.09h77.51zM77.74 42.55h101.68V71.1H77.74zM142 184.29l-28.33-.07a7.1 7.1 0 00-7.09 7.08v35.58H63.82V85.27h127.47v141.65h-42.22v-35.55a7.08 7.08 0 00-7.07-7.08z"
        />
      </g>
    </svg>
  );
}

export default SvgOffice;
