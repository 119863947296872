/* eslint-disable max-len */
import React from 'react';

function SvgVerwalter(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 100 100"
      {...props}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g>
          <path
            fill="#1D1D1B"
            fillRule="nonzero"
            d="M75.517 24.067l-23.15-10.771a5.605 5.605 0 00-4.891.074L29.32 22.523l2.528 5.005 15.35-7.733v60.661C40.764 76.76 26.85 64.59 26.85 52.406V30.065l-2.548-5.017a5.597 5.597 0 00-3.073 4.998v22.342c0 9.768 6.527 18.446 11.999 24.012.435.44 10.708 10.807 16.76 10.807 6.052 0 16.326-10.368 16.757-10.807 5.488-5.566 12.002-14.244 12.002-24.012V29.155a5.613 5.613 0 00-3.23-5.088zm-2.351 28.34c0 12.182-13.908 24.353-20.348 28.05V19.7l20.348 9.47v23.236z"
          />
          <path d="M0 0H100V100H0z" />
        </g>
      </g>
    </svg>
  );
}


export default SvgVerwalter;
