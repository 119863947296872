const styles = theme => ({
  svg: {
    display: 'inline-block',
    fill: theme.primaryFontColor,
    width: theme.iconSize,
    height: theme.iconSize,
    transition: theme.hoverTransition,
  },
});

export default styles;
